import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbTextAreaModule } from 'rev-shared/ui/textArea/VbTextArea.Module';
import { WebexTeamsAngularModule } from 'rev-portal/scheduledEvents/webex-teams/WebexTeamsAngular.Module';
import { ZoomAngularModule } from 'rev-portal/scheduledEvents/zoom/ZoomAngularModule';

import { RecordingMenuComponent } from './RecordingMenu.Component';

@NgModule({
	declarations: [
		RecordingMenuComponent,
	],
	exports: [
		RecordingMenuComponent
	],
	imports: [
		ButtonsAngularModule,
		CommonModule,
		FormGroupAngularModule,
		FormsModule,
		ReactiveFormsModule,
		SpinnerAngularModule,
		TranslateModule,
		TypeaheadModule,
		ValidationModule,
		VbTextAreaModule,
		WebexTeamsAngularModule,
		ZoomAngularModule
	]
})
export class RecordingAngularModule {}
