import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmChartsCoreModule } from 'rev-shared/ui/dataVisualization/amChartsCore/AmChartsCore.Module';

import { VbUiDurationLineChartComponent } from './VbUiDurationLineChart.Component';
import { VbUiLineChartComponent } from './VbUiLineChart.Component';
import { VbUiLineChartSmallComponent } from './VbUiLineChartSmall.Component';

const components = [
	VbUiDurationLineChartComponent,
	VbUiLineChartComponent,
	VbUiLineChartSmallComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		CommonModule,
		AmChartsCoreModule
	]
})
export class LineChartModule {}
