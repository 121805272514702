import {
	AfterViewInit,
	Directive,
	Input
} from '@vbrick/angular-ts-decorators';

import { INgModelController } from 'angular';

import {
	IPAddress,
	IPRange
} from 'rev-shared/util/IPAddress.Service';

export type IpInputModelValue = IPRange[];
export type IpInputGroupingModelValue = IpInputModelValue[];

const GROUP_IP_VALUE_SEPARATOR: string = ';';
const IP_RANGE_SEPARATOR: string = '-';
const NEW_LINE: string = '\n';

/**
 * vb-bulk-ip-input
 * This directive is intended to be applied to a textarea. It augments the ngModel so that the field may support the input of multiple IP addresses or ranges.
 * The default mode allows for 1 IP address or range per line.
 * When the ip-groupings input is set to "true", each line represents a grouping of IP addresses and ranges separated by a semicolon (;).
 * An invalidIPAddresses validator is applied to the ngModel.
 */
@Directive({
	selector: '[vb-bulk-ip-input]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbBulkIpInputDirective implements AfterViewInit {
	@Input() public ipGroupings: boolean;
	@Input() public isMulticast: boolean;

	private ngModel: INgModelController;

	constructor(
		private IPAddress: IPAddress
	) {
		'ngInject';
	}

	public ngAfterViewInit(): void {
		this.ngModel.$formatters = [modelValue => this.format(modelValue)];
		this.ngModel.$parsers.push(viewValue => this.parse(viewValue));
		this.ngModel.$validators.invalidIPAddresses = modelValue => this.validate(modelValue);
	}

	private format(modelValue: IpInputModelValue | IpInputGroupingModelValue): string {
		if (!this.validate(modelValue)) {
			return '';
		}

		return this.ipGroupings ?
			this.formatGroupings(modelValue as IpInputGroupingModelValue) :
			this.formatSingleInputs(modelValue as IpInputModelValue);
	}

	private formatGroupings(modelValue: IpInputGroupingModelValue): string {
		return (modelValue || [])
			.map(grouping => this.formatSingleInputs(grouping, GROUP_IP_VALUE_SEPARATOR))
			.join(NEW_LINE);
	}

	private formatSingleInputs(modelValue: IpInputModelValue, join: string = NEW_LINE): string {
		return (modelValue || [])
			.map(entry => entry.end ?
				entry.start + IP_RANGE_SEPARATOR + entry.end :
				entry.start
			)
			.join(join);
	}

	private parse(textInput: string): IpInputModelValue | IpInputGroupingModelValue {
		const lines = (textInput || '')
			.split(NEW_LINE)
			.map(line => line.trim())
			.filter(line => !!line);

		const result = this.ipGroupings ?
			this.parseGroupings(lines) :
			this.parseSingleInputs(lines);

		if (!result.length) {
			//returing empty array to avoid parse error.
			//parse error making form invalid
			return [];
		}

		return result;
	}

	private parseGroupings(lines: string[]): IpInputGroupingModelValue {
		return lines
			.map(line => line.split(GROUP_IP_VALUE_SEPARATOR))
			.map(grouping => this.parseSingleInputs(grouping));
	}

	private parseSingleInputs(inputs: string[]): IpInputModelValue {
		return inputs
			.map(line => line
				.split(IP_RANGE_SEPARATOR)
				.map(token => token.trim())
			)
			.map(([start, end]) => start ? { start, end: end || null } : null);
	}

	private validate(modelValue: IpInputModelValue | IpInputGroupingModelValue): boolean {
		if (!modelValue) {
			return false;
		}

		const groupings: IpInputGroupingModelValue = this.ipGroupings ?
			modelValue as IpInputGroupingModelValue :
			[modelValue as IpInputModelValue];

		return groupings
			.every(grouping => this.validateSingleInputs(grouping));
	}

	private validateSingleInputs(inputs: IpInputModelValue): boolean {
		return inputs
			.every(entry => {
				if (!entry || !entry.start) {
					return false;
				}

				return this.isMulticast ? this.validateMulticastIP(entry) : this.validateIP(entry);
			});
	}

	private validateIP(range: IPRange): boolean {
		return range.end ?
			this.IPAddress.validateRange(range) :
			this.IPAddress.validate(range.start);
	}

	private validateMulticastIP(range: IPRange): boolean {
		return range.end ?
			this.IPAddress.validateIPv4RangeMulticast(range) :
			this.IPAddress.validateIPv4Multicast(range.start);
	}
}
