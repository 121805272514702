import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { AccentHeaderAngularModule } from 'rev-shared/ui/accentHeader/AccentHeader.AngularModule';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { FlickityCarouselModule } from 'rev-shared/ui/flickityCarousel/FlickityCarousel.AngularModule';
import { ReportsServiceDownMsgModule } from 'rev-shared/ui/reports/reportsDown/ReportsServiceDownMsg.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { VBrickSharedPublicVideoPasswordModule } from 'rev-shared/media/publicVideoPassword/PublicVideoPassword.Module';
import { VbUiDatePickerModule } from 'rev-shared/ui/datePicker/VbUiDatePicker.Module';

import { MainHeaderAngularModule } from 'rev-portal/navigation/mainHeader/MainHeader.AngularModule';

import { VideoReportsModule } from '../videoReports/VideoReports.Module';

import { VideoPlaybackOverlayDialogComponent } from './VideoPlaybackOverlayDialog.Component';
import { VideoPlaybackReportsComponent } from './VideoPlaybackReports.Component';

import { VideoPlaybackReportsService } from './VideoPlaybackReports.Service';
import { VideoTextStatusAngularModule } from '../videoTextStatus/VideoTextStatus.AngularModule';

const components = [
	VideoPlaybackOverlayDialogComponent,
	VideoPlaybackReportsComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	imports: [
		AccentHeaderAngularModule,
		ButtonsAngularModule,
		CommonModule,
		FlickityCarouselModule,
		FormsModule,
		MainHeaderAngularModule,
		ReportsServiceDownMsgModule,
		SpinnerAngularModule,
		TooltipModule,
		TranslateModule,
		VBrickSharedPublicVideoPasswordModule,
		VideoReportsModule,
		VideoTextStatusAngularModule,
		VbUiDatePickerModule
	],
	providers: [
		VideoPlaybackReportsService
	]
})
export class VideoPlaybackAngularModule {}
