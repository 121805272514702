<div dropdown container="body" [ngClass]="styles.menuWrapper">
	<button type="button" dropdownToggle vbUiBtnSecondary [ngClass]="styles.actionButton">
		<span *ngIf="actionBtnInfo?.label else ellipsis">
			{{ actionBtnInfo.label }}
		</span>

		<ng-template #ellipsis>
			<span class="more-icon" [ngClass]="styles.moreIcon">
				<span class="circle"></span>
				<span class="circle"></span>
				<span class="circle"></span>
			</span>
		</ng-template>
	</button>

	<div *dropdownMenu [ngClass]="[
			styles.menuItemWrapper,
			menuWrapperCssClass || '']">
		<a *ngFor="let menuItem of menuItemsInfo" [ngClass]="styles.menuItem" (click)="onClick(menuItem)">
			{{ menuItem.label || (menuItem.labelKey | translate) }}
		</a>
	</div>
</div>