export const CsrfTokenCookie: string = 'csrfToken';
export const CsrfTokenHeader: string = 'Csrf-Token';
export const CsrtTokenQueryParam: string = 'csrfToken';

import { LegacyCookieSuffix, getCookie } from 'rev-shared/util/CookieUtil';

export function getXsrfCookieName(): string {
	const useFallback = !getCookie(CsrfTokenCookie);

	return useFallback ?
		CsrfTokenCookie + LegacyCookieSuffix :
		CsrfTokenCookie;
}
