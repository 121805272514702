import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { SelectAngularModule } from 'rev-shared/ui/select/SelectAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { VbUiLanguageTagsInputModule } from 'rev-shared/ui/ngTagsInput/languageTagsInput/VbUiLanguageTagsInput.Module';

import { LiveSubtitlesComponent } from './LiveSubtitles.Component';

const components = [
	LiveSubtitlesComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		ButtonsAngularModule,
		CommonModule,
		FormsModule,
		SelectAngularModule,
		TranslateModule,
		ValidationAngularModule,
		VbUiLanguageTagsInputModule
	]
})
export class LiveSubtitlesModule {}
