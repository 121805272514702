import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UIRouterModule } from '@uirouter/angular';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { PushModule } from 'rev-shared/push/Push.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbUiInsightModule } from 'rev-shared/ui/insight/VbUiInsight.Module';

import { SearchAngularModule } from 'rev-portal/search/Search.AngularModule';

import { AdminCategoryService } from './AdminCategory.Service';
import { EditCategoryComponent } from './EditCategory.Component';

@NgModule({
	declarations: [
		EditCategoryComponent
	],
	imports: [
		ButtonsAngularModule,
		CheckboxAngularModule,
		DialogAngularModule,
		DirectivesAngularModule,
		CommonModule,
		FormsModule,
		SearchAngularModule,
		FormGroupAngularModule,
		HttpClientModule,
		PushModule,
		NgxTrimDirectiveModule,
		SpinnerAngularModule,
		TooltipModule,
		TranslateModule,
		ValidationModule,
		ValidationAngularModule,
		VbUiInsightModule,
		UIRouterModule.forChild()
	],
	providers: [
		AdminCategoryService
	]
})
export class SharedCategoryModule{}
