import {
	Directive,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAttributes,
	IAugmentedJQuery,
	element as angularElement
} from 'angular';

/**
 * Apply this directive to a scrollable container and it will make your Bootstrap dropdown components
 * smarter about rendering within the visible region (vertically) by conditionally applying the "dropup" class.
 */
@Directive({
	selector: '[vb-scroll-container-dropup]',
	bindToController: true
})
export class VbScrollContainerDropupDirective implements OnInit {
	//Height of the dropdown-menu that will be displayed.
	@Input() public scrollContainerMenuHeight: number;

	//Element inside the scrollable region that is a parent of the dropdown and may be measured to determine
	//the present position of our dropdown toggle relative to the region.
	private scrollContainerOffsetChildSelector: string;

	constructor(
		private $attrs: IAttributes,
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.scrollContainerOffsetChildSelector = this.$attrs.scrollContainerOffsetChildSelector;

		if (!this.scrollContainerOffsetChildSelector) {
			throw Error('vb-scroll-container-dropup: scrollContainerOffsetChildSelector attribute is required!');
		}

		const $scrollContainer = this.$element;

		$scrollContainer.on('click', '.dropdown-toggle', event => {
			//elements
			const $target = angularElement(event.target);
			const $offsetChild = $target.parents(this.scrollContainerOffsetChildSelector);
			const $dropdown = $target.parents('.dropdown');

			//measurements
			const scrollContainerOffsetTop = $scrollContainer.offset().top;
			const scrollContainerHeight = $scrollContainer.height();
			const rowElementOffsetTop = $offsetChild.offset().top;

			//toggle the dropup class appropriately
			const useDropup = rowElementOffsetTop > (scrollContainerOffsetTop + scrollContainerHeight - this.scrollContainerMenuHeight);

			$dropdown.toggleClass('dropup', useDropup);
		});
	}
}
