import { NgModule } from '@vbrick/angular-ts-decorators';

import { DisableNgAnimateDirective } from './DisableNgAnimate.Directive';
import { ProhibitPublicAccessValidatorDirective } from './ProhibitPublicAccessValidator.Directive';
import { VbBulkIpInputDirective } from './VbBulkIpInput.Directive';
import { VbHiddenDirective } from './VbHidden.Directive';
import { VbImageRetryDirective } from './VbImageRetry.Directive';
import { VbLoadingBarComponent } from './VbLoadingBar.Component';
import { VbScheduleDigestDirective } from './VbScheduleDigest';
import { VbScrollContainerDropupDirective } from './VbScrollContainerDropup.Directive';
import { VbSelectOnClickDirective } from './VbSelectOnClick.Directive';
import { VbSortColumnDirective } from './VbSortColumn.Directive';
import { VbStretchDirective } from './VbStretch.Directive';
import { VbTailDirective } from './VbTail.Directive';
import { VbTitleComponent } from './VbTitle.Component';
import { VbVisibleDirective } from './VbVisible.Directive';
import { WatchDimensionsDirective } from './WatchDimensions.Directive';

import { downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.Util.Directives.New', // temporary
	declarations: [
		DisableNgAnimateDirective,
		ProhibitPublicAccessValidatorDirective,
		VbBulkIpInputDirective,
		VbHiddenDirective,
		VbImageRetryDirective,
		VbScheduleDigestDirective,
		VbScrollContainerDropupDirective,
		VbSelectOnClickDirective,
		VbSortColumnDirective,
		VbStretchDirective,
		VbTailDirective,
		VbVisibleDirective,
		WatchDimensionsDirective
	]
})
export default class VBrickUtilDirectivesModule {}

downgradeComponents(VBrickUtilDirectivesModule, [
	{
		component: VbTitleComponent,
		name: 'vbTitle'
	},
	{
		component: VbLoadingBarComponent,
		name: 'vbLoadingBar'
	}
]);
