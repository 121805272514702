import { debounce } from 'underscore';
import {
	Directive,
	DoCheck,
	OnInit,
	HostListener,
	ElementRef,
	Renderer2
} from '@angular/core';

/**
	Stretches an element to the bottom of the screen.
	Include the "vb-stretch-min-height" attribute  to set the min-height style, instead of height.
	attribute: bottom-anchor:  the gap to leave at the bottom of the element, in pixels. Default is 25 pixels.

	Example:
	<div vb-stretch vb-stretch-min-height bottom-anchor="25">...</div>
**/
@Directive({
	selector: '[vb-ui-stretch]',
})
export class VbUiStretchDirective implements OnInit, DoCheck {
	private bottomAnchor: string;
	private hasVbStretchMinHeight: boolean;
	private nativeElement: HTMLElement;
	private onResizeHandler: () => void;

	constructor(
		element: ElementRef,
		private renderer: Renderer2
	) {
		this.nativeElement = element.nativeElement;
	}

	public ngOnInit(): void {
		this.bottomAnchor = this.nativeElement.getAttribute('bottomAnchor');
		this.hasVbStretchMinHeight = !!this.nativeElement.getAttribute('vbStretchMinHeight');
		this.onResizeHandler = debounce(() => this.update(), 100);
		this.update();
	}

	@HostListener('window:resize')
	public onResize(): void {
		this.onResizeHandler();
	}

	public ngDoCheck(): void {
		this.onResizeHandler();
	}

	private update(): void {
		const viewportHeight = window.innerHeight;
		const top = this.offsetFromDocument( this.nativeElement).top;
		const bottomAnchor = this.bottomAnchor ? +this.bottomAnchor : 10;
		const heightValue = viewportHeight - top - bottomAnchor;

		if (this.hasVbStretchMinHeight) {
			this.renderer.setStyle(this.nativeElement, 'min-height', heightValue + 'px');

		} else {
			/*This condition check is to avoid height value negative scenario which comes intermittently*/
			if (heightValue < 0) {
				setTimeout(() => this.onResizeHandler(), 100, false);
				return;
			}
			this.renderer.setStyle(this.nativeElement, 'height', heightValue + 'px');
		}
	}

	private offsetFromDocument(element: HTMLElement): { top: number; left: number } {
		if (!element.getClientRects().length) {
			return { top: 0, left: 0 };
		}

		const rect = element.getBoundingClientRect();
		const win = element.ownerDocument.defaultView;
		return {
			top: rect.top + win.pageYOffset,
			left: rect.left + win.pageXOffset
		};
	}
}
