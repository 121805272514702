import {
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	LOCALE_ID,
	NgZone,
	OnDestroy,
	OnInit,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';

import type { default as AmChartsCore, Circle, Color, Component as AmChartsComponent, Sprite } from '@amcharts/amcharts4/core';

import { TranslateService } from '@ngx-translate/core';

import { noop } from 'rev-shared/util';

import styles from './VbUiAmChartsCore.Component.module.less';

export interface IOnChartReadyData {
	chart: AmChartsComponent;
}

const CHART_THEME_COLOR_PALETTE: string[] = ['#00a0f0', '#ee186f', '#b8a412', '#10719e', '#d12b1f'];
const TOOLTIP_BG_COLOR: string = '#000';
const TOOLTIP_TEXT_COLOR: string = '#fff';

@Component({
	selector: 'vb-ui-am-charts-core',
	templateUrl: './VbUiAmChartsCore.Component.html',
	host: {
		'[class]': 'styles.rootElement'
	}
})
export class VbUiAmChartsCoreComponent implements OnDestroy, OnChanges, OnInit {
	@Input() public chartConfig: any;
	@Input() public chartType: typeof Sprite;
	@Input() public data: any[];
	@Input() public updateEnabled: boolean; // data changes are treated as updates (as opposed to replace) to facilitate series animation

	@Output() public onChartReady = new EventEmitter<IOnChartReadyData>();

	public chart: AmChartsComponent;
	public readonly styles = styles;

	private am4Core: typeof AmChartsCore;
	@ViewChild('chartOutput', { static: true }) private chartOutput: ElementRef<HTMLElement>;
	private dataInternal: any[];
	private tooltipCircle: Circle;

	constructor(
		@Inject(LOCALE_ID) private localeId: string,
		private translateService: TranslateService,
		private zone: NgZone
	) {}

	public ngOnInit(): void {
		Promise.all<any, any>([ // TODO: temporary workaround for TypeScript 3.7.3 Promise.all bug
			import(/* webpackChunkName: "amChartsCore" */ '@amcharts/amcharts4/core'),
			import(/* webpackChunkName: "amChartsThemeAnimated" */ '@amcharts/amcharts4/themes/animated')
		])
			.then(([am4Core, amChartsAnimatedTheme]) => {
				this.am4Core = am4Core;

				// By design, you may apply more than 1 theme. They may customize different areas.
				am4Core.useTheme(amChartsAnimatedTheme.default);
				am4Core.useTheme(theme => this.applyChartColorTheme(theme));

				// Removes the watermark. Yep...that's all there is to it.
				am4Core.options.commercialLicense = true;

				this.createChart();
			});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.chartType || changes.chartConfig) {
			this.createChart();
		}

		if (changes.data) {
			this.onChartDataChanged();
		}
	}

	public ngOnDestroy(): void {
		if (this.chart) {
			this.chart.dispose();
			this.chart = null;
		}
	}

	/**
	 * Follows one of the amCharts examples for create a simple color palette theme.
	 */
	private applyChartColorTheme(target: any): void {
		if (target instanceof this.am4Core.ColorSet) {
			target.list = CHART_THEME_COLOR_PALETTE.map(color => this.am4Core.color(color));
		}
	}

	private applyTooltipToSeries(seriesConfigEntry: any): any {
		return {
			...seriesConfigEntry,
			tooltip: {
				getFillFromObject: false,
				background: {
					fill: this.am4Core.color(TOOLTIP_BG_COLOR)
				},
				events: {
					shown: event => this.setTooltipCircleColor(event.target.targetSprite.fill)
				},
				label: {
					background: this.tooltipCircle,
					fill: this.am4Core.color(TOOLTIP_TEXT_COLOR),
					paddingLeft: 24
				}
			}
		};
	}

	private createChart(): void {
		if (this.chart || !this.am4Core || !this.chartConfig || !this.chartType) {
			return;
		}

		this.resetChartData();

		this.initTooltipCircle();

		//behavior none to disable zoom..
		const cursor = { behavior: 'none', ...this.chartConfig.cursor };

		const mergedConfig: any = {
			...this.chartConfig,
			cursor,
			data: this.dataInternal,
			language: {
				locale: this.getLocaleConfig()
			},
			series: this.chartConfig.series.map(seriesEntry => this.applyTooltipToSeries(seriesEntry))
		};

		this.zone.runOutsideAngular(() => {
			this.chart = this.am4Core.createFromConfig(mergedConfig, this.chartOutput.nativeElement, this.chartType) as any; // their typings are a bit funky
		});

		this.onChartReady.emit({
			chart: this.chart
		});

		this.zone.run(noop);
	}

	private getLocaleConfig(): any {
		return { // see the AmCharts locale files for label possibilities
			'_decimalSeparator': getLocaleNumberSymbol(this.localeId, NumberSymbol.Decimal),
			'_thousandSeparator': getLocaleNumberSymbol(this.localeId, NumberSymbol.Group),
			'_big_number_suffix_3': this.translateService.instant('Format_Number_Abbreviation_Thousand')
		};
	}

	private initTooltipCircle(): void {
		const tooltipCircle = new this.am4Core.Circle();

		tooltipCircle.radius = 6;
		tooltipCircle.dx = 12;
		tooltipCircle.dy = 9;
		tooltipCircle.verticalCenter = 'top';

		this.tooltipCircle = tooltipCircle;
	}

	private onChartDataChanged(): void {
		if (this.chart) {
			if (this.updateEnabled && this.dataInternal && this.data && this.data.length === this.dataInternal.length) {
				this.updateChartData();
			} else {
				this.resetChartData();
				this.chart.data = this.dataInternal;
			}

			this.zone.run(noop);
		}
	}

	/**
	 * Complete reset of the chart data source. Animation is not supported. This is for a change to overall data as opposed to an update to the existing entries.
	 */
	private resetChartData(): void {
		this.dataInternal = [...(this.data || [])];
	}

	private setTooltipCircleColor(color: Color): void {
		this.tooltipCircle.fill = color;
	}

	/**
	 * If you want animated series transitions, the data entry object references must remain the same.
	 */
	private updateChartData(): void {
		this.dataInternal.forEach((internalEntry, internalEntryIndex) => Object.assign(internalEntry, this.data[internalEntryIndex]));

		this.chart.invalidateRawData();
	}
}
