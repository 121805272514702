import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { VideoTextStatusAngularModule } from 'rev-portal/media/videos/videoTextStatus/VideoTextStatus.AngularModule';
import { VideoThumbnailStatusAngularModule } from 'rev-portal/media/videos/videoThumbnailStatus/VideoThumbnailStatus.AngularModule';

import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { VbProfilePictureModule } from 'rev-shared/ui/profile/VbProfilePicture.Module';
import { Video360IndicatorModule } from 'rev-shared/media/video360Indicator/Video360Indicator.AngularModule';

import { VideoTileComponent } from './VideoTile.Component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { FormsModule } from '@angular/forms';

const entryComponents = [VideoTileComponent];
@NgModule({
	declarations: entryComponents,
	entryComponents,
	exports: entryComponents,
	imports: [
		VideoTextStatusAngularModule,
		VideoThumbnailStatusAngularModule,
		CommonModule,
		TranslateModule,
		UIRouterModule,
		VbProfilePictureModule,
		Video360IndicatorModule,
		DateAngularModule,
		RatingModule,
		FormsModule
	]
})
export class VideoTileAngularModule{}
