import { APP_BOOTSTRAP_LISTENER, ModuleWithProviders, NgModule, NgZone } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import 'signalr/jquery.signalR';

import SignalrRun from './Signalr.Run';
import { PushBus } from './PushBus.Service';
import { PushHubToken } from './PushHubToken';
import { PushService, PushServiceConfigToken } from './PushService';
import { SignalRHubsConnection } from './SignalRHubsConnection';
import { SignalRHubsConnectionToken } from './SignalRHubsConnectionToken';
import { SignalRStartupToken } from './SignalRStartupToken';
import { SignalrConnectionStatusComponent } from './SignalRConnectionStatus.Component';

const hubsConnection: SignalRHubsConnection = new SignalRHubsConnection(
	'/push/signalr',
	{
		useDefaultPath: false,
		autoReconnect: true,
		logging: false
	},
	{
		push: {
			clientMethods: [
				'routeMessage',
				'routeBatchMessages'
			],
			serverMethods: [
				'dispatchCommand',
				'subscribe',
				'unsubscribe',
				'dispatchCommandOnDisconnect',
				'tryCancelDisconnectCommand',
				'publishEvent'
			]
		}
	}
);

SignalrRun(hubsConnection.startupPromise, hubsConnection);

const components = [
	SignalrConnectionStatusComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		HttpClientModule,
		TranslateModule
	],
	providers: [
		{
			provide: APP_BOOTSTRAP_LISTENER,
			deps: [NgZone],
			useFactory: onAppBootstrap,
			multi: true
		},
		{ provide: PushHubToken, useValue: hubsConnection.getProxy('push') },
		{ provide: SignalRHubsConnectionToken, useValue: hubsConnection },
		{ provide: SignalRStartupToken, useValue: hubsConnection.startupPromise }
	]
})
export class PushModule {
	public static forRoot(httpCommandDispatchEnabled: boolean = false): ModuleWithProviders<PushModule> {
		return {
			ngModule: PushModule,
			providers: [
				{ provide: PushServiceConfigToken, useValue: { httpCommandDispatchEnabled } }
			]
		};
	}
}

function onAppBootstrap(zone: NgZone) {
	return () => hubsConnection.zone = zone;
}
