import { NgModule } from '@vbrick/angular-ts-decorators';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

export interface IDowngradeComponentEntry {
	component: any; // Angular component
	name: string; // camel-case AngularJS directive name
}

export interface IDowngradeInjectableEntry {
	injectable: any; // Angular injectable
	name: string; // AngularJS service name
}

export function downgradeComponents(angularJsModule: any, componentEntries: IDowngradeComponentEntry[]): void {
	const { module } = (angularJsModule as NgModule);

	componentEntries.forEach(componentEntry => module.directive(componentEntry.name, downgradeComponent({ component: componentEntry.component })));
}

export function downgradeInjectables(angularJsModule: any, injectableEntries: IDowngradeInjectableEntry[]): void {
	const { module } = (angularJsModule as NgModule);

	injectableEntries.forEach(injectableEntry => module.factory(injectableEntry.name, downgradeInjectable(injectableEntry.injectable)));
}
