import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { TeamService } from './Team.Service';
import { PushModule } from 'rev-shared/push/Push.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';

@NgModule({
	imports: [
		HttpClientModule,
		PushModule,
		SecurityAngularModule
	],
	providers: [
		TeamService
	]
})
export class SharedTeamAngularModule {
}
