import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { formatMediumDateTime } from 'rev-shared/date/DateFormatters';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { formatMeetingId } from './ZoomMeetingId';

export interface IZoomMeeting {
	id: number;
	startTime: Date;
	topic: string;
}

enum MeetingType {
	Scheduled = 'scheduled',
	Live = 'live',
	Upcoming = 'upcoming'
}

@Injectable({
	providedIn: 'root'
})
export class ZoomService {

	public meetings: IZoomMeeting[];
	public loading: boolean;

	constructor(
		private UserContext: UserContextService,
		private DateParsers: DateParsersService,
		private http: HttpClient
	) {
		'ngInject';
	}

	public loadMeetings(): void {
		this.loading = true;
		this.meetings = [];

		this.loadPage(0)
			.catch(err => console.error('error loading zoom meetings: ', err))
			.finally(() => this.loading = false);
	}

	private loadPage(pageNumber: number): Promise<void> {
		const email = this.UserContext.getUser().email;
		const params = { 'type': MeetingType.Upcoming };
		return lastValueFrom(this.http.get(`/scheduled-events/zoom-meetings/${email}`, {
			params: params
		}))
			.then((result: any) => {
				if(result.meetings ) {
					this.meetings.push(...result.meetings.map(m => this.readZoomMeeting(m)));
				}
				if(this.meetings.length < result.total_records) {
					return this.loadPage(pageNumber + 1).catch(e => {
						console.error('loadPage error: ', e);
					});
				}
			});
	}

	private readZoomMeeting(data: any): IZoomMeeting {
		return {
			...data,
			startTime: this.DateParsers.parseUTCDate(data.start_time)
		};
	}

	public formatMeeting(meeting: IZoomMeeting): string {
		if(!meeting || !meeting.id) {
			return meeting as any;
		}
		return `${meeting.topic} (${formatMeetingId(meeting.id)}) - ${formatMediumDateTime(meeting.startTime)}`;
	}

	public filterMeetings(query: string): IZoomMeeting[] {
		if(!this.meetings) {
			return;
		}
		query = (query || '').toLowerCase();
		return this.meetings.filter(m =>
			m.topic.toLowerCase().includes(query) ||
			(m.id + '').includes(query));
	}
}
