import { noop } from 'rev-shared/util';
import { Deferred } from './Deferred';

const getTrue = () => true;

export interface IPromiseQueue<T> {
	enqueue(fn: () => Promise<T>): Promise<T>;
}

export interface ICancellableQueue<T> extends IPromiseQueue<T> {
	cancel: () => void;
}

/**
	queue based on promises. Each enqueued operation runs synchronously until the queue is empty

	Example:
	let queue = PromiseQueue();

	queue.enqueue(function() {
		return loadData();
	});
*/
export function createPromiseQueue<T>(): ICancellableQueue<T> {
	let head = Promise.resolve(null as T);
	const cancelledPromise = () => Promise.reject('QueueCancelled');
	let isCancelled = false;

	return {
		/**
			adds an operation to the queue
			fn:  a function that returns a promise;
		**/
		enqueue(fn: () => Promise<T>): Promise<T> {
			if(isCancelled) {
				return cancelledPromise();
			}

			const result = head
				.then(() => {
					if(isCancelled) {
						return cancelledPromise();
					}

					return fn();
				});

			//Ignore failures
			head = result.catch(noop) as Promise<T>;

			return result;
		},

		cancel(): void {
			isCancelled = true;
		}
	};
}

/**
	Attempt to execute fn repeatedly until a successful result is returned.
		fn: A function that you would like to poll, must return a promise.
		isValueReady (optional function): recieves the result of fn's promise as a parameter, only called when fn resolves without error. Return true if the value is ready.
		shouldRetry (optional function): if fn's promise is rejected, this will be called with the failure value. Return true to abort further polling

	Returns a promise that will be resolved or rejected with the last poll attempt.
	Polling will back off according to the fibbonacci sequence( 1s, 1s, 2s, 3s, 5s, ..., up to 8s)

	example:

	PromiseUtil.retryUntilSuccess(function() {
		loadUsers();
	},
	function(userList) {
		return userList && userList.length > 0;
	},
	function(error) {
		//continue polling if request 404'd
		return error.statusCode === 404;
	});
**/
export function retryUntilSuccess<T>(fn: () => Promise<T>, isValueReady?: (...args) => boolean, shouldRetry?: (...args) => boolean): Promise<T> {
	const deferred: Deferred = new Deferred<T>();

	isValueReady = isValueReady || getTrue;
	shouldRetry = shouldRetry || getTrue;

	poll(1, 1);

	return deferred.promise;

	function poll(t1, t2) {
		Promise.resolve(fn())
			.then(value => {

				if(isValueReady(value)) {
					return deferred.resolve(value);
				}
				retryLater();

			}, err => {
				//todo: make this a parameter
				if(t1 > 11 || !shouldRetry(err)) {
					return deferred.reject(err);
				}
				retryLater();
			});

		function retryLater() {
			window.setTimeout(() => {
				poll(t2, t1 + t2);
			}, t1 * 1000); //fibbonaci backoff
		}
	}
}

//implementation of Q.race
//returns a promise resolved/rejected with the first resolved/rejected promise's result
export function race<T>(promises: Array<Promise<T>|T>): Promise<T> {
	return new Promise((resolve, reject) => {
		promises.forEach(promise => Promise.resolve(promise).then(resolve, reject));
	});
}

export function createThrottledQueue<T>(delayMs: number): IPromiseQueue<T>{
	let lastRunTime = 0;

	const queue = createPromiseQueue<T>();

	return {
		enqueue(fn) {
			return queue.enqueue(() => {
				const now = Date.now();
				const deferred: Deferred = new Deferred();
				const delay = delayMs + lastRunTime - now;
				lastRunTime = now;

				if (delay <= 0) {
					return fn();
				}

				window.setTimeout(deferred.resolve, delay);

				return deferred.promise
					.then(fn);
			});
		}
	};
}
