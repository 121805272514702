import { isString } from 'rev-shared/util';
import { formatNumber } from './format';

export class FileUtil {
	public static readonly archiveFileExtensions = ['zip', 'rar', '7z'];
	public static readonly documentFileExtensions = ['doc', 'docx', 'txt', 'pdf'];
	public static readonly fileSizeUnits = [
		' Bytes', 'KB', 'MB', 'GB', 'TB'
	];
	public static readonly fontFileExtensions = ['woff'];
	public static readonly imageFileExtensions = ['jpg', 'jpeg', 'gif', 'png'];
	public static readonly licenseFileExtensions = ['lic'];
	public static readonly Kb = 1 << 10;
	public static readonly Mb = 1 << 20;
	public static readonly Gb = 1 << 30;
	public static readonly presentationFileExtensions = ['ppt', 'pptx'];
	public static readonly spreadsheetFileExtensions = ['xls', 'xlsx', 'csv'];
	public static readonly transcriptionFileExtensions = ['srt', 'vtt'];
	public static readonly videoFileExtensions = ['avi', 'm4v', 'f4v', 'flv', 'mpg', 'ts', 'mp4', 'avi', 'wmv', 'asf', 'mov', 'mkv', 'mts', 'mp3', 'ogv'];

	public static isImageFile(extension: string): boolean {
		return FileUtil.imageFileExtensions.includes(extension);
	}

	public static isVideoFile(extension: string): boolean {
		return FileUtil.videoFileExtensions.includes(extension);
	}

	public static isZipFile(extension: string): boolean {
		return 'zip' === extension;
	}

	public static isPresentationFile(extension: string): boolean {
		return FileUtil.presentationFileExtensions.includes(extension);
	}

	public static isDocumentFile(extension: string): boolean {
		return FileUtil.documentFileExtensions.includes(extension);
	}

	public static isSpreadsheetFile(extension: string): boolean {
		return FileUtil.spreadsheetFileExtensions.includes(extension);
	}

	public static isArchiveFile(extension: string): boolean {
		return FileUtil.archiveFileExtensions.includes(extension);
	}

	public static isTranscriptionFile(extension: string): boolean {
		return FileUtil.transcriptionFileExtensions.includes(extension);
	}

	public static isLicenseFile(extension: string): boolean {
		return FileUtil.licenseFileExtensions.includes(extension);
	}

	public static parseFileName(fileName: string): any {
		const matches = /^(.*)\.([^.]*)$/.exec(fileName);
		if(matches) {
			return {
				name: matches[1],
				extension: matches[2].toLowerCase()
			};
		}
	}

	public static formatFileSize(size: string|number, numDecimalPlaces: number, sizeInputUnit?: string): string|number {
		if (isString(size)) {
			size = parseInt(size, 10);
		}

		const inputUnitExp = sizeInputUnit ? FileUtil.fileSizeUnits.indexOf(sizeInputUnit) : 0;

		if (size == null || !isFinite(size) || inputUnitExp < 0) {
			return size;
		}

		const sizeBytes = size * Math.pow(FileUtil.Kb, inputUnitExp);
		const unitOut = FileUtil.findTargetUnit(sizeBytes);
		const sizeOut = sizeBytes / Math.pow(FileUtil.Kb, unitOut);

		return formatNumber(sizeOut, '1.2-2') + FileUtil.fileSizeUnits[unitOut];
	}

	public static getFileExtensionCssClass(fileExtension: string): string {
		if(FileUtil.isImageFile(fileExtension)) {
			return 'picture';
		}
		return fileExtension ? `filetype ${fileExtension}` : '';
	}

	private static findTargetUnit(sizeBytes: number){
		const heuristicSize = Math.abs(sizeBytes) || 1;
		const unit = Math.floor(Math.log(heuristicSize) / Math.log(FileUtil.Kb));
		const maxAllowedUnit = FileUtil.fileSizeUnits.length - 1;
		return Math.min(unit, maxAllowedUnit);
	}
}
