import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';

import { AnalyticsService } from './Analytics.Service';

@NgModule({
	imports: [
		DateAngularModule,
		HttpClientModule
	],
	providers: [
		AnalyticsService
	]
})
export class AnalyticsModule {}
