import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { noop, Observable, Observer, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';

import { RecordingService } from './Recording.Service';

import { SparkService } from 'rev-portal/media/spark/Spark.Service';
import { WebcastVideoSource } from 'rev-portal/scheduledEvents/webcast/WebcastStatus';
import {
	ZoomService,
	IZoomMeeting
} from 'rev-portal/scheduledEvents/zoom/Zoom.Service';

import './recording-menu-component.less';

interface IRecentVcCallsResponse {
	recentVcCalls: IRecentVcCall[]
}

interface IRecentVcCall {
	sipAddress: string,
	sipPin?: number,
	when: string
}

interface IVCRecord {
	isDisabled: boolean;
	sipPin?: string;
	sipUrl?: string;
	title?: any;
	microsoftTeamsMeetingUrl?: string;
}

interface IWebexTeam {
	name: string;
	roomId: string;
	sipAddress: string;
}

@Component({
	selector: 'recording-menu',
	templateUrl: './RecordingMenu.Component.html'
})
export class RecordingMenuComponent implements OnInit, OnChanges {
	@Input() public teamId: string;
	@Input() public webexTeamsEnabled: boolean;
	@Input() public vciEnabled: boolean;
	@Input() public vciMsTeamsEnabled: boolean;
	@Input() public zoomEnabled: boolean;
	@Input() public zoomSipAddressSuffix: string;

	@ViewChild('form') public form: NgForm;

	public readonly WebcastVideoSource = WebcastVideoSource;

	public vcRecord: IVCRecord = { isDisabled: false };
	public sipAddresses$: Observable<string[]>;
	public webexTeam: IWebexTeam;
	public zoomMeetingId: string = '';
	public zoomMeetingPassword: string;

	public selectedRecordingSource: WebcastVideoSource;
	public translations: any;

	public get zoomMeetings(): IZoomMeeting[] {
		const meetings = this.ZoomService.filterMeetings(this.zoomMeetingId ?? '');
		if (!meetings) {
			return [];
		}

		return meetings;
	}

	constructor(
		private RecordingService: RecordingService,
		private SparkService: SparkService,
		private TranslateService: TranslateService,
		private StateService: StateService,
		public ZoomService: ZoomService
	) {}

	public ngOnInit(): void {
		this.autoSelectRecordingSource();
		this.sipAddresses$ = this.getRecentCalls$();

		if(this.zoomEnabled && !this.ZoomService.meetings) {
			this.ZoomService.loadMeetings();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if ((changes.webexTeamsEnabled || changes.vciEnabled || changes.zoomEnabled) && this.isSelectedRecordingSourceInvalid()) {
			this.autoSelectRecordingSource();
		}
	}

	public shapeVcRecordingData(): void {
		if (this.selectedRecordingSource === WebcastVideoSource.SIP_ADDRESS) {
			this.vcRecord.title = this.vcRecord.sipUrl;
		} else if (this.selectedRecordingSource === WebcastVideoSource.WEBEX) {
			this.vcRecord.title = this.webexTeam.name;
			this.vcRecord.sipUrl = this.webexTeam.sipAddress;
		} else if (this.selectedRecordingSource === WebcastVideoSource.ZOOM) {
			const meetings = this.ZoomService.filterMeetings(this.zoomMeetingId);
			this.zoomMeetingId = this.zoomMeetingId.replace(/-/g, '');
			this.vcRecord.title = meetings && meetings.length > 0 ? meetings[0].topic : `${this.TranslateService.instant('Zoom_Meeting')} - ${this.zoomMeetingId}`;
			this.vcRecord.sipUrl = this.zoomMeetingPassword
				? `${this.zoomMeetingId}.${this.zoomMeetingPassword}@${this.zoomSipAddressSuffix}`
				: `${this.zoomMeetingId}@${this.zoomSipAddressSuffix}`;
		} else if (this.selectedRecordingSource === WebcastVideoSource.MSTEAMS) {
			this.vcRecord.title = this.vcRecord.microsoftTeamsMeetingUrl;
		}
	}

	public get integrationEnabled(): boolean {
		return this.webexTeamsEnabled || this.vciEnabled || this.zoomEnabled || this.vciMsTeamsEnabled;
	}

	private startRecording(): void {
		// It's decided that user will not enter bitrate info.
		// 0 entered here means to use the default value
		// defined in the config file.
		this.shapeVcRecordingData();
		this.RecordingService
			.recordVideoConference(this.vcRecord.title, this.vcRecord.sipUrl, this.vcRecord.sipPin, 0, this.vcRecord.microsoftTeamsMeetingUrl, this.teamId)
			.then(({ id }) => {
				window.setTimeout(() => this.redirectToPlayback(id), 1500);
			})
			.catch(error => this.handleErrorScenario(error));
	}

	private redirectToPlayback(videoId: string): void {
		this.StateService.go('portal.video', { videoId })
			.finally(() => this.vcRecord.isDisabled = false);
	}

	private getRecentCalls$(): Observable<string[]> {
		// Typeahead can accept an observable as input
		// Map http repsonse to a filtered list based on what was being inputted
		return new Observable((observer: Observer<string>) => {
			observer.next(this.vcRecord.sipUrl);
		}).pipe(
			switchMap((query: string) => {
				if (query) {
					return this.RecordingService.recentVideoConferenceCalls().pipe(
						map((data: IRecentVcCallsResponse) => {
							return data?.recentVcCalls
								.filter(entry => entry.sipAddress.includes(query))
								.map(entry => entry.sipAddress) ?? [];
						}),
						catchError(err => {
							console.error(err);
							return of([]);
						})
					);
				}
				return of([]);
			})
		);
	}

	public preventMultipleRecordings(): void {
		this.vcRecord.isDisabled = true;
		this.startRecording();
		this.vcRecord.sipUrl = '';
	}

	private handleErrorScenario(error: any): void {
		this.vcRecord.isDisabled = false;
		if(error.hasIssue && error.hasIssue('RecordingHoursNotAvailable')) {
			this.form.form.setErrors({ 'invalid': true });
		} else {
			console.error('Error starting the recording conference', error);
		}
	}

	private autoSelectRecordingSource(): void {
		this.selectedRecordingSource = WebcastVideoSource.SIP_ADDRESS;
		if (!this.vciEnabled) {
			if (this.webexTeamsEnabled) {
				this.selectedRecordingSource = WebcastVideoSource.WEBEX;
			} else {
				this.selectedRecordingSource = WebcastVideoSource.ZOOM;
			}
		}
	}

	private isSelectedRecordingSourceInvalid(): boolean {
		return !this.vciEnabled && this.selectedRecordingSource === WebcastVideoSource.SIP_ADDRESS ||
			!this.webexTeamsEnabled && this.selectedRecordingSource === WebcastVideoSource.WEBEX ||
			!this.zoomEnabled && this.selectedRecordingSource === WebcastVideoSource.ZOOM ||
			!this.vciMsTeamsEnabled && this.selectedRecordingSource === WebcastVideoSource.MSTEAMS;
	}
}
