import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

import { ITimeMarker } from '@vbrick/vbrick-player/app/player/videogular/controls/VbTimeMarkers.Component';
import { VideogularComponent } from '@vbrick/vbrick-player/app/player/videogular/Videogular.Component';

import { createImageFileFromDataUri } from 'rev-shared/util/ImageUtils';
import { IVideoThumbnail } from 'rev-shared/media/videoSettings/IVideoThumbnail';
import { VideoService } from 'rev-shared/media/Video.Service';

import { VgStates } from '@vbrick/vbrick-player/app/player/videogular/VgStates';
import styles from './VideoThumbnailSelector.module.less';

@Component({
	selector: 'video-thumbnail-selector',
	templateUrl: './VideoThumbnailSelector.Component.html'
})
export class VideoThumbnailSelectorComponent implements OnInit {
	@Input() public videoId: string;
	@Input() public thumbnailUpdated: (IVideoThumbnail) => void;

	public readonly styles = styles;
	public video: any;
	public autoplay: boolean;
	public isVideoUnavailable: boolean;
	public vgAPI: VideogularComponent;
	public markers: ITimeMarker[];
	public imageUri: string;

	protected preservedVolume: number;

	constructor(
		private TranslateService: TranslateService,
		private VideoService: VideoService,
		public bsModalRef: BsModalRef
	) {
		this.isVideoUnavailable = true;
	}

	public ngOnInit(): void {
		this.loadVideo(this.videoId);
	}

	public onPlayerReady(vgAPI: VideogularComponent): void {
		this.vgAPI = vgAPI;

		if (this.preservedVolume) {
			vgAPI.setVolume(this.preservedVolume);
		}
	}

	protected loadVideo(videoId: string): void {
		this.autoplay = true;
		this.VideoService.getVideoPlayback(videoId)
			.then((video: any) => this.onLoadVideoSuccess(video))
			.catch(e => {
				this.onLoadVideoError(videoId);
				console.error(e);
			});
	}

	protected onLoadVideoSuccess(video: any): void {
		this.video = video.video;
		this.isVideoUnavailable = false;
	}

	protected onLoadVideoError(videoId: string): void {
		this.video = { id: videoId };
		this.isVideoUnavailable = true;
	}

	public closeDialog(): void {
		this.bsModalRef.hide();
	}

	public captureThumbnail(): void {
		// If the video has not been played or scrubbed, close the dialog
		if (this.vgAPI.currentState === VgStates.STOP) {
			this.bsModalRef.hide();
			return;
		}

		const videoElement = this.vgAPI.mediaElement;
		this.imageUri = this.generateUri(videoElement);
		this.bsModalRef.hide();
		const thumbnail = this.createFormData(this.imageUri);
		this.thumbnailUpdated(thumbnail);
	}

	private generateUri(videoElement: HTMLVideoElement): string {
		const { videoWidth, videoHeight } = videoElement;
		const cvs = document.createElement('canvas');
		cvs.width = videoWidth;
		cvs.height = videoHeight;
		const ctx = cvs.getContext('2d');
		ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
		return cvs.toDataURL('image/jpeg');
	}

	private createFormData(thumbnailUri: string): IVideoThumbnail {
		const formData = new FormData();
		const file = createImageFileFromDataUri(thumbnailUri);
		formData.append('thumbnail', file, 'thumbnail.jpg');
		return {
			imageId: '',
			thumbnailUri: thumbnailUri,
			formData: formData
		};
	}
}
