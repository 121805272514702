import { Directive, Input, OnChanges } from '@vbrick/angular-ts-decorators';
import { ITimeoutService, IPromise } from 'angular';
import { flatten } from 'underscore';

const delayMs = 1000;

/**
 * Sets a timer using $timeout to run at specified date(s). This will trigger a digest cycle to update the view.
 * Example: <div vb-schedule-digest="date"
 *  <div vb-schedule-digest="[date1, date2, ...]"
 */
@Directive({
	selector: '[vb-schedule-digest]',
	bindToController: true
})
export class VbScheduleDigestDirective implements OnChanges {
	@Input('<*vbScheduleDigest') public digestTime: Date|Date[];
	private timers: Array<IPromise<void>>;

	constructor(
		private $timeout: ITimeoutService
	) {
		'ngInject';
	}

	public ngOnChanges() {
		if(this.timers) {
			this.timers.forEach(t => t && this.$timeout.cancel(t));
		}

		this.timers = [...new Set<Date>(flatten([this.digestTime]) as Date[])]
			.filter(Boolean)
			.map(when => {
				const timeMs = when.getTime() - Date.now() + delayMs;

				if(timeMs > 0) {
					return this.$timeout(timeMs);
				}
			})
			.filter(Boolean);
	}
}
