<div class="system-login-shell">
	<div class="table-display">
		<div class="table-row">
			<div class="table-cell system-login-nav">
				<div class="main-nav">
					<div class="navbar">
						<div class="table-cell brand-cell">
							<branding-logo></branding-logo>
						</div>

						<div class="table-cell"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell table-cell-centered-form">
				<div class="system-login col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-xl-4 col-xl-offset-4">


					<div [hidden]="!status.loading">
						<vb-loading-spinner [msg]="'Login_LogoffMessage' | translate" [size]="'large'">
						</vb-loading-spinner>
					</div>

					<div [hidden]="!status.active">

						<form class="system-form" name="loginForm">

							<h1>{{ 'Login_LoggedOffMessage' | translate }}</h1>

							<div class="system-msg">
								<div class="">
									<span class="glyphicons clock"></span>{{ 'Login_LoggedOutMessage' | translate }}
								</div>
							</div>


							<div class="group">
								<span class="forgot-password">
									<a [href]="loginUrl">{{ 'Login' | translate }}</a>
								</span>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

