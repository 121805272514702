import { Injectable } from '@angular/core';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { PushService } from 'rev-shared/push/PushService';
import { PushBus } from 'rev-shared/push/PushBus.Service';

const UploadStatusRouteScope = 'UploadStatus';

@Injectable({
	providedIn: 'root'
})
export class ImageService {
	constructor(
		private PushService: PushService,
		private PushBus: PushBus
	) {}

	public uploadImage(context: string, file: FileWrapper): Promise<string> {
		return this.getImageUploadUri(context)
			.then(result => this.uploadFile(file, result, 'ImageStoringFinished', 'ImageStoringFailed'));
	}

	private getImageUploadUri(context: string): Promise<any> {
		return this.PushService.dispatchCommand('media:AddImageToAccount', { context }, 'ImageCreated')
			.then(result => ({
				id: result.message.imageId,
				uploadUri: result.message.uploadUri
			}));
	}

	public uploadFile(
		file: FileWrapper,
		uploadDetails: { id: string; uploadUri: string},
		finishedEvent: string,
		failedEvent: string
	): Promise<string> {
		file.setOptions({
			url: uploadDetails.uploadUri
		});

		const storingFinished = this.PushBus.awaitMessage({
			route: uploadDetails.id,
			routeScope: UploadStatusRouteScope,
			events: finishedEvent,
			rejectEvents: failedEvent
		});
		return storingFinished.subscribed
			.then(() => file.submit())
			.then(() => storingFinished)
			.then(() => uploadDetails.id);
	}
}
