<div [ngClass]="styles.formGroup" *ngIf="!!liveSubtitles">
	<label id="ewLiveEventSubtitles" [ngClass]="styles.topLabel">{{ 'Event_LiveEventSubtitles' | translate}}</label>
	<div [ngClass]="styles.outerContainer">
		<div [ngClass]="styles.row">
			<div [ngClass]="styles.innerContainer">
				<div vbUiRadioBtnGroup>
					<button type="button" [ngClass]="styles.radioBtn" [(ngModel)]="liveSubtitles.isLiveSubtiltesEnabled" [vbUiRadioBtn]="true" [notThemed]="!isAdHoc" [activeClass]="isAdHoc ? '' : 'active'" (ngModelChange)="onSubtitleOptionChange()">
						{{ 'UI_Enabled' | translate }}
					</button>
					<button type="button" [ngClass]="styles.radioBtn" [(ngModel)]="liveSubtitles.isLiveSubtiltesEnabled" [vbUiRadioBtn]="false" [notThemed]="!isAdHoc" [activeClass]="isAdHoc ? '' : 'active'" (ngModelChange)="onSubtitleOptionChange()">
						{{ 'UI_Disabled' | translate }}
					</button>
				</div>
			</div>
			<div *ngIf="errors && errors[ErrorDetails.INVALID_PRESENTATION_PROFILE_SOURCE]" [ngClass]="styles.error" role="alert" vbUiErrorMessage>
				{{ 'Event_LiveEventSubtitles_PresentaionProfileNotSupported' | translate }}
			</div>
			<div *ngIf="errors && errors[ErrorDetails.INVALID_SOURCE]" [ngClass]="styles.error" role="alert" vbUiErrorMessage>
				{{ 'Event_LiveEventSubtitles_SourceNotSupported' | translate }}
			</div>
			<div *ngIf="errors && errors[ErrorDetails.REV_IQ_DISABLE]" [ngClass]="styles.error" role="alert" vbUiErrorMessage>
				{{ 'Event_LiveEventSubtitles_TranscriptionNotEnabled' | translate }}
			</div>
			<div *ngIf="errors && errors[ErrorDetails.INSUFFICIENT_REV_IQ_CREDIT]" [ngClass]="styles.error" role="alert" vbUiErrorMessage>
				{{ 'Event_LiveEventSubtitles_InsufficientRevIQCredits' | translate }}
			</div>
		</div>
	</div>
</div>
<div *ngIf="liveSubtitles?.isLiveSubtiltesEnabled">
	<div [ngClass]="styles.formGroup">
		<label [ngClass]="styles.topLabel"></label>
		<div [ngClass]="styles.outerContainer">
			<div [ngClass]="styles.row">
				<div [ngClass]="styles.innerContainer">
					<label for="ewSubtitleLang" [ngClass]="styles.label">{{ 'Event_LiveEventSubtitles_SourceLanguage' | translate }}</label>
					<vb-ui-select>
						<select id="ewSubtitleLang" #selectElement name="ewSubtitleLang" [(ngModel)]="liveSubtitles.sourceLanguage" (change)="onSourceLanguageChange()" required>
							<option *ngFor="let language of sourceLanguages" [ngValue]="language.code">{{language.name}}</option>
						</select>
					</vb-ui-select>
				</div>
			</div>
		</div>
	</div>
	<div [ngClass]="styles.formGroup">
		<label [ngClass]="styles.topLabel"></label>
		<div [ngClass]="styles.outerContainer">
			<div [ngClass]="styles.row">
				<div [ngClass]="styles.innerContainer">
					<label for="ewSubtitleTranslationLang" [ngClass]="styles.label">{{ 'Event_LiveEventSubtitles_TranslateLanguages' | translate }}</label>
					<vb-ui-language-tags-input id="ewSubtitleTranslationLang" [tags]="selectedTranslations" [placeholder]="'Features_SelectLanguages' | translate" (onTagsChanged)="onTagsChanged($event.$event)" [filter]="filterTranslationLanguage" [languageSource]="translationLanguages">
					</vb-ui-language-tags-input>
				</div>
				<div *ngIf="errors && errors[ErrorDetails.MAX_TRANS_LANG]" [ngClass]="styles.error" role="alert" vbUiErrorMessage>
					{{ 'Event_LiveEventSubtitles_TranslateLanguagesCountError' | translate : { '0':  MAX_TRANS_LANG_COUNT } }}
				</div>
			</div>
		</div>
	</div>
</div>
