import { isAndroidChrome } from 'rev-shared/util/UserAgentUtil';

//Emoji popup and keyboard ui get into an unstable loop, shifting the ui forever.
const disableEmoji = isAndroidChrome();

export const DefaultToolbar: any[] = [
	{ name: 'styles', items: ['FontSize'] },
	{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat'] },
	!disableEmoji && { name: 'emoji', items: ['EmojiPanel'] },
	{ name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
	{ name: 'colors', items: ['TextColor', 'BGColor'] },
	{ name: 'links', items: ['Link', 'Unlink'] },
	{ name: 'undo', items: ['Undo', 'Redo'] }
].filter(Boolean);

export const SimpleToolbar: any[] = [
	{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat'] },
	!disableEmoji && { name: 'emoji', items: ['EmojiPanel'] }
].filter(Boolean);
