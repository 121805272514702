import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ILanguageCode } from 'rev-shared/media/transcription/TranscriptionLanguages.Service';
import { ILiveSubtitles } from './ILiveSubtitles';
import {
	InsufficientRevIQCredits,
	TranscriptionIsNotEnabled,
	UnsupportedLiveSubtitlesPresentationProfile,
	UnsupportedLiveSubtitlesSource
} from 'rev-portal/scheduledEvents/editWebcast/EditWebcast.Service';

import styles from './LiveSubtitles.module.less';

const DEFAULT_SOURCE_LANG = 'en';
const MAX_TRANS_LANG_COUNT = 7;
const ErrorDetails = {
	INVALID_PRESENTATION_PROFILE_SOURCE: 'InvalidPresentationProfileSource',
	INVALID_SOURCE: 'InvalidSource',
	REV_IQ_DISABLE: 'RevIQDisable',
	INSUFFICIENT_REV_IQ_CREDIT: 'InsufficientRevIQCredit',
	MAX_TRANS_LANG: 'invalidTransLangCount'
};

const bootstrapFormStyles = {
	formGroup: 'form-group',
	label: 'control-label',
	topLabel: 'control-label col-sm-3',
	outerContainer: 'col-sm-9',
	row: 'row',
	innerContainer: 'col-md-6 col-sm-9',
	error: 'margin-left-16 col-md-6 col-sm-9',
	radioBtn: `btn btn-admin btn-white`
};
const adHocStyles = {
	...Object.keys(bootstrapFormStyles).reduce((acc, key) => {
		acc[key] = '';
		return acc;
	}, {}),
	label: 'type-bold',
	topLabel: 'type-bold'
};

@Component({
	selector: 'live-subtitles',
	templateUrl: './LiveSubtitles.Component.html'
})
export class LiveSubtitlesComponent implements OnChanges {
	@Input() public liveSubtitles: ILiveSubtitles;
	@Input() public issue: string;
	@Input() public sourceLanguages: ILanguageCode[];
	@Input() public translationLanguages: ILanguageCode[];
	@Input() public isAdHoc: boolean;

	@Output() public subtitlesChanged: EventEmitter<ILiveSubtitles> = new EventEmitter();
	@Output() public validated: EventEmitter<ValidationErrors> = new EventEmitter();

	public readonly ErrorDetails = ErrorDetails;
	public readonly MAX_TRANS_LANG_COUNT = MAX_TRANS_LANG_COUNT;
	public readonly filterTranslationLanguage = (lang: ILanguageCode) => {
		return !this.liveSubtitles.translationLanguages.find(translation => translation === lang.code)
			&& this.liveSubtitles.sourceLanguage !== lang.code;
	};
	public styles: any = styles;

	public errors: ValidationErrors;
	public selectedTranslations: ILanguageCode[];
	private subscription: Subscription;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.liveSubtitles) {
			this.shapeLiveSubtitles();
		}

		this.validate();

		this.styles = {
			...styles,
			...(this.isAdHoc ? adHocStyles : bootstrapFormStyles )
		};
	}

	public ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	public onTagsChanged($event: ILanguageCode[]): void {
		this.selectedTranslations = $event;
		this.onSubtitlesUpdate();
	}

	private shapeLiveSubtitles(): void {
		if (!this.liveSubtitles?.isLiveSubtiltesEnabled) {
			this.initLiveSubtitles();
			return;
		}

		this.liveSubtitles.isLiveSubtiltesEnabled = !!this.liveSubtitles.sourceLanguage;
		this.selectedTranslations = (this.liveSubtitles.translationLanguages || [])
			.map(code => (
				{
					code,
					name: this.translationLanguages.find(lang => lang.code === code)?.name
				}
			));
	}

	private initLiveSubtitles(): void {
		this.liveSubtitles = {
			isLiveSubtiltesEnabled: false,
			sourceLanguage: DEFAULT_SOURCE_LANG,
			translationLanguages: []
		};
	}

	private validate(): void {
		const errors = this.liveSubtitles?.isLiveSubtiltesEnabled && {
			[ErrorDetails.INSUFFICIENT_REV_IQ_CREDIT]: this.issue === InsufficientRevIQCredits,
			[ErrorDetails.REV_IQ_DISABLE]: this.issue === TranscriptionIsNotEnabled,
			[ErrorDetails.INVALID_PRESENTATION_PROFILE_SOURCE]: this.issue === UnsupportedLiveSubtitlesPresentationProfile,
			[ErrorDetails.INVALID_SOURCE]: this.issue === UnsupportedLiveSubtitlesSource,
			[ErrorDetails.MAX_TRANS_LANG]: this.liveSubtitles?.translationLanguages?.length > MAX_TRANS_LANG_COUNT
		};

		const isValid = !errors || !Object.values(errors).some(Boolean);
		this.errors = isValid ? undefined : errors;
		this.validated.emit(this.errors);
	}

	public onSourceLanguageChange(): void {
		this.selectedTranslations = (this.selectedTranslations || []).filter(lang => lang.code !== this.liveSubtitles.sourceLanguage);
		this.onSubtitlesUpdate();
	}

	public onSubtitleOptionChange(): void {
		this.validate();
		this.onSubtitlesUpdate();
	}

	public onSubtitlesUpdate(): void {
		const data = this.liveSubtitles.isLiveSubtiltesEnabled ?
			{
				isLiveSubtiltesEnabled: this.liveSubtitles.isLiveSubtiltesEnabled,
				sourceLanguage: this.liveSubtitles.sourceLanguage,
				translationLanguages: (this.selectedTranslations || []).map(lang => lang.code)
			} : undefined;

		this.subtitlesChanged.emit(data);
	}
}
