import { Directive, Input } from '@angular/core';

import styles from './BaseReportKpi.module.less';

@Directive({})
export class BaseReportKpiComponent {
	@Input() public data: any;

	public styles = styles;
}
