import { PushService } from 'rev-shared/push/PushService';
import { SignalRHubsConnection } from 'rev-shared/push/SignalRHubsConnection';

import { SecurityContextService } from './SecurityContext.Service';
import { UserContextService } from './UserContext.Service';

export function securityBootstrap(
	pushService: PushService,
	SecurityContext: SecurityContextService,
	signalRHubsConnection: SignalRHubsConnection,
	UserContext: UserContextService
) {
	let token: string = UserContext.getCsrfToken();
	let isLoggedIn: boolean = UserContext.isUserLoggedIn();

	UserContext.userAuthenticated$.subscribe(updateSecurityToken);
	onUserContextChange();
	UserContext.userIdChanged$.subscribe(() => {
		//session is stable. no need for any $timeout...
		if (UserContext.isSessionStable) {
			onUserContextChange();
			return;
		}
		//allow time for userId unsubscribe to complete
		window.setTimeout(() => onUserContextChange(), 500);
	});

	function onUserContextChange(): void {
		updateSecurityToken();
		SecurityContext.reloadAuthorization();
	}

	function updateSecurityToken(): void {
		const newToken: string = UserContext.getCsrfToken();
		const newIsLoggedIn: boolean = UserContext.isUserLoggedIn();

		pushService.setCsrfToken(newToken);

		if (token !== newToken || isLoggedIn !== newIsLoggedIn) {
			token = newToken;
			isLoggedIn = newIsLoggedIn;
			signalRHubsConnection.bounce();
		}
	}
}
