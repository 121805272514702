import { debounce } from 'underscore';

import {
	Directive,
	DoCheck,
	OnDestroy,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAttributes,
	IAugmentedJQuery,
	ITimeoutService,
	IWindowService,
	element as angularElement
} from 'angular';

import { isDefined } from 'rev-shared/util';
/**
	Stretches an element to the bottom of the screen.
	Include the "vb-stretch-min-height" attribute  to set the min-height style, instead of height.
	attribute: bottom-anchor:  the gap to leave at the bottom of the element, in pixels. Default is 25 pixels.

	Example:
	<div vb-stretch vb-stretch-min-height bottom-anchor="25">...</div>
**/
@Directive({
	selector: '[vb-stretch]',
	bindToController: true
})
export class VbStretchDirective implements OnDestroy, OnInit, DoCheck {
	private onResizeHandler: () => void;

	constructor(
		private $attrs: IAttributes,
		private $element: IAugmentedJQuery,
		private $timeout: ITimeoutService,
		private $window: IWindowService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.onResizeHandler = debounce(() => this.update(), 100);

		this.update();

		angularElement(this.$window).on('resize', this.onResizeHandler);
	}

	public ngDoCheck(): void {
		this.onResizeHandler();
	}

	public ngOnDestroy(): void {
		angularElement(this.$window).off('resize', this.onResizeHandler);
	}

	private update() {
		const viewportHeight = angularElement(this.$window).height();
		const top = this.$element.offset().top;
		const bottomAnchor = isDefined(this.$attrs.bottomAnchor) ? +this.$attrs.bottomAnchor : 50;
		const heightValue = viewportHeight - top - bottomAnchor;

		if (isDefined(this.$attrs.vbStretchMinHeight)) {
			this.$element.css('min-height', heightValue);
		} else {
			/*This condition check is to avoid height value negative scenario which comes intermittently*/
			if (heightValue < 0) {
				this.$timeout(() => this.onResizeHandler(), 100, false);
				return;
			}

			this.$element.height(heightValue);
		}
	}
}
