import { Transition } from '@uirouter/angular';

import { AccessEntityType } from 'rev-shared/security/AccessEntityType';
import { AdminCategoryService } from 'rev-shared/media/category/AdminCategory.Service';
import { EditCategoryComponent } from 'rev-shared/media/category/EditCategory.Component';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { MEDIA_STATE_BROWSE } from '../StateConfig';

export const searchStates: IVbNg2StateDeclaration[] = [{
	name: 'portal.edit-category',
	url: '/category/edit?categoryId&parentCategoryId',
	params: {
		categoryId: null,
		parentCategoryId: null
	},
	component: EditCategoryComponent,
	resolve: [{
		token: 'categoryId',
		deps: [Transition],
		resolveFn: (t: Transition) => t.params().categoryId
	}, {
		token: 'parentCategoryId',
		deps: [Transition],
		resolveFn: (t: Transition) => t.params().parentCategoryId
	}, {
		token: 'category',
		deps: [AdminCategoryService, 'categoryId', 'parentCategoryId', UserContextService, SecurityContextService],
		resolveFn: (AdminCategoryService: AdminCategoryService, categoryId: string, parentCategoryId: string, UserContext: UserContextService, SecurityContext: SecurityContextService) =>
			categoryId ? AdminCategoryService.getCategory(categoryId) : {
				parentCategoryId,
				accountId: UserContext.getAccount().id,
				categoryPolicyItems: !SecurityContext.checkAuthorization('admin.media.categories.edit') ? [{
					id: UserContext.getUser().id,
					type: AccessEntityType.User,
					categoryManager: true
				}] : []
			}
	}, {
		token: 'cancelSref',
		deps: [Transition],
		resolveFn: (transition: Transition) => ({
			name: transition.from().name || MEDIA_STATE_BROWSE,
			params: transition.params('from')
		})
	}]
}];
