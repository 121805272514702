import {
	Directive,
	HostListener
} from '@vbrick/angular-ts-decorators';

import { IAugmentedJQuery } from 'angular';

@Directive({
	selector: '[vb-select-on-click]',
	bindToController: true
})
export class VbSelectOnClickDirective {
	constructor(
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	@HostListener('click')
	public onClick() {
		this.$element.select();
	}
}
