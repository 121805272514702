// Non-translated language names
export enum LanguageNames {
	da = 'Dansk',
	de = 'Deutsch',
	el = 'ελληνικά',
	en = 'English',
	'en-gb' = 'English (United Kingdom)',
	es = 'Español',
	'es-419' = 'Español (Latinoamérica)',
	'es-es' = 'Español (España)',
	fi = 'Suomi',
	fr = 'Français',
	'fr-ca' = 'Français (Canada)',
	id = 'Bahasa Indonesia',
	it = 'Italiano',
	ja = '日本語',
	ko = '한국어',
	nl = 'Nederlands',
	no = 'Norsk',
	pl = 'Polski',
	pt = 'Português',
	'pt-br' = 'Português (Brasileiro)',
	ru = 'Русский',
	sv = 'Svenska',
	th = 'ไทย',
	tr = 'Türkçe',
	zh = '中文',
	'zh-tw' = '中文',
	'zh-cmn-hans' = '中文普通话简体'
}
