<vb-title [title]="'Login_UserAgreement' | translate"></vb-title>

<div class="system-login-shell">
	<div class="table-display">
		<div class="table-row">
			<div class="table-cell system-login-nav">
				<div class="main-nav">
					<div class="navbar">
						<div class="table-cell brand-cell">
							<branding-logo></branding-logo>
						</div>

						<div class="table-cell"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell table-cell-centered-form">
				<div class="system-login col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-xl-4 col-xl-offset-4">

					<div [hidden]="!status.processing">
						<vb-loading-spinner [msg]="'Loading' | translate" [size]="'large'">
						</vb-loading-spinner>
					</div>

					<div class="system-form" [hidden]="!status.active">
						<h1>{{ 'Login_UserAgreement' | translate }}</h1>

						<p class="util--white-space-pre-wrap">{{ userAgreementMessage }}</p>

						<div class="row">

							<div class="col-sm-12">
								<div class="group pull-right">
									<button class="btn btn-icon-right btn-lg btn-sign-in" type="button" (click)="decline()">
										{{ 'Decline' | translate }}
									</button>

									<button class="btn btn-primary btn-icon-right btn-lg btn-sign-in" type="button" (click)="accept()">
										{{ 'Agree' | translate }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
