<form [ngClass]="styles.searchWrapper">
	<vb-ui-search-input name="searchInput" [ngClass]="styles.search" ngModel [placeholder]="'Reports_Attendees_Column_SearchPlaceholder' | translate">
	</vb-ui-search-input>
</form>
<vb-ui-infinite-scroll-grid [ngClass]="styles.grid" [getRows]="getRows" [clearScrollId]="AnalyticsService.clearEventScrollId" [pageSize]="pageSize" [quickFilterText]="searchText$ | async" [dataFetchContext]="dataFetchContext" (gridReady)="gridReady.emit($event)">
	<vb-ui-column-picker-group [headerName]="'Reports_Attendees_Column_Group_User' | translate" [id]="COLUMN_GROUP.USER" [isExpanded]="true">
	</vb-ui-column-picker-group>
	<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_System' | translate" [id]="COLUMN_GROUP.SYSTEM">
	</vb-ui-column-picker-group>
	<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_Session' | translate" [id]="COLUMN_GROUP.SESSION">
	</vb-ui-column-picker-group>
	<vb-ui-column-picker-group [headerName]="'Reports_Column_Group_Metrics' | translate" [id]="COLUMN_GROUP.METRICS">
	</vb-ui-column-picker-group>

	<!--Default Columns-->
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'DateViewed'" [headerName]="'Reports_Column_DateViewed' | translate" [sortable]="true" [filter]="false" [valueFormatter]="mediumDateValueFormatter">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.USER" [field]="'FullName'" [headerName]="'Reports_Attendees_Column_FullName' | translate" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.METRICS" [field]="'DropOff'" [headerName]="'Reports_Column_DropOff' | translate" [sortable]="true" [filter]="false">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.METRICS" [field]="'SessionTime'" [headerName]="'Reports_Attendees_Column_ViewingTime' | translate" [sortable]="true" [filter]="false">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.METRICS" [field]="'IsComplete'" [headerName]="'Reports_Column_Completed' | translate" [filter]="false" [sortable]="true">
	</vb-ui-data-grid-column>

	<!--Column Grouping-->
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.USER" [field]="'Username'" [headerName]="'Reports_Attendees_Column_UserName' | translate" [sortable]="true" [hide]="true" [cellRenderer]="'highlightText'" [tooltipField]="'Username'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.USER" [field]="'FirstName'" [headerName]="'FirstName' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.USER" [field]="'LastName'" [headerName]="'LastName' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.USER" [field]="'Email'" [headerName]="'Reports_Attendees_Column_Email' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>

	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'ZoneName'" [headerName]="'Reports_Column_ZoneName' | translate" [hide]="true" [sortable]="true" [tooltipField]="'ZoneName'" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'StreamDevice'" [headerName]="'Reports_Column_Device' | translate" [hide]="true" [sortable]="true" [tooltipField]="'StreamDevice'" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'StreamAccessed'" [headerName]="'Reports_Column_PlaybackUrl' | translate" [hide]="true" [sortable]="true" [tooltipField]="'StreamAccessed'" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SESSION" [field]="'IpAddress'" [headerName]="'IPAddress' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>

	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SYSTEM" [field]="'PlayerDevice'" [headerName]="'Reports_Attendees_Column_DeviceType' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SYSTEM" [field]="'OsFamily'" [headerName]="'Reports_Attendees_Column_Platform' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SYSTEM" [field]="'OsName'" [headerName]="'Reports_Attendees_Column_PlatformVersion' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
	<vb-ui-data-grid-column [columnPickerGroup]="COLUMN_GROUP.SYSTEM" [field]="'Browser'" [headerName]="'Reports_Attendees_Column_Browser' | translate" [hide]="true" [sortable]="true" [cellRenderer]="'highlightText'">
	</vb-ui-data-grid-column>
</vb-ui-infinite-scroll-grid>
