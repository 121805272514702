//angular
import 'angular';
import 'angular-resource';
import 'angular-animate';
import 'angular-messages'; //transcoding presets and form messages
import 'angular-sanitize';
import 'angular-aria';
import '@uirouter/angularjs';


import 'angular-ui-bootstrap/src/buttons'; //Ensures these all get rolled into sharedLib
import 'angular-ui-bootstrap/src/datepicker';
import 'angular-ui-bootstrap/src/datepickerPopup';
import 'angular-ui-bootstrap/src/dropdown';
import 'angular-ui-bootstrap/src/modal';
import 'angular-ui-bootstrap/src/rating';
import 'angular-ui-bootstrap/src/tabs';
import 'angular-ui-bootstrap/src/tooltip';
import 'angular-ui-bootstrap/src/typeahead';
