<div [ngClass]="styles.container" class="theme-primary-bg">
	<section [ngClass]="styles.rowDivider">
		<span [ngClass]="styles.dlgIcon" class="theme-primary-txt glyphicons camera"></span>
		<h4 class="theme-primary-txt">{{ 'Media_Videos_Settings_ThumbnailCapture' | translate }}</h4>
		<span [ngClass]="styles.spacer"></span>
		<span role="button" [ngClass]="styles.dlgIcon" class="theme-primary-txt glyphicons remove_2" [attr.aria-label]="'Close' | translate" (click)="closeDialog()"></span>
	</section>
	<div [ngClass]="styles.spinnerContainer" *ngIf="isVideoUnavailable; else thumbnailSelector">
		<vb-loading-spinner [size]="'large'">
		</vb-loading-spinner>
	</div>
	<ng-template #thumbnailSelector>
		<div [ngClass]="styles.playerContainer">
			<vb-video-player flex="1" [autoLoad]="true" [autoPlay]="false" [disableDualPlayback]="true" [durationMs]="video?.duration" [hasAudioOnly]="video?.hasAudioOnly" [heartbeatIntervalSecs]="video?.heartbeatInterval" [hidden]="!video?.playbacks" [is360]="video?.is360" [live]="video?.isLive" [playbacks]="video?.playbacks" [sourceType]="video?.sourceType" [subtitles]="[]" [thumbnailUri]="video?.thumbnailUri || (video?.hasAudioOnly ? '/shared/img/audio-thumbnail.png' : '')" [timeMarkers]="markers" [videoId]="video?.id" [videoTitle]="video?.title" [disableLogHeartbeat]="true" (onPlayerReady)="onPlayerReady($event.vgAPI)">
			</vb-video-player>
		</div>
	</ng-template>
	<button type="button" [ngClass]="styles.selectBtn" [disabled]="isVideoUnavailable" (click)="captureThumbnail()" vbUiBtnFab [attr.aria-label]="'Media_Videos_Settings_ThumbnailCapture' | translate">
		<span class="glyphicons camera"></span>
	</button>
</div>
