import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CsrfTokenHeader } from 'rev-shared/security/Tokens';

/**
 * Workaround for Angular's out of the box Xsrf intercepor deliberately excluding GET requests.
 *
 * https://github.com/angular/angular/blob/master/packages/common/http/src/xsrf.ts
 */
@Injectable()
class HttpXsrfInterceptor implements HttpInterceptor {
	constructor(
		private tokenService: HttpXsrfTokenExtractor
	) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: string = this.tokenService.getToken();

		if (
			!token ||
			req.method !== 'GET' ||
			req.url.startsWith('http')
		) {
			return next.handle(req);
		}

		return next.handle(req.clone({
			headers: req.headers.set(CsrfTokenHeader, token)
		}));
	}
}

export const HttpXSRFInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: HttpXsrfInterceptor,
	multi: true,
	deps: [
		HttpXsrfTokenExtractor
	]
};
