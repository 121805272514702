import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { PushService } from 'rev-shared/push/PushService';

@Injectable()
export class SparkService {
	private _categorySubscriptions: any;

	constructor(
		private http: HttpClient,
		private PushBus: PushBus,
		private PushService: PushService,
		private UserContext: UserContextService
	) {
	}

	public get categorySubscriptions(): any {
		return this._categorySubscriptions;
	}

	public cancelCategorySubscription(categoryId: string) {
		return this.PushService.dispatchCommand('media:CancelSparkCategorySubscription', {
			categoryId,
			userId: this.UserContext.getUser().id
		});
	}

	public createCategorySubscription(room: any, categoryId: string) {
		return this.PushService.dispatchCommand('media:CreateSparkCategorySubscription', {
			categoryId,
			roomId: room.id,
			roomTitle: room.title,
			userId: this.UserContext.getUser().id
		});
	}

	public getRooms(isGroupsOnly: boolean = false): Promise<any[]> {
		const userId = this.UserContext.getUser().id;
		return this.http.get<any>(`/network/users/${userId}/spark/rooms`, { params: { isGroupsOnly: isGroupsOnly + '' } })
			.toPromise()
			.then(response => response.rooms)
			.catch(err =>
				Promise.reject(err.status === 404 ?
					{ authenticationRequired: true } :
					err
				)
			);
	}

	public getWebexRoomDetails(roomId: string): Promise<any> {
		const userId = this.UserContext.getUser().id;

		return this.http.get(`/network/users/${userId}/spark/rooms/${roomId}/details`)
			.toPromise();
	}

	public loadCategorySubscriptions() {
		this._categorySubscriptions = {};

		if (!this.UserContext.isUserAuthenticated()) {
			return Promise.resolve();
		}

		const userId = this.UserContext.getUser().id;
		return this.http.get<any>(`/media/users/${userId}/spark-subscriptions`)
			.toPromise()
			.then(response =>
				response.categorySubscriptions.forEach(subscription => {
					this._categorySubscriptions[subscription.categoryId] = subscription;
				})
			);
	}

	public monitorCategorySubscriptions() {
		const userId: string = this.UserContext.getUser().id;

		return this.PushBus.subscribe(userId, {
			SparkCategorySubscriptionCreated: data => {
				this._categorySubscriptions[data.categoryId] = {
					roomId: data.roomId,
					roomTitle: data.roomTitle
				};
			},

			SparkCategorySubscriptionCancelled: data => {
				delete this._categorySubscriptions[data.categoryId];
			}
		});
	}

	public onAuthenticate(handler: () => void) {
		const userId: string = this.UserContext.getUser().id;

		const unsubscribe = this.PushBus.subscribe(userId, {
			SparkDetailsSaved() {
				unsubscribe();
				handler();
			}
		});

		return unsubscribe;
	}

	public shareVideo(message: any) {
		return this.http.post('/network/users/spark/rooms/share-video', message, {})
			.toPromise();
	}
}
