<vb-title [title]="'Title_UserConfirmation' | translate"></vb-title>

<vb-confirmation-dialog #userConfirmedDialog [title]="'Title_UserConfirmation' | translate" [message]="'Message_UserConfirmation' | translate" [actionText]="'Ok' | translate">
</vb-confirmation-dialog>

<div class="system-login-shell">
	<div class="table-display">
		<div class="table-row">
			<div class="table-cell system-login-nav">
				<div class="main-nav">
					<div class="navbar">
						<div class="table-cell brand-cell">
							<branding-logo></branding-logo>
						</div>

						<div class="table-cell"></div>
					</div>
				</div>
			</div>
		</div>


		<div class="table-row">
			<div class="table-cell table-cell-centered-form">
				<div class="system-login col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-xl-4 col-xl-offset-4">
					<!-- system errors -->
					<div [hidden]="status !== 'processing'">
						<vb-loading-spinner [msg]="'Loading' | translate" [size]="'large'">
						</vb-loading-spinner>
					</div>

					<div [hidden]="status !== 'saving'">
						<vb-loading-spinner [msg]="'Processing' | translate" [size]="'large'">
						</vb-loading-spinner>
					</div>

					<div [hidden]="status !== 'invalidToken'">
						<div class="status-msg">
							<h3>{{ 'UserConfirm_LinkNoLongerValid' | translate }}</h3>
							<button class="btn btn-primary btn-icon-right" uiSref="login">{{ 'UserConfirm_ReturnToLogin' | translate }}</button>
						</div>
					</div>

					<div [hidden]="status !== 'error'">
						<div class="status-msg">
							<span class="glyphicons circle_exclamation_mark"></span>&nbsp; {{ 'UnhandledError' | translate }}
						</div>
					</div>

					<div [hidden]="status !== 'invalidLicense'">
						<div class="status-msg">
							<h3>{{ 'License_Error_UserShort' | translate }}</h3>
							<button class="btn btn-primary btn-icon-right" uiSref="login">
								{{ 'UserConfirm_ReturnToLogin' | translate }}
							</button>
						</div>
					</div>

					<!-- end system errors -->
					<div [hidden]="status !== 'active'">
						<form #userConfirmationForm="ngForm" name="userConfirmationForm" class="system-form reset-password-form" (submit)="submit()">
							<h1>{{ 'UserConfirm_NewUserConfirmation' | translate }}</h1>

							<div class="form-group has-feedback" [ngClass]="{
								'required': passwordControl.pristine,
								'has-error': !passwordControl.pristine && passwordControl.invalid,
								'has-success': !passwordControl.pristine && passwordControl.valid
							}">

								<label>{{ 'Password' | translate }}</label>
								<input #passwordControl="ngModel" type="password" name="password" class="form-control" required [(ngModel)]="userConfirmation.password">

								<span class="form-control-feedback required-symbol">*</span>
								<span class="glyphicons ok_2 form-control-feedback"></span>
								<span class="glyphicons remove_2 form-control-feedback"></span>


								<label class="error-msg" [hidden]="!passwordRulesCheckerResult?.errorMinimumLength">
									{{ 'UserConfirm_MinimumLengthIs' | translate }} &nbsp;{{ passwordRules?.minimumLength }}.
								</label>
								<label class="error-msg" [hidden]="!passwordRulesCheckerResult?.errorMinimumUppercaseLetterCount">
									{{ 'UserConfirm_MustContainOneUppercaseLetter' | translate }}
								</label>
								<label class="error-msg" [hidden]="!passwordRulesCheckerResult?.errorMinimumLowercaseLetterCount">
									{{ 'UserConfirm_MustContainOneLowercaseLetter' | translate }}
								</label>
								<label class="error-msg" [hidden]="!passwordRulesCheckerResult?.errorMinimumNumberCount">
									{{ 'UserConfirm_MustContainOneDigit' | translate }}
								</label>
								<label class="error-msg" [hidden]="!passwordRulesCheckerResult?.errorMinimumSpecialCharacterCount">
									{{ 'UserConfirm_MustContainOneSpecialCharacter' | translate }}
								</label>
							</div>

							<div class="form-group has-feedback" [ngClass]="{
								'required': confirmPasswordControl.pristine,
								'has-error': !confirmPasswordControl.pristine && confirmPasswordControl.invalid,
								'has-success': !confirmPasswordControl.pristine && confirmPasswordControl.valid}">

								<label>{{ 'ConfirmPassword' | translate }}</label>
								<input #confirmPasswordControl="ngModel" class="form-control" type="password" required name="confirmPassword" [(ngModel)]="userConfirmation.confirmPassword">
								<span class="form-control-feedback required-symbol">*</span>
								<span class="glyphicons ok_2 form-control-feedback"></span>
								<span class="glyphicons remove_2 form-control-feedback"></span>

								<label class="error-msg" [hidden]="!(!confirmPasswordControl.errors?.required && confirmPasswordControl.errors?.noMatch)">
									{{ 'PasswordNoMatch' | translate }}
								</label>
							</div>


							<div class="form-group" [ngClass]="{
								'required': securityQuestionControl.pristine,
								'has-error': !securityQuestionControl.pristine && securityQuestionControl.invalid,
								'has-success': !securityQuestionControl.pristine && securityQuestionControl.valid
							}">
								<label>{{ 'SecurityQuestion' | translate }}</label>

								<select #securityQuestionControl="ngModel" name="securityQuestion" [(ngModel)]="userConfirmation.securityQuestion" required>
									<option value="">{{ 'UserConfirm_SecurityQuestion_SelectQuestion' | translate }}</option>
									<option>{{ 'UserConfirm_SecurityQuestion_WhereNewYear2000' | translate }}</option>
									<option>{{ 'UserConfirm_SecurityQuestion_CityNearestSibling' | translate }}</option>
									<option>{{ 'UserConfirm_SecurityQuestion_FavoriteBook' | translate }}</option>
									<option>{{ 'UserConfirm_SecurityQuestion_RoadGrewUp' | translate }}</option>
									<option>{{ 'UserConfirm_SecurityQuestion_FavoriteChildhoodFriend' | translate }}</option>
									<option>{{ 'UserConfirm_SecurityQuestion_FirstJobAttendingSchool' | translate }}</option>
									<option>{{ 'UserConfirm_SecurityQuestion_WhatWantToBe' | translate }}</option>
									<option>{{ 'UserConfirm_SecurityQuestion_WhereMeetSpouse' | translate }}</option>
									<option>{{ 'UserConfirm_SecurityQuestion_FirstMathTeacher' | translate }}</option>
								</select>
							</div>

							<div class="form-group has-feedback" [ngClass]="{
									'required': securityAnswerControl.pristine,
									'has-error': !securityAnswerControl.pristine && securityAnswerControl.invalid,
									'has-success': !securityAnswerControl.pristine && securityAnswerControl.valid}
								">
								<label>{{ 'UserConfirm_TypeYourAnswer' | translate }}</label>
								<input #securityAnswerControl="ngModel" type="text" name="securtyAnswer" minlength="4" [(ngModel)]="userConfirmation.securityAnswer" class="form-control" required>
								<span class="form-control-feedback required-symbol">*</span>
								<span class="glyphicons ok_2 form-control-feedback"></span>
								<span class="glyphicons remove_2 form-control-feedback"></span>

								<label class="error-msg" [hidden]="!(!securityQuestionControl.pristine && securityAnswerControl.invalid)">
									{{ 'MinLength' | translate: { '0': 4 } }}
								</label>

							</div>

							<div class="group system-form-buttons">
								<button class="btn btn-primary pull-right btn-lg" type="submit" [disabled]="userConfirmationForm.invalid">
									{{ 'UserConfirm_Submit' | translate }}
								</button>
							</div>
						</form>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
