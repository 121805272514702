import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AgGridModule } from '@ag-grid-community/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { HighlightModule } from 'rev-shared/pipes/highlight/Highlight.Module';
import { SearchInputModule } from 'rev-shared/ui/searchInputAngular/SearchInput.Module';
import { SidebarLayoutModule } from 'rev-shared/ui/sidebarLayoutAngular/SidebarLayout.Module';

import { VbUiDataGridComponent } from './VbUiDataGrid.Component';
import { VbUiInfiniteScrollGridComponent } from './infiniteScroll/VbUiInfiniteScrollGrid.Component';
import { VbUiTreeGridComponent } from './tree/VbUiTreeGrid.Component';

import { VbUiDataGridAutoGroupColumnComponent } from './columns/VbUiDataGridAutoGroupColumn.Component';
import { VbUiDataGridColumnComponent } from './columns/VbUiDataGridColumn.Component';

import { ActionMenuButtonCellRendererComponent } from './cellRenderers/ActionMenuButtonCellRenderer.Component';
import { ButtonGroupCellRendererComponent } from './cellRenderers/ButtonGroupCellRenderer.Component';
import { CheckboxCellRendererComponent } from './cellRenderers/CheckboxCellRenderer.Component';
import { HighlightCellRendererComponent } from './cellRenderers/HighlightCellRenderer.Component';
import { IconCellRendererComponent } from './cellRenderers/IconCellRenderer.Component';
import { LinkCellRendererComponent } from './cellRenderers/LinkCellRenderer.Component';
import { VbUiDataGridTooltipComponent } from './tooltip/VbUiDataGridTooltip.Component';

import { VbUiDataGridColumnPickerGroup } from './columnPicker/VbUiDataGridColumnPickerGroup.Component';
import { VbUiDataGridColumnPicker } from './columnPicker/VbUiDataGridColumnPicker.Component';

const gridRendererComponents = [
	ActionMenuButtonCellRendererComponent,
	ButtonGroupCellRendererComponent,
	CheckboxCellRendererComponent,
	HighlightCellRendererComponent,
	IconCellRendererComponent,
	LinkCellRendererComponent,
	VbUiDataGridTooltipComponent
];

const components = [
	...gridRendererComponents,
	VbUiDataGridColumnPickerGroup,
	VbUiDataGridAutoGroupColumnComponent,
	VbUiDataGridColumnComponent,
	VbUiDataGridColumnPicker,
	VbUiDataGridComponent,
	VbUiInfiniteScrollGridComponent,
	VbUiTreeGridComponent
];

@NgModule({
	declarations: components,
	entryComponents: gridRendererComponents, // any grid renderers must be entryComponents
	exports: components,
	imports: [
		AgGridModule,
		BsDropdownModule,
		ButtonsAngularModule,
		CheckboxAngularModule,
		CommonModule,
		CssRulesAngularModule,
		FormsModule,
		HighlightModule,
		SearchInputModule,
		SidebarLayoutModule,
		TranslateModule,
		UIRouterModule
	]
})
export class DataGridModule {}
