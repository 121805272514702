import { Component, Inject, Input, LOCALE_ID, OnChanges, SimpleChanges } from '@angular/core';
import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';

import { isNumber } from 'rev-shared/util';

import styles from './VbUiKpi.Component.module.less';

const ICON_DELTA_NEG: string = 'circle_arrow_down';
const ICON_DELTA_POS: string = 'circle_arrow_top';
const ICON_THRESHOLD_EXCEEDED: string = 'circle_exclamation_mark';
const ICON_THRESHOLD_WITHIN: string = 'circle_ok';

@Component({
	selector: 'vb-ui-kpi',
	templateUrl: './VbUiKpi.Component.html'
})
export class VbUiKpiComponent implements OnChanges {
	@Input() public decimalPlaces: number = 0;
	@Input() public hideChangeIndicators: boolean = false;
	@Input() public suffix: string = '';
	@Input() public reverseColors: boolean = false;
	@Input() public threshold: number;
	@Input() public value: number;

	public delta: number;
	public readonly localeDecimalSymbol: string = getLocaleNumberSymbol(this.localeId, NumberSymbol.Decimal);
	public readonly localeGroupSymbol: string = getLocaleNumberSymbol(this.localeId, NumberSymbol.Group);
	public readonly styles = styles;

	constructor(
		@Inject(LOCALE_ID) private localeId: string
	) {}

	public get deltaCssClass(): string {
		let delta: number = this.delta;

		if (this.reverseColors) {
			delta *= -1;
		}

		if (delta > 0) {
			return styles.valueDeltaPositive;
		}

		if (delta < 0) {
			return styles.valueDeltaNegative;
		}

		return '';
	}

	public get deltaIconCssClass(): string {
		if (isNumber(this.threshold)) {
			return '';
		}

		if (this.delta > 0) {
			return ICON_DELTA_POS;
		}

		if (this.delta < 0) {
			return ICON_DELTA_NEG;
		}

		return '';
	}

	public get thresholdIconCssClass(): string {
		if (isNumber(this.threshold)) {
			return this.delta > 0 ?
				ICON_THRESHOLD_WITHIN :
				ICON_THRESHOLD_EXCEEDED;
		}

		return '';
	}

	public ngOnChanges(changes: SimpleChanges): void {
		const isChange: boolean = !!(
			changes.threshold ||
			changes.value
		);

		if (isChange && isNumber(this.value)) {
			this.updateDelta(changes.value?.previousValue);
		}
	}

	private updateDelta(previousValue: number): void {
		if (isNumber(this.threshold)) {
			this.delta = this.value <= this.threshold ? 1 : -1;
			return;
		}

		this.delta = !this.hideChangeIndicators && isNumber(previousValue) ?
			this.value - previousValue :
			0;
	}
}
