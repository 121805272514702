import {
	AfterViewInit,
	Directive,
	Input
} from '@vbrick/angular-ts-decorators';

import { INgModelController } from 'angular';

interface IPortRange {
	end?: string | number;
	start: string | number;
}
const RANGE_SEPARATOR: string = '-';

@Directive({
	selector: '[vb-port-range-input]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbPortRangeInputDirective implements AfterViewInit {
	@Input() public minPort: number;
	@Input() public maxPort: number;

	private ngModel: INgModelController;
	private isMinMaxProvided: boolean;

	public ngAfterViewInit(): void {
		this.isMinMaxProvided = !!this.minPort && !!this.maxPort;
		this.ngModel.$formatters = [modelValue => this.format(modelValue)];
		this.ngModel.$parsers.push(viewValue => this.parse(viewValue));
		this.ngModel.$validators.invalidPortRange = (modelValue, viewValue) => this.validate(modelValue, viewValue);
	}


	private parse(textInput: string): IPortRange {
		const input = (textInput || '')
			.split(RANGE_SEPARATOR)
			.map(line => line.trim());

		if (!input.length) {
			return null; //to avoid parse error;
		}

		const end = input.slice(1, input.length).join('');

		return {
			start: input[0],
			end
		};
	}

	private format(modelValue: IPortRange): string {
		if (!modelValue || !modelValue.start) {
			return;
		}
		return modelValue.end ? `${modelValue.start}-${modelValue.end}` : modelValue.start.toString();
	}

	private validate(modelValue: IPortRange, viewValue: string): boolean {
		if (!modelValue) {
			return true;
		}

		const start = +modelValue.start;
		const end = +modelValue.end;

		if (isNaN(start) || (modelValue.end && isNaN(end))) {
			return false;
		}
		return end ? this.validatePortRange(start, end)
			: this.validatePort(start);
	}

	private validatePort(port: number) {
		return !this.isMinMaxProvided ? true
			: this.validateRange(this.minPort, port) && this.validateRange(port, this.maxPort);
	}

	private validatePortRange(start: number, end: number) {
		return start < end
			&& this.validatePortRangeWithMinMax(start, end);
	}

	private validatePortRangeWithMinMax(start: number, end: number): boolean {
		if (!this.isMinMaxProvided) {
			return true;
		}

		return this.validateRange(this.minPort, start)
			&& this.validateRange(end, this.maxPort);
	}

	private validateRange(min: number, max: number): boolean {
		return min < max;
	}
}
