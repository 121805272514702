import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';

import { VbUiInsightComponent } from './VbUiInsight.Component';

@NgModule({
	imports: [
		CommonModule,
		//UpgradeModule, commenting to workaround an error: "Trying to get the AngularJS injector before it being set."
	],
	declarations: [
		VbUiInsightComponent
	],
	exports: [
		VbUiInsightComponent
	]
})
export class VbUiInsightModule {
}
