<div layout="row" layout-wrap="false" flex-align="start">
	<button type="button" *ngIf="showExpander" [ngClass]="styles.expanderBtn" (click)="onExpanderClick()">
		<span class="glyphicons chevron-right" [ngClass]="isCollapsed ? '' : styles.expanderIconExpanded">
		</span>
	</button>

	<vb-ui-checkbox *ngIf="showGroupCheckbox" flex="1 1 0" [mixedStateEnabled]="true" [ngClass]="styles.groupCheckbox" [(ngModel)]="isGroupChecked" (touched)="onTouched()">
		{{ groupLabel }}
	</vb-ui-checkbox>
</div>

<ul [hidden]="isCollapsed" [ngClass]="[
		styles.list,
		showExpander ? styles.listWithExpander : ''
	]" ngForm="checkGroup">
	<li *ngFor="let option of internalModel" [hidden]="option.isHidden" [ngClass]="styles.listItem">
		<vb-ui-checkbox [disabled]="isDisabled" [name]="option.id" [ngClass]="styles.checkbox" [(ngModel)]="option.isChecked" (touched)="onTouched()">
			<span [innerHTML]="option.label | highlight : highlightMatch : styles.highlight">
			</span>
		</vb-ui-checkbox>
	</li>
</ul>