import { AfterContentInit, Component, ContentChildren, EventEmitter, HostBinding, Input, Output, QueryList, SimpleChanges, TemplateRef } from '@angular/core';

import { VbUiTabComponent } from './VbUiTab.Component';

import styles from './VbUiTabPanel.Component.module.less';

@Component({
	selector: 'vb-ui-tab-panel',
	templateUrl: './VbUiTabPanel.Component.html',
	host: {
		role: 'tabs'
	}
})
export class VbUiTabPanelComponent implements AfterContentInit {
	@Input() public allowSelectedTabClick: boolean;
	@Input() public verticalFill: boolean;
	@Input() public tabPanelInfoTemplate: TemplateRef<any>;
	@Input() public themed: boolean;

	@Output() public onSelect = new EventEmitter<{ tabId: string }>();

	@ContentChildren(VbUiTabComponent) public tabs: QueryList<VbUiTabComponent>;

	public readonly styles = styles;
	public readonly tabPanelId: string = '' + Math.random();

	public selectedTab: VbUiTabComponent;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.verticalFill
				&& !changes.verticalFill.isFirstChange()) {
			this.selectedTab?.toggleCssClass(styles.tabVerticalFill, this.verticalFill);
		}
	}

	public ngAfterContentInit(): void {
		this.selectTab(this.tabs.first);
	}

	@HostBinding('attr.layout')
	public get layout(): string {
		return this.verticalFill ?
			'column' :
			undefined;
	}

	public selectTab(tab: VbUiTabComponent): void {
		if (!this.allowSelectedTabClick && this.selectedTab === tab) {
			return;
		}

		if(this.selectedTab) {
			this.setTabActive(this.selectedTab, false);
		}
		this.setTabActive(tab, true);
		this.selectedTab = tab;

		this.onSelect.emit({ tabId: tab.tabId });
	}

	private setTabActive(tab: VbUiTabComponent, isActive: boolean): void {
		tab.toggleCssClass(styles.tabVerticalFill, this.verticalFill && isActive);
		tab.isActive = isActive;
	}
}
