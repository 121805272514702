import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
//import { tap } from 'rxjs/operators';

import { storageAvailable } from 'rev-shared/util/BrowserFeature.Service';

import { IUserContextUser } from './UserContext.Service';

const UserKey = 'ucs1';

/**
 * Uses local storage to share the user context between tabs/windows
 */
@Injectable({
	providedIn: 'root'
})
export class UserContextStoreService {
	private readonly subject$ = new Subject<IUserContextUser>();
	private readonly storage: Storage;

	public readonly user$ = this.subject$.asObservable()/*.pipe(
		tap(() => this.$timeout())
	)*/;

	constructor() {
		if(storageAvailable('localStorage')) {
			this.storage = window.localStorage;
			window.addEventListener('storage', e => this.onStorage(e));
		}
	}

	public setUser(user: IUserContextUser): void {
		this.subject$.next(user);
		if(this.storage) {
			this.storage.setItem(UserKey, this.userToJson(user));
			setTimeout(() => this.storage.removeItem(UserKey), 1000);
		}
	}

	private onStorage(e: StorageEvent): void {
		if(e.key === UserKey &&
			e.storageArea === this.storage &&
			e.newValue) {
			this.subject$.next(this.jsonToUser(e.newValue));
		}
	}

	private userToJson(user: IUserContextUser): string {
		return JSON.stringify(user);
	}

	private jsonToUser(user: string): IUserContextUser {
		try {
			return JSON.parse(user);
		} catch(e) {
			//ignore
		}
	}
}
