import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vb-short]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbShortDirective implements OnInit {
	private ngModel: INgModelController;

	public ngOnInit(): void {
		this.ngModel.$parsers.push(value => this.validate(value));
		this.ngModel.$formatters.push(value => this.validate(value));
	}

	private validate(value: string): string {
		this.ngModel.$setValidity('vbShort', ValidationRules.checkShortText(value));

		return value;
	}
}
