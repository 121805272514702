import { Directive } from '@angular/core';

import styles from './VbUiBtnNgx.Directive.module.less';

@Directive({
	selector: '[vbUiBtnPrimaryNgx]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbUiBtnPrimaryNgxDirective {
	public hostClass = `${styles.vbUiBtn} ${styles.btnPrimary} theme-accent theme-accent-font-border`;
}
