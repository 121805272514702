import { auto } from 'angular';
import { InjectionToken } from '@angular/core';

import { IDialogService } from './IDialog';

export const DIALOG_TOKEN = new InjectionToken('IDialogService');

//Patches old AngularJS 'Dialog' injectable to be provided in Angular
export const DialogAngularProvider = {
	provide: DIALOG_TOKEN,
	deps: ['$injector'],
	useFactory: ($injector: auto.IInjectorService): IDialogService => $injector.get('Dialog')
};
