import { Component, OnInit } from '@angular/core';

import { UserAuthenticationService } from 'rev-shared/security/UserAuthentication.Service';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';

@Component({
	selector: 'forgot-password',
	templateUrl: './ForgotPassword.Component.html'
})
export class ForgotPasswordComponent implements OnInit {
	public expiration: number;
	public lastError: string;
	public status: any;
	public username: string;

	constructor(
		private DateParsers: DateParsersService,
		private UserAuthenticationService: UserAuthenticationService
	) {}

	public ngOnInit(): void {
		this.resetStatus();
		this.status.active = true;
	}

	public submit(): void {
		this.resetStatus();
		this.status.processing = true;

		this.lastError = undefined;

		this.UserAuthenticationService.requestPasswordReset(this.username)
			.then(() => {
				this.resetStatus();
				this.status.success = true;
			}, (error: any) => {
				this.resetStatus();
				this.status.error = true;

				if (error.hasIssue('CommandDenied')) {
					this.lastError = 'ForgotPassword_CommandDenied';
				}
			});
	}

	private resetStatus(): void {
		this.status = {};
	}
}
