import { Directive, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
	selector: '[vbMax]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbMaxDirective),
			multi: true
		}
	]
})
export class VbMaxDirective {
	@Input() public vbMax: any;

	public validate(control: AbstractControl): ValidationErrors {
		if (this.vbMax == null || !control?.value) {
			return null;
		}
		return !(control.value >= this.vbMax) ? null : { vbMax: true };
	}
}
