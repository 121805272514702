import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';

@NgModule({
	imports: [
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates([{
				name: 'portal.edit-video.**',
				url: '/videos/:videoId/edit',
				loadChildren() {
					return import(/* webpackChunkName: 'edit-video' */ './EditVideo.Module')
						.then(m => m.EditVideoModule);
				}
			}])
		})
	]
})
export class EditorLoaderModule {
}
