import { NgModule } from '@angular/core';

import { TranscriptionModule } from 'rev-shared/media/transcription/Transcription.module';
import { VideoAdvancedSettingsAngularModule } from './advanced/VideoAdvancedSettings.AngularModule';

@NgModule({
	imports: [
		TranscriptionModule,
		VideoAdvancedSettingsAngularModule
	]
})
export class VideoSettingsAngularModule {}
