import {
	AfterContentInit,
	AfterViewInit,
	Component,
	ComponentFactoryResolver,
	ContentChild,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	LOCALE_ID,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewContainerRef,
	ViewEncapsulation,
	NgZone
} from '@angular/core';

import { AgGridAngular } from '@ag-grid-community/angular';
import { AngularFrameworkComponentWrapper } from '@ag-grid-community/angular/esm2015/lib/angularFrameworkComponentWrapper';
import { AngularFrameworkOverrides } from '@ag-grid-community/angular/esm2015/lib/angularFrameworkOverrides';
import type { Column, Module } from '@ag-grid-community/core';
import type { ColumnState } from '@ag-grid-community/core/dist/cjs/columnController/columnController';

import { TranslateService } from '@ngx-translate/core';

import { Subscription, merge } from 'rxjs';

import { ISidebarConfig } from 'rev-shared/ui/sidebarLayoutAngular/ISidebarConfig';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';

import { VbUiDataGridAutoGroupColumnComponent } from './columns/VbUiDataGridAutoGroupColumn.Component';
import { VbUiDataGridColumnComponent } from './columns/VbUiDataGridColumn.Component';

import { VbUiDataGridColumnPickerGroup } from './columnPicker/VbUiDataGridColumnPickerGroup.Component';

import { ActionMenuButtonCellRendererComponent } from './cellRenderers/ActionMenuButtonCellRenderer.Component';
import { ButtonGroupCellRendererComponent } from './cellRenderers/ButtonGroupCellRenderer.Component';
import { CheckboxCellRendererComponent } from './cellRenderers/CheckboxCellRenderer.Component';
import { HighlightCellRendererComponent } from './cellRenderers/HighlightCellRenderer.Component';
import { IconCellRendererComponent } from './cellRenderers/IconCellRenderer.Component';
import { LinkCellRendererComponent } from './cellRenderers/LinkCellRenderer.Component';
import { DUMMY_TOOLTIP, VbUiDataGridTooltipComponent } from './tooltip/VbUiDataGridTooltip.Component';

import { IVbUiDataGridContext } from './IVbUiDataGridContext';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';

import styles from './VbUiDataGrid.Component.module.less';
import { isString } from 'rev-shared/util';

export const COMPONENT_HOST = {
	'[class]': 'hostClass',
	layout: 'row',
	'layout-wrap': 'false'
};

export const COMPONENT_PROVIDERS = [
	AngularFrameworkComponentWrapper,
	AngularFrameworkOverrides
];

export const COMPONENT_TEMPLATE = `
	<ng-template [vbCssRules]="themeStyleOverrides"></ng-template>
	<vb-ui-sidebar
		*ngIf="columnPickerEnabled"
		[isMobileLayoutDisabled]="true"
		[openWidthPx]="250"
		[sidebarConfig]="sidebarConfig">

		<div slot="panelBody">
			<vb-ui-data-grid-column-picker
				[columns]="allColumns"
				[columnApi]="columnApi"
				[groups]="columnPickerGroups">
			</vb-ui-data-grid-column-picker>
		</div>

	</vb-ui-sidebar>
`;

export interface IVbUiDataGridState {
	columns: ColumnState[];
	quickFilter: string;
	sort: Array<{ colId: string; sort: string }>;
}

/**
 * vb-ui-data-grid
 * Extends the @ag-grid-community/angular grid with some of our desired core functionality:
 * - Lazy loading of ag-grid module dependencies.
 * - Defining common renderers for simple reuse.
 * - Sizing columns to fit.
 * - A ui-field shorthand input for populating getRowNodeId().
 * - Populating the grid context so that tools and information may easily be passed around (such as a getTranslation() function).
 */
@Component({
	selector: 'vb-ui-data-grid',
	template: COMPONENT_TEMPLATE,
	host: COMPONENT_HOST,
	providers: COMPONENT_PROVIDERS,
	encapsulation: ViewEncapsulation.None
})
export class VbUiDataGridComponent extends AgGridAngular implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
	@Input() public columnPickerEnabled: boolean;
	@Input() public sizeColumnsToFit: boolean = true;
	@Input() public restoreState: IVbUiDataGridState;
	@Input() public uidField: string; // Field containing a uid string. Shorthand for populating a getRowNodeId() getter.
	@Input() public themed: boolean;

	@Output() public currentStateChange = new EventEmitter<IVbUiDataGridState>();

	@ContentChildren(VbUiDataGridColumnComponent) public columns: QueryList<VbUiDataGridColumnComponent>;
	@ContentChild(VbUiDataGridAutoGroupColumnComponent) public autoGroupColumn: VbUiDataGridAutoGroupColumnComponent;
	@ContentChildren(VbUiDataGridColumnPickerGroup) public columnPickerGroups: QueryList<VbUiDataGridColumnPickerGroup>;

	public readonly styles = styles;

	public allColumns: Column[];
	public hostClass: string = `ag-theme-material box-block ${this.styles.root}`;
	public themeStyleOverrides: IRules;
	protected isDestroyed: boolean;
	protected subscriptions: Subscription[] = [];

	protected moduleLazyLoadPromise: Promise<void>;

	public sidebarConfig: ISidebarConfig = {
		buttons: [
			{
				iconClass: `vb-icon vb-icon-column-picker ${this.styles.columnPickerIcon}`,
				id: 'columnPicker',
				label: this.translateService.instant('UI_DataGrid_ColumnPicker_SidebarHeader')
			}
		]
	};

	constructor(
		elementDef: ElementRef,
		viewContainerRef: ViewContainerRef,
		@Inject(AngularFrameworkOverrides) angularFrameworkOverrides: AngularFrameworkOverrides,
		@Inject(AngularFrameworkComponentWrapper) frameworkComponentWrapper: AngularFrameworkComponentWrapper,
		_componentFactoryResolver: ComponentFactoryResolver,
		protected translateService: TranslateService,
		private ThemeService: ThemeService,
		public zone: NgZone,
		@Inject(LOCALE_ID) private localeId: string
	){
		super(elementDef, viewContainerRef, angularFrameworkOverrides, frameworkComponentWrapper, _componentFactoryResolver);
	}

	public ngOnInit(): void {
		this.moduleLazyLoadPromise = this.lazyLoadGridModules();

		this.subscriptions.push(
			this.firstDataRendered.subscribe(() => this.onFirstDataRenderedInternal()),
			this.gridSizeChanged.subscribe(() => this.onSizeChanged()),
			this.initGridStateChange(),
			this.ThemeService.brandingSettings$.subscribe(brandingSettings => this.applyCssRules(brandingSettings))
		);
	}

	public ngAfterContentInit(): void {
		// Apply translations for ag-grid labels
		this.localeText = {
			loadingOoo: this.translateService.instant('Loading'),
			noRowsToShow: this.translateService.instant('UI_Chart_NoDataAvailable')
		};

		// Context allows us to pass common things into our grid code such as renderers.
		this.context = {
			getQuickFilterText: () => this.quickFilterText,
			getTranslation: (key: string | string[]) => this.translateService.instant(key),
			localeId: this.localeId
		} as IVbUiDataGridContext;

		if (this.autoGroupColumn) {
			this.autoGroupColumnDef = this.autoGroupColumn.toColDef();
		}

		// Short-hand convenience feature for populating a getRowNodeId() function.
		if (this.uidField && !this.getRowNodeId) {
			this.getRowNodeId = data => data[this.uidField];
		}

		// Make commonly used cell renderers readily available by name/id.
		this.frameworkComponents = {
			actionMenuButton: ActionMenuButtonCellRendererComponent,
			buttonGroup: ButtonGroupCellRendererComponent,
			checkbox: CheckboxCellRendererComponent,
			icon: IconCellRendererComponent,
			link: LinkCellRendererComponent,
			highlightText: HighlightCellRendererComponent,
			gridTooltip: VbUiDataGridTooltipComponent
		};

		//To avoid warning - invalid colDef property '_ngContext'
		this.suppressPropertyNamesCheck = true;
	}

	public ngAfterViewInit() {
		return this.moduleLazyLoadPromise
			.then(() => {
				if (this.isDestroyed) {
					return;
				}

				this.applyConfigToColumns();

				this.applyRestoreState();

				// capture quickFilterText to silence feature warning
				const qf = this.quickFilterText;
				this.quickFilterText = null;

				super.ngAfterViewInit();

				// restore
				this.quickFilterText = qf;

				if (this.restoreState) {
					this.columnApi.setColumnState(this.restoreState.columns);
				}

				this.allColumns = this.columnApi.getAllColumns();
			});
	}

	public ngOnDestroy(): void {
		this.isDestroyed = false;

		this.subscriptions.forEach(sub => sub.unsubscribe());
		this.subscriptions = null;
	}

	private applyCssRules(brandingSettings: BrandingSettings): void {
		if (!this.themed) {
			return;
		}
		const primaryFontColor = brandingSettings.themeSettings.primaryFontColor;
		const primaryColor = brandingSettings.themeSettings.primaryColor;


		this.themeStyleOverrides = {
			[`.${styles.root} .ag-root-wrapper`]: {
				'background-color': ` ${primaryColor} !important`
			},
			[`.${styles.root} .ag-root-wrapper .ag-row `]: this.getheaderAndRowTheme(primaryColor, primaryFontColor),
			[`.${styles.root} .ag-root-wrapper .ag-header `]: this.getheaderAndRowTheme(primaryColor, primaryFontColor)
		};
	}

	public getCurrentState(): IVbUiDataGridState {
		const columns = this.columnApi.getColumnState();
		const sort = columns?.map(column => column.sort && { colId: column.colId, sort: column.sort }).filter(Boolean);

		return {
			columns,
			quickFilter: this.quickFilterText,
			sort
		};
	}

	protected get isClientSideModel(): boolean {
		return !this.rowModelType;
	}

	protected applyRestoreState(): void {
		if (this.restoreState) {
			this.columns.forEach(col => {
				if (this.restoreState.sort) {
					const sortMatch = this.restoreState.sort.find(sortCol => sortCol.colId === col.field);

					col.sort = sortMatch?.sort;
				}
			});

			if (isString(this.restoreState.quickFilter)) {
				this.quickFilterText = this.restoreState.quickFilter;
			}
		}
	}

	protected getAdditionalModules(): Promise<Module[]> {
		return Promise.resolve([]);
	}

	private getClientSideRowModel(): Promise<Module> {
		return import(/* webpackChunkName: "ag-grid-client-side-row-model" */ '@ag-grid-community/client-side-row-model')
			.then(clientSideExports => clientSideExports.ClientSideRowModelModule);
	}

	protected initGridStateChange(): Subscription {
		return merge(
			this.columnResized,
			this.columnVisible,
			this.filterChanged,
			this.sortChanged
		)
			.subscribe(() => this.currentStateChange.emit(this.getCurrentState()));
	}

	protected onFirstDataRenderedInternal(): void {
		if (this.sizeColumnsToFit && !this.restoreState) {
			this.api.sizeColumnsToFit();
		}
	}

	protected onSizeChanged(): void {
		if (this.sizeColumnsToFit && !this.columnPickerEnabled) {
			this.api.sizeColumnsToFit();
		}
	}

	public setQuickFilterText(value: string, ignoreRefresh?: boolean): void {
		const currentQuickFilterText: string = this.quickFilterText || '';

		if (currentQuickFilterText === value) {
			return;
		}

		this.quickFilterText = value;

		if (!ignoreRefresh) {
			this.api.setQuickFilter(value);
			this.columnApi.getAllColumns().forEach(col => {
				const colDef = col.getColDef();
				const filterEnable = col.isVisible() && colDef.filter ? colDef.colId || colDef.field : undefined;
				if (filterEnable) {
					col.setFilterActive(!!this.quickFilterText);
				}
			});
			this.api.refreshCells({ force: true });
		}
	}

	private getInfiniteRowModel(): Promise<Module> {
		return import(/* webpackChunkName: "ag-grid-infinite-row-model" */ '@ag-grid-community/infinite-row-model')
			.then(infiniteRowModelExports => infiniteRowModelExports.InfiniteRowModelModule);
	}

	private getRowModel(): Promise<Module> {
		return this.isClientSideModel ?
			this.getClientSideRowModel() :
			this.getInfiniteRowModel();
	}

	private lazyLoadGridModules(): Promise<void> {
		return Promise.all([
			this.getRowModel(),
			this.getAdditionalModules()
		])
			.then(([rowModelModule, additionalModules]) => {
				this.modules = [rowModelModule, ...additionalModules];
			});
	}

	private addDefaultTooltipConfigToColumn(col: VbUiDataGridColumnComponent): void {
		col.headerClass = col.headerTooltip
			? col.headerClass ? `${col.headerClass} ${styles.hasHeaderTooltipValue}` : styles.hasHeaderTooltipValue
			: col.headerClass;
		col.headerTooltip = col.headerTooltip || DUMMY_TOOLTIP;
		col.tooltipComponent = col.tooltipComponent || 'gridTooltip';
	}

	private applySortingOrderReversedToColumn(col: VbUiDataGridColumnComponent): void {
		if (col.sortingOrderReversed) {
			col.sortingOrder = ['desc', 'asc'];
		}
	}

	private disableColumnQuickFilterText(col: VbUiDataGridColumnComponent): void {
		if (!col.filter) {
			col.getQuickFilterText = () => '';
		}
	}

	private applyConfigToColumns(): void {
		this.columns.forEach(col => {
			this.applySortingOrderReversedToColumn(col);
			this.addDefaultTooltipConfigToColumn(col);
			this.disableColumnQuickFilterText(col);
			col.suppressMenu = true;
		});
	}

	private getheaderAndRowTheme(primaryColor: string, primaryFontColor: string): { [key: string]: string } {
		return {
			'background-color': ` ${primaryColor} !important`,
			'color': `${primaryFontColor} !important`,
			'border-color': `${this.ThemeService.primaryFontFade30}`
		};
	}
}
