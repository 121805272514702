import { NgZone } from '@angular/core';
import type { IDatasource, IGetRowsParams } from '@ag-grid-community/core';

import { noop } from 'rev-shared/util';

import { IGetInfiniteScrollRows } from './IGetInfiniteScrollRows';
import { IVbUiInfiniteScrollGridDataSourceParams } from './IVbUiInfiniteScrollGridDataSourceParams';

export class VbUiInfiniteScrollGridDataSource implements IDatasource {
	private currentPage: number = 0;
	public scrollId: string;
	public previousScrollId: string;
	public scrollExpired: boolean;

	constructor(
		protected getInfiniteScrollRows: IGetInfiniteScrollRows,
		protected isSortable: boolean,
		private zone: NgZone,
		private dataFetchContext?: any
	) {}

	public getRows(params: IGetRowsParams): void {
		const pageSize: number = params.endRow - params.startRow;
		const [sortModel] = params.sortModel;

		if (!params.startRow) {
			this.reset();
		}

		this.scrollExpired = false;
		const infiniteScrollParams = this.buildParams(params, pageSize, sortModel);

		this.getInfiniteScrollRows(infiniteScrollParams)
			.then(result => {
				++this.currentPage;
				this.scrollId = result.scrollId;
				this.previousScrollId = null; //scroll must be deleted by api when passed

				const { data, totalRows } = result;
				const lastRow: number = (this.currentPage - 1) * pageSize + data.length < totalRows ?
					-1 :
					totalRows;

				params.successCallback(data, lastRow);
			}, e => {
				if(e?.status == 404) {
					this.scrollExpired = true;
				}

				params.failCallback();
			})
			.finally(() => this.zone.run(noop));
	}

	public resetScrollId(): void {
		this.previousScrollId = this.scrollId;
		this.scrollId = null;
	}

	public setDataFetchContext(context: any): void {
		this.dataFetchContext = context;
	}

	protected buildParams(params: IGetRowsParams, pageSize: number, sortModel: any): IVbUiInfiniteScrollGridDataSourceParams {
		const { filterModel } = params;
		const filterFields: string[] = Object.keys(filterModel);
		const query = filterModel[filterFields[0]]?.filter;

		return {
			...params,
			dataFetchContext: this.dataFetchContext,
			filterFields,
			isSortAscending: !sortModel || sortModel.sort === 'asc',
			pageSize,
			query,
			scrollId: this.scrollId,
			previousScrollId: this.previousScrollId,
			sortField: sortModel?.colId
		};
	}

	protected reset(): void {
		this.currentPage = 0;
		this.scrollId = undefined;
	}
}
