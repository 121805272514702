<vb-title [title]="'Title_LogOn' | translate"></vb-title>

<div class="system-login-shell">
	<div class="table-display">
		<div class="table-row">
			<div class="table-cell system-login-nav">
				<div class="main-nav">
					<div class="navbar">
						<div class="table-cell brand-cell">
							<branding-logo></branding-logo>
						</div>

						<div class="table-cell"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell table-cell-centered-form">
				<div class="system-login col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-xl-4 col-xl-offset-4">

					<div [hidden]="!status.processing">
						<vb-loading-spinner [msg]="'Loading' | translate" [size]="'large'">
						</vb-loading-spinner>
					</div>

					<div [hidden]="!status.loading">
						<vb-loading-spinner [msg]="'Login_LogoffMessage' | translate" [size]="'large'">
						</vb-loading-spinner>
					</div>

					<div [hidden]="!status.active">

						<form #loginForm="ngForm" class="system-form" name="loginForm" (submit)="submit()">

							<h1>{{ 'SignIn' | translate }}</h1>

							<div class="system-msg error-msg" [hidden]="!status.badCredentials">
								<div>
									<span class="glyphicons remove_2"></span>{{ 'Login_BadCredentialsMessage' | translate }}
								</div>
							</div>

							<div class="system-msg error-msg" [hidden]="!status.lockedOut">
								<div>
									<span class="glyphicons remove_2"></span>{{ 'Login_LockedOutMessage' | translate }}
								</div>
							</div>

							<div class="system-msg error-msg" [hidden]="!status.suspended">
								<div>
									<span class="glyphicons remove_2"></span>{{ 'Login_SuspendedMessage' | translate }}
								</div>
							</div>

							<div class="system-msg error-msg" [hidden]="!status.maintenance">
								<div>
									<span class="glyphicons remove_2"></span>{{ 'Login_MaintenanceMessage' | translate }}
								</div>
							</div>

							<div class="system-msg error-msg" [hidden]="!status.error">
								<div>
									<span class="glyphicons remove_2"></span>{{ 'Login_ErrorMessage' | translate }}
								</div>
							</div>

							<div class="system-msg logged-out-msg" [hidden]="!status.loggedOut">
								<p><span class="glyphicons ok_2"></span>{{ 'Login_LoggedOutMessage' | translate }}</p>
							</div>

							<div class="system-msg" [hidden]="!status.sessionEnded">
								<div>
									<span class="glyphicons clock"></span>{{ 'Login_SessionEndedMessage' | translate }}
								</div>
							</div>

							<div class="system-msg error-msg" [hidden]="!status.invalidLicense">
								<div>
									<span class="glyphicons remove_2"></span>{{ 'License_Error_UserShort' | translate }}
								</div>
							</div>

							<div class="input-fields">
								<div class="username">
									<input [attr.aria-label]="'Username' | translate" autofocus class="form-control input-with-icon" id="username" name="username" [placeholder]="'Username' | translate" required type="text" [(ngModel)]="formData.username">
									<span class="glyphicons user"></span>
								</div>
								<div class="password">
									<input [attr.aria-label]="'Password' | translate" class="form-control input-with-icon" id="password" name="password" [placeholder]="'Password' | translate" required type="password" [(ngModel)]="formData.password">
									<span class="glyphicon glyphicon-lock"></span>
								</div>
							</div>

							<div class="group">
								<button class="btn btn-primary btn-icon-right btn-lg pull-right btn-sign-in" type="submit">
									{{ 'SignIn' | translate }}
								</button>
								<span class="forgot-password" *ngIf="allowPasswordReset()">
									<a [uiSref]="'forgot-password'">({{ 'ForgotPassword' | translate }})</a>
								</span>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
