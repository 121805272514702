<date-picker-styles></date-picker-styles>
<div class="vb-ui-date-picker" [ngClass]="styles.dateWrapper" [attr.role]="'group'" [attr.aria-label]="accessibilityLabel">
	<input type="text" name="dateInput" [attr.aria-label]="'DateInput' | translate" [disabled]="isDisabled" [ngClass]="styles.dateInput" #datePickerInput vbUiTextInput bsDatepicker #dp="bsDatepicker" [bsConfig]="config" [(ngModel)]="date" (ngModelChange)="onDateChange($event)" (blur)="onTouched()" triggers="">
	<span>
		<button type="button" vbUiBtnPrimary [attr.aria-label]="'SelectADate' | translate" [disabled]="isDisabled" [ngClass]="styles.dateBtn" (click)="dp.toggle()">
			<span class="glyphicons calendar">
			</span>
		</button>
	</span>
</div>
