import {
	StateService,
	Trace,
	TransitionService
} from '@uirouter/angularjs';

import {
	auto,
	IHttpProvider,
	ILogService,
	IWindowService
} from 'angular';

import { NgModule } from '@vbrick/angular-ts-decorators';

import { registerViewInterceptor } from './ViewInterceptor.Config';
import { registerExceptionHandler } from './ExceptionHandler.Config';
import { configureStateTransitionLogging } from './ConfigureStateTransitionLogging.Config';
import { StateTransitionScrollToTop } from './StateTransitionScrollToTop.Run';

@NgModule({
	id: 'VBrick.Shared.Config'
})
export default class ConfigModule {
	public static config(
		$httpProvider: IHttpProvider,
		$provide: auto.IProvideService) {
		'ngInject';

		registerExceptionHandler($provide);
		registerViewInterceptor($httpProvider);
	}

	public static run(
		$log: ILogService,
		$state: StateService,
		$trace: Trace,
		$transitions: TransitionService,
		$window: IWindowService
	) {
		'ngInject';

		configureStateTransitionLogging($log, $state, $trace, $transitions);
		StateTransitionScrollToTop($transitions, $window);
	}
}
