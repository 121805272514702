import {
	Directive,
	Input,
	OnChanges,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';

@Directive({
	selector: '[vb-max]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbMaxDirective implements OnInit {
	@Input() public vbMax: any;

	private ngModel: INgModelController;

	public ngOnInit(): void {
		this.ngModel.$validators.vbMax = modelValue => this.validate(modelValue);
	}

	public ngOnChanges(): void {
		this.ngModel.$validate();
	}

	private validate(modelValue: any): boolean {
		return this.vbMax == null || this.ngModel.$isEmpty(modelValue) || !(modelValue > this.vbMax);
	}
}
