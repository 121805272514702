<vb-title [title]="videoInfo.title"></vb-title>

<div class="system-login-shell" [ngStyle]="{ 'background-image': 'url(' + videoInfo.thumbnailUri + ')' }">
	<div class="table-row">
		<div class="table-cell table-cell-centered-form">
			<div class="system-login col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-xl-4 col-xl-offset-4">
				<form #videoPasswordForm="ngForm" class="system-form" name="videoPasswordForm" (submit)="submit()">
					<h1>{{ videoInfo.title }}</h1>

					<div class="description" *ngIf="videoInfo.description" [innerHTML]="videoInfo.description | safeHtml">
					</div>

					<div class="system-msg error-msg" [hidden]="!showPasswordError">
							<span class="glyphicons remove_2"></span>
							{{ 'IncorrectPassword' | translate }}
					</div>

					<div class="input-fields">
						<div class="password">
							<input type="password" class="form-control input-with-icon" name="password" [placeholder]="'Password' | translate" required [(ngModel)]="password" (ngModelChange)="onPasswordInputChange()" disable-password-autocomplete>

							<span class="glyphicon glyphicon-lock"></span>
						</div>
					</div>

					<div class="group">
						<button class="btn btn-primary btn-icon-right btn-lg pull-right btn-sign-in" type="submit" [disabled]="videoPasswordForm.invalid">
							{{ 'Ok' | translate }}
						</button>
					</div>

					<div class="group registered-user">
						{{ 'RegisteredUser' | translate }}
						<a (click)="loginRedirect()">{{ 'SignInHere' | translate }}</a>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
