import { DateUtil } from 'rev-shared/date/DateUtil';
import { Component, Input, AfterViewInit, DoCheck, OnChanges } from '@vbrick/angular-ts-decorators';
import { INgModelController, IAttributes, ITimeoutService, IOnChangesObject, IFormController } from 'angular';
import { TranslateService } from '@ngx-translate/core';
import { TimezoneService } from './Timezone.Service';

import { noop, isDate, isNumber, isUndefined, isDefined } from 'rev-shared/util';
import './VbDateTimeInput.Component.less';

interface IVbDateTimeModelController extends INgModelController {
	dateInput: INgModelController;
	timeInput: INgModelController;
}

/**
 * vb-date-time-input
 * Combined date and time input fields.
 * Accepts a timezoneId as input, which gets factored in to the date value set to the ngModel.
 * A name attribute is required.
 *
 * The dateInput and timeInput fields are exposed on the ngModel controller, so you may reference them in your form (example: validation display).
 * You may reference it by the name supplied to the element. This would look like:
 * 	$ctrl.myForm.myDateTimeInputName.dateInput.$error.required
 */

@Component({
	selector: 'vb-date-time-input',
	require: {
		ngModel: '?ngModel'
	},
	template: `
		<div ng-form class="row" name="$ctrl.form"
			role="group" aria-label="{{$ctrl.accessibilityLabel}}">
			<div class="col-sm-12 col-md-8">
				<div class="input-group">
					<input 	type="text"
						class="form-control input-date"
						ng-model="$ctrl.dateField.date"
						name="dateInput"
						ng-change="$ctrl.onDateTimeChange()"
						ng-required="$ctrl.required"
						uib-datepicker-popup="mediumDate"
						is-open="$ctrl.datePickerIsOpen"
						datepicker-options="{showWeeks: false}"
						show-button-bar="false"
						autocomplete="off"
						aria-label="{{$ctrl.TranslateService.instant('DateTimePicker_DateInput')}}">

					<span class="input-group-btn">
						<button class="btn btn-white" type="button" ng-click="$ctrl.toggleDatePicker()" aria-label="{{$ctrl.TranslateService.instant('SelectADate')}}">
							<span class="glyphicons calendar" style="cursor:pointer;" ></span>
						</button>
					</span>
				</div>
			</div>
			<div class="col-sm-12 col-md-4">
				<input 	type="text"
					class="form-control input-time"
					name="timeInput"
					ng-model="$ctrl.dateField.time"
					ng-change="$ctrl.onDateTimeChange()"
					ng-required="$ctrl.required"
					vb-time-input
					base-date="$ctrl.dateField.date"
					aria-label="{{$ctrl.TranslateService.instant('DateTimePicker_TimeInput')}}">
			</div>
		</div>
	`
})
export class VbDateTimeInputComponent implements AfterViewInit, DoCheck, OnChanges {
	@Input() public accessibilityLabel: string;
	@Input('<? disableTimezoneWatch') private disableTimezoneWatch: boolean;
	@Input('<? timezoneId') private timezoneId: string;

	private dateField: any;
	private datePickerIsOpen: boolean;
	private form: IFormController;
	private ngModel: IVbDateTimeModelController;
	private previousDateMs: number;
	private required: boolean;

	constructor(
		private $attrs: IAttributes,
		private $timeout: ITimeoutService,
		private TimezoneService: TimezoneService,
		public TranslateService: TranslateService,
	) {
		'ngInject';

		this.dateField = {};
	}

	public ngAfterViewInit(): void {
		if (!this.$attrs.name) {
			throw new Error('vb-date-time-input: name attribute missing');
		}

		this.required = isDefined(this.$attrs.required);

		this.ngModel.$render = noop;
		this.ngModel.$parsers.push(value => this.validateInput(value));
		this.ngModel.$formatters.push(value => this.validateInput(value));
		this.ngModel.dateInput = this.form.dateInput;
		this.ngModel.timeInput = this.form.timeInput;
	}

	public ngOnChanges(changes: IOnChangesObject): void {
		if (changes.timezoneId && isUndefined(this.$attrs.disableTimezoneWatch)) {
			this.onTimezoneIdChange();
		}
	}

	public ngDoCheck(): void {
		this.checkDate();
	}

	public onDateTimeChange(): void {
		const value = this.getDate();

		if (!value) {
			this.dateField.utcTimestamp = null;
			this.ngModel.$setViewValue(null);
			this.validateInput();
		} else {
			this.dateField.loading = true;
			this.updateDate().then(() => {
				this.dateField.loading = false;

				const oldDate: Date = this.tryGetDate(this.ngModel.$modelValue);
				const newDate: Date = this.getDate();

				const t1: number = oldDate && oldDate.getTime();
				const t2: number = newDate && newDate.getTime();

				if (t1 !== t2) {
					this.dateField.utcTimestamp = t2;
					this.ngModel.$setViewValue(newDate);
				}
			});
		}
	}

	public toggleDatePicker(): void {
		if (!this.datePickerIsOpen) {
			this.$timeout(() => this.datePickerIsOpen = true, 100);
		}
	}

	private checkDate(): void {
		const currentDate: Date = this.tryGetDate(this.ngModel.$modelValue);
		const currentDateMs: number = currentDate && currentDate.getTime();

		// update the model, if changed
		if (!Object.is(currentDateMs, this.previousDateMs)) {
			this.previousDateMs = currentDateMs;
			this.updateModel();
		}
	}

	private getDate(noOffset: boolean = false): Date {
		const date: Date = this.tryGetDate(this.dateField.date);
		const time: number = this.dateField.time;

		if (date && isNumber(time)) {
			const offset: number = (noOffset ? 0 : this.dateField.tzOffset) || 0;

			return new Date(date.getTime() + time + offset);
		}
	}

	private getUTCOffset(date: Date, timezoneId: string, isUtc: boolean = false): Promise<number> {
		if (timezoneId) {
			if (date) {
				return this.TimezoneService.getUTCOffset(timezoneId, [date], isUtc)
					.then((offsets: any[]) => offsets[0]);
			}

			return Promise.resolve(0);
		}

		return Promise.resolve( DateUtil.getOffset(date) );
	}

	private onTimezoneIdChange(): void {
		const utcDate: Date = this.tryGetDate(this.ngModel.$modelValue);
		const utcTimestamp: number = utcDate ? utcDate.getTime() : null;
		const timezoneId: string = this.timezoneId;

		if (timezoneId !== this.dateField.timezoneId &&
			utcTimestamp === this.dateField.utcTimestamp) {

			this.onDateTimeChange();
		}
	}

	private tryGetDate(date: Date): Date {
		if (isDate(date)) {
			return date;
		}
	}

	private validateInput(value?: any): any {
		const dateInput = this.form.dateInput;
		const timeInput = this.form.timeInput;

		let isValid: boolean = true;

		if (dateInput && timeInput) {
			const hasTime: boolean = Number.isFinite(timeInput.$modelValue);
			const hasDate: boolean = !!dateInput.$modelValue;

			const isValueMissing: boolean = hasDate && !hasTime || !hasDate && hasTime;

			isValid = !isValueMissing &&
				dateInput.$valid &&
				timeInput.$valid;
		}

		this.ngModel.$setValidity('dateTimeInput', isValid);

		return value;
	}

	private updateDate(): Promise<any> {
		const dateTime: Date = this.getDate(false);

		return this.getUTCOffset(dateTime, this.timezoneId)
			.then((offset: number) => {
				this.dateField.tzOffset = DateUtil.getOffset(dateTime) - offset;
			});
	}

	private updateModel(): void {
		const utcDate: Date = this.tryGetDate(this.ngModel.$viewValue);
		const utcTimestamp: number = utcDate ? utcDate.getTime() : null;
		const timezoneId: string = this.timezoneId;

		if (timezoneId === this.dateField.timezoneId &&
			utcTimestamp === this.dateField.utcTimestamp) {
			return;
		}

		if (utcDate) {
			this.getUTCOffset(utcDate, timezoneId, true)
				.then((offset: number) => {
					const tzOffset: number = DateUtil.getOffset(utcDate) - offset;
					const adjustedDate: Date = new Date(utcDate.getTime() - tzOffset);

					this.dateField = {
						tzOffset,
						date: DateUtil.getStartOfDay(adjustedDate),
						time: DateUtil.getTimeOfDay(adjustedDate),
						utcTimestamp: utcDate.getTime(),
						timezoneId
					};
				});

			this.dateField = { loading: true };
		}

		this.dateField = {};
	}
}
