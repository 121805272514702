import { NgZone } from '@angular/core';
import { chain, range } from 'underscore';
import { Subject } from 'rxjs';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { MessageHandlers } from 'rev-shared/push/MessageHandler';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { noop, isNumber } from 'rev-shared/util';
import { prefetchImage } from 'rev-shared/util/ImageUtils';

import { PresentationFileStatus } from './PresentationFileStatus';
import { WebcastModel } from '../webcast/model/WebcastModel';
import { WebcastPresentationService } from './WebcastPresentation.Service';

export interface IPresentationModelServices {
	DateParsers: DateParsersService;
	PushBus: PushBus;
	WebcastPresentationService: WebcastPresentationService;
}

export class PresentationModel {
	private DateParsers: DateParsersService;
	private WebcastPresentationService: WebcastPresentationService;
	private PushBus: PushBus;
	private updateSubject$ = new Subject<void>();
	public update$ = this.updateSubject$.asObservable();

	public currentSlideIndex: number;
	public downloadUrl: string;
	public isMoving: boolean;
	public lastSlideIndex: number;
	public name: string;
	public slideChangeEventsIgnored: boolean;
	public slides: any[];
	public status: PresentationFileStatus;
	private _slideDelay: number = 0;
	public hasPresentation: boolean;

	constructor(
		private scheduledEvent: WebcastModel,
		presentationFile: any,
		services: IPresentationModelServices,
		private zone: NgZone
	){
		Object.assign(this, services);
		this.initialize(presentationFile);
	}

	public registerPushHandlers(): IUnsubscribe {
		const triggerUpdate = (handlers: MessageHandlers) => Object.keys(handlers).reduce((acc: MessageHandlers, key: string) => {
			acc[key] = msg => {
				handlers[key](msg);
				this.updateSubject$.next();
				this.updateWebcast();
			};
			return acc;
		}, {});

		return this.PushBus.subscribe(this.scheduledEvent.id, triggerUpdate({
			PresentationUploadStarted: () => this.status = PresentationFileStatus.Uploading,
			PresentationUploadFailed: () => this.initialize({ status: PresentationFileStatus.UploadingFailed }),
			PresentationImageProcessingFailed: () => this.status = PresentationFileStatus.ProcessingFailed,

			PresentationUploadFinished: msgData => {
				this.initialize({
					status: PresentationFileStatus.ProcessingImages,
					name: msgData.name,
					downloadUrl: msgData.downloadUrl
				});
			},

			PresentationImageProcessingFinished: msgData => {
				this.initialize({
					imageCount: msgData.imageCount,
					slideUri: msgData.slideUri,
					status: PresentationFileStatus.Complete,
					name: msgData.name,
					lastUpdated: this.DateParsers.parseUTCDate(msgData.lastUpdated) || new Date()
				});

				if(this.scheduledEvent.presentationFile && !this.scheduledEvent.presentationFile.name) {
					this.scheduledEvent.presentationFile.name = msgData.name;
				}
			},

			CurrentPresentationSlideSet: msgData => {
				this.handleSlideMoved(msgData.slideNumber - 1);
			},

			WebcastDetailsSaved: msgData => {
				if (msgData.removePresentationFile) {
					this.initialize();
				}
			},

			WebcastPresentationRemoved: () => this.initialize()
		}));
	}

	public isFirstSlide(){
		return this.currentSlideIndex === 0;
	}

	public isLastSlide() {
		return this.currentSlideIndex === this.slides.length - 1;
	}

	public ignoreSlideChangeEvents(ignore: boolean): void {
		this.slideChangeEventsIgnored = ignore;
	}

	public movePreviousSlide(): void {
		this.moveSlide(this.currentSlideIndex - 1);
	}

	public moveNextSlide(): void {
		this.moveSlide(this.currentSlideIndex + 1);
	}

	public moveSlide(index: number, local?: boolean): void {
		const delay = this.currentSlideIndex === null ? 0 : this.slideDelayMilliseconds;
		index = this.lastSlideIndex = this.fixIndexBounds(index);
		// Fetching the slide at a random interval between 0 & 23secs (24 sec slide delay)
		// to allow rev to handle the thundering herd problem when fetching slides for all the
		// participants at the same time during slide change event.
		// Also added a random delay between 0 & 1 sec for the initial load to handle the problem
		// when the broadcast starts for the first time.
		const fetchDelay = delay > 0 ? this.getRandomInt(delay - 1000, delay/2): this.getRandomInt(1000, 0);
		var delayTime = Date.now() + delay;

		window.setTimeout(() => {
			this.prefetchSlide(this.slides[index])
				.then(() => {
					window.setTimeout(() => {
						this.currentSlideIndex = index;
						this.zone.run(noop);
					}, (delayTime - Date.now()));
				});
		}, fetchDelay);

		if (!local) {
			this.isMoving = true;
			this.WebcastPresentationService
				.setCurrentPresentationSlide(this.scheduledEvent.id, index)
				.finally(() => this.isMoving = false);
		}
	}

	public handleSlideMoved(index: number): void {
		if (!this.slideChangeEventsIgnored && this.currentSlideIndex !== index) {
			this.moveSlide(index, true);
		}
	}

	public prefetchAllSlidesInSeries(): Promise<void> {
		return Promise.all(
			this.slides.map(slide => this.prefetchSlide(slide))) as any;
	}

	public prefetchCurrentAndAdjacentSlides() {
		const currentIndex = this.currentSlideIndex;

		const slides = chain([currentIndex - 1, currentIndex, currentIndex + 1])
			.map(index => this.fixIndexBounds(index))
			.uniq()
			.map(index => this.slides[index])
			.value();

		return Promise.all(slides.map(slide => this.prefetchSlide(slide)));
	}

	public prefetchSlide(slide: any): Promise<void> {
		return prefetchImage(slide.uri);
	}

	private fixIndexBounds(index: number): number {
		return index < 0 ? 0 :
			index >= this.slides.length ?
				this.slides.length - 1 :
				index;
	}

	private getRandomInt(max: number, min: number): number {
		return Math.floor(Math.random() * (max - min)) + min;
	}

	public get currentSlide(): any {
		return this.slides[this.currentSlideIndex];
	}

	public get ready(): boolean {
		return this.status === PresentationFileStatus.Complete;
	}

	public get processing(): boolean {
		return this.status === PresentationFileStatus.Uploading || this.status === PresentationFileStatus.ProcessingImages;
	}

	public get processingImagesFailed(): boolean {
		return this.status === PresentationFileStatus.ProcessingFailed;
	}

	public get uploadFailed(): boolean {
		return this.status === PresentationFileStatus.UploadingFailed;
	}

	public get slideDelayMilliseconds(): number {
		return this._slideDelay;
	}

	public set slideDelayMilliseconds(value: number) {
		if(!value || value !== this.slideDelayMilliseconds) {
			if(isNumber(this.lastSlideIndex)) {
				this.currentSlideIndex = this.lastSlideIndex;
			}
		}
		this._slideDelay = value;
	}

	private initialize(presentationFile?: any): void {
		this.hasPresentation = !!presentationFile;

		const options = presentationFile || {};

		Object.assign(this, {
			currentSlideIndex: null,
			name: options.name || this.name,
			downloadUrl: options.downloadUrl || this.downloadUrl,
			lastSlideIndex: null,
			status: options.status,

			slides: range(options.imageCount).map(i => {
				return {
					uri: options.slideUri + (i + 1) + '?lastUpdated=' + options.lastUpdated.getTime() + '&cachingEnabled=' + this.scheduledEvent.mediaCachingEnabled,
					thumbnailUri: options.slideUri + (i + 1) + '/thumbnail' + '?lastUpdated=' + options.lastUpdated.getTime()
				};
			})
		});

		if (this.ready) {
			this.moveSlide((options.currentSlideNumber || 1) - 1, true);
		}

	}

	private updateWebcast(): void {
		this.scheduledEvent.update({
			presentation: this
		});
	}
}
