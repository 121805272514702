import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';

import { VbUiLanguageTagsInputComponent } from './VbUiLanguageTagsInput.Component';

@NgModule({
	imports: [
		CommonModule,
		UpgradeModule
	],
	declarations: [
		VbUiLanguageTagsInputComponent
	],
	exports: [
		VbUiLanguageTagsInputComponent
	]
})
export class VbUiLanguageTagsInputModule {
}
