// make sure that this matches the enum server-side
export enum LicenseType {
	BY_USERS = 'ByUsers',
	BY_HOURS = 'ByHours'
}

export enum FeatureName {
	USER_SEAT = 'Rev_Active_User_Seat',
	VIEWING_HOURS = 'Rev_Viewing_Hours',
	AI_HOURS = 'Rev_AI_Hours'
}
