import { NgModule } from '@angular/core';

import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';

import { SearchService } from './Search.Service';
import { SearchUtil } from './SearchUtil.Service';
import { InsightSearchHelper } from './InsightSearchHelper.Service';

@NgModule({
	imports: [
		SecurityAngularModule,
		DateAngularModule
	],
	providers: [
		InsightSearchHelper,
		SearchUtil,
		SearchService
	]
})
export class SearchAngularModule {}
