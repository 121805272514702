import { IHttpProvider, IRequestConfig, auto } from 'angular';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UtilService } from 'rev-shared/util/Util.Service';

const partialsUrl = '/partials/';
const languageParam = 'lang';

export function registerViewInterceptor($httpProvider: IHttpProvider): void {

	$httpProvider.interceptors.push(($injector: auto.IInjectorService) => {
		'ngInject';

		let UserContext: UserContextService;
		let Util: UtilService;
		let initialized: boolean;

		// injecting UserContext would create a circular dependency -
		// UserContext depends on http, and http depends on the httpProvider interceptors.
		return {
			request(config: IRequestConfig): IRequestConfig {
				try {
					ensureInitialized();

					if(config.url.startsWith(partialsUrl)) {

						config.url = UserContext.baseCdnUrl() +
							Util.addQueryParams(config.url, { [languageParam]: UserContext.getCurrentLanguage() });
					}

				} catch(e) {
					console.error('View Interceptor error: ', e);
				}

				return config;
			}
		};

		function ensureInitialized() {
			if(!initialized) {
				UserContext = ($injector.get('UserContext') as UserContextService);
				Util = $injector.get('Util');
				initialized = true;
			}

		}
	});
}
