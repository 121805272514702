<vb-ui-search-input [placeholder]="'UI_DataGrid_ColumnPicker_FilterPlaceholder' | translate" [(ngModel)]="columnFilter">
</vb-ui-search-input>

<ul [hidden]="isAllGroupOptionsHidden" [ngClass]="styles.list">

	<li *ngFor="let group of columnPickerGroups">
		<vb-ui-checkbox-group #checkboxGroup [filterText]="columnFilter" [filterMinLength]="2" [groupLabel]="group.headerName" [hidden]="checkboxGroup.isAllOptionsHidden" [isDefaultExpanded]="group.isDefaultExpanded" [options]="group.columnEntries" [showExpander]="true" [showGroupCheckbox]="true" [(ngModel)]="group.selectedColIds" (ngModelChange)="onCheckboxGroupSelectedChanged(group)">
		</vb-ui-checkbox-group>
	</li>

</ul>

<div [hidden]="!isAllGroupOptionsHidden" [ngClass]="styles.noResults">
	{{ 'UI_DataGrid_ColumnPicker_NoResults' | translate }}
</div>