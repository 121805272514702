<label [ngClass]="styles.RadioContainer">
	<input type="radio" [attr.aria-checked]="!!checked" [attr.aria-label]="accessibilityLabel" [attr.name]="name" [attr.value]="optionValue" (change)="onChangeInternal($event)">
	<span [ngClass]="[
			styles.vbRadioCheckmark,
			checked ? 'theme-accent theme-accent-font-border' : ''
		]">
		<span class="theme-accent-inverted" [ngClass]="styles.vbRadioCheckmarkChecked" [hidden]="!checked">
		</span>
	</span>

	<span [ngClass]="styles.vbRadioTextInput">
		<ng-content></ng-content>
	</span>
</label>
