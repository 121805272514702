import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UtilAngularModule } from 'rev-shared/util/UtilAngular.Module';

@NgModule({
	imports: [
		TranslateModule,
		UtilAngularModule
	]
})
export class TranscriptionModule {}
