import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { FileUtil } from 'rev-shared/util/FileUtil';

import { SafeHtmlPipe } from './SafeHtml.Pipe';

//todo: cleanup. Angular doesn't like having ts-decorators and ng annotations in same file
@Pipe({ name: 'fileSize' })
export class FileSizeAngularPipe implements PipeTransform {
	public transform = FileUtil.formatFileSize;
}

const exports = [
	FileSizeAngularPipe,
	SafeHtmlPipe
];

@NgModule({
	declarations: exports,
	exports
})
export class PipesAngularModule {
}
