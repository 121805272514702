import { Component, Input } from '@angular/core';

import { MinuteMs } from 'rev-shared/date/Time.Constant';
import { deepMergeObjects } from 'rev-shared/util/index';

import { VB_UI_LINE_CHART_HOST_CLASS, VB_UI_LINE_CHART_TEMPLATE, VbUiLineChartComponent } from './VbUiLineChart.Component';

const AXIS_FONT_SIZE: string = '10px';
const AXIS_LINE: any = {
	stroke: '#c5c5c5',
	strokeOpacity: 1,
	strokeWidth: 1
};
const DATE_AXIS_MIN_RANGE: number = MinuteMs;

@Component({
	selector: 'vb-ui-line-chart-small',
	host: {
		'[class]': VB_UI_LINE_CHART_HOST_CLASS
	},
	template: VB_UI_LINE_CHART_TEMPLATE
})
export class VbUiLineChartSmallComponent extends VbUiLineChartComponent {
	@Input() public valueAxisFormat: string;

	protected initChartConfig(): void {
		this.tooltipText = `{dateX.formatDate('HH:mm:ss')}: [bold]{valueY.formatNumber("#,###")}[/]`;

		super.initChartConfig();

		this.chartConfig = deepMergeObjects(this.chartConfig, {
			cursor: {
				behavior: 'none'
			},
			events: {
				datarangechanged: () => this.onDataRangeChanged()
			},
			paddingBottom: 4,
			paddingLeft: this.checkTwoDecimalFormat() ? -15 : -24,
			paddingRight: 8,
			paddingTop: 4,
			percentWidth: 100,
			zoomOutButton: {
				disabled: true
			}
		});
	}

	protected getDateAxis(): any {
		return deepMergeObjects(super.getDateAxis(), {
			adapter: { // gets the axis behaving with the desired zoom and range as data is added
				end: () => 1,
				keepSelection: () => this.getKeepSelection(),
				max: () => this.getDateAxisMax(),
				min: () => this.getDateAxisMin(),
				start: () => 0
			},
			renderer: {
				dy: -8,
				fontSize: AXIS_FONT_SIZE,
				height: 10,
				minGridDistance: 80,
				line: {
					...AXIS_LINE
				}
			}
		});
	}

	protected getDateAxisMax(): number {
		const dataItems = this.getDataItems();
		const startDate: Date = new Date(dataItems.first?.dates.dateX || 0);
		const lastDate: Date = new Date(dataItems.last?.dates.dateX || 0);

		return Math.max(
			+lastDate,
			+startDate + DATE_AXIS_MIN_RANGE
		);
	}

	protected getDateAxisMin(): number {
		return +this.getDataItems().first?.dates.dateX || 0;
	}

	protected getKeepSelection(): boolean {
		const dataItems = this.getDataItems();
		const startDate: Date = new Date(dataItems.first?.dates.dateX || 0);
		const lastDate: Date = new Date(dataItems.last?.dates.dateX || 0);

		return +lastDate <= +startDate + DATE_AXIS_MIN_RANGE;
	}

	protected getLineSeries(): any {
		return deepMergeObjects(super.getLineSeries(), {
			bullets: [{
				disabled: true // not performant for large data sets
			}],
			fillOpacity: this.fillEnabled ? .25 : 0
		});
	}

	protected getValueAxis(): any {
		return deepMergeObjects(super.getValueAxis(), {
			extraMax: .1,
			min: 0,
			numberFormatter: {
				numberFormat: this.valueAxisFormat || '#a'
			},
			renderer: {
				fontSize: AXIS_FONT_SIZE,
				line: AXIS_LINE,
				minGridDistance: 20,
				minLabelPosition: 0.7,
				paddingLeft: this.checkTwoDecimalFormat() ? 0 : 12, //This is driven by format. wiggle room to prevent text from being cut off
				paddingTop: 1
			},
			title: {
				adapter: {
					valign: () => 'bottom' // needed to work around AxisRendererY overriding the normal user config
				},
				dx: 34,
				fontSize: AXIS_FONT_SIZE,
				tooltip: {
					adapter: {
						pointerOrientation: () => 'left' // gets calculated incorrectly, so forcing it
					}
				},
				tooltipText: this.yAxisLabel,
				truncate: true,
				width: 68
			},
			width: 54 // helps prevent layout wiggle and occaisional miscalculations
		});
	}

	protected onDataRangeChanged(): void {
		// hack to force the chart to remeasure as a workaround for it not reliably rendering the date axis
		if (this.getDataItems().length === 2) {
			this.chart.htmlContainer.style.marginRight = '1px';
		}
	}

	private checkTwoDecimalFormat(): boolean {
		return this.valueAxisFormat === '#.00';
	}
}
