import { Injectable } from '@vbrick/angular-ts-decorators';
import { ICompiledExpression, IParseService } from 'angular';

export interface IRepeatExpression {
	collectionExpression: string;
	valueExpression: string;
	keyExpression: string;
	aliasAs: string;
	trackByExpression: string;
	getCollection: ICompiledExpression;
}

export type IParseNgRepeat = (ngRepeatExpression: string) => IRepeatExpression;

/**
 * Utility to parse an ng-repeat expression
 *
 * returns an object with the following
 *	collectionExpression - string: value of the collection expression
 *	getCollection - function(context): returns the collection from the context object
 *	valueExpression - string: the repeat let
 *	keyExpression - string: if repeating key/value pairs of an object, this is the value of the key expression
 *	trackByExpression - string: value of the track by expression
 *	aliasAs - string: value of the collection alias
 **/
@Injectable('ngRepeatParser')
export class NgRepeatParser {

	public $get($parse: IParseService): IParseNgRepeat{
		'ngInject';

		return (ngRepeat: string): IRepeatExpression => {

			const ngRepeatMatch = ngRepeat.match(/^\s*([\s\S]+?)\s+in\s+([\s\S]+?)(?:\s+as\s+([\s\S]+?))?(?:\s+track\s+by\s+([\s\S]+?))?\s*$/);

			if (!ngRepeatMatch) {
				throw new Error('Unable to parse ngRepeat: ' + ngRepeat);
			}

			const itemMatch = ngRepeatMatch[1].match(/^(?:(\s*[\$\w]+)|\(\s*([\$\w]+)\s*,\s*([\$\w]+)\s*\))$/);
			const collectionExpression = ngRepeatMatch[2];

			return {
				collectionExpression,
				valueExpression: itemMatch[3] || itemMatch[1],
				keyExpression: itemMatch[2],
				aliasAs: ngRepeatMatch[3],
				trackByExpression: ngRepeatMatch[4],
				getCollection: $parse(collectionExpression)
			};
		};
	}
}
