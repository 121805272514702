import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { TextInputAngularModule } from '../text-input/TextInputAngular.Module';
import { ButtonsAngularModule } from '../buttons/ButtonsAngular.Module';
import { VbUiInlineTextEditComponent } from './VbUiInlineTextEdit.Component';

const components = [
	VbUiInlineTextEditComponent
];

@NgModule({
	imports: [
		ButtonsAngularModule,
		CommonModule,
		FormsModule,
		TextInputAngularModule,
		TranslateModule
	],

	declarations: components,
	exports: components,
	bootstrap: components
})
export class InlineEditAngularModule {

}
