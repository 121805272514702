import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';

import { IPAddress } from 'rev-shared/util/IPAddress.Service';

@Directive({
	selector: '[vb-ip-address]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbIpAddressDirective implements OnInit {
	private ngModel: INgModelController;

	constructor(
		private IPAddress: IPAddress
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.ngModel.$parsers.push(value => this.validate(value));
		this.ngModel.$formatters.push(value => this.validate(value));
	}

	private validate(value: string): string {
		this.ngModel.$setValidity('vbIpAddress', this.IPAddress.validate(value));

		return value;
	}
}
