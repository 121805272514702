import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { VbUiReportCellComponent } from './VbUiReportCell.Component';

const components = [
	VbUiReportCellComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		TooltipModule
	]
})
export class ReportCellModule {}
