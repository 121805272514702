<!--Loading spinner-->
<vb-loading-spinner flex="fill" flex-align="center" layout="row" [size]="'large'" *ngIf="!videoSummary && !isReportsServiceDown">
</vb-loading-spinner>

<!--Reports-->
<div *ngIf="videoSummary">
	<button type="button" [ngClass]="styles.closeBtn" (click)="onClose.emit()" [tooltip]="'Media_Videos_Reports_Close' | translate" [placement]="'top left'" [attr.aria-label]="'Media_Videos_Reports_Close' | translate">
		<span class="glyphicons circle_remove"></span>
	</button>

	<video-analytics-wrapper>
		<ng-container header>
			<date-range-picker class="margin-right-10" [(ngModel)]="dateRange" (ngModelChange)="onDateRangeChanged()" [dropdown]="true" [menuRight]="true" [defaultLabel]="'AllTime' | translate"></date-range-picker>
			<a vbUiBtnSecondary href="/analytics/videos/{{ video?.id }}/summary/csv{{csvQueryParams}}" download>
				<span class="glyphicons download_alt margin-right-5"></span>
				{{ 'Reports_Csv' | translate }}
			</a>
		</ng-container>
		<ng-container body>
			<video-reports [ngClass]="styles.videoReports" [video]="video" [videoSummary]="videoSummary">
			</video-reports>
			<video-analytics-grid flex="fill" [videoId]="video?.id" [getRows]="VideoPlaybackReportsSvc.videoSessionsGetRow" [dataFetchContext]="dataFetchContext">
			</video-analytics-grid>
		</ng-container>
	</video-analytics-wrapper>

</div>

<!--Reports service down message-->
<div *ngIf="isReportsServiceDown">
	<button type="button" [ngClass]="styles.reportsServiceDownCloseBtn" (click)="onClose.emit()" vbUiBtnSecondary>
		<span class="glyphicons circle_remove"></span> {{ 'Media_Videos_Reports_Close' | translate }}
	</button>

	<reports-service-down-msg [ngClass]="styles.reportsServiceDownMsg">
	</reports-service-down-msg>
</div>
