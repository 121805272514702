import {
	Directive,
	OnInit,
	Input
} from '@vbrick/angular-ts-decorators';
import { INgModelController, IPromise, equals, ITimeoutService } from 'angular';

type ValidatorFn = () => IPromise<void>;
interface IValidators { [key: string]: ValidatorFn }

const CacheTimeMs = 1000;

/**
 * Wrapper around the ngModel.$asyncValidators property
 * Main purpose is to debounce multiple concurrent validation calls
 *
 * vbAsyncValidators: Object mapping a validation key to a validation function, which returns a promise. Rejected promise indicates an invalid state.
 * vbAsyncValidatorsData: Object mapping the validation key to any other data that is factored into the input validity. Validation requests will be debounced if the modelValue or validationData is unchanged.
 *
 */
@Directive({
	selector: '[vb-async-validators]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbAsyncValidatorsDirective implements OnInit {
	private ngModel: INgModelController;
	@Input() public vbAsyncValidators: IValidators;
	@Input() public vbAsyncValidatorsData: { [key: string]: any[] };

	constructor(
		private $timeout: ITimeoutService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		Object.keys(this.vbAsyncValidators).forEach(key => this.initValidator(key));
	}

	private initValidator(key: string) {
		const validateFn = this.vbAsyncValidators[key];
		let currentParams: any[];
		let currentResult: IPromise<void>;

		this.ngModel.$asyncValidators[key] = () => {
			const params = [this.ngModel.$modelValue, ...(this.vbAsyncValidatorsData[key] || [])];
			if(equals(params, currentParams)) {
				return currentResult;
			}

			currentParams = params;
			currentResult = validateFn();

			currentResult
				.then(() => this.$timeout(CacheTimeMs))
				.then(() => {
					currentParams = null;
					currentResult = null;
				});

			return currentResult;
		};
	}
}
