import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { VbRadioBtnComponent } from './VbRadioBtn.Component';
import { VbRadioGroupBtnComponent } from './VbRadioGroupBtn.Component';

const declarations = [
	VbRadioBtnComponent,
	VbRadioGroupBtnComponent
];

@NgModule({
	declarations,
	exports: declarations,
	imports: [
		CommonModule,
		FormsModule
	]

})
export class VbRadioBtnModule {}
