import {
	Directive,
	HostListener,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	INgModelController,
	ITimeoutService
} from 'angular';

@Directive({
	selector: '[vb-elastic-text-area]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbElasticTextAreaDirective implements OnInit {
	private initialHeight: string;
	private ngModel: INgModelController;

	constructor(
		private $element: IAugmentedJQuery,
		private $timeout: ITimeoutService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.initialHeight = this.$element.css('height');

		// add a formatter to intercept changes that aren't from the user (programmatic)
		this.ngModel.$formatters.push(value => {
			this.resize();

			return value;
		});

		// initial resize
		this.$timeout(() => this.resize(), 0);
	}

	@HostListener('blur keyup change')
	private onUserChange(): void {
		this.resize();
	}

	private resize(): void {
		this.$element
			.height(this.initialHeight) // reset to initial height
			.height(this.$element[0].scrollHeight + 'px'); // then use scrollHeight
	}
}
