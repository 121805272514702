import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ReportsServiceDownMsgComponent } from './ReportsServiceDownMsg.Component';

const components = [
	ReportsServiceDownMsgComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		CommonModule,
		TranslateModule
	]
})
export class ReportsServiceDownMsgModule {}
