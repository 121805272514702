import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dictionary as _Dictionary } from 'underscore';

import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { MessageHandler } from 'rev-shared/push/MessageHandler';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { PushService } from 'rev-shared/push/PushService';
import { orderBy } from 'rev-shared/util/SortUtil';

import { Team } from './Team.Contract';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';

@Injectable()
export class TeamService {

	private unsubscribes: IUnsubscribe[];

	public accountTeams: { [accountId: string]: Team[] } = {};
	public teams: { [teamId: string]: Team } = {};

	constructor(
		private httpClient: HttpClient,
		private PushService: PushService,
		private PushBus: PushBus,
		private SecurityContext: SecurityContextService
	) {
		this.unsubscribes = [];
	}

	public loadTeams(accountId: string): Promise<any> {
		return this.httpClient.get(`/network/accounts/${accountId}/teams`)
			.toPromise<any>()
			.then(response => {
				this.accountTeams[accountId] = orderBy(response.teams, t => t.name);
				this.registerPushListeners(accountId);
			});
	}

	public loadTeam(teamId: string): Promise<any> {
		return this.httpClient.get(`/network/teams/${teamId}`)
			.toPromise<any>()
			.then(response => this.teams[teamId] = response);
	}

	public unloadTeams(accountId: string): void {
		delete this.accountTeams[accountId];
		this.unregisterPushListeners(accountId);
	}

	public unloadTeam(teamId: string): void{
		delete this.teams[teamId];
	}

	public createTeam(team: Team): Promise<any> {
		return this.PushService.dispatchCommand(
			'network:CreateTeam',
			this.getTeamCommand(team),
			'TeamCreated')
			.then(result => result.message);
	}

	public saveTeam(team: Team): Promise<any> {
		return this.PushService.dispatchCommand(
			'network:SaveTeamDetails',
			this.getTeamCommand(team),
			'TeamDetailsSaved')
			.then(result => result.message);
	}

	public removeTeam(accountId: string, teamId: string): Promise<any> {
		return this.PushService.dispatchCommand('network:DeleteTeam', {
			accountId,
			teamId
		});
	}

	public uploadLogo(accountId: string, teamId: string, file: FileWrapper): Promise<any> {
		return this.PushService.dispatchCommand(
			teamId ? 'network:AddImageToTeam' : 'media:AddImageToAccount', {
				accountId,
				teamId,
				context: 'Team'
			}, 'ImageCreated')
			.then(data => {
				const { imageId, uploadUri } = data.message;
				file.setOptions({ url: uploadUri });

				const result = this.PushBus.awaitMessage({
					route: imageId,
					routeScope: 'UploadStatus',
					events: 'ImageStoringFinished',
					rejectEvents: 'ImageStoringFailed'
				});

				return result.subscribed
					.then(() => file.submit())
					.then(() => result)
					.then(() => imageId);
			});
	}

	private getTeamCommand(team: Team): any {
		return {
			accountId: team.accountId,
			teamId: team.id,
			name: team.name,
			description: team.description,
			imageId: team.imageId,
			bgColor: team.bgColor,
			themeColor: team.themeColor,
			teamMembers: (team.teamMembers || []).map(({ id, type, rolesState }) => ({
				id,
				type,
				roleTypes: Object.entries(rolesState)
					.filter(keyValue => keyValue[1])
					.map(keyValue => keyValue[0]),
			}))
		};
	}

	private registerPushListeners(accountId: string): void {
		this.unsubscribes[accountId] = this.PushBus.subscribe(accountId, 'Admin.Teams', this.teamEventHandler(accountId));
	}

	private unregisterPushListeners(accountId: string): void {
		if(this.unsubscribes[accountId]) {
			this.unsubscribes[accountId]();
			delete this.unsubscribes[accountId];
		}
	}

	private teamEventHandler(accountId: string): _Dictionary<MessageHandler> {
		const teams: Team[] = this.accountTeams[accountId];
		return {
			TeamCreated: (data: any): void => {
				teams.unshift({
					accountId: data.accountId,
					id: data.teamId,
					name: data.name,
					description: data.description,
					imageId: data.imageId,
					bgColor: data.bgColor,
					themeColor: data.themeColor,
					isNew: true
				});
			},
			TeamDetailsSaved: (data: any): void => {
				const team: Team = teams.find(x => x.id === data.teamId);
				if(team) {
					Object.assign(team, {
						name: data.name,
						description: data.description
					});
				}
			},
			TeamDeleted: (data: any): void => {
				const index: number = teams.findIndex(team => team.id === data.teamId);
				if(index >= 0) {
					teams.splice(index, 1);
				}
			}
		};
	}

	public isSetToDefaultAsTeamAdmin(): boolean {
		return !this.SecurityContext.checkAuthorization('admin.media.teams.edit')
			&& this.isCreateTeamAccess;
	}

	public get isCreateTeamAccess(): boolean {
		return this.SecurityContext.checkAuthorization('teams.add');
	}
}
