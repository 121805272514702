import 'jquery';

import 'moment';

import 'bootstrap/dist/css/bootstrap.css';

//glyphicons-pro
import 'glyphicons-pro/css/glyphicons.css';
import 'glyphicons-pro/css/filetypes.css';

//karla font
import 'karla/karla.css';

//misc
import 'underscore'; //should be imported only where used
import 'autofill-event'; //polyfill for firing a change event on form fields when populated but autofill. Augments either jquery or angular's jqLite.
import 'imports-loader?wrapper=window!signalr';
import 'classlist-polyfill';
import './polyfill'
