<div class="fixed-ratio-wrapper grid-item-list" uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}">
	<div class="fixed-ratio">
		<div class="col-sm-6" *ngFor="let link of links">
			<div class="image-wrapper">
				<div class="fixed-ratio-wrapper">

					<div class="fixed-ratio">
						<img *ngIf="link.uri; else noUri;" [src]="link.uri" [alt]="'ThumbnailForAria' | translate : { '0': link.title }"/>

						<ng-template #noUri>
							<div class="no-item"></div>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="fixed-ratio-wrapper">
	<div class="fixed-ratio">
		<div class="fixed-aspect-item" [ngClass]="styles.infoContainer">
			<h2>
				<a uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}">
					{{category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name}}
				</a>
			</h2>

			<div [ngClass]="styles.tileFooter" layout="row" layout-wrap="false">
				<span flex="fill" [ngPlural]="category.videoCount || 0">
					<ng-template ngPluralCase="=0"></ng-template>
					<ng-template ngPluralCase="=1">
						{{'Media_OneVideo' | translate}}
					</ng-template>
					<ng-template ngPluralCase="other">
						{{'Media_NumVideos' | translate: { '0': (category.videoCount | number) } }}
					</ng-template>
				</span>
				<span>
					<a class="margin-right-5" uiSref="portal.edit-category" [uiParams]="{ categoryId: category.id }" *ngIf="category.canEdit" [attr.aria-label]="'EditCategory' | translate">
						<span class="glyphicons cogwheel"></span>
					</a>

					<span class="glyphicons lock margin-right-5" *ngIf="category.restricted">
					</span>
					<span class="glyphicons folder_closed"></span>
				</span>
			</div>
		</div>
	</div>
</div>
