import { Component, Input } from '@angular/core';

import { ApprovalStatus } from 'rev-shared/media/MediaConstants';
import { VideoStatus } from 'rev-shared/media/VideoStatus';

import { IVideoPlaybackConfig } from 'rev-portal/media/videos/videoPlayback/IVideoPlaybackConfig';

import './video-tile.less';

@Component({
	selector: 'video-tile',
	templateUrl: './VideoTile.Component.html'
})

export class VideoTileComponent {
	@Input() public config: IVideoPlaybackConfig;
	@Input() public hasMediaEditAuth: boolean;
	@Input() public isGuest: boolean;
	@Input() public mediaFeatures: any;
	@Input() public playlist: any;
	@Input() public userId: string;
	@Input() public video: any;

	public readonly approvalStatusOptions = ApprovalStatus;
	public readonly videoStatusOptions = VideoStatus;

	public get videoStatus(): string {
		return this.video.status;
	}

	public get uploaderName(): string {
		return [this.video.uploaderFirstName, this.video.uploaderLastName].filter(Boolean).join(' ');
	}

	public get hasEditVideoAuth(): boolean {
		return this.video && (this.video.editAcl || []).includes(this.userId);
	}

	public get isUploading(): boolean {
		return this.videoStatus === VideoStatus.NOT_UPLOADED ||
			this.videoStatus === VideoStatus.UPLOADING;
	}

	public get isProcessing(): boolean {
		return this.videoStatus === VideoStatus.PROCESSING ||
			this.videoStatus === VideoStatus.UPLOADING_FINISHED;
	}

	public get isProcessingFailed(): boolean {
		return this.videoStatus === VideoStatus.UPLOAD_FAILED ||
			this.videoStatus === VideoStatus.PROCESSING_FAILED ||
			this.videoStatus === VideoStatus.READY_BUT_PROCESSING_FAILED;
	}

	public get isPasswordProtected(): boolean {
		return this.isGuest && this.video.hasPassword;
	}
}
