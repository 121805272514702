import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { sortBy } from 'underscore';
import { isString } from 'rev-shared/util';

import {
	IRevConnectInfo,
	IRevConnectLoggingInfo
} from '@vbrick/vbrick-player-hls-plugin/IRevConnectInfo';

import { ITranslationStrings } from 'rev-shared/util/ITranslationStrings';
import { LanguageNames } from 'rev-shared/constants/LanguageNames';
import { TranslationsRequestService } from 'rev-shared/util/TranslationsRequest.Service';
import { VideoSourceType } from 'rev-shared/media/VideoSourceType';
import { getUrlPath } from 'rev-shared/util/Util.Service';

import { IVbPlaybackTokenConfig } from 'vbrick-player-src/IVbPlaybackTokenConfig';
import { HlsUtilService } from 'vbrick-player-src/HlsUtil.Service';
import { SupportedPlaybacksService } from 'vbrick-player-src/SupportedPlaybacks.Service';
import { VG_CONFERENCE_DUAL_PLAYBACK_EXTENSION } from '@vbrick/vbrick-player-conference-dual-hls-plugin/DualHlsConstants';

import { MimeTypes } from './MimeTypes';
import { IModernPlayback } from './IModernPlayback';
import { getPlaybackToken, PLAYBACK_TOKEN_END_POINT, useWithCredentials } from './PlaybackTokenUtility';
import { ResourceType } from './ResourceType';

export enum StreamType {
	FLV = 'FLV',
	H264 = 'H264',
	HLS = 'HLS',
	MP4 = 'MP4',
	MULTICAST = 'Multicast',
	RTMP = 'RTMP'
}

export interface IModernSubtitle {
	label?: string;
	src: string;
	language: string;
}

const REV_CONNECT_TOKEN_RESOURCE_AUD: string = 'rev_connect';
const tokenRenewalIntervalInMins: number = 60;

@Injectable({
	providedIn: 'root'
})
export class VideoPlayerAdapterService {
	private playerTranslations: ITranslationStrings | Promise<ITranslationStrings>;

	constructor(
		private HlsUtilService: HlsUtilService,
		private SupportedPlaybacks: SupportedPlaybacksService,
		private TranslationsRequest: TranslationsRequestService,
		private http: HttpClient
	) {}

	public convertToModernPlayerPlaybacks(resourceId: string, resourceType: ResourceType, playbacks: any[], isLive: boolean, videoSourceType: string, is360Enabled: boolean = false, revConnectLoggingInfo?: IRevConnectLoggingInfo, disableDualPlayback?: boolean): Promise<IModernPlayback[]> {
		return this.getPlayerTranslations()
			.then(playerTranslations => {
				let modernPlaybacks =
					playbacks
						.filter(playback => playback.player !== 'Vbrick') // filter out playbacks for the legacy player
						.map(playback => {
							const originalSrc: string = playback.url;
							const src: string = playback.url;
							const type: MimeTypes = this.getModernPlayerPlaybackType(playback.videoFormat, src);
							const tokenConfig: IVbPlaybackTokenConfig = this.shapeTokenConfig(resourceId, resourceType, src);

							return {
								is360Enabled,
								isDefault: playback.isDefault,
								isLive,
								label: this.getPlaybackLabel(playback, type, playerTranslations),
								originalSrc,
								qValue: playback.qValue,
								revConnectInfo: this.shapeRevConnectInfo(playback.revConnectInfo, revConnectLoggingInfo, originalSrc),
								src,
								type,
								tokenConfig
							};
						});

				//if video conference source, prepend dual entries for all m3u8 entires (this is a player-specific interpretation of standard hls)
				if (!disableDualPlayback && videoSourceType === VideoSourceType.VIDEO_CONFERENCE) {
					const dualPlaybacks = modernPlaybacks
						.filter(playback => playback.src.includes('.m3u8'))
						.map(hlsPlayback => Object.assign({}, hlsPlayback, {
							type: MimeTypes.DUAL,
							src: hlsPlayback.src.replace('.m3u8', VG_CONFERENCE_DUAL_PLAYBACK_EXTENSION)
						}));

					modernPlaybacks = dualPlaybacks.concat(modernPlaybacks);
				}

				return modernPlaybacks;
			});
	}

	public convertToModernSubtitles(subtitles: Array<{ languageCode: string; src: string }>): IModernSubtitle[] {
		return sortBy(subtitles.map(subtitle => {
			return {
				label: LanguageNames[subtitle.languageCode],
				src: subtitle.src,
				language: subtitle.languageCode
			};
		}), 'language');
	}

	public getModernPlayerPlaybackType(videoFormat: string, src: string): MimeTypes {
		const isRtmp = src.startsWith('rtmp://');

		if (this.HlsUtilService.isMulticast(src, null)) {
			videoFormat = this.HlsUtilService.TYPE_VBRICK_MULTICAST;
		} else if (isRtmp || src.startsWith('rtmfp://') || src.includes('.f4m')) {
			return null;
		} else if (src.includes('.m3u8')) { //back-end returns the wrong format for HLS
			videoFormat = 'HLS';
		} else if (src.includes('.flv') || src.includes('.f4v')) {
			videoFormat = 'FLV';
		} else if (src.includes('.m4v')) { //mpeg4-part2
			videoFormat = 'Mpeg4';
		}

		if (!isString(videoFormat)) {
			return;
		}

		switch (videoFormat.trim().toLowerCase()) {
			case 'h264':
			case 'mpeg4':
				return MimeTypes.MP4;

			case 'hls':
				return MimeTypes.HLS;

			case 'flv':
				return MimeTypes.FLV;

			default:
				return null;
		}
	}

	public getPlayerTranslations(): Promise<any> {
		if (this.playerTranslations) {
			return Promise.resolve(this.playerTranslations);
		}

		return this.playerTranslations = this.TranslationsRequest.requestTranslations('/partials/shared/media-player/vbrick-player-translations.html', 'vbrickPlayerTranslations')
			.then(translations => this.playerTranslations = translations);
	}

	public getStreamType(videoUrl: string, videoFormat: string): StreamType {
		videoUrl = videoUrl.trim().toLowerCase();
		videoFormat = videoFormat?.trim().toLowerCase();

		if (this.SupportedPlaybacks.isMulticastSource(videoUrl, '')){
			return StreamType.MULTICAST;
		} else if(videoUrl.startsWith('rtmp://')){
			return StreamType.RTMP;
		}

		const mimeType = this.getModernPlayerPlaybackType(videoFormat, videoUrl);

		switch (mimeType) {
			case MimeTypes.MP4:
				return StreamType.MP4;
			case MimeTypes.HLS:
				return StreamType.HLS;
			case MimeTypes.FLV:
				return StreamType.FLV;
			default:
				return null;
		}
	}

	public getPlaybackForUrl(url: string, modernPlaybacks: any[], rawPlaybacks: any[], isRevConnect: boolean = false): any {
		const modernPlayback = (modernPlaybacks || []).find(playback => url === playback.src && !!playback.revConnectInfo === isRevConnect); //compensate for query token and any other modifications

		if (modernPlayback) {
			const playback = (rawPlaybacks || []).find(playback => modernPlayback.originalSrc === playback.url && !!playback.revConnectInfo === isRevConnect);
			const streamType = this.getStreamType(url, playback?.videoFormat);

			//return a copy of the original playback with the streamType corrected
			return Object.assign({}, playback, { streamType });
		}

		return null;
	}

	private getPlaybackLabel(playback: any, type: MimeTypes, playerTranslations: any): string {
		if (type === MimeTypes.HLS) {
			return playerTranslations.hlsPlaybackMenuOption;
		} else if (playback.label === '-') {
			return playerTranslations.originalPlaybackMenuOption;
		}

		return playback.label;
	}

	private shapeTokenConfig(resourceId: string, resourceType: ResourceType, src: string): IVbPlaybackTokenConfig {
		const tokenType = getPlaybackToken(src);

		if(!tokenType) {
			return;
		}

		return {
			path: getUrlPath(src),
			renewalIntervalInMins: tokenRenewalIntervalInMins,
			resourceId,
			resourceType,
			tokenName: tokenType,
			tokenEndpoint: PLAYBACK_TOKEN_END_POINT,
			withCredentials: useWithCredentials(src)
		};
	}

	private shapeRevConnectInfo(revConnectInfo: IRevConnectInfo, loggingInfoInput: IRevConnectLoggingInfo, originalSrc: string): any {
		if (!revConnectInfo) {
			return undefined;
		}

		const loggingInfo: IRevConnectLoggingInfo = { ...loggingInfoInput };

		if (loggingInfo && loggingInfo.remoteLoggingConfig) {
			loggingInfo.remoteLoggingConfig.context.originUrl = originalSrc;
		}

		const output = {
			...revConnectInfo,
			loggingInfo
		};

		output.jwtToken.getToken = () => this.getRevConnectSignalingToken(revConnectInfo.zoneInfo.zoneId);

		return output;
	}

	private getRevConnectSignalingToken(zoneId: string): Promise<string> {
		return this.http.get<{ token: string }>('/auth/token', {
			params: {
				aud: REV_CONNECT_TOKEN_RESOURCE_AUD,
				zoneId
			}
		})
			.toPromise()
			.then(result => result.token);
	}
}
