import {
	Component,
	Input,
	OnInit,
	Output,
	HostBinding,
	EventEmitter
} from '@angular/core';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { IDateRange } from 'rev-shared/ui/datePicker/DateRangePicker.Component';
import { formatIsoDate } from 'rev-shared/date/DateFormatters';
import { urlEncode } from 'rev-shared/util/Util.Service';

import { VideoPlaybackReportsService } from './VideoPlaybackReports.Service';

import styles from './VideoPlaybackReports.module.less';

@Component({
	selector: 'video-playback-reports',
	templateUrl: './VideoPlaybackReports.Component.html',
	host: {
		'[class]': 'hostClasses'
	}
})
export class VideoPlaybackReportsComponent implements OnInit {
	@Input() public video: any;
	@Output() public onClose: EventEmitter<any> = new EventEmitter();

	public readonly styles = styles;

	public dateRange: IDateRange;
	public dataFetchContext: any;
	public isReportsServiceDown: boolean;
	public videoSummary: any;
	public csvQueryParams: string;

	public isInit: boolean;
	public hostClasses: any;

	constructor(
		public VideoPlaybackReportsSvc: VideoPlaybackReportsService
	) {}

	public ngOnInit(): void {
		this.loadData();
		this.hostClasses = {
			[this.styles.rootLoading]: this.isInit,
			[this.styles.root]: true
		};
	}

	public onDateRangeChanged(): void {
		const dates = this.dateRange?.range;
		if(dates?.length) {
			const [startDate, endDate] = dates.map(formatIsoDate);

			this.dataFetchContext = { startDate, endDate };
			this.csvQueryParams = '?' + urlEncode(this.dataFetchContext);
		}
		else {
			this.dataFetchContext = {};
			this.csvQueryParams = '';
		}

		this.loadData();
	}

	private loadData(): void {
		this.isReportsServiceDown = false;

		lastValueFrom(this.VideoPlaybackReportsSvc.getVideoPlaybackReportsData(this.video.id, this.dataFetchContext))
			.then(videoSummary => this.videoSummary = videoSummary)
			.catch(e => {
				console.log('VideoPlaybackReports error:', e);
				this.isReportsServiceDown = true;
			})
			.finally(() => this.isInit = false);
	}


}
