import { HourMs, MinuteMs } from 'rev-shared/date/Time.Constant';

export function isTimeToStart(startDate: Date, endDate: Date, lobbyTimeMinutes: number): boolean {
	const t = Date.now();

	return t > startDate.getTime() - lobbyTimeMinutes * MinuteMs
		&& t < endDate.getTime();
}

export function isTimeToStartPreProduction(preProdDurationMs: number, startDate: Date): boolean {
	const t = Date.now();
	const webcastStart = startDate.getTime();
	const preProdStart = webcastStart - preProdDurationMs;

	return t > preProdStart && t < webcastStart;
}

export function isPastEndDate(endDate: Date, bufferTime: number = HourMs): boolean {
	const t = Date.now();

	return t > endDate.getTime() + bufferTime;
}
