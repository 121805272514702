import { enableProdMode, NgModuleRef, NgZone } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { UrlService, UIRouter } from '@uirouter/angular';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';

const ANGULAR_DEFAULT_LOCALE: string = 'en-US';

const localeId: string = BootstrapContext.bcp47Locale;

export function bootstrapAngularApp(angularRootModule): Promise<any> {
	if (process.env.NODE_ENV === 'production' && !BootstrapContext.debug) { // provided by webpack
		enableProdMode();
	}

	if (localeId !== ANGULAR_DEFAULT_LOCALE) {
		import(
			/* webpackChunkName: 'ngLocale-[request]' */
			/* webpackMode: 'lazy' */
			/* webpackInclude: /de|en|es|fr|it|ja|pt|zh/ */ // feature doesn't seem to work quite right
			/* webpackExclude: /extra/ */
			`@angular/common/locales/${localeId}.js`
		)
			.then(({ default: localeData }) => registerLocaleData(localeData, localeId));
	}

	return platformBrowserDynamic()
		.bootstrapModule(angularRootModule)
		.then(startAngularUiRouter);
}

function startAngularUiRouter(platformRef: NgModuleRef<any>): NgModuleRef<any> {
	const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;
	const ngZone: NgZone = platformRef.injector.get(NgZone);

	ngZone.run(() =>{
		urlService.listen();
		urlService.sync();
	});

	return platformRef;
}
