import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { UnlistedVideoIndicatorAngularModule } from 'rev-shared/media/unlistedVideoIndicator/UnlistedVideoIndicator.AngularModule';
import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';

import { CategoryTileComponent } from './CategoryTile.Component';
import { searchStates } from './StateDeclarations';
import { SharedCategoryModule } from 'rev-shared/media/category/SharedCategory.Module';

const entryComponents = [
	CategoryTileComponent
];

@NgModule({
	imports: [
		CommonModule,
		SharedCategoryModule,
		TranslateModule,
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates(searchStates)
		}),
		UnlistedVideoIndicatorAngularModule
	],
	declarations: entryComponents,
	entryComponents
})
export class SearchAngularModule {}
