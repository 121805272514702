import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { TransitionService, Transition, UrlService } from '@uirouter/angular';
import { Subscription } from 'rxjs';

import { isMobileSafariWithDialogBlocking } from 'rev-shared/util/UserAgentUtil';

import { StateContext } from './StateContext';

/**
 * Utility component to show a confirmation dialog if the user attempts to close the browser,
 * navigate to anther page in rev, or another site.
 */
@Directive({
	selector: '[vbNavigationHandler]'
})
export class VbNavigationHandlerDirective implements AfterViewInit, OnDestroy {
	@Input() public locationChangeMessage: string; // Confirmation message. Shown before a state change, or url change.
	@Input() public unloadMessage: string; // Confirmation message. Shown before the page unloads.

	// Return false to show a confirmation message and block navigation.
	@Input() public onNavigate: () => boolean;

	private readonly transitionDebounceTime = 1000;
	private contextStateName: string;
	private isConfirmAllowed: boolean;
	private lastTransitionTime: number = Date.now();
	private removeTransitionOnStartHandler: () => void;
	private unsubOnUrlChange: () => void;

	constructor(
		private el: ElementRef,
		private $transitions: TransitionService,
		private url: UrlService
	) {
	}

	public ngAfterViewInit(): void {
		this.contextStateName = StateContext(this.el.nativeElement).name;
		this.isConfirmAllowed = !isMobileSafariWithDialogBlocking();

		this.unsubOnUrlChange = this.url.onChange(e => this.onLocationChangeStart(e)) as () => void;
		this.removeTransitionOnStartHandler = this.$transitions.onStart({}, this.onTransitionStart) as () => any;
		window.addEventListener('beforeunload', this.onBeforeUnload);
	}

	public ngOnDestroy(): void {
		window.removeEventListener('beforeunload', this.onBeforeUnload);
		this.removeTransitionOnStartHandler();
		this.unsubOnUrlChange?.();
	}

	private confirmLocationChange(): boolean {
		return !this.isConfirmAllowed || window.confirm(this.locationChangeMessage);
	}

	private ignoreLocationChangeStart(): boolean {
		return Date.now() - this.lastTransitionTime < this.transitionDebounceTime;
	}

	private isChildState(stateName: string): boolean {
		return stateName.startsWith(this.contextStateName);
	}

	private onBeforeUnload = (): string => {
		if (this.onNavigate() === false) {
			return this.unloadMessage;
		}
	};

	private onLocationChangeStart = (event: any): void => {
		if (this.ignoreLocationChangeStart()) {
			return;
		}

		if(this.onNavigate() === false && !this.confirmLocationChange()) {
			event.preventDefault();
		}
	};

	private onTransitionStart = (transition: Transition): boolean => {
		this.lastTransitionTime = Date.now();

		if (this.isChildState(transition.to().name)) {
			return;
		}

		if (this.onNavigate() === false && !this.confirmLocationChange()) {
			return false;
		}
	};
}
