import { NgModule } from '@angular/core';

import { NgxBoostrapTabOrderDirective } from './NgxBootstrapTabOrderDirective';
import { VbImageRetryDirective } from './VbImageRetryAngular.Directive';
import { VbLoadingBarComponent } from './VbLoadingBar.Component';
import { VbScheduleChangeDetectionDirective } from './VbScheduleChangeDetection.Directive';
import { VbScrollItemXDirective } from './VbScrollItemX.Directive';
import { VbTitleComponent } from './VbTitle.Component';
import { VbTitleService } from './VbTitle.Service';
import { VbUiStretchDirective } from './VbUiStretch.Directive';
import { VbUiTailAngularDirective } from './VbTailAngular.Directive';
import { VbWatchDimensionsDirective } from './VbWatchDimensions.Directive';

const entryComponents = [
	VbLoadingBarComponent,
	VbTitleComponent
];

const directives = [
	NgxBoostrapTabOrderDirective,
	VbImageRetryDirective,
	VbScheduleChangeDetectionDirective,
	VbScrollItemXDirective,
	VbUiStretchDirective,
	VbUiTailAngularDirective,
	VbWatchDimensionsDirective
];

@NgModule({
	declarations: [...entryComponents, ...directives],
	entryComponents,
	exports: [...entryComponents, ...directives],
	providers: [
		VbTitleService
	]
})
export class DirectivesAngularModule {}
