import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';
import {
	IAttributes,
	INgModelController
} from 'angular';

/**
 * Used for server side errors that come from invalid data.
 * Supports a comma-separated list of error flag strings or a single value.
 *
 * <input vb-clear-on-change="hostNameInUse">
 * <input vb-clear-on-change="hostNameInUse, anotherBackendError">
 * In the component, set the error status with:  formName.inputName.$setValidity("errorKey", false);
 * The error will be present on the form controller until the ngModel changes.
 */
@Directive({
	selector: '[vb-clear-on-change]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbClearOnChangeDirective implements OnInit {
	private errorFlags: string;
	private ngModel: INgModelController;

	constructor(
		private $attrs: IAttributes
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.errorFlags = this.$attrs.vbClearOnChange;

		this.ngModel.$parsers.push(value => this.clearError(value));
		this.ngModel.$formatters.push(value => this.clearError(value));
	}

	private clearError(value: string): string {
		this.errorFlags
			.split(',')
			.map(errorFlag => errorFlag.trim())
			.filter(errorFlag => errorFlag.length)
			.forEach(errorFlag => this.ngModel.$setValidity(errorFlag, true));

		return value;
	}
}
