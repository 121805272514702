<form [ngClass]="{
	'theme-primary': params.themed
}">
	<div class="modal-header" [ngClass]="{
			'theme-accent-bg theme-primary-font-border-fade-50': params.themed
		}">
		<span [hidden]="!(params.headerIconClass)" [ngClass]="params.headerIconClass" class="glyphicons icon-style" [attr.icon-type]="params.headerIconType">
		</span>
		<h4 [ngClass]="{
			'theme-accent-txt': params.themed
		}">
			{{params.title}}
			<button class="close pull-right btn btn-transparent" [class.theme-accent-txt]="params.themed" [hidden]="!params.cancelText" [ngClass]="params.themed ? styles.themedCloseBtn : ''" (click)="hide()" [attr.aria-label]="'Close' | translate">

				<span class="glyphicons remove_2"></span>
			</button>
		</h4>
	</div>
	<div class="modal-body">
		<div *ngIf="params.htmlMessage" [innerHTML]="params.htmlMessage">
		</div>
		<div *ngIf="!params.htmlMessage">
			{{params.message}}
		</div>

		<vb-ui-checkbox [ngClass]="styles.rememberCheckbox" *ngIf="params.rememberText" [(ngModel)]="remember">
			{{ params.rememberText }}
		</vb-ui-checkbox>
	</div>

	<div class="modal-footer" *ngIf="!params.themed">
		<button class="btn btn-white btn-admin" [hidden]="!params.cancelText" (click)="hide()">
			{{params.cancelText}}
		</button>

		<button class="btn btn-white" [hidden]="!params.secondaryActionText" (click)="onActionInternal(ConfirmationDialogAction.SECONDARY)">
			{{params.secondaryActionText}}
		</button>

		<button class="btn btn-primary" type="submit" [hidden]="!params.actionText" (click)="onActionInternal(ConfirmationDialogAction.PRIMARY)" autofocus>
			{{params.actionText}}
		</button>
	</div>

	<div class="modal-footer" *ngIf="params.themed">
		<button class="btn" [hidden]="!params.cancelText" (click)="hide()" vbUiBtnSecondaryNgx>
			{{params.cancelText}}
		</button>

		<button class="btn" [hidden]="!params.secondaryActionText" (click)="onActionInternal(ConfirmationDialogAction.SECONDARY)" vbUiBtnSecondaryNgx>
			{{params.secondaryActionText}}
		</button>

		<button class="btn" type="submit" [hidden]="!params.actionText" (click)="onActionInternal(ConfirmationDialogAction.PRIMARY)" autofocus vbUiBtnPrimaryNgx>
			{{params.actionText}}
		</button>
	</div>
</form>
