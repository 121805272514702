import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountUpModule } from 'ngx-countup';

import { VbUiKpiComponent } from './VbUiKpi.Component';

const components = [
	VbUiKpiComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		CommonModule,
		CountUpModule
	]
})
export class KpiModule {}
