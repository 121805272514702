<vb-title [title]="'Title_ForgotPassword' | translate"></vb-title>

<div class="system-login-shell">
	<div class="table-display">
		<div class="table-row">
			<div class="table-cell system-login-nav">
				<div class="main-nav">
					<div class="navbar">
						<div class="table-cell brand-cell">
							<branding-logo></branding-logo>
						</div>

						<div class="table-cell"></div>
					</div>
				</div>
			</div>
		</div>

		<div class="table-row">
			<div class="table-cell table-cell-centered-form">
				<div class="system-login col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-xl-4 col-xl-offset-4">

					<div class="centered-copy" [hidden]="!status.processing">
						<vb-loading-spinner [msg]="'ForgotPassword_ProcessingMessage' | translate" [size]="'large'">
						</vb-loading-spinner>
					</div>

					<div [hidden]="status.processing">
						<form #forgotPassword="ngForm" class="system-form" name="forgotPassword" (submit)="submit()">
							<h1>{{ 'ForgotPassword_Heading' | translate }}</h1>

							<div [hidden]="!status.error" class="system-msg error-msg">
								<div [ngSwitch]="lastError">
									<p *ngSwitchCase="'ForgotPassword_CommandDenied'">{{ 'ForgotPassword_CommandDenied' | translate }}</p>
									<p *ngSwitchDefault><span class="glyphicons remove_2"></span>{{ 'UnhandledError' | translate }}</p>
								</div>
								<a class="btn btn-primary btn-icon-right" uiSref="login">{{ 'ReturnToLogin' | translate }}</a>
							</div>
							<div class="system-msg" [hidden]="!status.success">
								<span class="glyphicons ok_2"></span>{{ 'ForgotPassword_EmailSent' | translate }}
							</div>

							<div class="input-fields">
								<div class="username">
									<input [attr.aria-label]="'Username' | translate" autofocus class="form-control input-with-icon" id="username" name="username" [(ngModel)]="username" [placeholder]="'Username' | translate" required type="text">
									<span class="glyphicons user"></span>
								</div>
							</div>

							<div class="group system-form-buttons">
								<div class="pull-right responsive-block">
									<div class="btn-toolbar" [hidden]="!status.active">
										<a class="btn btn-white btn-lg" href="#/login">{{ 'Cancel' | translate }}</a>
										<button class="btn btn-primary btn-lg" [disabled]="forgotPassword.invalid" [hidden]="!status.active" type="submit">
											{{ 'ForgotPassword_Submit' | translate }}
										</button>
									</div>
									<a [hidden]="!status.success" class="btn btn-primary btn-icon-right pull-right btn-lg" href="#/login">{{ 'SignIn' | translate }}</a>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
