import angular from 'angular';

/**
 * Directive for inline forms consisting of a text input and buttons on a single line
 * Automatically turns the submit button into a primary button that's disabled when the form is invalid
 *
 * Usage:
 * <form vb-inline-create name="formName" ng-submit="submitForm()">
 *  <input type="text" name="..." ng-model="...">
 *  <button type="submit">Create</button>
 *  <a class="btn-cancel" type="button">Anchor tag with button type</a>
 *  <label validation="nameInUse">Name is in use</label>
 * </form>

	Attributes:
		processing: If the expression evaluates to true, hide the buttons and show the processing label

 **/
export default function vbInlineCreate() {
	return {
		restrict: 'AE',
		compile(element, attrs) {
			var formName = element.attr('name');
			var processing = attrs.processing;
			var input = element.find('input').addClass('form-control');
			var buttons = getTransformedButtons(element.find('button,a[type=button]'));
			var validationLabels = getValidationLabels(element.find('label'));

			var inputContainer = angular.element('<div class="table-cell table-cell-fill"></div>')
				.append(validationLabels)
				.append(input);

			var buttonContainer = angular.element('<div class="table-cell"></div>');
			var buttonToolbar = angular.element('<div class="btn-toolbar"></div>')
				.attr('ng-hide', processing)
				.append(buttons);
			buttonContainer.append(buttonToolbar);

			element
				.prepend(buttonContainer)
				.prepend(inputContainer);

			function getValidationLabels(labels) {
				var wrappedValidationLabels = [];
				angular.forEach(labels, label => {
					label = angular.element(label);
					var validationAttr = label.attr('validation');
					var processingAttr = label.attr('processing');

					if (validationAttr || validationAttr === '') {
						var wrappedLabel = angular.element('<div class="alert alert-danger"></div>')
							.append(angular.element('<span class="glyphicons circle_exclamation_mark"></span>'))
							.append(label);

						validationAttr && wrappedLabel.attr('ng-show', formName + '.' + input.attr('name') + '.$error.' + validationAttr); //wrap input in ang elem?
						wrappedValidationLabels.push(wrappedLabel);

					} else if (processingAttr || processingAttr === '') {
						label.attr('ng-show', processing);
					}
				});
				return wrappedValidationLabels;
			}

			function getTransformedButtons(buttons) {
				angular.forEach(buttons, button => {
					button = angular.element(button).addClass('btn');
					if (button.attr('type') === 'submit') {
						button
							.addClass('btn-primary')
							.attr('ng-disabled', formName + '.$invalid');
					} else {
						button.attr('type', 'button');
					}
				});
				return buttons;
			}
		}
	};
}
