import { VideoStatus } from 'rev-shared/media/VideoStatus';

import { VC_STATUS } from './model/VcStatus';
import { WebcastModel } from './model/WebcastModel';
import { WebcastStatus } from './WebcastStatus';

export function updateWaitingStatusForWebexMeeting(webcast: WebcastModel): void {
	if (!webcast.isWebexLiveStreamType) {
		return;
	}
	switch(webcast.status) {
		case WebcastStatus.Starting:
		case WebcastStatus.VideoSourceStarting:
		case WebcastStatus.RecordingStarting:
			if (webcast.currentUser.isEventAdmin) {
				updateVCStatus(webcast, VC_STATUS.WaitingForStream);
			}
			break;
		case WebcastStatus.Broadcasting:
		case WebcastStatus.InProgress:
			if (webcast.recordingVideoStatus === VideoStatus.STREAMING_WAITING) {
				updateVCStatus(webcast, VC_STATUS.WaitingForStream);
			}
			break;
	}
}
export function updateWaitingStatus(webcast: WebcastModel): void {
	if (webcast.adminEnrichedPresentationProfile || webcast.isRtmpStreamType) {
		switch(webcast.status) {
			case WebcastStatus.Starting:
				updateVCStatus(webcast, VC_STATUS.Initializing);
				break;

			case WebcastStatus.WaitingForStream:
				updateVCStatus(webcast, VC_STATUS.WaitingForStream);
				break;
		}
	} else if (webcast.isWebexLiveStreamType) {
		updateWaitingStatusForWebexMeeting(webcast);
	}
}
function updateVCStatus(webcast: WebcastModel, vcStatus: VC_STATUS): void {
	webcast.startingVideoSource = true;
	webcast.vcStatus.update(vcStatus);
}
