import { HookResult, Transition, TransitionService } from '@uirouter/angular';

import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';

import { UserContextService } from './UserContext.Service';

export function SecurityUiRouterStateInterceptor(transitionService: TransitionService): void {
	transitionService.onStart({}, (transition: Transition) => {
		const toStateDeclaration: IVbNg2StateDeclaration = transition.to();
		const { allowAllAccess, authorizationKey } = toStateDeclaration;

		if (!allowAllAccess || authorizationKey) {
			const injector = transition.injector();
			const SecurityContext: SecurityContextService = injector.get(SecurityContextService);
			const UserContext: UserContextService = injector.get(UserContextService);

			return checkAuthenticationAndAuthorization(toStateDeclaration, SecurityContext, UserContext);
		}
	});
}

function checkAuthenticationAndAuthorization(toStateDeclaration: IVbNg2StateDeclaration, SecurityContext: SecurityContextService, UserContext: UserContextService): HookResult {
	const {
		allowAllAccess,
		allowGuestAccess,
		allowPublicVideoAccess,
		allowRegisteredGuestAccess,
		authorizationKey,
		name
	} = toStateDeclaration;

	return SecurityContext.initializationPromise
		.then(() => {
			const error401 = { status: 401 };
			const isAuthenticatedUser: boolean = UserContext.isUserAuthenticated();
			const isGuestAccessAllowedAndAuthorized: boolean = allowGuestAccess && SecurityContext.checkAuthorization('guest'); // VOD guest
			const isPublicVideoAccessAuthorized: boolean = allowPublicVideoAccess && SecurityContext.checkAuthorization('publicVideoAccess');
			const isRegisteredGuest = UserContext.isRegisteredGuest();
			const isRegisteredGuestAccessAllowedAndAuthorized: boolean = allowRegisteredGuestAccess && isRegisteredGuest;// webcast registered guest

			if (!isAuthenticatedUser &&
				!allowAllAccess &&
				!isGuestAccessAllowedAndAuthorized &&
				!isPublicVideoAccessAuthorized &&
				!isRegisteredGuestAccessAllowedAndAuthorized
			) {
				console.log('State Change not allowed, not authenticated');
				return Promise.reject(error401);
			} else if (isRegisteredGuest && !isRegisteredGuestAccessAllowedAndAuthorized) {
				UserContext.logOutUser();
			}

			if (authorizationKey) {
				return SecurityContext.allowStateChange({ authorizationKey, name })
					.then((allowed: boolean) => {
						if(allowed) {
							return true;
						}
						console.log('State Change not allowed, authKey');
						return Promise.reject(error401);
					})
					.catch(e => {
						console.error('State Change not allowed, SecurityContext error. ', name, e);
						return Promise.reject(e);
					});
			}
		});
}
