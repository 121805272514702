import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Transition, StateService, ResolvableLiteral } from '@uirouter/angular';

import { Subscription } from 'rxjs';

import { LoginRedirectService } from '../LoginRedirect.Service';
import { UserContextService } from '../UserContext.Service';
import { SecurityContextService } from '../SecurityContext.Service';

export const LoginResolve = {
	fwdUrl($transition$: Transition): string {
		'ngInject';

		return $transition$.params().fwdUrl;
	},

	initiateLogin($transition$: Transition): string {
		'ngInject';

		return $transition$.params().initiateLogin;
	},

	invalidCredentials($transition$: Transition): boolean {
		'ngInject';

		return $transition$.params().invalidCredentials;
	},

	sessionEnded($transition$: Transition): boolean {
		'ngInject';

		return $transition$.params().sessionEnded;
	}
};

export const LoginResolveNg2: ResolvableLiteral[] = [
	{
		token: 'fwdUrl',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().fwdUrl
	},
	{
		token: 'initiateLogin',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().initiateLogin
	},
	{
		token: 'invalidCredentials',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().invalidCredentials
	},
	{
		token: 'sessionEnded',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().sessionEnded
	}
];

interface ILoginStatus {
	active: boolean;
	badCredentials: boolean;
	error: boolean;
	invalidLicense: boolean;
	loading: boolean;
	lockedOut: boolean;
	loggedOut: boolean;
	maintenance: boolean;
	processing: boolean;
	sessionEnded: boolean;
	suspended: boolean;
}

@Component({
	selector: 'login',
	templateUrl: './Login.Component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
	@Input() public disablePasswordReset?: boolean;
	@Input() public fwdUrl: string;
	@Input() public initiateLogin: boolean;
	@Input() public invalidCredentials: boolean;
	@Input() public sessionEnded: boolean;
	@Input('loginStyles') public styles: any;
	@Input() public thumbnailUri?: string;

	@ViewChild(NgForm) protected loginForm: NgForm;

	public formData: { username: string; password: string };
	public status: ILoginStatus;

	private loadedLanguage: string;
	private subscription: Subscription;

	constructor(
		protected $state: StateService,
		protected LoginRedirectService: LoginRedirectService,
		protected SecurityContext: SecurityContextService,
		protected UserContext: UserContextService
	) {}

	public ngOnInit(): void {
		this.loadedLanguage = this.UserContext.getCurrentLanguage();

		if (!this.doLogOut()) {
			this.resetStatus();
			this.status.active = true;
			this.status.badCredentials = this.invalidCredentials;
		}

		this.formData = {
			username: !this.UserContext.isRegisteredGuest() ? this.UserContext.getUser().username : null, // don't show the id generated for a registered guest
			password: null
		};

		this.subscription = this.UserContext.userIdChanged$.subscribe(() => {
			if(this.UserContext.isUserAuthenticated()) {
				this.onUserAuthenticated();
			}
		});
	}

	public ngOnDestroy(): void {
		if(this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	protected onUserAuthenticated() {
		this.redirectToLandingUrl();
	}

	public submit(): void {
		if (!this.loginForm.valid) {
			return;
		}

		this.resetStatus();
		this.status.processing = true;

		this.UserContext.authenticateUser(this.formData.username, this.formData.password)
			.catch((result: any) => {
				this.resetStatus();
				this.status.active = true;
				this.onLoginError(result);
			});

		this.formData.password = null;
	}

	/**
	 * If User visits the login form after being logged in - automatically log them out
	 */
	private doLogOut(): boolean {
		if(!this.UserContext.isUserLoggedIn()) {
			return false;
		}

		this.resetStatus();
		this.status.loading = true;

		this.UserContext.logOutUser(this.sessionEnded || this.UserContext.isRegisteredGuest())
			.finally(() => {
				this.resetStatus();
				this.status.active = true;

				if (this.sessionEnded) {
					this.status.sessionEnded = true;
				} else {
					this.status.loggedOut = true;
				}
			});

		return true;
	}

	public onLoginError(result: any): void {
		if (result === 'LogOnFailed') {
			this.status.badCredentials = true;
		} else if (result === 'LockedOut') {
			this.status.lockedOut = true;
		} else if (result === 'NotActive') {
			this.status.suspended = true;
		} else if (result === 'LogOnFailedMaintenance') {
			this.status.maintenance = true;
		} else if (result === 'InvalidLicense') {
			this.status.invalidLicense = true;
		} else if (result.userAgreementRequired) {
			this.$state.go('user-agreement', {
				fwdUrl: this.fwdUrl,
				userId: result.userId
			});
		} else {
			this.status.error = true;
		}
	}

	private resetStatus(): void {
		this.status = {
			active: false,
			loading: false,
			loggedOut: false,
			processing: false,
			error: false,
			badCredentials: false,
			sessionEnded: false,
			suspended: false,
			maintenance: false,
			lockedOut: false,
			invalidLicense: false
		};
	}

	public redirectToLandingUrl(forceRefresh?: boolean): void {
		const nextLanguage = this.UserContext.getUser().resolvedLanguage;
		const refreshPage = forceRefresh ||
			nextLanguage && this.loadedLanguage !== nextLanguage;

		this.LoginRedirectService.redirectToLandingPage(this.fwdUrl, refreshPage);
	}

	public allowPasswordReset(): boolean {
		return !this.disablePasswordReset && this.SecurityContext.allowStateChangeSync('forgot-password');
	}
}
