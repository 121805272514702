import { Component } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { getFilterText } from './HighlightCellRenderer.Component';

export interface ILinkCellCfg {
	href?: string;
	uiSref?: string;
	cssClass?: string;
	iconClass?: string;
	uiSrefParams?: any;
	target?: string;
	isIconLink?: boolean;
}

export interface ILinkCellRendererParams<T> {
	getCellCfg(rowData: T): ILinkCellCfg;
}

interface ILinkCellRendererMergedParams<T> extends ICellRendererParams, ILinkCellRendererParams<T> {}

import highlightStyles from './HighlightCellRenderer.module.less';

@Component({
	selector: 'link-cell-renderer',
	templateUrl: './LinkCellRenderer.Component.html'
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
	public cssClass: string;
	public iconClass: string;
	public href: string;
	public target: string;
	public uiSref: string;
	public uiSrefParams: any;

	public linkText: string;
	public plainText: string;
	public highlightText: string;
	public highlightClass: string = highlightStyles.highlight;

	public agInit(params: ILinkCellRendererMergedParams<any>): void {
		this.updateCell(params);
	}

	public refresh(params: ILinkCellRendererMergedParams<any>): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: ILinkCellRendererMergedParams<any>): void {
		//sometimes grid data is null while grid is loading.
		const cfg: any = params.getCellCfg(params.data || {});
		const textValue = params.valueFormatted ?? params.value;

		this.cssClass = cfg.cssClass;
		this.iconClass = cfg.iconClass;
		this.href = cfg.href;
		this.target = cfg.target;
		this.uiSref = cfg.uiSref;
		this.uiSrefParams = cfg.uiSrefParams;
		this.highlightText = getFilterText(params);

		this.linkText = !cfg.isIconLink ? textValue : '';
		this.plainText = cfg.isIconLink ? textValue : '';
	}
}
