import { IInjectable, ResolvableLiteral, Transition, TransitionStateHookFn, StateDeclaration } from '@uirouter/angular';

import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';

/**
 * Adapts an Angular 2+ state declaration so it plays nice with the hybrid routing implementation.
 *
 * Resolves are a particular problem under this setup as they continue to use AngularJS dependency injection
 * even when defined on the Angular side, which leaves you having to implement a strange middle state where
 * you're forced to manually inject every depedency. This allows you to write standard ResolvableLiteral
 * objects and does the work of adapting them to work with the hybrid injection system.
 * @param states
 */
export function ng2StatesToHybridStates(states: IVbNg2StateDeclaration[]): IVbNg2StateDeclaration[] {
	return states.map(ng2StatesToHybridState);
}

export function ng2StatesToHybridState(state: IVbNg2StateDeclaration): IVbNg2StateDeclaration {
	return {
		...state,
		resolve: ng2ResolveToHybridResolve(state.resolve as ResolvableLiteral[]),
		onEnter: convertHookFn(state.onEnter),
		onExit: convertHookFn(state.onExit)
	};
}

function ng2ResolveToHybridResolve(ng2Resolve: ResolvableLiteral[] = []): { [key: string]: IInjectable } {
	return ng2Resolve.reduce((output, currentResolve) => {
		output[currentResolve.token] = ($transition$: Transition) => {
			'ngInject';

			const injector = $transition$.injector();
			const asyncDepGets = (currentResolve.deps || [])
				.map(dep => injector.getAsync(dep));

			return Promise.all(asyncDepGets)
				.then(injectedDeps => currentResolve.resolveFn.apply(null, injectedDeps));
		};

		return output;
	}, {});
}


function convertHookFn(hookFn: TransitionStateHookFn): any {
	return hookFn ? ($transition$: Transition, $state$: StateDeclaration) => {
		'ngInject';
		return hookFn($transition$, $state$);
	} :
		undefined;
}
