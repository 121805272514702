import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';

export const defaultLogo = {
	name: 'default-logo',
	url:  `${BootstrapContext.baseCdnUrl}/img/vbrick-product.png`
};

export const defaultFont = {
	name: 'Roboto',
	url:  `${BootstrapContext.baseCdnUrl}/font/roboto-regular-webfont.woff`
};

export const defaultColors = {
	accentColor: '#00a0f0',
	accentFontColor: '#fff',
	featuredVideoBackgroundColor: '#141414',
	primaryColor:  '#141414',
	primaryFontColor: '#cbcbcb'
};

export const defaultHeaderColors = {
	background: '#1a1a1a',
	font: '#d1d1d1'
};

export const defaultHelpUri = 'https://portal.vbrick.com/help/rev/7400/';
