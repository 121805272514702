import {
	Component,
	EventEmitter,
	Input,
	Inject,
	forwardRef,
	Output
} from '@angular/core';

import { VbRadioGroupBtnComponent } from './VbRadioGroupBtn.Component';

import styles from './VbRadioBtn.Component.module.less';

/**
 * Radio Button common component.
 * usage -
 * <vb-radio-group-btn>
 * 		<vb-ui-radio-btn
			[name]="'radio'"
			[vbUiRadioBtn]="'radio1'"
			[(ngModel)]="buttonValue">
			Radio 1
		</vb-ui-radio-btn>
		<vb-ui-radio-btn
			[name]="'radio'"
			[vbUiRadioBtn]="'radio2'"
			[(ngModel)]="buttonValue">
			Radio 2
* 		</vb-ui-radio-btn>
* </<vb-radio-group-btn>
 */
@Component({
	selector: 'vb-radio-btn',
	templateUrl: './VbRadioBtn.Component.html',
	host: {
		'class': 'box-block'
	}
})
export class VbRadioBtnComponent {
	@Input() public accessibilityLabel: string;
	@Input() public name: string; // Group of buttons should share the same name. Used by the underlying native radio component for nav within the group.
	@Input() public optionValue: string;

	@Output() public changed: EventEmitter<VbRadioBtnComponent> = new EventEmitter<VbRadioBtnComponent>();

	public readonly styles = styles;
	public checked: boolean;

	constructor(
		@Inject(forwardRef(() => VbRadioGroupBtnComponent)) private parent: VbRadioGroupBtnComponent
	){}

	public onChangeInternal(event: Event): void {
		event.stopPropagation();
		this.parent.onOptionSelect(this);
	}
}
