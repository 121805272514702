import ResizeObserver from 'resize-observer-polyfill';
import { debounce } from 'underscore';

import { IAugmentedJQuery } from 'angular';
import { NgZone } from '@angular/core';

import {
	Directive,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from '@vbrick/angular-ts-decorators';

import { getIosVersion, isMobileSafari, isIeOrLegacyEdge } from 'rev-shared/util/UserAgentUtil';

const compatibleIosVersion: number = 11.0;

/**
 * watchDimensions directive
 * Watches the dimensions of the container it is placed on and resizes the specified child content region for best fit while maintaining its proportions.
 *
 * OPTIONS
 *
 * watch-dimensions
 * Value should be an array of child elements (raw element or jQuery selection) that you wish to resize so that it maintains its proportion
 * when this element's size has changed. Leveraging ng-ref is suggested to gain references to elements in the template.
 *
 * natural-ratio
 * By default this assumes the content is 16:9 aspect. To override, set the natural-ratio boolean input flag to true.
 * and this will calculate the aspect ratio at first run based on the natural dimensions of the provided element to resize.
 *
 */
@Directive({
	selector: '[watch-dimensions]',
	bindToController: true
})
export class WatchDimensionsDirective implements OnInit, OnChanges, OnDestroy {
	@Input('< watchDimensions') public elementsToResize: IAugmentedJQuery[];
	@Input() public naturalRatio: boolean;

	private readonly padding: number = 32;

	private debouncedRedraw: () => void;
	private $elementsToResize: IAugmentedJQuery;
	private ratio: number;
	private inverse: number;
	private isResizeCompatible: boolean;
	private observer: ResizeObserver;
	private elementHeightCopy: number;

	constructor(
		private $element: IAugmentedJQuery,
		private ngZone: NgZone
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.debouncedRedraw = debounce(() => this.redraw(), 10);
		this.observer = new ResizeObserver(() => this.debouncedRedraw());
		this.isResizeCompatible = this.checkResizeCompatible();

		if (this.naturalRatio) { //extract the ratio from the natural dimensions of the element
			this.applyNaturalRatioBehavior();
		} else { //use 16:9
			this.ratio = 0.5625;
			this.inverse = 1.77777;
		}

		//redraw on resize
		this.ngZone.runOutsideAngular(() => { // performs very poorly with zone.js
			this.observer.observe(this.$element[0]);
		});
	}

	public ngOnChanges(changes: SimpleChanges<this>): void {
		if (changes.elementsToResize && this.elementsToResize) {
			this.$elementsToResize = this.elementsToResize.reduce((output, current) => output.add(current), $()); // combine jq selections

			if (this.naturalRatio) {
				this.applyNaturalRatioBehavior();
			}
		}
	}

	public ngOnDestroy(): void {
		this.observer.disconnect();
	}

	private applyNaturalRatioBehavior(): void {
		const image: HTMLImageElement = this.$elementsToResize[0] as HTMLImageElement;

		if (!image) {
			return;
		}

		image.onload = () => {
			this.ratio = image.naturalHeight / image.naturalWidth;
			this.inverse = image.naturalWidth / image.naturalHeight;
			this.debouncedRedraw();
		};

		//workaround an IE bug where it doesn't reliably fire the img onload event (this triggers it)
		if (isIeOrLegacyEdge()) {
			this.$elementsToResize.attr('src', this.$elementsToResize.attr('src'));
		}
	}

	private heightByWidthConstraint(width: number): number {
		return width * this.ratio;
	}

	private redraw(): void {
		const elementHeight: number = this.$element.height();
		const elementWidth: number = this.$element.width();
		const thisRatio = elementHeight / elementWidth;

		if (!this.ratio || !isFinite(thisRatio)) {
			return;
		}

		if (thisRatio >= this.ratio) { // constrain by width
			this.resizeByWidth(elementWidth);
		} else { // constrain by height
			if(!this.isResizeCompatible && elementHeight === this.elementHeightCopy) {
				this.elementHeightCopy = this.heightByWidthConstraint(elementWidth - this.padding);
				this.resizeByWidth(elementWidth);
				return;
			}

			this.resizeByHeight(elementHeight);
		}
	}

	private resizeByHeight(elementHeight: number): void {
		const elemHeight: number = elementHeight - this.padding;
		this.elementHeightCopy = elemHeight;

		this.$elementsToResize
			.css('height', this.naturalRatio ? '' : elemHeight)
			.width(elemHeight * this.inverse);
	}

	private resizeByWidth(width: number): void {
		const elemWidth: number = width - this.padding;
		const elemHeight: number = this.heightByWidthConstraint(elemWidth);

		this.$elementsToResize
			.css('width', this.naturalRatio ? '' : elemWidth)
			.height(elemHeight);
	}

	public checkResizeCompatible(): boolean {
		return !isMobileSafari()
			|| getIosVersion() >= compatibleIosVersion;
	}
}
