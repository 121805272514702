import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { VbAuthorizationBaseAngular } from './VbAuthorizationBaseAngular';
import { SecurityContextService } from './SecurityContext.Service';

/**
 * vbAuthorizationKey
 * Input is an authorization key string supplied as a value to vbAuthorizationKey.
 * Functions like ng-if where the condition is if you are authorized.
 * <div *vbAuthorizationKey="'teams.add'">Content</div>
 *
 * To provide fallback content to users who do not have authorization, add an else templateRef in the same style as *ngIf.
 * <div *vbAuthorizationKey="'teams.add'; else elseTemplateRef">Content</div>
 * <ng-template #elseTemplateRef>Else content!</ng-template>
 *
 * To surface the content if the user does not have the given authorization, supply the `fallback` arg to the structual directive.
 * <div *vbAuthorizationKey="'teams.add'; fallback true">Content</div>
 */
@Directive({
	selector: '[vbAuthorizationKey]'
})
export class VbAuthorizationKeyAngularDirective extends VbAuthorizationBaseAngular {
	@Input() public vbAuthorizationKey: string;
	@Input() public vbAuthorizationKeyFallback: boolean; // fed in similar to Else
	@Input('vbAuthorizationKeyElse') public elseTemplateRef: TemplateRef<HTMLElement>;

	constructor(
		private SecurityContext: SecurityContextService,
		templateRef: TemplateRef<HTMLElement>,
		viewContainer: ViewContainerRef
	) {
		super(templateRef, viewContainer);
	}

	public checkPermission(): boolean {
		return !!this.vbAuthorizationKeyFallback !== this.SecurityContext.checkAuthorization(this.vbAuthorizationKey); // XOR
	}
}
