import { WebcastModel } from './WebcastModel';
import { WebcastStatus as WebcastStatusType } from '../WebcastStatus';

export class WebcastStatus {
	private _isEnded: boolean = false;
	private _isCancelled: boolean = false;
	private _isFailed: boolean = false;
	public hasBroadcasted: boolean = this.isBroadcasting;

	constructor(
		private webcast: WebcastModel
	) {}

	public reset() {
		this.isEnded = false;
		this.isCancelled = false;
		this.isFailed = false;
		this.hasBroadcasted = false;
	}
	public get isEventAdminConnected(): boolean {
		return this.isInProgress || this.isBroadcasting;
	}

	public get isScheduled(): boolean {
		return this.webcast.status === WebcastStatusType.Scheduled;
	}
	public set isScheduled(isScheduled: boolean) {
		if (isScheduled) {
			this.webcast.status = WebcastStatusType.Scheduled;
		}
	}

	public get isStarting(): boolean{
		return this.webcast.status === WebcastStatusType.Starting;
	}
	public set isStarting(isStarting: boolean) {
		if (isStarting) {
			this.webcast.status = WebcastStatusType.Starting;
		}
	}

	public get isVideoSourceStarting(): boolean {
		return this.webcast.status === WebcastStatusType.VideoSourceStarting;
	}

	public set isVideoSourceStarting(isStarting: boolean) {
		if (isStarting) {
			this.webcast.status = WebcastStatusType.VideoSourceStarting;
		}
	}

	public get isInProgress(): boolean {
		return this.webcast.status === WebcastStatusType.InProgress;
	}
	public set isInProgress(isInProgress: boolean) {
		if (isInProgress) {
			this.reset();
			this.webcast.status = WebcastStatusType.InProgress;
		}
	}

	public get isBroadcasting(): boolean{
		return this.webcast.broadcast.isActive;
	}

	public set isBroadcasting(isActive: boolean) {
		this.hasBroadcasted = this.hasBroadcasted || isActive;
		this.webcast.status = isActive ? WebcastStatusType.Broadcasting : WebcastStatusType.InProgress;
	}

	public get isWaitingForStream(): boolean {
		return this.webcast.status === WebcastStatusType.WaitingForStream;
	}

	public get isStarted(): boolean{
		return !this.isScheduled && !this.isCompleted;
	}

	public get isFailed(): boolean{
		return this._isFailed;
	}
	public set isFailed(isFailed) {
		isFailed = !!isFailed;

		if (isFailed) {
			this.isScheduled = true;
		}
		this._isFailed = isFailed;
	}

	//TODO: Remove. Use isCompleted
	public get isEnded(): boolean {
		return this._isEnded;
	}
	public set isEnded(isEnded: boolean) {
		isEnded = !!isEnded;

		if (isEnded) {
			this.isScheduled = true;
		}
		this._isEnded = isEnded;
	}

	public get isCancelled(): boolean {
		return this._isCancelled;
	}
	public set isCancelled(isCancelled: boolean) {
		isCancelled = !!isCancelled;

		if (isCancelled) {
			this.isScheduled = true;
		}

		this._isCancelled = isCancelled;
	}

	public get isCompleted() {
		return this.webcast.status === WebcastStatusType.Completed;
	}
}
