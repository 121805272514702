import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PushService } from 'rev-shared/push/PushService';

import { IPoll } from '../editWebcast/EditWebcast.Contract';
import { IPollAnswer } from './Polls.Contract';
import { Poll } from './Poll';

@Injectable({
	providedIn: 'root'
})
export class PollsService {
	constructor(
		private http: HttpClient,
		private PushService: PushService
	) {}

	public getPolls(eventId: string): Promise<Poll[]> {
		return this.http.get<any>(`/scheduled-events/${eventId}/polls`)
			.toPromise()
			.then(result => result.polls);
	}

	public getAttendeePolls(eventId: string): Promise<Poll[]> {
		return this.http.get<any>(`/scheduled-events/${eventId}/attendee-polls`)
			.toPromise()
			.then(result => result.polls);
	}

	public addWebcastPolls(webcastId: string, polls: IPoll[]): Promise<string[]> {
		return this.PushService.dispatchCommand('scheduledEvents:AddWebcastPolls', {
			webcastId,
			polls: polls.map(poll => ({
				question: poll.question,
				answers: this.pluckAndCompactAnswers(poll.answers),
				multipleChoice: poll.multipleChoice
			}))
		}, ['WebcastPollsAdded'])
			.then(result => result.message.pollIds);
	}

	public updateWebcastPolls(webcastId: string, polls: IPoll[]): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:UpdateWebcastPolls', {
			webcastId,
			polls: polls.map(poll => this.convertPollForCommand(poll, { id: poll.id }))
		});
	}

	public deleteWebcastPolls(webcastId: string, pollIds: string[]): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:DeleteWebcastPolls', {
			webcastId,
			pollIds
		});
	}

	private pluckAndCompactAnswers(answers?: IPollAnswer[]): string[] {
		return (answers || []).map(answer => answer.text).filter(Boolean);
	}

	public convertPollForCommand(poll: IPoll, data?: any): any {
		return {
			question: poll.question,
			answers: this.pluckAndCompactAnswers(poll.answers),
			multipleChoice: poll.multipleChoice,
			...(data || {})
		};
	}

}
