import { NgModule } from '@vbrick/angular-ts-decorators';

import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

import { PushBus } from './PushBus.Service';
import { PushHubToken } from './PushHubToken';
import { PushService } from './PushService';
import { SignalRHubsConnectionToken } from './SignalRHubsConnectionToken';
import { SignalRStartupToken } from './SignalRStartupToken';

@NgModule({
	id: 'VBrick.Shared.Push'
})
export default class PushDowngradeModule {}

downgradeInjectables(PushDowngradeModule, [
	{
		name: 'PushBus',
		injectable: PushBus
	},
	{
		name: 'PushHub',
		injectable: PushHubToken
	},
	{
		name: 'PushService',
		injectable: PushService
	},
	{
		name: 'SignalRHubsConnection',
		injectable: SignalRHubsConnectionToken
	},
	{
		name: 'SignalRStartup',
		injectable: SignalRStartupToken
	}
]);
