import { Component,	Input, OnInit } from '@angular/core';

import { TransitionPromise } from '@uirouter/angular';

import { setCookie } from 'rev-shared/util/CookieUtil';

import { IVideoBasicInfo } from '../Media.Contract';

import './public-video-password.less';

@Component({
	selector: 'public-video-password',
	host: {
		class: 'primary-background-dark'
	},
	templateUrl: './PublicVideoPassword.Component.html'
})
export class PublicVideoPasswordComponent implements OnInit {
	@Input() public cookieKey: string;
	@Input() public isPasswordIncorrect: boolean;
	@Input() public loginRedirect: () => void;
	@Input() public submitRedirect: () => TransitionPromise;
	@Input() public videoInfo: IVideoBasicInfo;

	public password: string;
	public showPasswordError: boolean;

	public ngOnInit(): void {
		this.showPasswordError = this.isPasswordIncorrect;
	}

	public onPasswordInputChange(): void {
		this.showPasswordError = false;
	}

	private onTransitionError(): void {
		this.showPasswordError = true;
		this.password = null;
	}

	public submit(): void {
		setCookie(this.cookieKey, this.password);

		this.submitRedirect()
			.transition
			.onError(null, () => this.onTransitionError());
	}
}
