import { OnChanges, Component, Input } from '@angular/core';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { getColorRgb } from './ColorUtility';

import './common-theme-styles.less';

@Component({
	selector: 'vb-theme-styles',
	template: `<ng-template [vbCssRules]="themeStyles"></ng-template>`
})
export class ThemeStylesComponent implements OnChanges {
	@Input() private brandingSettings: BrandingSettings;
	public themeStyles: IRules;

	public ngOnChanges(): void {
		if(this.brandingSettings && this.brandingSettings.isLoaded) {
			this.updateStyles(this.brandingSettings);
		}
	}

	public updateStyles(brandingSettings: BrandingSettings): void {
		const theme = brandingSettings.themeSettings;
		const header = brandingSettings.headerSettings;

		this.themeStyles = {
			'.theme-primary, .theme-primary-bg': {
				'background-color':  theme.primaryColor
			},
			'.theme-primary, .theme-primary-txt': {
				color: theme.primaryFontColor
			},
			'.theme-primary-txt-important': {
				color: `${theme.primaryFontColor} !important`
			},
			'.theme-primary-border': {
				'border-color': theme.primaryFontColor
			},
			'.theme-accent-bg': {
				'background-color': theme.accentColor
			},
			'.theme-accent-txt': {
				color: `${theme.accentFontColor} !important`
			},
			[`  .theme-accent,
				.theme-accent-btn:hover,
				.theme-hover-zone:hover .theme-accent-btn,
				.theme-accent-btn.active`]: {
				'background-color': `${theme.accentColor} !important`,
				'color': `${theme.accentFontColor} !important`
			},
			'.theme-accent-low': {
				'background-color': theme.accentColor,
				'color': theme.accentFontColor
			},
			'.theme-accent-inverted': {
				'background-color': `${theme.accentFontColor} !important`,
				'color': `${theme.accentColor} !important`
			},
			[
			`.theme-accent-border,
				.theme-accent-border-focus:focus,
				.theme-accent-border-hover:hover`
			]: {
				'border-color': theme.accentColor
			},
			[
			`.theme-accent-border-important`
			]: {
				'border-color': `${theme.accentColor} !important`
			},
			'.theme-accent-font-border': {
				'border-color': theme.accentFontColor
			},
			[`	.theme-accent-link,
				.theme-accent-link:hover,
				.theme-accent-link:focus,
				.theme-accent-link:visited,
				.theme-accent-icon:before`]: {
				color: `${theme.accentColor} !important`
			},
			'@font-face': {
				'font-family': 'theme-font',
				'src': `url("${theme.fontUri}") format('woff')`
			},
			'.theme-font': {
				'font-family': 'theme-font'
			},
			'.theme-header, .theme-header-bg': {
				'background-color': `${header.backgroundColor} !important`
			},
			'.theme-header, .theme-header-txt': {
				color: `${header.fontColor} !important`
			},
			'.theme-header-font-border-color': {
				'border-color': `${header.fontColor} !important`
			},
			'.theme-primary-font-border-fade-50': {
				'border-color': getColorRgb(theme.primaryFontColor, '.5')
			},
			'.theme-primary-font-border-fade-30': {
				'border-color':  `${getColorRgb(theme.primaryFontColor, '.3')} !important`
			},
		};
	}
}
