import { INgModelController, IOnChangesObject } from 'angular';
import { AfterViewInit, Directive, Input, OnChanges, OnInit } from '@vbrick/angular-ts-decorators';
import moment from 'moment';

import { isNumber, isDefined } from 'rev-shared/util';
import { DateParser, DateParserFactory } from './DateParser.Contract';

const defaultFormat = 'LT';

/**
 * Input for a time string
 * Attrs:
 *  vb-time-input: a moment time format string. https://momentjs.com/docs/#/displaying/format/
 *  base-date: a date object used to calculate the time of day.  For example: if base-date is 3/9/2014 (DST begins at 2:00am on 3/9), and the input value is 10:00 am. The output value will be 9 hours.
 * Output value:
 *  the parsed time of day in ms
 */
@Directive({
	selector: '[vb-time-input]',
	require: {
		ngModel: 'ngModel'
	}
})
export class VbTimeInputDirective implements AfterViewInit, OnInit, OnChanges {
	@Input() private vbTimeInput: string;
	@Input('<? baseDate') private baseDateInput: Date;

	private baseDate: Date;
	private format: string;
	private ngModel: INgModelController;
	private parseTime: DateParser;

	constructor(
		private DateParser: DateParserFactory
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.format = this.vbTimeInput || defaultFormat;
		this.parseTime = this.DateParser(this.format, true);

		this.resetBaseDate();
	}

	public ngAfterViewInit(): void {
		this.ngModel.$parsers.unshift((value: string) => {
			let result: any = value;
			let valid: boolean = true;

			if (value) {
				result = this.parseTime(value, this.baseDate);
				valid = result != null;
			}

			this.ngModel.$setValidity('timeInput', valid);

			return result;
		});

		this.ngModel.$formatters.unshift(() => {
			const time: number = this.ngModel.$modelValue;
			let result: any = time;
			let valid: boolean;

			if (isNumber(time) && time >= 0) {
				result = moment(this.baseDate)
					.add(time, 'milliseconds')
					.format(this.format);

				valid = true;
			} else {
				valid = !time;
			}

			this.ngModel.$setValidity('timeInput', valid);

			return result;
		});
	}

	public ngOnChanges(changes: IOnChangesObject): void {
		if (isDefined(changes.baseDateInput)) {
			this.resetBaseDate();

			// Do not call $setViewValue here as it will trigger an ng-change
			// pitfall with this is that validators will not run either.
			let value: any = this.ngModel.$viewValue;

			if (value) {
				value = this.parseTime(value, this.baseDate);
			}

			this.ngModel.$modelValue = value;
		}
	}

	private resetBaseDate(): void {
		this.baseDate = moment(this.baseDateInput).startOf('d').toDate();
	}
}
