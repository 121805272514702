import { Pipe, PipeTransform } from '@vbrick/angular-ts-decorators';

import { HourMs } from 'rev-shared/date/Time.Constant';

@Pipe({ name: 'vbTimespanToHours' })
export class VbTimespanToHoursPipe implements PipeTransform {
	public transform(milliseconds: number): number {
		return Math.round(milliseconds / HourMs);
	}
}
