import { DistUrl } from 'rev-shared/bootstrap/BootstrapContext';

import { configureCkEditor } from './configureCkEditor';
import { loadScript } from './LoadScript';

let ckEditorPromise: Promise<void>;

export function loadEditor(): Promise<void> {
	const basePath = (window as any).CKEDITOR_BASEPATH = `${DistUrl}/ckeditor/`;
	const ckScriptSrc = basePath + 'ckeditor.js';

	if (!ckEditorPromise) {
		ckEditorPromise = loadScript(ckScriptSrc)
			.then(configureCkEditor);

		ckEditorPromise.catch(e =>
			console.error('Unable to load CKEditor: ', e));
	}

	return ckEditorPromise;
}
