<span [ngClass]="[
		styles.value,
		deltaCssClass
	]" [countUp]="value" [options]="{
		decimal: localeDecimalSymbol,
		decimalPlaces: decimalPlaces,
		duration: .4,
		separator: localeGroupSymbol,
		suffix: suffix
	}" [reanimateOnClick]="false">
</span>

<span class="glyphicons" [ngClass]="[
		styles.icon,
		deltaIconCssClass,
		thresholdIconCssClass
	]">
</span>