import {
	AfterContentInit,
	Component,
	Input,
	forwardRef,
	ContentChildren,
	QueryList
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';

import { VbRadioBtnComponent } from './VbRadioBtn.Component';

/**
 * Radio Button common component.
 * usage -
 * <vb-ui-radio-btn
		[name]="'radio'"
		[vbUiRadioBtn]="'radio1'"
		[(ngModel)]="buttonValue">
		Radio 1
	</vb-ui-radio-btn>
	<vb-ui-radio-btn
		[name]="'radio'"
		[vbUiRadioBtn]="'radio2'"
		[(ngModel)]="buttonValue">
		Radio 2
* </vb-ui-radio-btn>
 */
@Component({
	selector: 'vb-radio-group-btn',
	template: '<ng-content></ng-content>',
	host: {
		'role': 'radiogroup',
		'class': 'box-block'
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbRadioGroupBtnComponent),
			multi: true
		}
	],
})
export class VbRadioGroupBtnComponent extends BaseControlValueAccessor<string> implements AfterContentInit {
	@ContentChildren(forwardRef(() => VbRadioBtnComponent), { descendants: true })
	private radios: QueryList<VbRadioBtnComponent>;

	private selectedRadio: VbRadioBtnComponent;

	public ngAfterContentInit(): void {
		setTimeout(() => {
			this.selectedRadio = this.radios.find(radio => radio.optionValue === this.value);
			if (this.selectedRadio) {
				this.selectedRadio.checked = true;
			}
		});
	}

	public onOptionSelect(radio: VbRadioBtnComponent) {
		if (this.selectedRadio === radio) {
			return;
		}
		this.resetAllRadios();

		this.selectedRadio = radio;
		this.selectedRadio.checked = true;
		this.updateModelValue(this.selectedRadio.optionValue);
		this.selectedRadio.changed.emit(radio);
	}

	private resetAllRadios(): void {
		this.radios.forEach(radio => radio.checked = false);
	}
}
