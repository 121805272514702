import { Component, Input, OnInit } from '@angular/core';
import { ResolvableLiteral, Transition } from '@uirouter/angular';

import { Subscription } from 'rxjs';

import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

export const SSOLogoutResolve = {
	fwdUrl($transition$: Transition): string {
		'ngInject';
		return $transition$.params().fwdUrl;
	},

	sessionEnded($transition$: Transition): boolean {
		'ngInject';
		return $transition$.params().sessionEnded;
	}
};

export const SSOLogoutNg2Resolve: ResolvableLiteral[] = [
	{
		token: 'fwdUrl',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().fwdUrl
	},
	{
		token: 'sessionEnded',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().sessionEnded
	}
];

@Component({
	selector: 'sso-logout',
	templateUrl: './SSOLogout.Component.html'
})
export class SSOLogoutComponent implements OnInit {
	@Input() public fwdUrl: string;
	@Input() public sessionEnded: boolean;

	public loginUrl: string;
	public status: {
		active?: boolean;
		loading?: boolean;
	};

	private loadedLanguage: string;
	private subscription: Subscription;

	constructor(
		private LoginRedirectService: LoginRedirectService,
		private UserContext: UserContextService
	) {}

	public ngOnInit(): void {
		this.loadedLanguage = this.UserContext.getCurrentLanguage();
		this.loginUrl = this.LoginRedirectService.getLoginLink(this.fwdUrl);

		if (this.UserContext.isUserAuthenticated()) {
			this.status = { loading: true };

			this.UserContext.logOutUser(this.sessionEnded)
				.finally(() => {
					this.status = { active: true };
				});
		} else {
			this.status = { active: true };
		}

		this.subscription = this.UserContext.userIdChanged$.subscribe(() => {
			if(this.UserContext.isUserAuthenticated()) {
				this.redirectToLandingUrl();
			}
		});
	}

	public ngOnDestroy(): void {
		if(this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	private redirectToLandingUrl(): void {
		const nextLanguage = this.UserContext.getUser().resolvedLanguage;
		const refreshPage = nextLanguage && this.loadedLanguage !== nextLanguage;

		this.LoginRedirectService.redirectToLandingPage(this.fwdUrl, refreshPage);
	}
}
