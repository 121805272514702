import { NgModule } from '@angular/core';
import { UIRouterModule, Transition } from '@uirouter/angular';

import { once } from 'underscore';

import { ng2StatesToHybridStates } from 'rev-shared/uiRouterUtils/HybridRouterUtils';
import { lazyLoadFromTransition } from 'rev-shared/util/AngularJsLazyLoadHelper.Service';
import { VIDEO_PLAYBACK_STATE_NAME, VIDEO_PLAYLIST_PLAYBACK_STATE_NAME, VIDEO_SETTINGS_STATE_NAME } from './videoPlayback/Constants';

const lazyLoad = once((transition: Transition): Promise<any> =>
	lazyLoadFromTransition(
		transition,
		import(/* webpackChunkName: 'videos' */ './Videos.Module'),
		'VideosModule'
	));

@NgModule({
	imports: [
		UIRouterModule.forChild({
			states: ng2StatesToHybridStates([{
				name: `${VIDEO_PLAYBACK_STATE_NAME}.**`,
				url: '/videos/:videoId?startAt&autoplay&query',
				lazyLoad
			}, {
				name: `${VIDEO_PLAYLIST_PLAYBACK_STATE_NAME}.**`,
				url: '/playlist/:playlistId/videos/?videoId',
				lazyLoad
			}, {
				name: `${VIDEO_SETTINGS_STATE_NAME}.**`,
				url: '/video-settings/:videoId',
				lazyLoad
			}])
		})
	]
})
export class VideosLoaderModule {
}
