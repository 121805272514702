import { Pipe } from '@vbrick/angular-ts-decorators';

import { formatMediumDateTime } from './DateFormatters';

@Pipe({ name: 'vbDateTimeMedium' })
export class VbDateTimeMediumPipe {
	public transform(date: any): string {
		return formatMediumDateTime(date);
	}
}
