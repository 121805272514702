<div layout="row" [ngClass]="styles.kpiWrapper">
	<vb-ui-report-cell flex="1 1 0" [header]="'Media_Videos_TotalViews' | translate">
		<vb-ui-kpi [value]="data.totalViewsSinceUpload" [hideChangeIndicators]="true">
		</vb-ui-kpi>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Reports_UniqueViewers' | translate">
		<vb-ui-kpi [value]="data.totalUniqueViews" [hideChangeIndicators]="true">
		</vb-ui-kpi>
	</vb-ui-report-cell>

	<vb-ui-report-cell *ngIf="!isLive" flex="1 1 0" [header]="'Reports_CompletionRate' | translate">
		<vb-ui-kpi [suffix]="'%'" [value]="data.completionRate" [hideChangeIndicators]="true">
		</vb-ui-kpi>
	</vb-ui-report-cell>

	<vb-ui-report-cell flex="1 1 0" [header]="'Reports_AverageViewingTime' | translate">
		<span [ngClass]="styles.kpiData">
			{{ data.averageTime | vbTimespan:true:true }}
		</span>
	</vb-ui-report-cell>
</div>
