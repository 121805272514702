import {
	AfterViewInit,
	Directive,
	Input
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	ITimeoutService
} from 'angular';

@Directive({
	selector: '[autofocus]'
})
export class AutoFocusDirective implements AfterViewInit {
	constructor(
		private $element: IAugmentedJQuery,
		private $timeout: ITimeoutService
	) {
		'ngInject';
	}

	public ngAfterViewInit(): void {
		this.$timeout(() => this.$element.focus(), 100); //delay this. If the element is being unhidden, then the digest loop needs to complete first
	}
}
