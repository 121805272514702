import {
	Directive
} from '@vbrick/angular-ts-decorators';
import {
	IAttributes,
	IAugmentedJQuery,
	IInterpolateService,
	IParseService,
	IScope
} from 'angular';

import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';
import { ICompiledExpression } from 'angular';

interface ITranslationString {
	key: string;
	expr: string;
	nonBindable: boolean;
}
interface ITranslations {
	[key: string]: string;
}

/**
	Allow use of translated strings in your controller.

	attributes:
		name:  The translation table will be published into the related scope, under this name.
		on-ready, on-changes:
			Event handlers. Ready is fired as soon as the translations are available. Changes is only fired if the strings contain data bindings, whenever a value changes.

	child elements:
		translation
			key attribute - the translation's key
			value attribute or innerHTML - the translation's value

	example:
	<vb-translation-strings name="translations" on-ready="$ctrl.onStringsReady($event.translations)"
		<translation key="error">@Model.Strings.ErrorMessage</translation>
		-- or --
		<translation key="error" value="@Model.Strings.ErrorMessage"></translation>
	</vb-translation-strings>

	Data Interpolation:

	use ng-non-bindable to retain any angular interpolation expressions. Then the full string can be used in your controller. For example:

	<translation key="myMessage" ng-non-bindable>Are you sure to want to delete {{dme.name}} ?</translation>

	then in controller, do:
		var formatMessageFn = $interpolate(translations.myMessage);
		...

		var message = formatMessageFn({dme});

**/

export class VbTranslationStrings {
	public restrict: string = 'E';

	constructor(
		private $parse: IParseService,
		private $interpolate: IInterpolateService
	){
		'ngInject';
	}

	public compile(tEl: IAugmentedJQuery){
		tEl.css('display', 'none');

		const translationEls = Array.from(tEl[0].querySelectorAll('translation'));
		const translationExpressions = translationEls.map(el => this.compileTranslation(el));

		return (scope: IScope, iEl: IAugmentedJQuery, iAttrs: IAttributes) => {
			const onChanges = this.$parse(iAttrs.onChanges);
			const translationValues = {};
			translationExpressions.forEach(translation => this.linkTranslation(translation, scope, translationValues, onChanges));
			this.updateDataBindings(scope, iAttrs, translationValues);
		};
	}

	private updateDataBindings(scope: IScope, { name, onReady }: IAttributes, translations: ITranslations) {
		this.$parse(name).assign(scope, translations);
		this.$parse(onReady)(scope, new ComponentCallbackEvent({ translations }));
	}

	private compileTranslation(el: Element): ITranslationString{
		const result = {
			key: el.getAttribute('key'),
			expr: el.getAttribute('value') || el.innerHTML,
			nonBindable: el.hasAttribute('ng-non-bindable')
		};
		el.innerHTML = ''; //prevent compiling
		return result;
	}

	private linkTranslation({ key, expr, nonBindable }: ITranslationString, scope: IScope, translations: ITranslations, onChanges: ICompiledExpression) {
		const valueFn = !nonBindable ? this.$interpolate(expr, true) : null;

		if(valueFn){
			this.watchKey(key, valueFn, scope, translations, onChanges);
		}
		else {
			translations[key] = expr;
		}
	}

	private watchKey(key: string, templateFn: (context: any) => string, scope: IScope, translations: ITranslations, onChanges: ICompiledExpression): void {
		const watch = scope.$watch(() => templateFn(scope),
			(value: string) => {
				translations[key] = value;
				onChanges(scope, new ComponentCallbackEvent({ translations }));
			});

		translations[key] = templateFn(scope);
	}
}
