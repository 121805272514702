import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild
} from '@angular/core';

import { VbImageRetryDirective } from 'rev-shared/util/directives/VbImageRetryAngular.Directive';

enum ForgivingImageState {
	ERROR,
	LOADING,
	SPINNER,
	SUCCESS
}

const SPINNER_SURFACING_DELAY_MS: number = 2000;

@Component({
	selector: 'vb-ui-forgiving-image',
	templateUrl: './VbUiForgivingImage.Component.html',
	host: {
		class: 'box-block'
	}
})
export class VbUiForgivingImageComponent implements OnChanges, OnInit, OnDestroy {
	@Input() public alt: string;
	@Input() public ariaRetryButtonLabel: string;
	@Input() public retryButtonLabel: string;
	@Input() public src: string;
	@Input() public totalDelayTimeMs: number;

	private nativeElement: HTMLElement;
	private spinnerTimeout: number;

	public readonly ForgivingImageState = ForgivingImageState;
	public currentState: ForgivingImageState;
	public img: HTMLImageElement;

	@ViewChild(VbImageRetryDirective)
	public vbImageRetry: VbImageRetryDirective;

	constructor(
		element: ElementRef
	){
		this.nativeElement = element.nativeElement;
	}

	public ngOnInit(): void {
		this.img = this.nativeElement.querySelector('img');
		this.reset();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.src) {
			this.reset();
		}
	}

	public ngOnDestroy(): void {
		this.clearSpinnerTimeout();
	}

	private clearSpinnerTimeout(): void {
		if (this.spinnerTimeout) {
			window.clearTimeout(this.spinnerTimeout);
			this.spinnerTimeout = null;
		}
	}

	public onImageLoaded(): void {
		this.updateCurrentState(ForgivingImageState.SUCCESS);
	}

	public onRetriesExhausted(): void {
		this.updateCurrentState(ForgivingImageState.ERROR);
	}

	public reset(): void {
		this.updateCurrentState(ForgivingImageState.LOADING);

		if (this.src) {
			this.showSpinnerAfterDelay();
		}
	}

	public showSpinnerAfterDelay(): void {
		this.spinnerTimeout = window.setTimeout(() => {
			if (this.currentState === ForgivingImageState.LOADING) {
				this.updateCurrentState(ForgivingImageState.SPINNER);
			}
		}, SPINNER_SURFACING_DELAY_MS);
	}

	public tryAgain(): void {
		this.reset();
		this.vbImageRetry.resetAndTryAgain();
	}

	public updateCurrentState(value: ForgivingImageState): void {
		this.clearSpinnerTimeout();

		window.setTimeout(() => this.currentState = value);
	}
}
