import angular from 'angular';
import { Injectable } from '@vbrick/angular-ts-decorators';
import { auto, ITimeoutService } from 'angular';
import { Transition } from '@uirouter/angularjs';

import { isString } from 'rev-shared/util';

@Injectable('AngularJsLazyLoadHelper')
export class AngularJsLazyLoadHelperService {
	constructor(
		private $injector: auto.IInjectorService,
		private $timeout: ITimeoutService
	) {
		'ngInject';
	}

	/**
	 * @param  {Promise<any>} dynamicImport - module to be dynamically imported
	 * @param  {string} exportName? - optional param.If the imported module
	 * is not marked as default then module class name needs to be passed here.
	 * Refer GoogleAnalyticsModuleLoader.ts for more info.
	 */
	public lazyLoad(dynamicImport: Promise<any>, exportName: string = 'default'): Promise<void> {
		return dynamicImport
			.then(exports => {
				const exportModule: any = exports[exportName];

				const moduleId: string = isString(exportModule) ?
					exportModule :
					exportModule.module.name;

				if (!this.$injector.modules[moduleId]) {
					this.$injector.loadNewModules([moduleId]);
				}
				this.triggerDigest();
			});
	}

	private triggerDigest() {
		//Not using angular IPromise, digest does not run automatically.
		this.$timeout(0);
	}
}

export function lazyLoadFromTransition(
	transition: Transition,
	dynamicImport: Promise<any>,
	exportName?: string
): Promise<any> {

	const injector = transition.injector();
	const self = injector.get('AngularJsLazyLoadHelper') as AngularJsLazyLoadHelperService;

	return self.lazyLoad(dynamicImport, exportName) as Promise<any>;
}

/**
 * Lazy load an AngularJs and Angular Module
 * Used in state declaration loadChildren Method
 *
 * Loads the angularJs module, and returns the angular module, which would be loaded by ui router.
 */
export function lazyLoadHybridModule<T>(
	dynamicImport: Promise<T>,
	getAngularModule: (modules: T) => any,
	getAngularJsModule: (modules: T) => any,
): Promise<any> {
	return dynamicImport
		.then(modules => {
			angular.element(document.body)
				.injector()
				.loadNewModules([
					getAngularJsModule(modules).module.name
				]);
			return getAngularModule(modules);
		})
		.catch(e => {
			console.error('Error lazyLoadHybridModule.', e);
			return Promise.reject(e);
		});

}
