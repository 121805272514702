import {
	INgModelController,
} from 'angular';
import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';

import { isString } from 'rev-shared/util';
import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vb-integer]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbIntegerDirective implements OnInit {
	private ngModel: INgModelController;

	constructor() {
		'ngInject';
	}

	public ngOnInit(): void {
		this.ngModel.$parsers.push(value => {
			if(!value || !isString(value)){
				return value;
			}
			const modelVal = +value;
			return isNaN(modelVal) ? value : modelVal;
		});

		this.ngModel.$validators.vbInteger = (_, viewValue) => ValidationRules.checkInteger(viewValue);
	}
}
