<div dropdown #dropdown="bs-dropdown" container="body" [insideClick]="true" [ngClass]="styles.wrapper">
	<button type="button" dropdownToggle class="upload-btn btn" [ngClass]="styles.uploadBtn">
		<span class="glyphicons disk_open" aria-hidden="true">
		</span>
		<div [hidden]="!(btnText)" class="util-uppercase upload-text">
			{{btnText}}
		</div>
	</button>
	<div *dropdownMenu class="navdrop-menu dropdown-menu-right upload-media dropdown-menu" [ngClass]="styles.dropdownMenu">
		<upload-menu [teamId]="teamId" (onCloseUploadMenu)="dropdown.isOpen = false">
		</upload-menu>
	</div>
</div>