const urlRegexp = /([a-z]+):\/\/([^:]*)(:(\d+))?(\/.*)/;

export function parseQueryString(str: string): any {
	const result = {};

	if(str) {
		const pairs = str.split(/&/);

		pairs.forEach(rawPair => {
			const pair = rawPair.split(/=/);
			result[pair[0]] = pair[1] === undefined ? true : pair[1];
		});
	}

	return result;
}

export function parseUrl(url: string): { scheme, host, port, path } {
	const parts = url.match(urlRegexp);

	if(!parts){
		return null;
	}

	const [, scheme, host, , port, path] = parts;

	return { scheme, host, port, path };
}

export function buildUrl(scheme: string, host: string, port: string, path: string): string {
	return `${scheme}://${host}:${port}${path}`;
}
