export function getColorRgb(hex: string, opacity?: string): string {
	const rgb = hexToRgb(hex);
	return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b},${opacity ?? '1'})`;
}

//from stackoverflow
export function hexToRgb(hex: string): any {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function(m, r, g, b) {
		return r + r + g + g + b + b;
	});

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}
