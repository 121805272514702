import { NgModule } from '@angular/core';
import { VideoTileAngularModule } from 'rev-shared/ui/videoTile/VideoTile.AngularModule';

@NgModule({
	imports: [
		VideoTileAngularModule
	]
})
export class PlaylistsAngularModule {}

