import 'rev-shared/bootstrap/AssignWebpackPublicPath';
import 'core-js/es/promise'; // Promise polyfill has to be loaded before zone.js

import 'hidden-attribute-polyfill'; // IE10 document mode
import 'rev-shared/bootstrap/MatchesPolyfill'; // IE10 document mode

import 'rev-shared/bootstrap/zoneFlags';
import 'zone.js';
import 'reflect-metadata';

import 'webpack.sharedLib';
