import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { VideoThumbnailSelectorComponent } from './video-thumbnail/video-thumbnail-selector/VideoThumbnailSelector.Component';
import { VideoSupplementalFilesAngularModule } from './video-supplemental-files/VideoSupplementalFiles.AngularModule';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { VideoPlayerAngularModule } from 'rev-shared/videoPlayer/VideoPlayerAngular.Module';

@NgModule({
	declarations: [
		VideoThumbnailSelectorComponent
	],
	imports: [
		ButtonsAngularModule,
		CommonModule,
		DialogAngularModule,
		SpinnerAngularModule,
		TranslateModule,
		VideoPlayerAngularModule,
		VideoSupplementalFilesAngularModule
	]
})
export class VideoAdvancedSettingsAngularModule {
	constructor(dialog: DialogRegistrationService) {
		dialog.register('videoThumbnailSelector', {
			component: VideoThumbnailSelectorComponent
		});
	}
}
