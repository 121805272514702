import { NgModule } from '@angular/core';

import { StatusSpinnersAngularModule } from 'rev-shared/statusSpinners/StatusSpinnersAngular.Module';

import { EditorLoaderModule } from './editVideo/EditVideoLoader.Module';
import { VideoPlaybackAngularModule } from './videoPlayback/VideoPlaybackAngular.Module';
import { VideoSettingsAngularModule } from './VideoSettings/VideoSettingsAngular.Module';

@NgModule({
	imports: [
		EditorLoaderModule,
		StatusSpinnersAngularModule,
		VideoPlaybackAngularModule,
		VideoSettingsAngularModule
	]
})
export class VideosAngularModule {}
