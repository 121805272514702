import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { VbUiAmChartsCoreComponent } from './VbUiAmChartsCore.Component';

const components = [
	VbUiAmChartsCoreComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		TranslateModule
	]
})
export class AmChartsCoreModule {}
