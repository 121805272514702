import {
	Directive,
	Input,
	OnChanges
} from '@vbrick/angular-ts-decorators';

import {
	animate,
	IAugmentedJQuery
} from 'angular';

import './VbHidden.less';

//TODO: Migration. probably not needed, given how little this is used.
// Can bind to the style directly:   <div [style.visibility]="isVisible() ? 'visibile': 'hidden'"

/**
 * Fork of ng-hide. hides using the visibility property instead of display
 */
@Directive({
	selector: '[vb-hidden]',
	bindToController: true
})
export class VbHiddenDirective implements OnChanges {
	@Input() public vbHidden: boolean;

	private readonly VB_HIDDEN_CLASS_NAME: string = 'vb-hidden';

	constructor(
		private $animate: animate.IAnimateService,
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnChanges(): void {
		if (this.vbHidden) {
			this.$animate.addClass(this.$element, this.VB_HIDDEN_CLASS_NAME);
		} else {
			this.$animate.removeClass(this.$element, this.VB_HIDDEN_CLASS_NAME);
		}
	}
}
