import {
	Directive,
	HostListener
} from '@vbrick/angular-ts-decorators';

/***
 * Example:  <input type="text" ng-model="..." vb-disable-submit
 *
 * This will stop the input from submitting the whole form. Added because some components use nested forms that should not interfere with outer form
 ***/
@Directive({
	selector: '[vb-disable-submit]'
})
export class VbDisableSubmitDirective {
	@HostListener('keydown', ['$event'])
	private onKeydown($event: JQueryKeyEventObject): boolean {
		if ($event.keyCode === 13) {
			$event.preventDefault();

			return false;
		}
	}
}
