import {
	Directive,
	Input,
	OnChanges
} from '@vbrick/angular-ts-decorators';

import {
	animate,
	IAugmentedJQuery
} from 'angular';

/**
 * Fork of ng-show. hides using the visibility property instead of display
 */
@Directive({
	selector: '[vb-visible]',
	bindToController: true
})
export class VbVisibleDirective implements OnChanges {
	@Input() public vbVisible: boolean;

	private readonly VB_HIDDEN_CLASS_NAME: string = 'vb-hidden';

	constructor(
		private $animate: animate.IAnimateService,
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnChanges(): void {
		if (this.vbVisible) {
			this.$animate.removeClass(this.$element, this.VB_HIDDEN_CLASS_NAME);
		} else {
			this.$animate.addClass(this.$element, this.VB_HIDDEN_CLASS_NAME);
		}
	}
}
