import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VbUiFormGroupComponent } from './VbUiFormGroupAngular.Component';

@NgModule({
	declarations: [
		VbUiFormGroupComponent
	],
	exports: [
		VbUiFormGroupComponent
	],
	imports: [
		CommonModule
	]
})
export class FormGroupAngularModule { }
