import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	Output,
	EventEmitter,
	ViewChild,
	NgZone,
	Inject
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { TransitionService } from '@uirouter/angularjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AccountLicenseService } from 'rev-shared/security/AccountLicense.Service';
import { noop } from 'rev-shared/util';
import { BrowserFeatureService } from 'rev-shared/util/BrowserFeature.Service';
import { DIALOG_TOKEN } from 'rev-shared/ui/dialog/Dialog.AngularProvider';
import { EncodingTypes } from 'rev-shared/constants/EncodingTypes';
import { FileUtil } from 'rev-shared/util/FileUtil';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import {
	ICancellableQueue,
	IPromiseQueue,
	createPromiseQueue,
	createThrottledQueue,
	race
} from 'rev-shared/util/PromiseUtil';
import { IDialogService } from 'rev-shared/ui/dialog/IDialog';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import {
	ISessionKeepalive,
	SessionService
} from 'rev-shared/security/Session.Service';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { RecordingPolicy } from 'rev-shared/media/RecordingPolicy';
import { UserAccountService } from 'rev-shared/security/UserAccount.Service';
import { isMobileSafariWithDialogBlocking } from 'rev-shared/util/UserAgentUtil';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoSource } from 'rev-shared/media/VideoSource';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { RevCreateService } from 'rev-portal/media/import/RevCreate.Service';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { ThemeSettings, HeaderSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { UploadService } from 'rev-portal/media/import/Upload.Service';

import { DirectUrlType, UploadUrlType } from './UploadConstant';

import './upload-menu.less';

interface IUploadMenuStatus {
	active: boolean;
	error: boolean;
	loading: boolean;
	processing: boolean;
}

@Component({
	selector: 'upload-menu',
	templateUrl: './UploadMenu.Component.html',
	host: {
		'class': 'theme-header-bg theme-header-txt'
	}
})
export class UploadMenuComponent implements OnDestroy, OnInit {
	@Input() public teamId: string;
	@Output() private onCloseUploadMenu: EventEmitter<any> = new EventEmitter();

	@ViewChild('form') public form: NgForm;

	public readonly DirectUrlType = DirectUrlType;
	public readonly UploadUrlType = UploadUrlType;

	private accountId: string;
	private isConfirmAllowed: boolean;
	private maxFileUploadSizeBytes: number;
	private unloadListeners: Array<() => void>;
	private unsubscribeTransitionHook: () => void;
	private uploadQueue: ICancellableQueue<any>;
	private videoCreateQueue: IPromiseQueue<any>;
	public chunkUploadSizeBytes: number;
	public features: any;
	public fileDragAndDropSupported: boolean;
	public is360Video: boolean;
	public presentationProfiles: string[];
	public readonly EncodingTypes: string[] = EncodingTypes;
	public status: IUploadMenuStatus;
	public themeStyles: IRules;
	public videoLink: any;
	public videoLinks: any[];
	public videos: any[];
	public viewingHoursUnavailable: boolean;

	constructor(
		public zone: NgZone,
		@Inject(DIALOG_TOKEN) private Dialog: IDialogService,
		private $transitions: TransitionService,
		private BrowserFeature: BrowserFeatureService,
		private MediaFeatures: MediaFeaturesService,
		private PushBus: PushBus,
		private RevCreateService: RevCreateService,
		private Session: SessionService,
		private ThemeService: ThemeService,
		private TranslateService: TranslateService,
		private UploadService: UploadService,
		private UserAccount: UserAccountService,
		private accountLicense: AccountLicenseService,

		UserContext: UserContextService
	) {
		this.accountId = UserContext.getAccount().id;
	}

	public ngOnInit(): void {
		this.fileDragAndDropSupported = this.BrowserFeature.fileDragAndDrop();
		this.is360Video = false;
		this.isConfirmAllowed = !isMobileSafariWithDialogBlocking();
		this.uploadQueue = createPromiseQueue();
		this.videoCreateQueue = createThrottledQueue(3000);
		this.videos = [];
		this.videoLinks = [];
		this.videoLink = {};
		this.themeStyles = {
			'upload-menu': {
				'border-color': this.themeSettings.accentColor
			},
			'upload-menu .nav-item:not(.active)': {
				'background-color': `${this.themeSettings.accentColor} !important`
			},
			'upload-menu .nav-item:not(.active) a': {
				color: `${this.themeSettings.accentFontColor } !important`
			},
			'upload-menu .nav-item.active a:focus': {
				'background-color': `${this.headerSettings.backgroundColor} !important`
			},
			'upload-menu .nav-tabs li.active a span': {
				'color': `${this.headerSettings.fontColor} !important`
			}

		};

		this.initialize();
		this.unsubscribeTransitionHook = this.$transitions.onStart({}, () => this.closeUploadMenu()) as () => void;
	}

	public ngOnDestroy(): void {
		this.unloadListeners.forEach(destroyUnloadListener => destroyUnloadListener());

		this.videos.forEach(upload => upload.file.abort());

		this.unsubscribeTransitionHook();
	}

	public get headerSettings(): HeaderSettings {
		return this.ThemeService.brandingSettings.headerSettings;
	}

	public get themeSettings(): ThemeSettings {
		return this.ThemeService.brandingSettings.themeSettings;
	}

	public get isWebexManualImportEnabled(): boolean {
		return this.features?.webexSitesSettings?.some(site => site.isEnabled && site.enableManualImport);
	}

	public get isRecordingAllowed(): boolean {
		return this.features?.recordingPolicy !== RecordingPolicy.DISABLE;
	}

	private addCancelHandler(video: any): void {
		video.cancelPromise = new Promise<void>(resolve => {
			video.cancel = () => {
				if (video.id && video.status.submitted) {
					this.UploadService.cancelUpload(video.id);
				}

				video.aborted = true;
				video.file.abort();
				resolve();
			};
		});
	}

	public closeUploadMenu(): void {
		this.onCloseUploadMenu.emit();
	}

	public addFile({ file }): void {
		const is360: boolean = this.features.enable360VideoUploading && this.is360Video;
		const video = {
			accountId: this.accountId,
			file,
			is360,
			name: file.name,
			progress: 0,
			source: is360 ? VideoSource.UPLOAD_360 : VideoSource.UPLOAD,
			status: { complete: false, error: false, invalidFile: false, exceededMaxFileSize: false, uploading: true },
			title: file.prettyName,
			uploadDate: new Date()
		};

		this.videos.push(video);

		if (!file.isVideoFile) {
			video.status.invalidFile = true;
			video.status.uploading = false;
			file.abort();
			return;
		}

		if (this.maxFileUploadSizeBytes > 0 && file.size >= this.maxFileUploadSizeBytes) {
			video.status.exceededMaxFileSize = true;
			video.status.uploading = false;
			file.abort();
			return;
		}

		const sessionKeepalive: ISessionKeepalive = this.Session.createKeepalive();
		sessionKeepalive.begin();

		if (this.is360Reset(file)) {
			this.is360Video = false;
		}

		this.addCancelHandler(video);

		this.createVideo(video)
			.then(() => this.uploadVideo(video))
			.then(() => {
				video.status.complete = true;
			})
			.catch(() => {
				video.status.error = true;
			})
			.finally(() => {
				video.status.uploading = false;
				sessionKeepalive.end();
			});
	}

	private is360Reset(file: any): boolean {
		if (!file.file) {
			return true;
		}

		const originalFiles = file.file.originalFiles;
		if (!originalFiles || originalFiles.length < 2) {
			return true;
		}

		const lastFile = originalFiles[originalFiles.length - 1];
		return file.name === lastFile.name && file.size === lastFile.size;
	}

	private addVideoByDirectUrl({ directUrl }) {
		const video = {
			accountId: this.accountId,
			encodingType: directUrl.encodingType,
			type: directUrl.type,
			url: directUrl.url
		};

		this.UploadService.createVideoLink(video, this.teamId)
			.then(videoId => {
				this.videoCreationSuccess({
					id: videoId,
					title: video.url
				});
			})
			.catch(error => {
				this.videoCreationFailure({
					title: video.url
				});
			});
	}

	private addVideoByPresentationProfile({ presentationProfile }) {
		this.UploadService.createVideoByPresentationProfile(presentationProfile, this.teamId)
			.then(videoId => this.videoCreationSuccess({
				id: videoId,
				title: presentationProfile.name
			}))
			.catch(() => this.videoCreationFailure({
				title: presentationProfile.name
			}));
	}

	public addVideoLink(): void {
		this.resetStatus();
		this.status.processing = true;
		const { linkType } = this.videoLink;

		switch (linkType) {
			case UploadUrlType.DIRECT_URL:
				this.addVideoByDirectUrl(this.videoLink);
				break;
			case UploadUrlType.PRESENTATION_PROFILE:
				this.addVideoByPresentationProfile(this.videoLink);
				break;
		}
		this.form.form.reset();
	}

	public cancelUpload(video: any, $event: BaseJQueryEventObject): void {
		$event.stopPropagation();
		video.cancel();

		this.dismissUpload(video);
	}

	public clearVideo(video: any): void {
		const i = this.videos.indexOf(video);

		if (i >= 0) {
			this.videos.splice(i, 1);
		}
	}

	private confirmUnload(): string {
		if (this.isUploading()) {
			return this.TranslateService.instant('Uploads_ConfirmUnload');
		}
	}

	private createVideo(video: any): Promise<any> {
		return this.videoCreateQueue.enqueue(() => {
			if (video.aborted) {
				return;
			}

			return race([
				video.cancelPromise,
				this.UploadService.createVideo(video, this.teamId)
					.then(result => {
						video.id = result.id;
						video.file.setOptions({
							url: result.videoUploadUri
						});
					})]);
		});
	}

	public dismissAllImports(): void {
		this.UploadService.dismissAllImportStatuses(this.teamId);
	}

	public dismissAllUploads(): void {
		this.videos
			.filter(video => !video.status.uploading)
			.forEach(video => {
				const i = this.videos.indexOf(video);

				if (i >= 0) {
					this.videos.splice(i, 1);
				}
			});
	}

	public dismissAllVideoLinks(): void {
		this.videoLinks = [];
	}

	private dismissUpload(upload: any): void {
		const i = this.videos.indexOf(upload);

		if (i >= 0) {
			this.videos.splice(i, 1);
		}
	}

	public importRevEdit(): void {
		if (this.RevCreateService.isSupportedPlatform) {
			this.RevCreateService.openExternalDownloadPage();
		} else {
			this.Dialog
				.getDialog('RevCreateDownloadDialog')
				.open();
		}
	}

	public importWebEx(): void {
		this.Dialog
			.getDialog('ImportWebExDialog')
			.open({
				webexSitesSettings: (this.features.webexSitesSettings || []).filter(site => site.isEnabled && site.enableManualImport),
				teamId: this.teamId
			}, {
				backdrop: 'static',
				size: 'lg',
				openedClass: 'webexImportModal'
			});
	}

	private initialize(): void {
		this.resetStatus();
		this.resetVideoLink();
		this.features = {
			enableAddUrls: false,
			enableSpark: false,
			vciSettings: {
				isEnabled: false,
				isMsTeamsEnabled: false,
			},
			zoomSettings: {
				isEnabled: false,
				sipAddressSuffix: ''
			}
		};
		this.status.loading = true;

		Promise.all([
			this.loadFeatures(),
			this.loadAccount(),
			this.loadLicense()
		])
			.then(() => {
				this.resetVideoLink();

				this.resetStatus();
				this.status.active = true;
				this.zone.run(noop);
			});

		this.unloadListeners = this.initializeUnloadListeners();
	}

	public get videoImports(): any[] {
		return this.UploadService.getImportStatuses(this.teamId);
	}

	private initializeUnloadListeners(): Array<() => void> {
		const beforeUnloadEvent: string = 'beforeunload';
		const beforeUnloadHandler = () => this.confirmUnload();
		const removeBeforeUnloadListener = () => window.removeEventListener(beforeUnloadEvent, beforeUnloadHandler);

		window.addEventListener(beforeUnloadEvent, beforeUnloadHandler);

		return [
			this.$transitions.onStart({ exiting: 'portal' }, transition => {
				if (this.isUploading() && this.isConfirmAllowed && !window.confirm(this.TranslateService.instant('Uploads_ConfirmTransition'))) {
					return false;
				}
			}) as () => void,

			this.$transitions.onStart({ exiting: 'portal.media', entering: 'portal.video-basic-settings' }, transition => {
				if (this.isUploading() && this.isConfirmAllowed && this.teamId && !window.confirm(this.TranslateService.instant('Uploads_ConfirmTransition'))) {
					return false;
				}
			}) as () => void,

			this.PushBus.subscribe(this.accountId, 'Media.Videos', {
				VideoDeleted: data => {
					const uploads = (data.videoSource === 'Upload' ? this.videos : this.videoLinks);

					const i = uploads.findIndex(upload => upload.id === data.videoId);

					if (i >= 0) {
						uploads.splice(i, 1);
					}
				}
			}),

			this.PushBus.subscribe(this.accountId, this.UserAccount.RouteScope, {
				AccountDetailsSaved: data => this.storeDetails(data)
			}),

			removeBeforeUnloadListener
		];
	}

	private isUploading(): boolean {
		return this.videos.some(upload => upload.status.uploading);
	}

	private loadAccount() {
		return this.UserAccount
			.fetchAccountDetail(this.accountId)
			.then(() => this.storeDetails(this.UserAccount.fullAccountDetails[this.accountId]));
	}

	private loadFeatures() {
		return this.MediaFeatures
			.getFeatures(this.accountId)
			.then(features => {
				this.features = features;
			});
	}

	private loadLicense() {
		return this.accountLicense.reload()
			.then(() => this.viewingHoursUnavailable = !this.accountLicense.mediaViewingAllowed);
	}

	public loadPresentaionProfiles() {
		return this.UploadService.fetchPresentationProfiles(this.accountId, this.teamId)
			.then(data => this.presentationProfiles = data.presentationProfiles);
	}

	private storeDetails(data: any): void {
		this.maxFileUploadSizeBytes = data.maxFileUploadSizeGb * FileUtil.Gb;
		this.chunkUploadSizeBytes = data.chunkUploadSizeBytes;
	}

	private resetStatus(): void {
		this.status = {
			active: false,
			error: false,
			loading: false,
			processing: false
		};
	}

	private resetVideoLink(): void {
		this.videoLink = {
			directUrl: {
				url: undefined,
				type: DirectUrlType.LIVE,
				encodingType: EncodingTypes[0]
			},
			linkType: UploadUrlType.DIRECT_URL,
			presentationProfile: undefined
		};
	}

	private uploadVideo(video: any): Promise<any> {
		return this.uploadQueue.enqueue(() => {
			if (video.aborted) {
				return;
			}

			video.status.creating = true;

			const uploadPromise = this.UploadService.waitUntilCreated(video.id)
				.then(() => {
					video.status.creating = false;

					if(video.aborted){
						return this.UploadService.cancelUpload(video.id);
					}

					video.status.submitted = true;
					const file: FileWrapper = video.file;

					return lastValueFrom(file.submit$.pipe(
						tap(e => {
							if(e.isProgress) {
								video.progress = e.loaded / e.total;
								video.bitrate = e.bitrate;
								console.log('progress: ', e);
							}
						})
					));

				});

			return race([uploadPromise, video.cancelPromise]);
		});
	}

	private videoCreationFailure(video: any): void {
		this.resetStatus();
		this.status.active = true;

		this.videoLinks.push({
			title: video.title,
			status: { error: true }
		});
	}

	private videoCreationSuccess(video: any): void {
		this.resetStatus();
		this.status.active = true;

		this.videoLinks.push({
			id: video.id,
			status: { complete: true },
			title: video.title,
			uploadDate: new Date()
		});

		this.resetVideoLink();

		this.form.form.markAsPristine();
	}
}
