import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IGetInfiniteScrollRows } from 'rev-shared/ui/dataGrid/infiniteScroll/IGetInfiniteScrollRows';
import { IVbUiInfiniteScrollGridDataSourceResult } from 'rev-shared/ui/dataGrid/infiniteScroll/IVbUiInfiniteScrollGridDataSourceResult';


import {
	Observable
} from 'rxjs';

import {
	map
} from 'rxjs/operators';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable()
export class VideoPlaybackReportsService {
	constructor(
		private DateParsers: DateParsersService,
		private http: HttpClient
	) {}

	public videoSessionsGetRow: IGetInfiniteScrollRows = params => lastValueFrom(this.fetchVideoSessions(
		params.context.videoId,
		{
			size: params.pageSize,
			search: params.query,
			fields: params.query ? Object.keys(params.filterModel) : undefined,
			scrollId: params.scrollId,
			sortDirection: params.isSortAscending ? 'asc' : 'desc',
			sortBy: params.sortField,
			startDate: params.dataFetchContext?.startDate,
			endDate: params.dataFetchContext?.endDate
		}
	)).then<IVbUiInfiniteScrollGridDataSourceResult>(result => ({
		data: result.sessions,
		scrollId: result.scrollId,
		totalRows: result.total
	}));

	private fetchVideoSessions(videoId: string, params: any): Observable<any> {
		return this.http.post<any>(`/analytics/videos/${videoId}/user-sessions`, params);
	}

	public getVideoPlaybackReportsData(videoId: string, params?: any): Observable<any> {
		return this.http.get<any>(`/analytics/videos/${videoId}/summary`, { params })
			.pipe(
				map(videoSummary => this.shapeVideoSummary(videoSummary))
			);
	}

	private roundupCompletionRate(data: string): number {
		if (data.endsWith('%')) {
			data = data.substring(0, data.length - 1);
		}

		return Math.round(parseFloat(data));
	}

	private shapeVideoSummary(videoSummary: any): any {
		return Object.assign(videoSummary, {
			averageTime: this.DateParsers.parseTimespan(videoSummary.averageTime),
			totalTime: this.DateParsers.parseTimespan(videoSummary.totalTime),
			completionRate: this.roundupCompletionRate(videoSummary.completionRate),
			sessions: (videoSummary.sessions || []).map(session => (
				{
					key: this.DateParsers.parseTimespan(session.key),
					value: session.value
				}
			))
		});
	}
}
