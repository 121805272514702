import { Component, Input, OnChanges } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { MediaStateService, TITLE_SORT, UPLOADER_NAME_SORT } from 'rev-portal/media/MediaState.Service';

import styles from './VbSortFilter.module.less';

@Component({
	selector: 'vb-sort-filter',
	templateUrl: './VbSortFilter.Component.html'
})
export class VbSortFilterComponent implements OnChanges {
	@Input() public isSearch: boolean;
	@Input() public showSortRecommended: boolean;
	@Input() public sortField: string;

	public activeLink: any;
	public links: any[];
	public readonly styles = styles;

	constructor(
		public MediaStateService: MediaStateService,
		private $state: StateService
	) {}

	public ngOnChanges(): void {
		this.links = [
			this.isSearch && {
				sort: '_score',
				label: 'Media_CurrentSortValue_Relevance'
			},
			this.showSortRecommended && {
				sort: 'recommended',
				desc: true,
				label: 'Media_Recommended'
			}, {
				sort: 'whenUploaded',
				desc: true,
				label: 'Media_CurrentSortValue_UploadDate'
			}, {
				sort: TITLE_SORT,
				label: 'Media_CurrentSortValue_Title'
			}, {
				sort: 'viewCount',
				desc: true,
				label: 'Media_Videos_Views'
			}
		].filter(Boolean);

		this.activeLink = this.links.find(l => l.sort === this.sortField);
	}

	public setSortField(link: any): void {
		this.activeLink = link;
		this.MediaStateService.setSortField(link.sort);
		this.MediaStateService.setIsSortDesc(!!link.desc);
		this.onChange();
	}

	public onChange(): void {
		const baseState = this.$state.current.name?.replace(/\.[^\.]*-sidebar$/g, '');
		this.$state.reload(baseState);
	}
}
