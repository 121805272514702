import { ITimeoutService, IHttpService } from 'angular';

import { PushService } from 'rev-shared/push/PushService';
import { SignalRHubsConnection } from 'rev-shared/push/SignalRHubsConnection';

import { SecurityContextService } from './SecurityContext.Service';
import { UserContextService } from './UserContext.Service';
import { CsrfTokenHeader, getXsrfCookieName } from './Tokens';


export function SecurityRun(
	$http: IHttpService,
	$timeout: ITimeoutService,
	UserContext: UserContextService
): void {
	let token: string = UserContext.getCsrfToken();
	let isLoggedIn: boolean = UserContext.isUserLoggedIn();
	let xsrfHeaderSet: boolean;

	if(token) {
		initXsrfHeader();
	}

	UserContext.userAuthenticated$.subscribe(updateSecurityToken);
	onUserContextChange();
	UserContext.userIdChanged$.subscribe(() => {
		//session is stable. no need for any $timeout...
		if(UserContext.isSessionStable) {
			onUserContextChange();
			return;
		}
		//allow time for userId unsubscribe to complete
		$timeout(() => onUserContextChange(), 500);
	});

	function onUserContextChange(): void {
		updateSecurityToken();
		//SecurityContext.reloadAuthorization();
	}

	function updateSecurityToken(): void {
		const newToken: string = UserContext.getCsrfToken();
		const newIsLoggedIn: boolean = UserContext.isUserLoggedIn();
		if(newToken && !xsrfHeaderSet) {
			initXsrfHeader();
		}

		//PushService.setCsrfToken(newToken);

		if(token !== newToken || isLoggedIn !== newIsLoggedIn) {
			token = newToken;
			isLoggedIn = newIsLoggedIn;
			//SignalRHubsConnection.bounce();
		}
	}

	function initXsrfHeader() {
		$http.defaults.xsrfHeaderName = CsrfTokenHeader;
		$http.defaults.xsrfCookieName = getXsrfCookieName();
		xsrfHeaderSet = true;
	}
}
