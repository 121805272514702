import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import type { GridReadyEvent } from '@ag-grid-community/core';

import { Observable } from 'rxjs';
import { filter, distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { AnalyticsService } from 'rev-shared/analytics/Analytics.Service';
import { IGetInfiniteScrollRows } from 'rev-shared/ui/dataGrid/infiniteScroll/IGetInfiniteScrollRows';
import { isString } from 'rev-shared/util';
import { mediumDateValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/MediumDateTimeValueFormatter';
import { VbUiInfiniteScrollGridComponent } from 'rev-shared/ui/dataGrid/infiniteScroll/VbUiInfiniteScrollGrid.Component';

import { COLUMN_GROUP } from './ColumnConfig';

import styles from './VideoReports.module.less';

const SEARCH_KEY_LENGTH: number = 2;

@Component({
	selector: 'video-analytics-grid',
	templateUrl: './VideoAnalyticsGrid.Component.html'
})
export class VideoAnalyticsGridComponent {
	@Input() public dataFetchContext: any;
	@Input() public filterText: string;
	@Input() public getRows: IGetInfiniteScrollRows;
	@Input() public videoId: string;

	@ViewChild(VbUiInfiniteScrollGridComponent)
	public grid: VbUiInfiniteScrollGridComponent;
	@ViewChild(NgModel)
	public searchValue: NgModel;
	public searchText$: Observable<string>;


	@Output() public gridReady = new EventEmitter<GridReadyEvent>();

	public readonly COLUMN_GROUP = COLUMN_GROUP;
	public readonly mediumDateValueFormatter = mediumDateValueFormatter;
	public readonly pageSize: number = 100;
	public readonly styles = styles;

	constructor(
		public AnalyticsService: AnalyticsService
	) {}

	public ngAfterViewInit(): void {
		this.grid.suppressMultiSort = true;
		this.grid.columnPickerEnabled = true;

		this.grid.defaultColDef = {
			...this.grid.defaultColDef,
			minWidth: 100,
			resizable: true
		};

		this.grid.context = {
			...this.grid.context,
			videoId: this.videoId
		};

		this.searchText$ = this.searchValue.valueChanges
			.pipe(
				filter(text => isString(text) && (!text || text.length >= SEARCH_KEY_LENGTH)),
				debounceTime(500),
				distinctUntilChanged()
			);
	}
}
