import { auto, ILogService } from 'angular';
import { isString } from 'rev-shared/util';

export function registerExceptionHandler($provide: auto.IProvideService) {
	$provide.decorator('$exceptionHandler', ($log: ILogService) => {
		'ngInject';

		return handleException;

		function handleException(exception: any, cause: any) {
			if(isString(exception)) {
				const msg = exception as string;

				if(
					//Suppresses case where a promise being rejected with a normal value gets logged as an error.
					//Promises rejected with an exception are still logged.
					msg.startsWith('Possibly') ||
					//discard bogus qcycle messages, which seem to come from angular's own $timeout
					msg.startsWith('[$q:qcycle]')) {

					return;
				}
			}

			$log.error(exception, cause);
		}
	});
}
