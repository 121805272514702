import { Component } from '@angular/core';
import styles from './ReportsServiceDownMsgComponent.module.less';

@Component({
	selector: 'reports-service-down-msg',
	templateUrl: './ReportsServiceDownMsg.Component.html'
})

export class ReportsServiceDownMsgComponent {
	public readonly styles = styles;
}
