import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	NgZone,
	OnDestroy,
	Output,
	ViewChild
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { noop } from 'rev-shared/util';

import { loadEditor } from './loadEditor';
import { DefaultToolbar } from './Constants';
import styles from './VbUiHtmlEditor.module.less';

const CSS_FOCUS_CLASS: string = 'has-focus,theme-accent-border-important';

/**
 * wysiswyg html editor
 * Example:
 * <vb-ui-html-editor
		[(ngModel)]="output">
	</vb-ui-html-editor>
 */
@Component({
	selector: 'vb-ui-html-editor',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbUiHtmlEditor),
			multi: true
		}
	],
	template: `
		<div [ngClass]="styles.editorLoading" [hidden]="loaded">
			<vb-loading-spinner [block]="true" [size]="'medium'"></vb-loading-spinner>
		</div>
		<div #editor></div>
	`,
	host: {
		'[class]': 'styles.root'
	}
})
export class VbUiHtmlEditor implements ControlValueAccessor, AfterViewInit, OnDestroy {
	@Input() public editorConfig: CKEDITOR.config;
	@Input() public singleLineMode: boolean; //If set, Enter key will cause submit() to fire
	@Output() public submit = new EventEmitter<void>();

	@ViewChild('editor') public editorWrapper: ElementRef;

	public readonly styles = styles;
	public loaded: boolean;

	private destroyed: boolean = false;
	private editor: CKEDITOR.editor;
	private editorReadyPromise: Promise<void>;
	private iFrame: HTMLIFrameElement;
	private initValue: any;
	private isEditorReady: boolean;

	private onChange: (value: any) => void;
	private onTouched: () => void;

	constructor(
		private element: ElementRef,
		private zone: NgZone
	) {
	}

	public ngAfterViewInit(): void {
		loadEditor()
			.then(() => this.initEditor())
			.then(() => this.writeValue(this.initValue))
			.then(() => this.loaded = true);
	}

	private onValueChange(): void {
		const value = this.editor.getData();
		this.onChange(value);
		this.zone.run(noop);
	}

	public getConfig(): CKEDITOR.config {
		return {
			language: BootstrapContext.ckEditorLocale,
			toolbar: DefaultToolbar,
			removeButtons: '',
			keystrokes: this.singleLineMode ? [
				[13, 'vbSubmit'] //Enter, but not shift+enter
			]: undefined,

			enterMode: CKEDITOR.ENTER_BR,
			ignoreEmptyParagraph: true,
			fillEmptyBlocks: false,
			extraAllowedContent: 'span(vb-emoji)',
			...this.editorConfig
		};
	}

	public writeValue(value: any): void {
		if (!this.isEditorReady) {
			this.initValue = value;
			return;
		}
		this.editor.setData(value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	private initEditor(): Promise<void> {
		if(this.destroyed) {
			return;
		}

		const config = this.getConfig();
		this.editor = CKEDITOR.replace(this.editorWrapper.nativeElement, config);
		this.editorReadyPromise = new Promise(resolve => this.editor.on('instanceReady', () => resolve()));

		this.editor.on('change', () => this.onValueChange());
		this.editor.on('dataReady', () => this.onValueChange());
		this.editor.on('focus', () => this.updateFocusClass(true));
		this.editor.on('blur', () => {
			this.onTouched();
			this.updateFocusClass(false);
		});
		this.editor.on('dataReady', () => this.isEditorReady && this.zone.run(noop));

		if(this.singleLineMode){
			this.editor.addCommand('vbSubmit', {
				exec: () => {
					this.onValueChange();
					this.submit.emit();
					return true;
				}
			});
		}

		return this.editorReadyPromise.then(() => {
			this.isEditorReady = true;
			this.iFrame = this.element.nativeElement.querySelector('iframe');
		});
	}

	private updateFocusClass(isAdded: boolean): void {
		CSS_FOCUS_CLASS.split(',').forEach(className => this.iFrame.classList.toggle(className, isAdded));
	}

	public ngOnDestroy(): void {
		this.destroyed = true;
		if (this.editorReadyPromise) {
			this.editorReadyPromise.then(() => this.editor.destroy());
		}
	}
}
