import angular from 'angular';

import FormDirectivesModule from './form/FormDirectives.Module';
import ValidationDirectivesModule from './form/validation/ValidationDirectives.Module';
import VBrickUtilDirectivesModule from './Directives.Module';

import vbInlineCreate from './form/vbInlineCreateDirective';
import vbInput from './form/vbInputDirective';

export default angular.module('VBrick.Util.Directives', [
	FormDirectivesModule.module.name,
	ValidationDirectivesModule.module.name,
	VBrickUtilDirectivesModule.module.name
])
	.directive(vbInlineCreate.name, vbInlineCreate)
	.directive(vbInput.name, vbInput)

	.name;
