import {
	Directive,
	Input,
	OnChanges,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';

@Directive({
	selector: '[vb-min]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbMinDirective implements OnInit, OnChanges {
	@Input() public vbMin: any;

	private ngModel: INgModelController;

	public ngOnInit(): void {
		this.ngModel.$validators.vbMin = modelValue => this.validate(modelValue);
	}

	public ngOnChanges(): void {
		this.ngModel.$validate();
	}

	private validate(modelValue: any): boolean {
		return this.vbMin == null || this.ngModel.$isEmpty(modelValue) || !(modelValue < this.vbMin);
	}
}
