import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';
import './bs-datepicker.css';

import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';

import styles from './DateRangePicker.module.less';
import { DateUtil } from 'rev-shared/date/DateUtil';
import { TranslateService } from '@ngx-translate/core';
import { formatMediumDate } from 'rev-shared/date/DateFormatters';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { NgxLocaleLoader } from './NgxLocaleLoader.Service';

export type TDateRange = [Date, Date];

export interface IDateRange {
	range: TDateRange;
	label: string
}

@Component({
	selector: 'date-range-picker',
	templateUrl: './DateRangePicker.Component.html',
	host: {
		'[class]': 'styles.root'
	},
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DateRangePickerComponent),
		multi: true
	}]
})
export class DateRangePickerComponent extends BaseControlValueAccessor<IDateRange> implements OnInit {
	@Input() public defaultLabel: string;
	@Input() public dropdown: boolean;
	@Input() public limit1Year: boolean;
	@Input() public menuRight: boolean;
	@Input() public maxDateRange: number;
	@ViewChild(BsDropdownDirective) public bsDropdownDirective: BsDropdownDirective;

	public readonly styles = styles;

	public minDate: Date;
	public maxDate: Date;
	public ranges: IDateRange[];
	public customLabel: string;
	public bsConfig: any;

	constructor(
		NgxLocaleLoader: NgxLocaleLoader,
		private Translate: TranslateService
	) {
		super();
		NgxLocaleLoader.load();
	}

	public ngOnInit(): void {
		if(this.limit1Year) {
			this.maxDate = moment()
				.startOf('d')
				.toDate();
			this.minDate = moment(this.maxDate)
				.subtract(1, 'y')
				.toDate();
		}

		this.customLabel = this.Translate.instant('Custom');

		this.ranges = [
			this.getDaysRange(7),
			this.getDaysRange(30),
			this.getMonthRange('Media_ThisMonth', 0),
			this.getMonthRange('Reports_LastMonth', 1),
			{
				label: this.Translate.instant('Media_ThisYear'),
				range: getDateRange('y', 0, 0)
			},
			{
				label: this.Translate.instant('LastYear'),
				range: getDateRange('y', -1, -1)
			},
			!(this.maxDateRange || this.limit1Year) && {
				label: this.Translate.instant('AllTime'),
				range: [] as any
			}
		].filter(Boolean);

		this.bsConfig = {
			maxDateRange: this.maxDateRange || undefined
		};
	}

	public getLinkClass(linkRange: IDateRange): string {
		if(linkRange.label === this.value?.label) {
			return 'theme-accent';
		}
	}

	public onDateRangeChanged(range: TDateRange): void {
		if(range == this.value?.range) {
			//ngx component calls change fn after external changes too
			return;
		}

		this.updateModelValue({
			label: this.customLabel,
			range: range ? [
				DateUtil.getStartOfDay(range[0]),
				DateUtil.getEndOfDay(range[1])
			] : undefined
		});

		this.bsDropdownDirective?.hide();
	}

	public onRangeLinkClicked({ label, range }: IDateRange): void {

		this.updateModelValue({
			label,
			range: range && [...range]
		});

		if(range) {
			this.bsDropdownDirective?.hide();
		}

	}

	public getDaysRange(n: number): IDateRange {
		return {
			range: getDateRange('d', -n, -1),
			label: this.Translate.instant('Reports_VideoReports_LastNDays', { '0' : n })
		};
	}

	public getMonthRange(label: string, n: number): IDateRange {
		return {
			label: this.Translate.instant(label),
			range: getDateRange('M', -n, -n)
		};
	}

	public getLabel(): string {
		if(!this.value) {
			return this.defaultLabel;
		}

		const { label, range } = this.value;

		if((range && label === this.customLabel)) {
			return range.map(formatMediumDate).join(' - ');
		}

		return label || this.defaultLabel;
	}
}

//Calculate a range of dates for a unit, from start to end inclusive
function getDateRange(unit: moment.unitOfTime.Base, startRange: number, endRange: number): TDateRange {
	const start = moment()
		.startOf(unit)
		.add(startRange, unit);

	const end = moment()
		.endOf(unit)
		.add(endRange, unit);

	return [start.toDate(), end.toDate()];
}
