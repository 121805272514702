import { Component, Input, HostBinding, OnInit } from '@angular/core';

import styles from './VbUiReportCell.Component.module.less';

export enum VbUiReportCellSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large'
}

@Component({
	selector: 'vb-ui-report-cell',
	templateUrl: './VbUiReportCell.Component.html',
	host: {
		'[class]': 'hostClass',
		layout: 'column',
		'layout-wrap': 'false'
	}
})
export class VbUiReportCellComponent implements OnInit {
	@Input() public footers: Array<{title: string; data: any}>;
	@Input() public header: string;
	@Input() public noHeaderBottomBorder: boolean;
	@Input() public size: VbUiReportCellSize = VbUiReportCellSize.SMALL;
	@Input() public subheader: string;

	public readonly styles = styles;
	public hostClass: {[key: string]: boolean};

	public ngOnInit(): void {
		this.hostClass = {
			[this.styles.root]: true,
			[this.styles.noHeaderBottomBorder]: this.noHeaderBottomBorder,
			[this.styles.sizeLarge]: this.isSizeLargeClass,
			[this.styles.sizeMedium]: this.isSizeMediumClass,
		};
	}

	public get isSizeLargeClass(): boolean {
		return this.size === VbUiReportCellSize.LARGE;
	}

	public get isSizeMediumClass(): boolean {
		return this.size === VbUiReportCellSize.MEDIUM;
	}
}
