import {
	Directive,
	Input,
	OnChanges,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';

@Directive({
	selector: '[vb-max-length]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbMaxLengthDirective implements OnInit, OnChanges {
	@Input() public vbMaxLength: any;

	private ngModel: INgModelController;

	public ngOnInit(): void {
		this.ngModel.$validators.vbMaxLength = modelValue => this.validate(modelValue);
	}

	public ngOnChanges(): void {
		this.ngModel.$validate();
	}

	private validate(modelValue: any): boolean {
		return !modelValue ||
			!(modelValue.length >= 0) ||
			modelValue.length <= this.vbMaxLength;
	}
}
