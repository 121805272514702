import { Component, Input } from '@angular/core';

import type { XYChart } from '@amcharts/amcharts4/.internal/charts/types/XYChart';
import { IOnChartReadyData } from '../amChartsCore/VbUiAmChartsCore.Component';

import { VB_UI_LINE_CHART_HOST_CLASS, VB_UI_LINE_CHART_TEMPLATE, VbUiLineChartComponent } from './VbUiLineChart.Component';

@Component({
	selector: 'vb-ui-duration-line-chart',
	host: {
		'[class]': VB_UI_LINE_CHART_HOST_CLASS
	},
	template: VB_UI_LINE_CHART_TEMPLATE
})
export class VbUiDurationLineChartComponent extends VbUiLineChartComponent {
	@Input() public baseUnit: string;
	@Input() public durationField: number;
	@Input() public inputDurationFormat: string;

	protected initChartConfig(): void {
		this.tooltipText = '{valueX.formatDuration()}: [bold]{valueY}[/]';
		super.initChartConfig();

		this.chartConfig = Object.assign(this.chartConfig, {
			series: [this.getLineSeries()],
			xAxes: [this.getDurationAxis()],
			yAxes: [this.getValueAxis()]
		});
	}

	protected getDurationAxis(): any {
		return {
			type: 'DurationAxis',
			baseUnit: this.baseUnit || 'millisecond',
			cursorTooltipEnabled: false,
			dataFields: {
				category: this.durationField
			},
			durationFormatter: {
				durationFormat: this.inputDurationFormat || 'mm:ss'
			},
			min: 0,
			renderer: {
				grid: {
					disabled: true
				}
			},
			strictMinMax: true,
			title: {
				text: this.xAxisLabel
			}
		};
	}

	protected getValueAxis(): any {
		return Object.assign(super.getValueAxis(), {
			min: 0
		});
	}

	protected getLineSeries(): any {
		const lineSeries = Object.assign(super.getLineSeries(), {
			dataFields: {
				valueX: this.durationField,
				valueY: this.valueField
			}
		});
		return lineSeries;
	}
	public onChartReadyInternal(event: IOnChartReadyData): void {
		super.onChartReadyInternal(event);
		//workaround to show tootip in durationAxis and valueAxis
		this.chart.cursor.snapToSeries = this.chart.series.getIndex(0);
	}
}
