import { Directive } from '@vbrick/angular-ts-decorators';
import { IAttributes } from 'angular';
import { VbAuthorizationBaseDirective, vbAuthorizationBaseDirectiveConfig } from './VbAuthorizationBase.Directive';

/**
 * vb-authorization-key
 * Input is an authorization key supplied as a value to vb-authorization-key.
 * Functions like ng-if where the condition is if you are authorized.
 *
 * To provide fallback content to users who do not have access to the state, add the `vb-unauthorized-fallback` attribute.
 */
@Directive(Object.assign({
	selector: '[vb-authorization-key]'
}, vbAuthorizationBaseDirectiveConfig))
export class VbAuthorizationKeyDirective extends VbAuthorizationBaseDirective {
	public static get $inject() {
		return VbAuthorizationBaseDirective.$inject;
	}

	public static set $inject(value: Readonly<string[]>) {
		VbAuthorizationBaseDirective.$inject = value;
	}

	public isAuthorized(): boolean {
		return this.SecurityContext.checkAuthorization(this.$attrs.vbAuthorizationKey);
	}
}
