import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { VbUiBtnFabDirective } from './VbUiBtnFab.Directive';
import { VbUiBtnPrimaryAngularDirective } from './VbUiBtnPrimaryAngular.Directive';
import { VbUiBtnPrimaryNgxDirective } from './VbUiBtnPrimaryNgx.Directive';
import { VbUiBtnSecondaryDirective } from './VbUiBtnSecondaryAngular.Directive';
import { VbUiBtnSecondaryNgxDirective } from './VbUiBtnSecondaryNgx.Directive';
import { VbUiRadioBtnDirective } from './VbUiRadioBtnAngular.Directive';
import { VbUiRadioBtnGroupDirective } from './VbUiRadioBtnGroupAngular.Directive';

const directives = [
	VbUiBtnFabDirective,
	VbUiBtnPrimaryAngularDirective,
	VbUiBtnPrimaryNgxDirective,
	VbUiBtnSecondaryDirective,
	VbUiBtnSecondaryNgxDirective,
	VbUiRadioBtnDirective,
	VbUiRadioBtnGroupDirective
];

@NgModule({
	declarations: directives,
	exports: directives,
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule
	]
})
export class ButtonsAngularModule {}
