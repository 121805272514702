import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClearOnChangeDirective } from './VbClearOnChange.AngularDirective';
import { VbEmailDirective } from './VbEmail.AngularDirective';
import { VbIntegerDirective } from './VbInteger.AngularDirective';
import { VbMaxDirective } from 'rev-shared/util/directives/form/validation/VbMax.AngularDirective';
import { VbMaxLengthDirective } from './VbMaxLength.AngularDirective';
import { VbMinDirective } from './VbMinAngular.Directive';
import { VbRequiredDirective } from './VbRequired.Directive';
import { VbUrlDirective } from './VbUrlAngular.Directive';

const exports = [
	ClearOnChangeDirective,
	VbEmailDirective,
	VbIntegerDirective,
	VbMaxDirective,
	VbMaxLengthDirective,
	VbMinDirective,
	VbRequiredDirective,
	VbUrlDirective
];

@NgModule({
	declarations: exports,
	exports,
	imports: [
		CommonModule,
		FormsModule
	]
})
export class ValidationModule {

}
