import { WebcastModel } from './WebcastModel';

export class WebcastLayout {
	public _isVertical: boolean;
	public isRightSidebarOpen: boolean;
	public _isPresentationVisible: boolean;
	public isPresentationSlidesVisible: boolean;
	public isVideoVisible: boolean;
	public isFullscreenVideo: boolean;

	constructor(
		private webcast: WebcastModel
	) {
		this.reset();
	}

	public reset() {
		this._isVertical = false;
		this.isRightSidebarOpen = false;
		this._isPresentationVisible = true;
		this.isPresentationSlidesVisible = this.webcast.presentation.hasPresentation;
		this.isVideoVisible = true;
		this.isFullscreenVideo = false;
	}

	public get displayLayoutControls(): boolean {
		if (this.webcast.currentUser.isEventAdmin) {
			return true;
		}

		if (!this.webcast.webcastStatus.isStarted) {
			return false;
		}

		const isAppropriateStatus = this.webcast.currentUser.isEventAdminOrModerator ?
			true : this.webcast.webcastStatus.isBroadcasting;

		return isAppropriateStatus &&
			(!this.isVideoVisible ||
			this.webcast.presentation.hasPresentation ||
			this.webcast.currentUser.canControlPresentationSlides);
	}

	public get isPresentationVisible(): boolean {
		return this.webcast.presentation.hasPresentation && this._isPresentationVisible;
	}
	public set isPresentationVisible(isVisible: boolean) {
		this._isPresentationVisible = isVisible;
	}

	public get isVertical(): boolean {
		//vertical stacking if you have more than 1 region to stack
		return this._isVertical && this.isPresentationVisible && this.isVideoVisible;
	}
	public set isVertical(isVertical: boolean) {
		this._isVertical = isVertical;
	}
	public get canToggleView(): boolean {
		return this.webcast.presentation.hasPresentation && this.webcast.webcastStatus.isBroadcasting && !this.webcast.currentUser.canControlPresentationSlides;
	}
	public get canToggleVisibility(): boolean {
		return ( this.webcast.webcastStatus.isBroadcasting || (this.webcast.currentUser.isEventAdmin && this.webcast.webcastStatus.isInProgress));
	}

	public get isBroadcastContentVisible(): boolean {
		return this.webcast.currentUser.isEventAdminOrModerator ||
			(
				this.webcast.webcastStatus.isBroadcasting &&
				!this.webcast.isUpdatingPlaybacks
			);
	}

	public get isBroadcastContentRendered(): boolean {
		return this.webcast.currentUser.isEventAdminOrModerator ||
			this.webcast.webcastStatus.hasBroadcasted ||
			this.webcast.webcastStatus.isBroadcasting;
	}
}
