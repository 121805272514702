import { NgModule } from '@vbrick/angular-ts-decorators';
import AngularUiRouter, { StateService } from '@uirouter/angularjs';

import {
	IHttpProvider,
	ILocationProvider,
	ITimeoutService,
	IHttpService
} from 'angular';

import VBrickSharedPushModule from 'rev-shared/push/Push.downgrade.Module';
import VBrickSharedUtilModule from 'rev-shared/util/Util.Module';

import { AccountLicenseService } from './AccountLicense.Service';
import { LicenseService } from './License.Service';
import { LoginRedirectService } from './LoginRedirect.Service';
import { PasswordValidationService } from './PasswordValidation.Service';
import { RoleService } from './Role.Service';
import { SecurityContextService } from './SecurityContext.Service';
import { SecurityRedirectHelperService } from './SecurityRedirectHelper.Service';
import { SECURITY_STARTUP_INJECTION_TOKEN } from './SecurityStartup.Provider';
import { SessionService } from './Session.Service';
import { UserAccountService } from './UserAccount.Service';
import { UserAuthenticationService } from './UserAuthentication.Service';
import { UserContextService } from './UserContext.Service';
import { UserLocalIPService } from './UserLocalIP.Service';

import { SecurityRun } from './Security.Run';
import { SessionConfig } from './Session.Config';
import { VbAuthorizationKeyDirective } from './VbAuthorizationKey.Directive';
import { VbAuthorizeStateDirective } from './VbAuthorizeState.Directive';
import { UserContextStoreService } from './UserContextStore.Service';
import { ACCOUNT_DETAIL_SERVICE_TOKEN } from './AccountDetail.Provider';

import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.Security',
	declarations: [ // these have Angular equivalents
		VbAuthorizationKeyDirective,
		VbAuthorizeStateDirective
	],
	imports: [
		AngularUiRouter,
		VBrickSharedPushModule,
		VBrickSharedUtilModule
	],
	providers: [
		RoleService
	]
})
export default class SecurityModule {
	public static config(
		$httpProvider: IHttpProvider,
		$locationProvider: ILocationProvider
	): void {
		'ngInject';

		SessionConfig($httpProvider); // see SessionExtendHttpInterceptor for Angular equivalent
		$locationProvider.hashPrefix(''); // default got changed to !
	}

	public static run(
		$http: IHttpService,
		$timeout: ITimeoutService,
		UserContext: UserContextService
	): void {
		'ngInject';

		// See SecurityBootstrapAngular for the Angular equuivalent.
		// This remains for handling of the XSRF config needed for the AngularJS side.
		SecurityRun($http, $timeout, UserContext);
	}
}

downgradeInjectables(SecurityModule, [
	{
		injectable: ACCOUNT_DETAIL_SERVICE_TOKEN,
		name: 'AccountDetail'
	},
	{
		injectable: AccountLicenseService,
		name: 'accountLicense'
	},
	{
		injectable: LicenseService,
		name: 'LicenseService'
	},
	{
		injectable: LoginRedirectService,
		name: 'LoginRedirectService'
	},
	{
		injectable: PasswordValidationService,
		name: 'PasswordValidationService'
	},
	{
		injectable: SecurityContextService,
		name: 'SecurityContext'
	},
	{
		injectable: SecurityRedirectHelperService,
		name: 'SecurityRedirectHelper'
	},
	{
		injectable: SECURITY_STARTUP_INJECTION_TOKEN,
		name: 'SecurityStartup'
	},
	{
		injectable: SessionService,
		name: 'Session'
	},
	{
		injectable: UserAccountService,
		name: 'UserAccount'
	},
	{
		injectable: UserAuthenticationService,
		name: 'UserAuthenticationService'
	},
	{
		injectable: UserContextService,
		name: 'UserContext'
	},
	{
		injectable: UserContextStoreService,
		name: 'UserContextStore'
	},
	{
		injectable: UserLocalIPService,
		name: 'UserLocalIPService'
	}
]);
