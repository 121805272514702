import { Injectable, Inject } from '@angular/core';

import { compact as _compact, pick as _pick } from 'underscore';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { pluck, pairwise, filter, distinctUntilChanged } from 'rxjs/operators';

import { isDefined, isUndefined } from 'rev-shared/util';
import { getCookie, unsetCookie } from 'rev-shared/util/CookieUtil';
import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';

import { UserAuthenticationService } from './UserAuthentication.Service';
import { CsrfTokenCookie } from './Tokens';
import { UserContextStoreService } from './UserContextStore.Service';

export interface IUserContextAccount {
	id: string;
	isRootAccount: boolean;
	language: string;
	name: string;
	readonlyUserProfile: boolean;
}

export interface IUserContextUser {
	email?: string;
	firstName?: string;
	fullName?: string;
	id: string;
	isSsoUser?: boolean;
	language?: string;
	resolvedLanguage?: string;
	lastName?: string;
	username?: string;
	webcastId?: string;
	profileImageUri?: string;
}

/**
 * User Context
 * Holds information about the currently logged in user.
 */
@Injectable({
	providedIn: 'root'
})
export class UserContextService {
	private readonly userAuthenticatedSubject$: Subject<IUserContextUser>;
	private readonly userSubject$: Subject<IUserContextUser>;
	private readonly userSessionStartTimeCookie = 'sessionStart';

	private account: IUserContextAccount; // The account the user is logged into
	private loadedLanguage: string;
	private rootHostName: string;
	private sessionStable: boolean;
	private user: IUserContextUser;

	public registeredGuest: boolean;

	public readonly userAuthenticated$: Observable<IUserContextUser>;

	//The currently logged in user.
	public readonly user$: Observable<IUserContextUser>;

	//Emits a value when the user id changes, such as logging in or out.
	public readonly userIdChanged$: Observable<void>;

	constructor(
		private UserAuthenticationService: UserAuthenticationService,
		private UserContextStore: UserContextStoreService
	) {
		'ngInject';

		const user: IUserContextUser = _pick(BootstrapContext.user, [
			'email',
			'firstName',
			'fullName',
			'id',
			'isSsoUser',
			'language',
			'lastName',
			'username',
			'webcastId',
			'profileImageUri'
		]);

		this.account = _pick(BootstrapContext.account, [
			'id',
			'isRootAccount',
			'language',
			'name',
			'readonlyUserProfile'
		]);

		this.registeredGuest = (BootstrapContext.user || {}).isRegisteredGuest === true;
		this.rootHostName = BootstrapContext.rootHostName;
		this.sessionStable = !!user.id;
		this.user = user;
		this.loadedLanguage = BootstrapContext.language;

		this.userAuthenticatedSubject$ = new Subject<IUserContextUser>();
		this.userSubject$ = new BehaviorSubject<IUserContextUser>(this.user);
		this.userAuthenticated$ = this.userAuthenticatedSubject$.asObservable();
		this.user$ = this.userSubject$.asObservable();
		this.userIdChanged$ = this.user$.pipe(
			pluck('id'),
			pairwise(),
			filter<any>(([a, b]) => a !== b)
		);

		this.UserContextStore.user$.pipe(
			distinctUntilChanged((a, b) => {
				if(!a || !b) {
					throw new Error('missing data');
				}
				return a.id === b.id;
			})
		)
			.subscribe(user => this.onExternalUserChange(user));

		this.UserContextStore.setUser(user);
	}

	public acceptUserAgreement(userId: string, ssoLogin: boolean): Promise<any> {
		return this.UserAuthenticationService.acceptUserAgreement(userId, ssoLogin)
			.then((user: any) => {
				if(user) {
					user.isSsoUser = ssoLogin;

					return this.initializeUserAuthentication(user);
				}
			});
	}

	/**
	 * Attempt to authenticate a user
	 * Returns a promise object:
	 * If the login attempt fails, the promise will be rejected with the following result object:
	 * 	{
	 * 		isAuthenticateFailure - true if the user was not authenticated
	 * 		attemptsRemaining - If authentication failed, number of attempts that will be allowed until the account is locked.
	 * 		whenUnlocked - If the account was locked, date the account will be unlocked
	 * 	}
	 */
	public authenticateUser(username: string, password: string): Promise<any> {
		return this.UserAuthenticationService.authenticateUser(username, password)
			.then((user: any) => {
				if(!user) {
					throw new Error('User is null');
				}

				user.username = username;
				return this.initializeUserAuthentication(user);
			});
	}

	public onExternalUserChange(user: IUserContextUser): void {
		this.user = user;
		this.sessionStable = !!user.id;
		this.userAuthenticatedSubject$.next(user);
		this.userSubject$.next(user);
	}

	public getAccount(): IUserContextAccount {
		return this.account;
	}

	public getCurrentLanguage(): string {
		return this.loadedLanguage;
	}

	public getLanguagePreferenceOrder(): string[] {
		return BootstrapContext.languagePreferenceOrder;
	}

	public getCsrfToken(): string {
		return getCookie(CsrfTokenCookie, true);
	}

	public getRootHostName(): string {
		return this.rootHostName;
	}

	public getSessionStartTime(): number {
		return +getCookie(this.userSessionStartTimeCookie, true);
	}

	public ssoEnabled(): boolean {
		return BootstrapContext.ssoEnabled;
	}

	public baseCdnUrl(): string {
		return BootstrapContext.baseCdnUrl;
	}

	/**
	 * Returns the user who is currently logged in:
	 */
	public getUser(): IUserContextUser {
		return this.user;
	}

	public isGuest(): boolean {
		return isUndefined(this.user.id);
	}

	/**
	 * Returns true if user is logged in as a webcast guest.
	 */
	public isRegisteredGuest(): boolean {
		return this.isUserLoggedIn() && this.registeredGuest;
	}

	public isSessionStable(): boolean {
		return this.sessionStable;
	}

	/**
	 * Returns true if user is logged in normally
	 */
	public isUserAuthenticated(): boolean {
		return this.isUserLoggedIn() && !this.registeredGuest;
	}

	/**
	 * Returns true if user is logged in normally, or as a registered guest
	 */
	public isUserLoggedIn(): boolean {
		return isDefined(this.user.id);
	}

	/**
	 * Ends the users authenticated session. Logs the user out and invalidate the current access token.
	 * returns promise
	 *
	 * localLogOut: do not call the logout server api.
	 */
	public logOutUser(localLogOut: boolean = false): Promise<any> {
		if(this.isRegisteredGuest()) {
			localLogOut = true;
		}

		const doLocalLogout = () => {
			unsetCookie(CsrfTokenCookie, true);
			unsetCookie(this.userSessionStartTimeCookie, true);

			this.registeredGuest = false;
			this.sessionStable = false;
			this.user = {
				firstName: undefined,
				id: undefined
			};

			this.UserContextStore.setUser(this.user);
		};

		if(localLogOut){
			doLocalLogout(); //run synchronously
			return Promise.resolve();
		}

		return this.UserAuthenticationService.doLogout(this.user.id)
			.finally(doLocalLogout);
	}

	public registerGuestUser(user: { userId: string; name: string; webcastId: string }): void {
		this.initializeUserAuthentication({
			id: user.userId,
			firstName: user.name,
			webcastId: user.webcastId
		});

		this.registeredGuest = true;
	}

	public updateUserInfo(user: IUserContextUser): void {
		this.user = {
			...this.user,
			...user,
			fullName: _compact([user.firstName, user.lastName]).join(' ')
		};
		this.userSubject$.next(this.user);
	}

	public updateProfileImageUri(profileImageUri: string): void {
		this.user.profileImageUri = profileImageUri;
		this.userSubject$.next(this.user);
	}

	/**
	 * Create the initial state of the user authentication
	 * @param accessToken
	 * @param user
	 */
	private initializeUserAuthentication(user: IUserContextUser): Promise<any> {
		this.registeredGuest = false;
		this.sessionStable = false;
		this.user = {
			email: user.email,
			firstName: user.firstName,
			fullName: _compact([user.firstName, user.lastName]).join(' '),
			id: user.id,
			isSsoUser: user.isSsoUser,
			language: user.language,
			resolvedLanguage: user.resolvedLanguage,
			lastName: user.lastName,
			username: user.username,
			webcastId: user.webcastId,
			profileImageUri: user.profileImageUri
		};
		this.userAuthenticatedSubject$.next(this.user);

		return this.waitForSessionToStabilize()
			.then(() => {
				this.sessionStable = true;
				this.UserContextStore.setUser(this.user);
			})
			.catch(e => {
				console.log('Session not stable', e);
				return Promise.reject({ sessionNotStable: true });
			});
	}

	private waitForSessionToStabilize(): Promise<any> {
		return retryUntilSuccess(
			() => this.UserAuthenticationService.checkSessionHealth(),
			undefined,
			err => err.status !== 400
		);
	}

	public assignUser(user: IUserContextUser) {
		this.user = {
			...user,
			fullName: _compact([user.firstName, user.lastName]).join(' ')
		};
	}
}
