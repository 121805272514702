<vb-loading-spinner [hidden]="!(status.loading)" [block]="true" [size]="'large'">
</vb-loading-spinner>

<ng-template [vbCssRules]="themeStyles"></ng-template>

<tabset justified="true" *ngIf="!status.loading">
	<tab heading="{{ 'Uploads_UploadFiles' | translate }}" tabOrder="1">
		<form fileUpload class="form-horizontal form-upload-files" *ngIf="chunkUploadSizeBytes" method="post" enctype="multipart/form-data" [singleFileUploads]="true" [maxChunkSize]="chunkUploadSizeBytes" (onAdd)="addFile($event)" (submit)="noop()">
			<vb-ui-checkbox id="is360Video" name="is360Video" [(ngModel)]="is360Video" uiControl *ngIf="features.enable360VideoUploading">
				{{ 'Uploads_360Video' | translate }}
			</vb-ui-checkbox>

			<div class="upload-file-btn-wrapper file-upload-drop-zone">
				<div class="btn-block btn-file" vbUiBtnPrimary>
					<span class="glyphicons file plus margin-right margin-top-minus5">
					</span>
					<span class="position-relative">{{ 'AddFile' | translate }}</span>
					<small [hidden]="!(fileDragAndDropSupported)">{{ 'Uploads_ClickOrDragInFile' | translate }}</small>
					<input type="file" accept=".mts,video/mp4,video/x-m4v,video/x-flv,video/*,audio/mpeg,audio/mp3,audio/mpeg3,audio/x-mpeg-3" [attr.aria-label]="'AddFile' | translate " multiple="multiple" name="upload">
				</div>
			</div>
		</form>
		<!-- TODO: when scrolling lists throughout app, underlying page should NOT scroll -->
		<div class="scrollable-area-wrapper" [hidden]="!(videos.length)">
			<ol class="list-unstyled scrollable-area">
				<li *ngFor="let video of videos" class="upload-file-wrapper" [ngClass]="{ 'upload-complete': video.status.complete,
				                'upload-error': video.status.error || video.status.invalidFile || video.status.exceededMaxFileSize,
						        'upload-pending': !video.id}">
					<div class="upload-file">
						<h4 layout="row" layout-wrap="false">
							<a [hidden]="!(!video.status.error && video.id)" uiSref="portal.video-settings" [uiParams]="{videoId: video.id}" class="upload-title btn-icon-left text-ellipsis type-bold" flex="fill">
								{{ video.title }}
							</a>
							<span class="upload-title text-ellipsis type-normal" flex="fill" [hidden]="!(video.status.error)">
								<span class="glyphicons circle_exclamation_mark margin-right-3"></span>
								{{ video.title }}
							</span>
							<span class="upload-title text-ellipsis type-normal" flex="fill" [hidden]="!(!video.id && !video.status.error)">
								{{ video.title }}
							</span>

							<video-360-indicator class="margin-left" *ngIf="video.is360">
							</video-360-indicator>

							<vb-loading-spinner class="margin-left" [hidden]="!(video.status.creating)"></vb-loading-spinner>
						</h4>


						<p class="upload-date" [hidden]="!(video.status.complete && !video.status.error)">
							{{ video.uploadDate | vbDateTimeMedium }}
							<span class="glyphicons circle_ok"></span>
						</p>

						<p class="error-msg" [hidden]="!(video.status.error)">{{ 'UploadError' | translate }}
							<span class="glyphicons circle_remove" (click)="clearVideo(video)" title="{{ 'Remove' | translate }}"></span>
						</p>

						<p class="error-msg" [hidden]="!(video.status.invalidFile)">{{ 'UploadInvalidFileType' | translate }}
							<span class="glyphicons circle_remove" (click)="clearVideo(video)" title="{{ 'Remove' | translate }}"></span>
						</p>

						<p class="error-msg" [hidden]="!(video.status.exceededMaxFileSize)">{{ 'UploadExceededMaxFileSize' | translate }}
							<span class="glyphicons circle_remove" (click)="clearVideo(video)" title="{{ 'Remove' | translate }}"></span>
						</p>

						<div class="progress-wrapper with-cancel mini" [hidden]="!(!video.status.complete && !video.status.error && !video.status.invalidFile && !video.status.exceededMaxFileSize)">
							<div class="progress">
								<div class="progress-bar" role="progressbar" [ngStyle]="{ width: video.progress * 100 + '%' }">
									<span class="sr-only">{{ ( video.progress * 100) | number: 0 }}% {{ 'Complete' | translate }}</span>
								</div>
							</div>
	                        <span class="glyphicons circle_remove" (click)="cancelUpload(video, $event)" [hidden]="!(!video.status.complete)"></span>
						</div>
					</div>
				</li>
			</ol>
		</div>
		<div class="menu-footer group">
			<a [hidden]="!(videos.length)" (click)="dismissAllUploads()" class="pull-left text-color-inherit">{{ 'ClearCompleted' | translate }}</a>
			<a uiSref="portal.media.uploads" *vbAuthorizationKey="'media.add'" class="pull-right text-color-inherit">{{ 'Uploads_SeeAllUploads' | translate }}</a>
			<div [hidden]="!(viewingHoursUnavailable)" class="util-white-space-normal util-clear-both alert-danger upload-viewing-hours-warning">{{ 'Uploads_ViewingHoursNotAvailable' | translate }}</div>
		</div>


	</tab>
	<tab heading="{{ 'AddUrls' | translate }}" *ngIf="features.enableAddUrls" (selectTab)="loadPresentaionProfiles()" tabOrder="2">
		<div class="container-fluid" [hidden]="!(status.error)">
			<div class="row">
				<div class="col-xs-12">
					<div class="panel panel-default" style="padding:1px;">
						<div class="centered-copy">
							<div role="alert" class="alert alert-danger">
								<span class="glyphicons circle_exclamation_mark"></span>&nbsp;{{ 'UnhandledError' | translate }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<form #form="ngForm" novalidate (submit)="addVideoLink()">
			<div class="link-url-wrapper group">
				<vb-ui-form-group>
					<label for="createVideoUsing" #uiLabel>{{ 'CreateUsing' | translate }}</label>
					<vb-ui-select uiControl>
						<select id="createVideoUsing" class="form-control" #selectElement [(ngModel)]="videoLink.linkType" name="createVideoUsing">
							<option [value]="UploadUrlType.DIRECT_URL">
								{{ 'DirectUrl' | translate }}
							</option>
							<option [value]="UploadUrlType.PRESENTATION_PROFILE">
								{{ 'PresentationProfile' | translate }}
							</option>
						</select>
					</vb-ui-select>
				</vb-ui-form-group>
				<vb-ui-form-group *ngIf="videoLink.linkType === UploadUrlType.DIRECT_URL">
					<label for="uvWebAddress" #uiLabel>{{ 'WebAddress' | translate }}</label>
					<input type="text" id="uvWebAddress" name="uvWebAddress" #url="ngModel" placeholder="{{ 'LinkToUrl' | translate }}" vbUiTextInput required uiControl vbUiUrl class="form-control" [ngClass]="{'form-control-focused': focus}" [(ngModel)]="videoLink.directUrl.url">
					<div class="has-error control-label" role="alert" vbUiErrorMessage uiValidation>
						<span *ngIf="!form.valid && form.touched">
							{{ 'InvalidUrl' | translate }}
						</span>
					</div>
				</vb-ui-form-group>
				<vb-ui-form-group *ngIf="videoLink.linkType === UploadUrlType.PRESENTATION_PROFILE">
					<label for="uvPresentationProfile" #uiLabel>{{ 'PresentationProfile' | translate }}</label>
					<vb-ui-select uiControl>
						<select id="uvPresentationProfile" #selectElement class="form-control" required="required" [(ngModel)]="videoLink.presentationProfile" name="uvPresentationProfile">
							<option [ngValue]="option" *ngFor="let option of presentationProfiles">{{option.name}}</option>
						</select>
					</vb-ui-select>
				</vb-ui-form-group>
				<div class="flex-navigation-bar equal-spacing">
					<div [hidden]="!(videoLink.linkType === UploadUrlType.DIRECT_URL)">
						<vb-ui-form-group>
							<label id="uvVideoType" #uiLabel>{{ 'VideoType' | translate }}</label><br/>
							<div vbUiRadioBtnGroup aria-labelledby="uvVideoType" uiControl>
								<button type="button" name="type" [(ngModel)]="videoLink.directUrl.type" [vbUiRadioBtn]="DirectUrlType.LIVE">
									{{ 'Live' | translate }}
								</button>
								<button type="button" name="type" [(ngModel)]="videoLink.directUrl.type" [vbUiRadioBtn]="DirectUrlType.VOD">
									{{ 'VOD' | translate }}
								</button>
							</div>
						</vb-ui-form-group>
					</div>
					<div [hidden]="!(videoLink.linkType === UploadUrlType.DIRECT_URL)">
						<vb-ui-form-group>
							<label for="uvEncodingType" #uiLabel>{{ 'EncodingType' | translate }}</label>
							<vb-ui-select uiControl>
								<select id="uvEncodingType" class="form-control selectize-style" name="uvEncodingType" type="text" #selectElement [(ngModel)]="videoLink.directUrl.encodingType" required ng-options="encodingType for encodingType in EncodingTypes">
									<option *ngFor="let encodingType of EncodingTypes">{{ encodingType }}</option>
								</select>
							</vb-ui-select>
						</vb-ui-form-group>
					</div>
					<div class="fixed padding-left-0">
						<vb-ui-form-group>
							<label #uiLabel>&nbsp;</label><br/>
							<button type="submit" uiControl [disabled]="!form.valid" [hidden]="status.processing" class="btn-block btn-icon-left" vbUiBtnPrimary>
								<span class="glyphicons plus"></span>
								{{ 'Add' | translate }}
							</button>
							<button [disabled] uiControl [attr.aria-label]="'Processing' | translate " [hidden]="!(status.processing)" class="btn-block" vbUiBtnPrimary>
								<vb-loading-spinner></vb-loading-spinner>
							</button>
						</vb-ui-form-group>
					</div>
				</div>
			</div>
		</form>

		<!-- TODO: when scrolling lists throughout app, underlying page should NOT scroll -->
		<div class="scrollable-area-wrapper" [hidden]="!(videoLinks.length)">
			<ol class="list-unstyled scrollable-area">
				<li *ngFor="let video of videoLinks" class="upload-file-wrapper" [ngClass]="{ 'upload-complete': video.status.complete,
				                'upload-error': video.status.error}">
					<div class="upload-file">
						<h4>
							<a uiSref="portal.video-settings" [uiParams]="{videoId: video.id}" [hidden]="video.status.error" class="upload-name btn-icon-left">
								{{ video.title }}
							</a>
							<span [hidden]="!(video.status.error)" class="upload-name btn-icon-left">
								<span class="glyphicons circle_exclamation_mark margin-right-3"></span>
								{{ video.title }}
							</span>
						</h4>

						<p class="upload-date" [hidden]="!(video.status.complete && !video.status.error)">
							{{ video.uploadDate | vbDateTimeMedium }}
							<span class="glyphicons circle_ok"></span>
						</p>

						<p class="error-msg" role="alert" [hidden]="!(video.status.error)">{{ 'UploadError' | translate }}</p>
					</div>
				</li>
			</ol>
		</div>
		<div class="menu-footer group">
			<a [hidden]="!(videoLinks.length)" (click)="dismissAllVideoLinks()" class="pull-left text-color-inherit">{{ 'ClearCompleted' | translate }}</a>
			<a uiSref="portal.media.uploads" *vbAuthorizationKey="'media.add'" class="pull-right text-color-inherit">{{ 'Uploads_SeeAllUploads' | translate }}</a>
			<div [hidden]="!(viewingHoursUnavailable)" class="util-white-space-normal util-clear-both alert-danger upload-viewing-hours-warning">{{ 'Uploads_ViewingHoursNotAvailable' | translate }}</div>
		</div>
	</tab>
	<tab heading="{{ 'Media_Recording_Tab_Header' | translate }}" tabOrder="3" *ngIf="isRecordingAllowed">
		<recording-menu [teamId]="teamId" [webexTeamsEnabled]="features.enableSpark" [vciEnabled]="features.vciSettings.isEnabled" [vciMsTeamsEnabled]="features.vciSettings.isMSTeamsEnabled" [zoomEnabled]="features.zoomSettings.isEnabled" [zoomSipAddressSuffix]="features.zoomSettings.sipAddressSuffix">
		</recording-menu>
	</tab>
	<tab heading="{{ 'Uploads_Tools' | translate }}" tabOrder="4">
		<button type="button" class="btn-transparent upload-import-button" (click)="importWebEx()" [hidden]="!(isWebexManualImportEnabled)">
			<span class="upload-import-button-logo webex-logo"></span>
			{{ 'Uploads_Import_WebEx' | translate }}
		</button>

		<button *ngIf="!teamId" type="button" class="btn-transparent upload-import-button" (click)="importRevEdit()">
			<span class="upload-import-button-logo upload-import-button-rev-create"></span>
			<span>{{ 'Uploads_Import_RevCreate' | translate }}</span>
		</button>

		<!-- TODO: when scrolling lists throughout app, underlying page should NOT scroll -->
		<div class="scrollable-area-wrapper" [hidden]="!(videoImports.length)">
			<ol class="list-unstyled scrollable-area">
				<li *ngFor="let video of videoImports" class="upload-file-wrapper" [ngClass]="{ 'upload-complete': video.status.complete,
				                'upload-error': video.status.error,
						'upload-loading': !video.status.complete && !video.status.error}">
					<div class="upload-file">
						<h4>
							<a [hidden]="!(!video.status.error && video.id)" uiSref="portal.video-settings" [uiParams]="{videoId: video.id}" class="upload-name btn-icon-left">
								{{ video.title }}
							</a>
							<span [hidden]="!(video.status.error)" class="upload-name btn-icon-left">
								<span class="glyphicons circle_exclamation_mark margin-right-3"></span>
								{{ video.title }}
							</span>
							<span [hidden]="!(!video.id && !video.status.error)">
								{{ video.title }} <vb-loading-spinner></vb-loading-spinner>
							</span>
						</h4>

						<p class="upload-date" [hidden]="!(video.status.complete && !video.status.error)">
							{{ video.uploadDate | vbDateTimeMedium }}
							<span class="glyphicons circle_ok"></span>
						</p>

						<p role="alert" class="error-msg" [hidden]="!(video.status.error)">{{ 'UploadError' | translate }}</p>
					</div>
				</li>
			</ol>
		</div>
		<div class="menu-footer group">
			<a [hidden]="!(videoImports.length)" (click)="dismissAllImports()" class="pull-left text-color-inherit">{{ 'ClearCompleted' | translate }}</a>
			<a uiSref="portal.media.uploads" *vbAuthorizationKey="'media.add'" class="pull-right text-color-inherit">{{ 'Uploads_SeeAllUploads' | translate }}</a>
			<div [hidden]="!(viewingHoursUnavailable)" class="util-white-space-normal util-clear-both alert-danger upload-viewing-hours-warning">{{ 'Uploads_ViewingHoursNotAvailable' | translate }}</div>
		</div>
	</tab>
</tabset>
