import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';

import { SparkService } from './Spark.Service';
import { VbSubscribeSparkCategoryButtonComponent } from './VbSubscribeSparkCategoryButton.Component';

const components = [
	VbSubscribeSparkCategoryButtonComponent
];

@NgModule({
	imports: [
		CommonModule,
		DialogAngularModule,
		SecurityAngularModule,
		TranslateModule
	],
	declarations: components,
	exports: components,
	providers: [
		SparkService
	]
})
export class SparkAngularModule {
}
