import { ILogService } from 'angular';

import {
	Category,
	Rejection,
	StateService,
	TransitionService,
	Trace
} from '@uirouter/angularjs';

export function configureStateTransitionLogging($log: ILogService, $state: StateService, $trace: Trace, $transitions: TransitionService){
	//enable uirouter transition logging
	$trace.enable(Category.TRANSITION);

	$transitions.onError({}, transition => $log.log('Unable to transition to state: ', transition.to().name, transition.error()));

	$state.onInvalid(toState => $log.log('Invalid state transition: ', toState));

	// custom error handler based on the default in uirouter
	$state.defaultErrorHandler($error$ => {
		if ($error$ instanceof Error && $error$.stack) {
			$log.error($error$);
			$log.error($error$.stack);
		} else if ($error$ instanceof Rejection) {
			// rejections are expected, so use log() rather than error() to prevent confusion
			$log.log($error$.toString());

			if ($error$.detail && $error$.detail.stack) {
				$log.log($error$.detail.stack);
			}
		} else {
			$log.error($error$);
		}
	});
}
