<div [ngClass]="[
		styles.loadingContainer,
		block ? 'loading-container ' + size : 'box-inline'
	]">

	<ng-container *ngIf="!msgDisplayBelowSpinner && !msg && block">
		<ng-content class="status-msg vb-loading-spinner-status-msg" [ngClass]="styles.msg"></ng-content>
	</ng-container>

	<ng-container *ngIf="!msgDisplayBelowSpinner && msg && block">
		<div class="status-msg vb-loading-spinner-status-msg" [ngClass]="styles.msg">{{ msg }}</div>
	</ng-container>

	<span class="theme-accent-icon" flex-align="center" flex-justify="center" [ngClass]="iconNgClass">
		<span class="loader" [ngClass]="loaderNgClass" [ngStyle]="{
				'border-color': state === SpinnerState.COMPLETE ? accentColor : undefined,
				'border-left-color': accentColor,
				'visibility': state === SpinnerState.INACTIVE ? 'hidden' : undefined
			}">
		</span>
	</span>

	<ng-container *ngIf="msgDisplayBelowSpinner && !msg && block">
		<ng-content class="status-msg vb-loading-spinner-status-msg" [ngClass]="styles.msg"></ng-content>
	</ng-container>

	<ng-container *ngIf="msgDisplayBelowSpinner && msg && block">
		<div class="status-msg vb-loading-spinner-status-msg" [ngClass]="styles.msg">
			{{ msg }}
		</div>
	</ng-container>
</div>
