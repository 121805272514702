import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PushBus } from 'rev-shared/push/PushBus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { BrandingService } from './Branding.Service';
import { BrandingSettings } from './BrandingSettings.Contract';
import { getColorRgb } from './ColorUtility';

@Injectable({
	providedIn: 'root'
})
export class ThemeService {

	private settings: BrandingSettings;
	private customThemeSettings: BrandingSettings;

	public isPreviewActive: boolean;
	public isCustomThemeActive: boolean;
	public initialized: boolean;

	public get brandingSettings(): BrandingSettings {
		return (this.isCustomThemeActive || this.isPreviewActive) ?
			this.customThemeSettings :
			this.settings;
	}

	public brandingSettingsSubject$ = new BehaviorSubject<BrandingSettings>(undefined);
	public brandingSettings$: Observable<BrandingSettings> = this.brandingSettingsSubject$.asObservable();

	public get accentFontColor(): string {
		return this.brandingSettings.themeSettings.accentFontColor;
	}

	public get accentColor(): string {
		return this.brandingSettings.themeSettings.accentColor;
	}

	public get headerFontColor(): string {
		return this.brandingSettings.headerSettings.fontColor;
	}

	public get headerBackgroundColor(): string {
		return this.brandingSettings.headerSettings.backgroundColor;
	}

	public get primaryColor(): string {
		return this.brandingSettings.themeSettings.primaryColor;
	}

	public get primaryFontColor(): string {
		return this.brandingSettings.themeSettings.primaryFontColor;
	}

	public get primaryFontFade30(): string {
		return getColorRgb(this.primaryFontColor, '.3');
	}

	constructor(
		private BrandingService: BrandingService,
		private UserContext: UserContextService,
		private PushBus: PushBus
	){
		this.settings = new BrandingSettings();

		UserContext.userIdChanged$.pipe(
			filter(() => !UserContext.isUserLoggedIn())
		)
			.subscribe(() => this.endCustomThemeMode());
	}

	public initialize(): Promise<void> {
		if(this.initialized) {
			return Promise.resolve();
		}

		this.initialized = true;
		this.loadSettings()
			.then(() => this.subscribePush());
	}

	public loadSettings(): Promise<void> {
		return this.BrandingService.getBranding(this.UserContext.getAccount().id, false)
			.then(brandingSettings => {
				this.settings = brandingSettings;
				this.brandingSettingsSubject$.next(this.brandingSettings);
			});
	}

	public setPreviewMode(brandingSettings: BrandingSettings): void {
		this.setCustomThemeMode(brandingSettings);
	}

	public endPreviewMode(): BrandingSettings {
		return this.endCustomThemeMode();
	}

	public setCustomThemeMode(brandingSettings: BrandingSettings, partialThemeChange?: boolean): void {
		let settings;
		if (partialThemeChange) {
			this.isCustomThemeActive = true;
			settings = { ...this.brandingSettingsSubject$.value };
			settings.themeSettings = { ...settings.themeSettings, ...brandingSettings.themeSettings };
			settings.headerSettings = { ...settings.headerSettings, ...brandingSettings.headerSettings };
		} else {
			this.isPreviewActive = true;
			settings = brandingSettings;
		}
		this.customThemeSettings = settings;
		this.brandingSettingsSubject$.next(this.brandingSettings);
	}

	public endCustomThemeMode(): BrandingSettings {
		const result = this.customThemeSettings;
		this.isCustomThemeActive = false;
		this.isPreviewActive = false;
		this.customThemeSettings = null;
		this.brandingSettingsSubject$.next(this.brandingSettings);
		return result;
	}

	private subscribePush(): void {
		this.PushBus.subscribe(this.UserContext.getAccount().id, {
			BrandingSettingsSaved: e => {
				if (e.isTeamVideosCarouselAvailable) {
					this.loadSettings();
				}
				else {
					this.settings = this.BrandingService.readBrandingSettings(e.settings);
					this.brandingSettingsSubject$.next(this.brandingSettings);
				}
			}
		});
	}
}
