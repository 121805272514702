import { NgZone } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators';

import { VideogularComponent } from 'vbrick-player-src/videogular/Videogular.Component';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { DeviceType } from 'rev-portal/admin/devices/deviceManagement/DeviceContract';
import { ILiveSubtitles } from 'rev-shared/ui/liveSubtitles/ILiveSubtitles';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { MinuteMs } from 'rev-shared/date/Time.Constant';
import { PollsModelService } from 'rev-portal/scheduledEvents/polls/PollsModel.Service';
import { PresentationFileStatus } from 'rev-portal/scheduledEvents/presentations/PresentationFileStatus';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { Question } from 'rev-portal/scheduledEvents/questions/Question';
import { RecordingPolicy } from 'rev-shared/media/RecordingPolicy';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { isPastEndDate, isTimeToStart, isTimeToStartPreProduction } from 'rev-shared/webcast/WebcastTimeCalculation';
import { noop } from 'rev-shared/util';

import { BaseWebcast } from '../BaseWebcast';
import { BroadcastStatus } from './BroadcastStatus';
import { IPreProduction } from '../Webcast.Contract';
import { IRtmpSettings } from '../../editWebcast/IRtmpSettings';
import { Polls } from '../../polls/Polls';
import { PresentationModel } from '../../presentations/PresentationModel';
import { RecordingStatus } from './RecordingStatus';
import { RunItem, WebcastRunType, WebcastRunStatus } from './RunItem';
import { VcStatus } from './VcStatus';
import { WebcastLayout } from './WebcastLayout';
import { WebcastService } from '../Webcast.Service';
import { WebcastStatus } from './WebcastStatus';
import { WebcastUser } from './WebcastUser';
import { WebcastVideoSource } from '../WebcastStatus';
import { WebcastPresentationService } from '../../presentations/WebcastPresentation.Service';
import { IWebcastBrandingSettings } from 'rev-portal/scheduledEvents/webcast/WebcastBranding';

export interface IWebcastModelRequiredServices {
	DateParsers: DateParsersService;
	PollsModelService: PollsModelService;
	PushBus: PushBus;
	UserContext: UserContextService;
	WebcastPresentationService: WebcastPresentationService;
	WebcastService: WebcastService;
	zone: NgZone;
}

export class WebcastModel extends BaseWebcast {
	private readonly DateParsers: DateParsersService;
	private readonly PollsModelService: PollsModelService;
	private readonly PushBus: PushBus;
	private readonly UserContext: UserContextService;
	private readonly WebcastPresentationService: WebcastPresentationService;
	private readonly WebcastService: WebcastService;
	private readonly zone: NgZone;

	private readonly webcastSubject$ = new BehaviorSubject<WebcastModel>(this);

	private features: IMediaFeatures;

	public readonly webcast$ = this.webcastSubject$.asObservable()
		.pipe(
			tap(() => this.zone.run(noop)),
			tag('webcast$')
		);

	public readonly currentUser: WebcastUser;
	public readonly presentation: PresentationModel;
	public readonly recording: RecordingStatus;
	public readonly broadcast: BroadcastStatus;
	public readonly vcStatus: VcStatus;
	public readonly webcastStatus: WebcastStatus;
	public readonly layout: WebcastLayout;
	public readonly video: { vgAPI: VideogularComponent } = { vgAPI: null };

	public readonly environmentId: string;
	public readonly mediaCachingEnabled: boolean;
	public videoHeartbeatInterval: number; //s
	public recordingDeviceId: string; //consider move to recordingStatus
	public readonly recordingVideoStatus: VideoStatus;
	public isRecording: boolean;
	public disableAutoRecording: boolean;
	public startingVideoSource: boolean;
	public waitingForVideoSource: boolean;
	public playbacks: any[];
	public isUpdatingPlaybacks: boolean;
	public backgroundImages: any[];
	public recordingIsReconnecting: boolean;

	public myQuestions: Question[];
	public publicQuestions: Question[];
	//deprecated.. will go in final clean up.
	public unsubscribeAttendeeQuestions: IUnsubscribe;

	public attendeeQuestionsSubscription: Subscription;

	public runItems: RunItem[];
	public preProductionRun: RunItem;
	public productionRun: RunItem;
	public currentRun: RunItem;
	public preProduction: IPreProduction;
	public polls: Polls;
	public linkedVideoId: string;
	public linkedVideo?: { id: string; title: string; isActive: boolean };

	public liveSubtitles: ILiveSubtitles;
	public isPresentationProfileDeviceInactive: boolean;
	public isTrustedPublicWebcast: boolean;
	public eventHostName: { firstName?: string, lastName: string };
	public eventHostFullName: string;
	public hideShareUrl: boolean;

	public rtmpSettings: IRtmpSettings;

	public enableCustomBranding: boolean = false;
	public webcastBrandingSettings: IWebcastBrandingSettings = {};

	constructor(
		webcastData: any,
		webcastUserData: any,
		requiredServices: IWebcastModelRequiredServices
	) {
		super();
		Object.assign(this, webcastData, requiredServices);

		this.polls = this.PollsModelService.initPolls(this);
		this.presentation = new PresentationModel(this, webcastData.presentationFile, {
			DateParsers: this.DateParsers,
			PushBus: this.PushBus,
			WebcastPresentationService: this.WebcastPresentationService
		},
		this.zone);
		this.currentUser = new WebcastUser(this, {
			id: this.UserContext.getUser().id,
			...webcastUserData
		});
		this.recording = new RecordingStatus(this, this.PushBus, this.WebcastService);
		this.broadcast = new BroadcastStatus(this, this.PushBus, this.WebcastService);
		this.vcStatus = new VcStatus(this);
		this.webcastStatus = new WebcastStatus(this);
		this.layout = new WebcastLayout(this);

		if(this.runItems) {
			this.runItems = this.runItems.map(i => new RunItem(i));
			this.initRunItems();
		}
		this.eventHostFullName = `${this.eventHostName?.firstName ?? ''} ${this.eventHostName?.lastName ?? ''}`;
	}

	public update(data: Partial<WebcastModel>) {
		Object.assign(this, data);
		this.webcastSubject$.next(this);
	}

	public get canDownloadPresentationFile(): boolean {
		//TODO: Move to presentation model object
		return this.presentationFileDownloadAllowed &&
			this.presentation.status === PresentationFileStatus.Complete &&
			!!this.presentation.downloadUrl;
	}

	public get isAutomated(): boolean {
		if(this.currentRun && this.currentRun.isPreProduction) {
			return false;
		}
		return this.automatedWebcast;
	}

	public get isDeviceEncoder(): boolean {
		return this.deviceType === DeviceType.Encoder;
	}

	public isTimeToStart(): boolean {
		return isTimeToStart(this.startDate, this.endDate, this.lobbyTimeMinutes);
	}

	public isPastEndDate(): boolean {
		return isPastEndDate(this.endDate);
	}

	public isTimeToStartPreProduction(): boolean {
		if(this.preProduction) {
			return isTimeToStartPreProduction(this.preProduction.durationMs, this.startDate);
		}
	}

	public get isReadyToStart(): boolean {
		const preProdRun = this.preProductionRun;
		if(this.currentUser.isEventAdmin &&
			preProdRun && preProdRun.isStarted) {
			return false;
		}

		const run = this.productionRun;
		return run && (
			run.isStarted ||
			this.isTimeToStart()
		) &&
			!this.webcastStatus.isCancelled &&
			!this.webcastStatus.isFailed;
	}

	public get isReadyToStartPreProduction(): boolean {
		const run = this.preProductionRun;
		return run && (
			run.isStarted ||
			this.isTimeToStartPreProduction()
		) &&
			this.productionRun.isScheduled &&
			!this.webcastStatus.isCancelled &&
			!this.webcastStatus.isFailed;
	}

	public get preProdStartDate(): Date {
		return this.preProduction && new Date(this.startDate.getTime() - this.preProduction.durationMs);
	}

	public get lobbyStartDate(): Date {
		return new Date(this.startDate.getTime() - this.lobbyTimeMinutes * MinuteMs);
	}

	public get isVideoConferencingType(): boolean {
		return !!this.vcSipAddress || this.isWebexLiveStreamType || this.isRtmpStreamType || this.videoSource === WebcastVideoSource.MSTEAMS;
	}

	public get enableLiveStreamStatusDisplay(): boolean {
		return this.isVideoConferencingType || this.isEnrichedPresentationProfile;
	}

	public get isWebexLiveStreamType(): boolean {
		return this.videoSource === WebcastVideoSource.WEBEX_LIVE_STREAM;
	}

	public get isRtmpStreamType(): boolean {
		return this.videoSource === WebcastVideoSource.RTMP;
	}

	public get adminEnrichedPresentationProfile(): boolean {
		return this.currentUser.isEventAdmin
			&& this.isEnrichedPresentationProfile;
	}

	public get isEnrichedPresentationProfile(): boolean {
		return this.videoSource === WebcastVideoSource.PRESENTATION
			&& this.liveSubtitles?.isLiveSubtiltesEnabled;
	}

	public get isVCI(): boolean {
		return this.vcSipAddress ||
			this.vcMicrosoftTeamsMeetingUrl ||
			this.isEnrichedPresentationProfile ||
			this.isRtmpStreamType as any;
	}

	public assignCurrentRun(isPreProd: boolean): void {
		this.currentRun = isPreProd ? this.preProductionRun :
			this.productionRun;
	}

	public getRunItem(runNumber: number): RunItem {
		return this.runItems.find(r => r.runNumber === runNumber);
	}

	public addRunItem(run: any): void {
		this.runItems.push(new RunItem(run));
		this.initRunItems();
	}

	public removePreProdRuns(): void {
		this.runItems = this.runItems.filter(({ runType }) => runType !== WebcastRunType.PreProduction);
		this.initRunItems();
	}

	public initFeatures(features: IMediaFeatures): void {
		this.features = features;
	}

	public isPollsEnabled(): boolean {
		return this.features &&
			this.features.webcastEngagementSettings.allowPolls && this.pollsEnabled;
	}

	public isChatEnabled(): boolean {
		return this.features &&
			this.features.webcastEngagementSettings.allowChat && this.chatEnabled;
	}

	public isQuestionAndAnswerEnabled(): boolean {
		return this.features &&
			this.features.webcastEngagementSettings.allowQuestionAndAnswer && this.questionAndAnswerEnabled;
	}

	public isRecordingAllowed(): boolean {
		return this.features &&
			this.features.recordingPolicy !== RecordingPolicy.DISABLE;
	}

	public isStartOrStopRecordingAllowed(): boolean {
		return this.features?.recordingPolicy === RecordingPolicy.ALLOW;
	}

	public isRecordingMandatory(): boolean {
		return this.features.recordingPolicy === RecordingPolicy.REQUIRE;
	}

	private initRunItems(): void {
		this.preProductionRun = this.runItems.find(i =>
			i.isPreProduction &&
			i.status !== WebcastRunStatus.Ended);

		this.productionRun = this.runItems.find(i => i.isMainEvent);
	}
}
