import { StateDeclaration, StateService } from '@uirouter/angularjs';
import { parse, tail } from '@uirouter/core';
import $ from 'jquery';
import { element, IAugmentedJQuery } from 'angular';

//copied from ui-router. Looks up state the element belongs to
export function StateContext(el: IAugmentedJQuery | HTMLElement): StateDeclaration {
	const $el = element(el);
	const $uiView = $el.inheritedData('$uiView');
	const path = parse('$cfg.path')($uiView);
	return path ? tail<any>(path).state : undefined;
}

export function getNestedSrefs(tElement: IAugmentedJQuery): string[] {
	return $('[ui-sref]:not([vb-sref-any-exclude])', tElement).toArray().map((el: HTMLElement) => {
		const stateRef: string = el.getAttribute('ui-sref');

		return (/^([^(]+)/).exec(stateRef)[1];
	});
}

export function isNestedSrefActive(srefs: string[], $state: StateService, relativeTo: string): boolean {
	const opts = relativeTo ? { relative: relativeTo } : null;

	return srefs.some(sref => $state.includes(sref, null, opts));
}
