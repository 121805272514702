import { Component, Input } from '@angular/core';

const REASON_TRANSLATIONS: { [reason: string]: string } = {
	'EmbedNotAuthorized': 'EmbedNotAuthorized',
	'event': 'Event_ErrorNotAuthorized',
	'AttendeeRemoved': 'Event_AttendeeRemovedFromWebcast'
};

@Component({
	selector: 'unauthorized401',
	templateUrl: './Unauthorized401.Component.html'
})
export class Unauthorized401Component {
	@Input() public reason: string;

	public get reasonTranslationKey(): string {
		return this.reason ?
			REASON_TRANSLATIONS[this.reason] :
			null;
	}
}
