<vb-title [title]="'Title_ResetPassword' | translate"></vb-title>

<div class="system-login-shell">
	<div class="table-display">
		<div class="table-row">
			<div class="table-cell system-login-nav">
				<div class="main-nav">
					<div class="navbar">
						<div class="table-cell brand-cell">
							<branding-logo></branding-logo>
						</div>

						<div class="table-cell"></div>
					</div>
				</div>
			</div>
		</div>

		<div class="table-row">
			<div class="table-cell table-cell-centered-form">
				<div class="system-login col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-xl-4 col-xl-offset-4">

					<div [hidden]="!(status.processing || status.loading)">
						<vb-loading-spinner [msg]="'Loading' | translate" [size]="'large'">
						</vb-loading-spinner>
					</div>

					<div [hidden]="!status.invalidToken">
						<div class="status-msg">
							<h3>{{ 'PasswordReset_InvalidLink' | translate }}</h3>
							<button class="btn btn-primary btn-icon-right" uiSref="login">{{ 'ReturnToLogin' | translate }}</button>
						</div>
					</div>

					<div [hidden]="!status.error">
						<div class="status-msg">
							<h3>
								<span class="glyphicons circle_exclamation_mark"></span>&nbsp;{{ 'PasswordReset_Error' | translate }}
							</h3>
							<button class="btn btn-primary btn-icon-right" uiSref="login">{{ 'ReturnToLogin' | translate }}</button>
						</div>
					</div>

					<div [hidden]="!status.awaitingSecurityQuestionReset">
						<div class="status-msg">
							<h3>
								<span class="glyphicons remove_2"></span>{{ 'PasswordReset_LimitReached' | translate }}
							</h3>
							<button class="btn btn-primary btn-icon-right" uiSref="login">{{ 'ReturnToLogin' | translate }}</button>
						</div>
					</div>

					<div [hidden]="!status.active">
						<form #passwordResetForm="ngForm" class="system-form reset-password-form" name="passwordResetForm" (submit)="submit()">
							<h1>{{ 'ForgotPasswordNoQuestion' | translate }}</h1>

							<div class="system-msg error-msg" [hidden]="!status.passwordResetFailed">
								<div class="">
									<span class="glyphicons remove_2"></span>{{ 'PasswordReset_Failed' | translate }}
								</div>
							</div>

							<h4>{{ 'SecurityQuestion' | translate }}</h4>
							<div class="form-group" [ngClass]="{
									'required': (securityAnswerControl.pristine),
									'has-error': !(securityAnswerControl.pristine) && securityAnswerControl.invalid,
									'has-success': !(securityAnswerControl.pristine) && securityAnswerControl.valid
								}">

								<label for="rpSecurityAnswer">{{ passwordReset.securityQuestion }}</label>
								<input #securityAnswerControl="ngModel" class="form-control" id="rpSecurityAnswer" name="securityAnswer" required type="text" [(ngModel)]="passwordReset.securityAnswer">
							</div>

							<h4>{{ 'UserEdit_ResetPasswordButton' | translate }}</h4>
							<div class="form-group has-feedback" [ngClass]="{
									'required': (passwordControl.pristine),
									'has-error': !(passwordControl.pristine) && passwordControl.invalid,
									'has-success': !(passwordControl.pristine) && passwordControl.valid
								}">
								<label for="rpNewPassword">{{ 'New_Password' | translate }}</label>
								<input #passwordControl="ngModel" class="form-control" id="rpNewPassword" name="password" required type="password" [(ngModel)]="passwordReset.password">

								<span class="form-control-feedback required-symbol">*</span>
								<span class="glyphicons ok_2 form-control-feedback"></span>
								<span class="glyphicons remove_2 form-control-feedback"></span>


								<label class="error-msg" [hidden]="!ctrlPasswordResult?.errorMinimumLength">
									{{ 'UserConfirm_MinimumLengthIs' | translate }} {{ passwordRules.minimumLength }}.
								</label>
								<label class="error-msg" [hidden]="!ctrlPasswordResult?.errorMinimumUppercaseLetterCount">
									{{ 'UserConfirm_MustContainOneUppercaseLetter' | translate }}
								</label>
								<label class="error-msg" [hidden]="!ctrlPasswordResult?.errorMinimumLowercaseLetterCount">
									{{ 'UserConfirm_MustContainOneLowercaseLetter' | translate }}
								</label>
								<label class="error-msg" [hidden]="!ctrlPasswordResult?.errorMinimumNumberCount">
									{{ 'UserConfirm_MustContainOneDigit' | translate }}
								</label>
								<label class="error-msg" [hidden]="!ctrlPasswordResult?.errorMinimumSpecialCharacterCount">
									{{ 'UserConfirm_MustContainOneSpecialCharacter' | translate }}
								</label>

							</div>

							<div class="form-group has-feedback" [ngClass]="{
									'required': (confirmPasswordControl.pristine),
									'has-error': !(confirmPasswordControl.pristine) && confirmPasswordControl.invalid,
									'has-success': !(confirmPasswordControl.pristine) && confirmPasswordControl.valid
								}">
								<label for="rpConfirmPassword">{{ 'ConfirmPassword' | translate }}</label>
								<input #confirmPasswordControl="ngModel" class="form-control" id="rpConfirmPassword" name="confirmPassword" required type="password" [(ngModel)]="passwordReset.confirmPassword">

								<span class="form-control-feedback required-symbol">*</span>
								<span class="glyphicons ok_2 form-control-feedback"></span>
								<span class="glyphicons remove_2 form-control-feedback"></span>

								<label class="error-msg" [hidden]="confirmPasswordControl.pristine || confirmPasswordControl.valid">
									{{ 'PasswordNoMatch' | translate }}
								</label>
							</div>

							<div class="group system-form-buttons">
								<div class="pull-right">
									<button class="btn btn-primary btn-lg" [disabled]="passwordResetForm.invalid" type="submit">
										{{ 'PasswordReset_Submit' | translate }}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
