import { Injectable } from '@vbrick/angular-ts-decorators';
import { IPromise, resource, IQService } from 'angular';
import { isEqual } from 'underscore';

import { AccessEntityType } from 'rev-shared/security/AccessEntityType';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { PushService } from 'rev-shared/push/PushService';
import { RecordingPolicy } from 'rev-shared/media/RecordingPolicy';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { WebcastTemplatesService } from 'rev-shared/webcast/WebcastTemplates.Service';
import { formatTimespan } from 'rev-shared/date/DateFormatters';

import { ISearchResult } from 'rev-portal/search/InsightSearchHelper.Service';
import { SearchConstants } from 'rev-portal/search/SearchConstants';
import { SearchService, IAccessEntityIdentifier } from 'rev-portal/search/Search.Service';

import { EditWebcastModel } from './EditWebcastModel';
import { EventAccessControl } from '../EventAccessControl';
import { IVideoSource } from './EditWebcast.Contract';
import { Poll } from '../polls/Poll';
import { PollsService } from '../polls/Polls.Service';
import { WebcastPresentationService } from '../presentations/WebcastPresentation.Service';
import { WebcastService } from '../webcast/Webcast.Service';
import { WebcastVideoSource } from '../webcast/WebcastStatus';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { ImageService } from 'rev-shared/media/Image.Service';

export const AutomatedDatePastError = 'AutomatedWebcastDatePast';
export const InsufficientRevIQCredits = 'InsufficientRevIQCredits';
export const ShortcutNotAvailableError = 'WebcastShortcutNameAvailabilityNotValid';
export const TranscriptionIsNotEnabled = 'TranscriptionIsNotEnabled';
export const UnsupportedLiveSubtitlesSource = 'UnsupportedLiveSubtitlesSource';
export const UnsupportedLiveSubtitlesPresentationProfile = 'UnsupportedLiveSubtitlesPresentationProfile';
export const UploadFailedError = 'UploadFailed';
export const VideoSourceNotAvailableError = 'VideoSourceNotAvailable';
export const ViewingHoursNotAvailable = 'ViewingHoursNotAvailable';
export const RtmpPushUrlConflict = 'RtmpPushUrlConflict';

export const LIVE_SUBTITLES_ERRORS = [
	InsufficientRevIQCredits,
	TranscriptionIsNotEnabled,
	UnsupportedLiveSubtitlesSource,
	UnsupportedLiveSubtitlesPresentationProfile
];

const FORM_ERRORS: string[] = [
	AutomatedDatePastError,
	RtmpPushUrlConflict,
	ShortcutNotAvailableError,
	UploadFailedError,
	VideoSourceNotAvailableError,
	...LIVE_SUBTITLES_ERRORS
];

const UploadStatusRouteScope = 'UploadStatus';

@Injectable('EditWebcastService')
export class EditWebcastService {
	private readonly videoSourceScheduleResource: resource.IResourceClass<resource.IResource<{ videoSources: IVideoSource[] }>>;

	constructor(
		private $q: IQService,
		$resource: resource.IResourceService,
		private DateParsers: DateParsersService,
		private ImageService: ImageService,
		private MediaFeatures: MediaFeaturesService,
		private PollsService: PollsService,
		private PushBus: PushBus,
		private PushService: PushService,
		private SearchService: SearchService,
		private UserContext: UserContextService,
		private WebcastService: WebcastService,
		private WebcastPresentationService: WebcastPresentationService,
		private WebcastTemplatesService: WebcastTemplatesService
	) {
		'ngInject';
		this.videoSourceScheduleResource = $resource('/scheduled-events/accounts/video-sources/schedule');
	}

	public getVideoSourceSchedule(startDate: Date, endDate: Date): IPromise<IVideoSource[]> {
		return this.videoSourceScheduleResource.get({ startDate, endDate }).$promise
			.then(({ videoSources }) => {
				videoSources.forEach(source => {
					source.displayName = [source.deviceName, source.name].filter(Boolean).join(' - ');

					source.events.forEach(e => {
						Object.assign(e, {
							startDate: this.DateParsers.parseUTCDate(e.startDate),
							endDate: this.DateParsers.parseUTCDate(e.endDate),
							eventAdminFirstName: e.eventAdminFirstName || '',
							eventAdminLastName: e.eventAdminLastName || ''
						});
						const preProdDurationMs = this.DateParsers.parseTimespan(e.preProductionDuration);
						if(preProdDurationMs > 0) {
							e.startDate = new Date(e.startDate.getTime() - preProdDurationMs);
						}
					});

					source.presentationProfiles.forEach(profile => {
						profile.videoSource = source;
					});
				});

				return videoSources;
			});
	}

	public queryEventAdmins(query: string): Promise<ISearchResult> {
		const accountId = this.UserContext.getAccount().id;
		return this.SearchService
			.queryAccessEntities({
				accountId,
				query,
				type: [SearchConstants.accessEntityTypes.user],
				sortField: SearchConstants.nameSortField,
				sortAscending: true,
				count: SearchConstants.accessEntityPageSize,
				accountPermissions: [
					SearchConstants.rights.createEvents
				],
				noScroll: true
			})
			.then(result => ({
				items: result.accessEntities,
				count: result.totalHits
			}));
	}

	public queryMediaContributors(query: string): Promise<ISearchResult> {
		const accountId = this.UserContext.getAccount().id;
		return this.SearchService
			.queryAccessEntities({
				accountId,
				query,
				type: [SearchConstants.accessEntityTypes.user],
				sortField: SearchConstants.nameSortField,
				sortAscending: true,
				count: SearchConstants.accessEntityPageSize,
				accountPermissions: [
					SearchConstants.rights.createEvents,
					SearchConstants.rights.mediaContributor
				],
				noScroll: true
			})
			.then(result => ({
				items: result.accessEntities,
				count: result.totalHits
			}));
	}

	public deleteWebcast(webcastId: string): IPromise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:DeleteWebcast', { webcastId });
	}

	public getWebcast(webcastId: string): IPromise<EditWebcastModel> {
		return this.WebcastService.getWebcast(webcastId, true, true)
			.then(webcast => this.$q.all([
				webcast,
				webcast.pollsEnabled && this.PollsService.getPolls(webcastId)
			]))
			.then(([webcast, polls]) => new EditWebcastModel({
				...webcast,
				polls
			}));
	}

	public initNewWebcastModel(data: any): IPromise<EditWebcastModel> {
		return this.WebcastTemplatesService.getDefaultTemplate()
			.then(template => {
				const webcast = new EditWebcastModel({
					eventAdminIds: [this.UserContext.getUser().id],
					...data
				});

				if(template.metadata) {
					webcast.applyTemplate(template);
				}
				else {
					webcast.customFields = template.customFields;
				}

				return webcast;
			});
	}

	public saveWebcast(webcast: EditWebcastModel): IPromise<any> {
		const command = this.buildWebcastCommand(webcast);
		return (
			webcast.id ?
				this.PushService.dispatchCommand('scheduledEvents:SaveWebcastDetails', command, 'WebcastDetailsSaved') :
				this.PushService.dispatchCommand('scheduledEvents:ScheduleWebcast', command, 'WebcastScheduled')
		)
			.then(result => {
				const webcastId = result.message.webcastId;
				webcast.id = webcastId;

				return this.$q.all([
					this.uploadPresentation(webcast),
					this.savePolls(webcast)
				]);
			})
			.catch(err => {
				console.error(err);
				if(err === UploadFailedError) {
					return this.$q.reject(UploadFailedError);
				}

				if(!err.hasIssue){
					return this.$q.reject();
				}

				return this.$q.reject(
					FORM_ERRORS.find(error => err.hasIssue(error))
				);
			});
	}

	public saveTemplateFromWebcastId(templateName: string, webcastId: string): IPromise<void> {
		return this.getWebcast(webcastId)
			.then(webcast => this.saveWebcastTemplate(templateName, webcast));
	}

	public saveWebcastTemplate(templateName: string, webcast: EditWebcastModel): IPromise<any> {
		return this.PushService.dispatchCommand('scheduledEvents:AddWebcastTemplate', {
			name: templateName,
			polls: (webcast.polls || []).map(poll => this.PollsService.convertPollForCommand(poll)),
			...this.buildWebcastCommand(webcast, true)
		});
	}

	public isFormErrorCode(err: string): boolean {
		return FORM_ERRORS.includes(err);
	}

	private buildWebcastCommand(webcast: EditWebcastModel, isTemplateCommand?: boolean): any {
		const features = this.MediaFeatures.accountFeatures;

		return {
			webcastId: webcast.id,
			cloneWebcastId: webcast.cloneWebcastId,
			title: webcast.title,
			shortcutName: webcast.shortcutName,
			description: webcast.description,
			startDate: webcast.startDate,
			endDate: webcast.endDate,
			timezoneId: webcast.timezoneId,
			videoSource: webcast.videoSource,
			accessControl: webcast.accessControl,
			chatEnabled: webcast.chatEnabled,
			pollsEnabled: webcast.pollsEnabled,
			questionAndAnswerEnabled: webcast.questionAndAnswerEnabled,
			questionOption: webcast.questionOption,
			imageId: webcast.imageId,
			templateImageId: webcast.templateImageId,
			isBackgroundFill: webcast.isBackgroundFill,
			userIdSlideControl: webcast.moderators.find(({ id }) => webcast.userIdSlideControl === id) ? webcast.userIdSlideControl : null,
			tags: !isTemplateCommand || features.enableTags ? webcast.tags : [],
			categoryIds: !isTemplateCommand || features.enableCategories ? webcast.categories.map(({ categoryId }) => categoryId) : [],
			unlisted: webcast.unlisted,
			estimatedAttendees: webcast.estimatedAttendees || 0,
			lobbyTimeMinutes: webcast.lobbyTimeMinutes,

			eventAdminIds: webcast.eventAdmins.map(getId),
			moderatorIds: webcast.moderators.map(getId),
			userIds: webcast.accessControl === EventAccessControl.Private ? filterByType(AccessEntityType.User, webcast.accessEntities) : null,
			groupIds: webcast.accessControl === EventAccessControl.Private ? filterByType(AccessEntityType.Group, webcast.accessEntities) : null,
			password: webcast.accessControl === EventAccessControl.Public ? webcast.password : null,
			registrationFields: webcast.accessControl === EventAccessControl.Public
				? webcast.registrationFields.map(f => ({
					id: f.id,
					includedByHost: !!f.includedByHost
				}))
				: null,
			customFieldValues: (webcast.customFields || []).map(({ id, value, fieldType }) => ({ id, value, fieldType })),

			presentationProfileId: webcast.videoSource === WebcastVideoSource.PRESENTATION ? webcast.presentationProfileId : null,
			webexTeam: webcast.videoSource === WebcastVideoSource.WEBEX ? webcast.webexTeam : null,
			vcSipAddress: webcast.videoSource === WebcastVideoSource.SIP_ADDRESS ? webcast.vcSipAddress
				: webcast.videoSource === WebcastVideoSource.WEBEX ? webcast.webexTeam.sipAddress
					: webcast.videoSource === WebcastVideoSource.ZOOM
						? webcast.zoom.meetingPassword
							? `${webcast.zoom.meetingId}.${webcast.zoom.meetingPassword}@${this.MediaFeatures.accountFeatures.zoomSettings.sipAddressSuffix}`
							: `${webcast.zoom.meetingId}@${this.MediaFeatures.accountFeatures.zoomSettings.sipAddressSuffix}`
						: null,
			vcMicrosoftTeamsMeetingUrl: webcast.videoSource === WebcastVideoSource.MSTEAMS ? webcast.vcMicrosoftTeamsMeetingUrl : null,
			automatedWebcast: webcast.videoSource === WebcastVideoSource.PRESENTATION && webcast.automatedWebcast,
			autoplay: webcast.autoplay,
			closedCaptionsEnabled: webcast.videoSource === WebcastVideoSource.PRESENTATION && webcast.closedCaptionsEnabled,
			disableAutoRecording: webcast.disableAutoRecording,
			autoAssociateVod: webcast.autoAssociateVod,
			recordingUploaderUserId: this.MediaFeatures.accountFeatures.recordingPolicy === RecordingPolicy.DISABLE
				? null
				: (webcast.recordingUploaderUsers?.length > 0 ? webcast.recordingUploaderUsers[0].id : webcast.recordingUploaderUserId),
			redirectVod: webcast.redirectVod, presentationFileDownloadAllowed: !!(webcast.presentationFile &&
				webcast.presentationFile.name &&
				webcast.presentationFileDownloadAllowed),

			removePresentationFile: !webcast.presentationFile && !!webcast.originalPresentationFile,

			preProduction: webcast.preProduction && {
				duration: formatTimespan(webcast.preProduction.durationMs),
				groupIds: filterByType(AccessEntityType.Group, webcast.preProduction.attendees),
				userIds: filterByType(AccessEntityType.User, webcast.preProduction.attendees)
			},
			liveSubtitles: webcast.liveSubtitles?.isLiveSubtiltesEnabled ? webcast.liveSubtitles : undefined,
			hideShareUrl: webcast.hideShareUrl,
			enableCustomBranding: webcast.enableCustomBranding,
			webcastBrandingSettings: {
				headerColor: webcast.webcastBrandingSettings.headerColor,
				headerFontColor: webcast.webcastBrandingSettings.headerFontColor,
				primaryFontColor: webcast.webcastBrandingSettings.primaryFontColor,
				accentColor: webcast.webcastBrandingSettings.accentColor,
				accentFontColor: webcast.webcastBrandingSettings.accentFontColor,
				primaryColor: webcast.webcastBrandingSettings.primaryColor,
				logoImageId: webcast.webcastBrandingSettings.logoImageId
			},
			rtmpSettings: webcast.rtmpSettings,
			zoom: webcast.zoom
		};
	}

	private uploadPresentation(webcast: EditWebcastModel): IPromise<void> | undefined {
		const file = webcast.presentationFile as FileWrapper;
		if(file && file.submit) {
			return this.WebcastPresentationService.uploadPresentationFile(file, webcast.id)
				.then(() => {
					webcast.originalPresentationFile = webcast.presentationFile = { name: file.name };
				});
		}
	}

	public uploadBackgroundImage(webcast: EditWebcastModel, file: FileWrapper): IPromise<void> {

		return this.PushService.dispatchCommand(
			webcast.id ? 'scheduledEvents:AddImageToWebcast' : 'media:AddImageToAccount', {
				accountId: this.UserContext.getAccount().id,
				webcastId: webcast.id,
				context: 'Webcast'
			}, 'ImageCreated')
			.then(data => {
				const { imageId, uploadUri } = data.message;

				webcast.imageId = imageId;
				file.setOptions({ url: uploadUri });

				const result = this.PushBus.awaitMessage({
					route: imageId,
					events: 'ImageStoringFinished',
					rejectEvents: 'ImageStoringFailed'
				});

				return result.subscribed
					.then(() => file.submit())
					.then(() => result)
					.then(data => {
						const { thumbnailUri } = data.message;
						webcast.thumbnailUri = thumbnailUri;
					});
			});
	}

	public uploadLogoImage(webcast: EditWebcastModel): Promise<string> {
		const logoFile: FileWrapper = webcast.webcastBrandingSettings.logoFile;
		return logoFile
			? this.ImageService.uploadImage('Webcast', logoFile)
			: Promise.resolve(webcast.webcastBrandingSettings.logoImageId);
	}

	private savePolls(webcast: EditWebcastModel): IPromise<void> {
		const polls = webcast.polls || [];
		const pollIds = new Set(polls.map(getId).filter(Boolean));

		const newPolls = polls.filter(poll => webcast.pollsEnabled && !poll.id);

		const updatedPolls = polls.filter(p => {
			const oldPoll = webcast.originalPolls[p.id];

			return webcast.pollsEnabled &&
				p.id &&
				(oldPoll as Poll).totalResponses === 0 &&
				!arePollsEqual(oldPoll, p);
		});

		const deletedPollIds =
			Object.keys(webcast.originalPolls)
				.filter(id => !webcast.pollsEnabled || !pollIds.has(id));

		return this.$q.all([
			newPolls.length && this.PollsService.addWebcastPolls(webcast.id, newPolls),
			updatedPolls.length && this.PollsService.updateWebcastPolls(webcast.id, updatedPolls),
			deletedPollIds.length && this.PollsService.deleteWebcastPolls(webcast.id, deletedPollIds)
		])
			.then(([pollIds]) => {
				if(pollIds) {
					pollIds.forEach((id, index) => newPolls[index].id = id);
				}
				webcast.originalPolls = polls.reduce((pollsById, p) => ((pollsById[p.id] = copyPoll(p)), pollsById), {});
			});
	}

	public updateLinkedVideo(webcastId: string, videoId: string, redirectVod: boolean): IPromise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:UpdateLinkedVideo', { webcastId, videoId, redirectVod });
	}
}

function filterByType(type: AccessEntityType, accessEntities: IAccessEntityIdentifier[]): string[] {
	if(!accessEntities || accessEntities.length === 0) {
		return;
	}
	return accessEntities
		.filter(e => e.type === type)
		.map(getId);
}

function getId({ id }: any): string {
	return id;
}

function arePollsEqual(a, b): boolean {
	return isEqual(pollData(a), pollData(b));
}

function pollData(p: any): any {
	return [
		p.question,
		p.answers,
		p.multipleChoice
	];
}

function copyPoll({ question, answers, multipleChoice, totalResponses }: any): any {
	return {
		totalResponses,
		multipleChoice,
		question,
		answers: answers.map(({ text }) => ({ text }))
	};
}
