import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';

import { VbUiHtmlEditor } from './VbUiHtmlEditor.Component';

const components = [
	VbUiHtmlEditor
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		SpinnerAngularModule
	]
})
export class VbUiHtmlEditorModule {}
