export const MULTICAST_STREAM_NAME_SUFFIX = '-Multicast';
export const HLS_AKAMAI_STREAM_NAME_SUFFIX: string = '-HLSAkamai';
export const HLS_VBRICKCDN_STREAM_NAME_SUFFIX: string = `-HLS-CDN`;
export const HLS_STREAM_NAME_SUFFIX = '-HLS';
export const UNICAST_STREAM_NAME_SUFFIXES: string[] = [HLS_STREAM_NAME_SUFFIX, '-RTMP', '-RTP', '-RTSP'];
export const VBRICK_MULTICAST_STREAM_NAME_SUFFIX: string = '-VBM';
export const REFLECTION_STREAM_NAME_SUFFIX: string = '-Reflection';

export const STREAM_ICON_CLASSES = {
	'icon-could-stream': stream => isAkamaiStream(stream.name) || isVbrickCdnStream(stream.name),
	'icon-vbrick-multicast-stream': stream => isVbrickMulticastStream(stream.name),
	'icon-multicast-stream': stream => stream.name.endsWith(MULTICAST_STREAM_NAME_SUFFIX),
	'icon-unicast-stream': stream => endsWithSome(stream.name, UNICAST_STREAM_NAME_SUFFIXES),
	'icon-reflection-stream': stream => stream.name.endsWith(REFLECTION_STREAM_NAME_SUFFIX)
};

export function isVbrickMulticastStream(streamName: string) {
	return streamName.endsWith(VBRICK_MULTICAST_STREAM_NAME_SUFFIX);
}

export function isAkamaiStream(streamName: string) {
	return streamName.endsWith(HLS_AKAMAI_STREAM_NAME_SUFFIX);
}

export function isVbrickCdnStream(streamName: string) {
	return streamName.endsWith(HLS_VBRICKCDN_STREAM_NAME_SUFFIX);
}

function endsWithSome(streamName: string, suffixes: string[]): boolean {
	return suffixes.some(str => streamName.endsWith(str));
}
