import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';

@Directive({
	selector: '[vb-color-hex]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbColorHexDirective implements OnInit {
	private ngModel: INgModelController;

	private readonly colorRegExp = /^#([0-9a-f]{3}){1,2}$/i;

	public ngOnInit(): void {
		this.ngModel.$parsers.push(value => this.validate(value));
		this.ngModel.$formatters.push(value => this.validate(value));
	}

	private validate(value: string): string {
		this.ngModel.$setValidity('vbColor', !value || Boolean(value.match(this.colorRegExp)));

		return value;
	}
}
