import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleData, defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import moment from 'moment';

import { DateUtil } from 'rev-shared/date/DateUtil';
import { MomentLocaleLoader } from 'rev-shared/date/MomentLocaleLoader.Provider';
import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';

const localeId = BootstrapContext.momentLocale;

@Injectable({
	providedIn: 'root'
})
export class NgxLocaleLoader {
	private result: Promise<void>;

	constructor(
		private MomentLocaleLoader: MomentLocaleLoader,
		private BsLocaleService: BsLocaleService,
		private TranslateService: TranslateService,
	){}

	public load(): Promise<void> {
		if(this.result) {
			return this.result;
		}

		this.result = this.MomentLocaleLoader.load()
			.then(() => {
				defineLocale(localeId, this.getLocaleConfig());
				this.BsLocaleService.use(localeId);
			});

		return this.result;
	}

	private getLocaleConfig(): LocaleData {
		return {
			abbr: localeId,
			months: moment.months(),
			monthsShort: moment.monthsShort(),
			weekdays: moment.weekdays(),
			weekdaysShort: moment.weekdaysShort(),
			weekdaysMin: moment.weekdaysMin(),
			longDateFormat: DateUtil.constructMomentLongDateFormat(localeId),
			invalidDate: this.TranslateService.instant('InvalidDate')
		};
	}
}
