<div flex="fill" [attr.layout-lt-md]="isMobileLayoutDisabled ? null : 'column'" [class.open]="isOpen" [ngClass]="[
		styles.sidebarPanel,
		themed ? 'theme-primary theme-primary-txt theme-primary-font-border-fade-50 sidebarPanelWrapper' : styles.sidebarPanelFixedColor
	]" [style.width]="isOpen && openWidthPx ? openWidthPx + 'px' : ''">
	<div [attr.flex-lt-md]="isMobileLayoutDisabled ? null : 'fill'" layout="column" layout-wrap="false" [ngClass]="styles.sidebarPanelLayout" [style.width]="isOpen && openWidthPx ? openWidthPx + 'px' : ''">

		<header layout="row" layout-wrap="false" [ngClass]="styles.header">

			<div flex="fill">
				{{ buttonConfigLastClick?.label }}
			</div>

			<div>
				<ng-content select="[slot='headerControls']"></ng-content>
			</div>

			<button [hidden]="themed && !isMobileWindow" type="button" [attr.aria-label]="'Event_CloseSidebarPanel' | translate" vbUiBtnPrimary (click)="closeSidebar()">
				<span class="glyphicons remove_2"></span>
			</button>
		</header>

		<div flex="fill" flex-align="center" [ngClass]="styles.sidebarPanelBody" ui-view="sidebar">
			<ng-content select="[slot='panelBody']"></ng-content>
		</div>
	</div>
</div>

<div [ngClass]="[
		styles.sidebarButtonContainer,
		themed ? 'theme-primary theme-primary-txt theme-primary-font-border-fade-50 sidebarButtonsWrapper' : styles.sidebarButtonContainerFixedColor
	]" role="menu" [attr.aria-label]="'SideBarMenu' | translate">
	<button *ngFor="let button of sidebarConfig.buttons" [hidden]="button.visible === false" type="button" [class.active]="button.id === activeSidebarButtonId" [ngClass]="[
			styles.sidebarButton,
			themed ? 'theme-primary-txt theme-primary-font-border-fade-50' : styles.sidebarButtonFixedColor
		]" role="menuitem" [attr.aria-label]="button.label" (click)="sidebarButtonClick(button)" [tooltip]="button.label" container="body" [adaptivePosition]="false" [placement]="isMobileWindow ? 'top-left' : 'left'">
		<span class="glyphicons" [ngClass]="button.iconClass"></span>
		<span class="glyphicons bell" [ngClass]="styles.notificationIcon" [hidden]="!button.notification?.enabled">
		</span>
		<span [ngClass]="styles.notificationCount" *ngIf="button.notification?.count">
			{{ button.notification.count }}
		</span>
	</button>
</div>
