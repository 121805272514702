import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { UIRouterModule } from '@uirouter/angular';

import { TranslateModule } from '@ngx-translate/core';

import { BrandingPreviewBannerComponent } from 'rev-portal/branding/BrandingPreviewBanner.Component';
import { BrandingPreviewButtonComponent } from 'rev-portal/branding/BrandingPreviewButton.Component';

import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { PushModule } from 'rev-shared/push/Push.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';

import { BrandingLogoComponent } from './BrandingLogo.Component';
import { ThemeStylesComponent } from './ThemeStyles.Component';

const components = [
	BrandingLogoComponent,
	BrandingPreviewBannerComponent,
	BrandingPreviewButtonComponent,
	ThemeStylesComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		HttpClientModule,
		PushModule,
		SecurityAngularModule,
		TranslateModule,
		CssRulesAngularModule,
		CommonModule,
		UIRouterModule
	]
})
export class BrandingAngularModule {}
