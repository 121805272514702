import {
	Directive,
	Input,
	OnInit,
} from '@vbrick/angular-ts-decorators';
import { isUndefined } from 'rev-shared/util';

@Directive({
	selector: '[vb-sort-column]',
	bindToController: true
})
export class VbSortColumnDirective implements OnInit {
	@Input() private sortColumnName: string;
	@Input() private ascendingSorting: boolean;

	public get sortField(): string {
		return this.sortColumnName;
	}

	public get sortAscending(): boolean {
		return this.ascendingSorting;
	}

	public ngOnInit(): void {
		this.ascendingSorting = isUndefined(this.ascendingSorting) || this.ascendingSorting;
	}

	public onSortField({ fieldName }: {fieldName: string}): void {
		this.ascendingSorting = this.sortColumnName !== fieldName || !this.ascendingSorting;
		this.sortColumnName = fieldName;
	}
}
