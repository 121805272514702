import { IDirective, IScope, IAugmentedJQuery, IAttributes, ITranscludeFunction } from 'angular';
import { SecurityContextService } from './SecurityContext.Service';
import { Directive, AfterViewInit } from '@vbrick/angular-ts-decorators';
import type { DirectiveOptionsDecorated } from '@vbrick/angular-ts-decorators/types/directive';

import { isDefined } from 'rev-shared/util';

// Directive configuration base modeled after ngIf
export const vbAuthorizationBaseDirectiveConfig: Partial<DirectiveOptionsDecorated> = {
	multiElement: true,
	priority: 599, // offset from ngIf
	transclude: 'element'
};

/**
 * A base for authorization directives meant to function in the same manner as ngIf.
 * Provide your own authorization check.
 *
 * To provide fallback content to users who do not have access to the state, add the `vb-unauthorized-fallback` attribute.
 */
@Directive({ selector: '' })
export class VbAuthorizationBaseDirective implements AfterViewInit {
	private ngIfDirective: IDirective;

	constructor(
		ngIfDirective: IDirective[],
		protected $attrs: IAttributes,
		protected $element: IAugmentedJQuery,
		protected $scope: IScope,
		protected $transclude: ITranscludeFunction,
		protected SecurityContext: SecurityContextService
	) {
		'ngInject';

		this.ngIfDirective = ngIfDirective[0];
	}

	public ngAfterViewInit(): void {
		// Whether or not to behave as fallback content, shown according to the negated result
		const isUnauthorizedFallback: boolean = isDefined(this.$attrs.vbUnauthorizedFallback);

		// Define our ngIf condition
		this.$attrs.ngIf = () => {
			const isAuthorizedResult: boolean = this.isAuthorized();

			return isUnauthorizedFallback ? !isAuthorizedResult : isAuthorizedResult;
		};

		// Apply the ngIf behavior
		(this.ngIfDirective.link as any).apply(this.ngIfDirective, [this.$scope, this.$element, this.$attrs, null, this.$transclude]);
	}

	protected isAuthorized(): boolean {
		//revisit here later.
		return false;
	}
}
