import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';

import type { XYChart } from '@amcharts/amcharts4/.internal/charts/types/XYChart';

import { IOnChartReadyData } from '../amChartsCore/VbUiAmChartsCore.Component';

import styles from './VbUiLineChart.Component.module.less';

export const VB_UI_LINE_CHART_HOST_CLASS: string = 'styles.lineChart';
export const VB_UI_LINE_CHART_TEMPLATE: string = `
	<vb-ui-am-charts-core
		[ngClass]="styles.core"
		[chartConfig]="chartConfig"
		[chartType]="chartType"
		[data]="data"
		(onChartReady)="onChartReadyInternal($event)">
	</vb-ui-am-charts-core>
`;

@Component({
	selector: 'vb-ui-line-chart',
	host: {
		'[class]': VB_UI_LINE_CHART_HOST_CLASS
	},
	template: VB_UI_LINE_CHART_TEMPLATE
})
export class VbUiLineChartComponent implements OnInit {
	@Input() public data: any;
	@Input() public dateField: string;
	@Input() public fillEnabled: boolean;
	@Input() public incrementalData: any;
	@Input() public inputDateFormat: string;
	@Input() public integerValueAxis: boolean;
	@Input() public tooltipText: string;
	@Input() public valueField: string;
	@Input() public xAxisLabel: string;
	@Input() public yAxisLabel: string;

	@Output() public onChartReady = new EventEmitter<IOnChartReadyData>();

	protected chart: XYChart;
	public chartConfig: any;
	public chartType: typeof XYChart;
	public readonly styles = styles;

	public ngOnInit(): void {
		Promise.all([
			import(/* webpackChunkName: "amChartsXYChart" */ '@amcharts/amcharts4/.internal/charts/types/XYChart'),
			import(/* webpackChunkName: "amChartsXYCursor" */ '@amcharts/amcharts4/.internal/charts/cursors/XYCursor'),
			import(/* webpackChunkName: "amChartsCircleBullet" */ '@amcharts/amcharts4/.internal/charts/elements/CircleBullet'),
			import(/* webpackChunkName: "amChartsLineSeries" */ '@amcharts/amcharts4/.internal/charts/series/LineSeries'),
			import(/* webpackChunkName: "amChartsNumberFormatter" */ '@amcharts/amcharts4/.internal/core/formatters/NumberFormatter')
		])
			.then(([xyChartExports]) => {
				this.chartType = (xyChartExports as any).XYChart; // TODO: TypeScript 3.7.3 bug workaround
				this.initChartConfig();
			});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.chart && changes.incrementalData) {
			this.addIncrementalData();
		}
	}

	protected getDateAxis(): any {
		return {
			type: 'DateAxis',
			cursorTooltipEnabled: false,
			dataFields: {
				category: this.dateField
			},
			renderer: {
				grid: {
					disabled: true
				}
			},
			strictMinMax: true,
			title: {
				text: this.xAxisLabel
			},
			extraMax: .1
		};
	}

	protected getLineSeries(): any {
		return {
			type: 'LineSeries',
			bullets: [{
				type: 'CircleBullet',
				defaultState: {
					properties: {
						opacity: 0
					}
				},
				events: {
					inited: event => event.target.opacity = 0 // workaround for the defaultState not being respected when data is appended
				},
				states: {
					hover: {
						properties: {
							opacity: 1
						}
					}
				}
			}],
			dataFields: {
				dateX: this.dateField,
				valueY: this.valueField
			},
			fillOpacity: +!!this.fillEnabled,
			strokeWidth: 2,
			tooltipText: this.tooltipText ||
				'{dateX}: [bold]{valueY.formatNumber("#,###")}[/]'
		};
	}

	protected getValueAxis(): any {
		return {
			type: 'ValueAxis',
			cursorTooltipEnabled: false,
			strictMinMax: true,
			title: {
				text: this.yAxisLabel
			},
			min: 0,
			maxPrecision: this.integerValueAxis ? 0 : undefined
		};
	}

	protected initChartConfig(): void {
		this.chartConfig = {
			cursor: {
				lineY: {
					disabled: true
				},
				type: 'XYCursor'
			},
			dateFormatter: {
				inputDateFormat: this.inputDateFormat || 'yyyy-MM-dd'
			},
			series: [this.getLineSeries()],
			xAxes: [this.getDateAxis()],
			yAxes: [this.getValueAxis()],
			paddingRight: 20
		};
	}

	protected getDataItems(seriesIndex: number = 0) {
		return this.chart.series.getIndex(seriesIndex).dataItems;
	}

	public onChartReadyInternal(event: IOnChartReadyData): void {
		this.chart = event.chart as XYChart;

		this.onChartReady.emit({
			chart: this.chart
		});
	}

	private addIncrementalData(): void {
		const currentMaxDate = +this.getDataItems()?.last?.dates?.dateX || 0;

		//make sure incremental data x-axis should be greater than currentMaxDate.
		const filteredIncrementalData = this.incrementalData.filter(data => +new Date(data.key) > currentMaxDate);
		this.chart.addData(filteredIncrementalData);
	}
}
