import { HttpXsrfCookieExtractor } from '@angular/common/esm2015/http/src/xsrf';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpXsrfTokenExtractor } from '@angular/common/http';

import { getXsrfCookieName } from 'rev-shared/security/Tokens';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

/**
 * Extends the default HttpXsrfCookieExtractor in order to facilitate a dynamic cookie name.
 * Normally this is a static string injectable. However, ours is determined on the fly.
 * This makes it consistent with the AngularJS side.
 */
@Injectable()
class VbXsrfCookieExtractor extends HttpXsrfCookieExtractor {
	public cookieName: string;

	constructor(
		@Inject(DOCUMENT) private doc: any,
		@Inject(PLATFORM_ID) private platform: string,
		private userContext: UserContextService
	) {
		super(doc, platform, getXsrfCookieName());

		this.userContext.userAuthenticated$.subscribe(() => this.cookieName = getXsrfCookieName());
	}
}

export const VbXsrfCookieExtractorProvider = {
	provide: HttpXsrfTokenExtractor,
	useClass: VbXsrfCookieExtractor
};
