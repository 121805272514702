import { NgModule } from '@vbrick/angular-ts-decorators';

import { downgradeInjectables } from 'rev-shared/util/AngularHybridUtils';

import { DATE_PARSER_FACTORY_SERVICE_TOKEN } from './DateParser.Provider';
import { DateParsersService } from './DateParsers.Service';
import { DateRangePipe } from './DateRange.Pipe';
import { MomentLocaleLoader } from './MomentLocaleLoader.Provider';
import { TimezoneService } from './Timezone.Service';
import { VbDateTimeInputComponent } from './VbDateTimeInput.Component';
import { VbDateTimeMediumPipe } from './VbDateTimeMedium.Pipe';
import { VbExcludeSecondsFromTimespanPipe } from './VbExcludeSecondsFromTimespan.AngularJs.Pipe';
import { VbFormatDatePipe } from './VbFormatDate.Pipe';
import { VbFormatTimespanLargeHoursPipe } from './VbFormatTimespanLargeHours.AngularJs.Pipe';
import { VbTimeInputDirective } from './VbTimeInput.Directive';
import { VbTimespanPipe } from './VbTimespan.AngularJs.Pipe';
import { VbTimespanToHoursPipe } from './VbTimespanToHours.Pipe';
import { VbTimespanToSecondsPipe } from './VbTimespanToSeconds.AngularJs.Pipe';

@NgModule({
	id: 'VBrick.Date',
	declarations: [
		DateRangePipe,
		VbDateTimeInputComponent,
		VbExcludeSecondsFromTimespanPipe,
		VbFormatDatePipe,
		VbFormatTimespanLargeHoursPipe,
		VbDateTimeMediumPipe,
		VbTimeInputDirective,
		VbTimespanPipe,
		VbTimespanToHoursPipe,
		VbTimespanToSecondsPipe
	],
	providers: [
	]
})
export default class SharedDateModule {}

downgradeInjectables(SharedDateModule, [
	{
		injectable: DATE_PARSER_FACTORY_SERVICE_TOKEN,
		name: 'DateParser'
	},
	{
		injectable: DateParsersService,
		name: 'DateParsers'
	},
	{
		injectable: TimezoneService,
		name: 'TimezoneService'
	},
	{
		injectable: MomentLocaleLoader,
		name: 'MomentLocaleLoader'
	}
]);
