import {
	Component
} from '@angular/core';


import styles from './VideoReports.module.less';

@Component({
	selector: 'video-analytics-wrapper',
	templateUrl: './VideoAnalyticsWrapper.Component.html',
	host: {
		'[class]': 'styles.root',
		layout: 'column',
		'layout-wrap': 'false'
	}
})
export class VideoAnalyticsWrapperComponent {
	public readonly styles = styles;
}
