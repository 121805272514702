import {
	AfterViewInit,
	Directive,
	Input,
	OnChanges,
	SimpleChanges
} from '@vbrick/angular-ts-decorators';

import { INgModelController } from 'angular';
import { isBoolean } from 'underscore';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vb-email]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbEmailDirective implements AfterViewInit, OnChanges {
	@Input('<? vbEmail') public isActive: boolean;

	private ngModel: INgModelController;

	public ngAfterViewInit(): void {
		// overrides the default email validator that may be applied when type="email"
		this.ngModel.$validators.email =
			this.ngModel.$validators.vbEmail =
			modelValue => this.validate(modelValue);
	}

	public ngOnChanges(changes: SimpleChanges<this>): void {
		if (changes.isActive && isBoolean(this.isActive)) {
			this.ngModel.$validate();
		}
	}

	private validate(value: string): boolean {
		return this.isActive === false ?
			true :
			ValidationRules.checkEmail(value);
	}
}
