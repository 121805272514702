import { Injectable } from '@angular/core';

import { IMediaStateConfig, TopType } from './IMediaStateConfig';

import { IVideoPlaybackConfig } from 'rev-portal/media/videos/videoPlayback/IVideoPlaybackConfig';

export const TITLE_SORT: string = 'title.sort';
export const UPLOADER_NAME_SORT: string = 'uploaderName.sort';
export const VIDEO_SELECTION_LIMIT = 1000;

export interface IMediaColumnConfig {
	[key: string]: number;
}

export enum MediaViewMode {
	TABLE = 'table',
	TILES = 'tiles'
}

export enum MediaType {
	PLAYLIST = 'playlist',
	VIDEO = 'video'
}

export interface ICategoryPath {
	canEdit?: boolean;
	children: ICategoryPath[];
	id: string;
	name: string;
	parentCategoryId: string;
	videoCount: number;
}

export interface ISearchResultsState {
	categoryPath?: ICategoryPath[];
	playlist?: any;
	searchQuery?: string;
	mediaCount?: number;
	mediaType?: MediaType;
	getSelectedCount?: () => number;
}

@Injectable()
export class MediaStateService {
	private readonly defaultColumnConfig: IMediaColumnConfig = {
		[TITLE_SORT]: 0,
		whenUploaded: 1,
		[UPLOADER_NAME_SORT]: 2,
		duration: 3,
		viewCount: 4,
		lastViewed: 5,
		deleteOnExpiration: -1,
		expiryDate: -1,
		selection: -1
	};

	public readonly mediaState = {
		backLink: null,
		columns: Object.assign({}, this.defaultColumnConfig),
		isBulkEditDisabled: false,
		isFilteringDisabled: false,
		isSortDesc: false,
		isSortingDisabled: false,
		isViewModeDisabled: false,
		sortField: TITLE_SORT,
		viewMode: MediaViewMode.TILES
	};

	public readonly adjustMediaResultTop: TopType = TopType.MARGIN;
	public readonly redirectToAllTeams: boolean = true;
	public readonly showBulkEdit: boolean = true;
	public readonly showEditTeam: boolean = true;
	public readonly showSignInBtn: boolean;
	public readonly showSubscribeSparkBtn: boolean = true;
	public readonly showUploadToTeam: boolean = true;
	public readonly baseCategoryId: string;
	public readonly videoPlaybackConfig: IVideoPlaybackConfig;
	public searchResultsState: ISearchResultsState = {};
	public showPendingButton: boolean;
	public showSortRecommended: boolean = true;

	public getSortField(): string {
		return this.mediaState.sortField;
	}

	public setSortField(sortField?: string): void {
		this.mediaState.sortField = sortField || TITLE_SORT;
	}

	public getIsSortDesc(): boolean {
		return this.mediaState.isSortDesc;
	}

	public setIsSortDesc(isSortDesc?: boolean): void {
		this.mediaState.isSortDesc = !!isSortDesc;
	}

	public setDefaultSortDirection(): void {
		this.mediaState.isSortDesc = this.mediaState.sortField !== TITLE_SORT;
	}

	public setIsFilteringDisabled(isFilteringDisabled?: boolean): void {
		this.mediaState.isFilteringDisabled = !!isFilteringDisabled;
	}

	public getIsSortingDisabled(): boolean {
		return this.mediaState.isSortingDisabled;
	}

	public setIsSortingDisabled(isSortingDisabled?: boolean): void {
		this.mediaState.isSortingDisabled = !!isSortingDisabled;
	}

	public getViewMode(): MediaViewMode {
		return this.mediaState.viewMode;
	}

	public setViewMode(viewMode?: MediaViewMode): void {
		this.mediaState.viewMode = viewMode || MediaViewMode.TILES;
	}

	public setIsViewModeDisabled(isDisabled?: boolean): void {
		this.mediaState.isViewModeDisabled = !!isDisabled;
	}

	public setIsBulkEditDisabled(isDisabled?: boolean): void {
		this.mediaState.isBulkEditDisabled = !!isDisabled;
	}

	public getMediaState() {
		return this.mediaState;
	}

	public getColumns() {
		return this.mediaState.columns;
	}

	public setColumns(value: IMediaColumnConfig): void {
		if (value) {
			Object.keys(this.mediaState.columns)
				.forEach(key => this.mediaState.columns[key] = -1);

			Object.assign(this.mediaState.columns, value);
		}
	}

	public reset(): void {
		this.setSortField();
		this.setIsSortDesc();
		this.setIsSortingDisabled();
		this.setViewMode();
		this.setIsViewModeDisabled();
		this.setIsBulkEditDisabled();
		this.resetColumns();
	}

	public resetColumns(): void {
		this.setColumns(this.defaultColumnConfig);
	}

	public setBackLink(backLink?: string): void {
		this.mediaState.backLink = backLink;
	}

	public navigateBack(): void {
		if (this.mediaState.backLink) {
			window.location = this.mediaState.backLink;
		}
	}

	public initMediaStateConfig(config: IMediaStateConfig): void {
		Object.assign(this, config);
	}

	public get adjustMarginTop(): boolean {
		return this.adjustMediaResultTop === TopType.MARGIN;
	}

	public get adjustPaddingTop(): boolean {
		return this.adjustMediaResultTop === TopType.PADDING;
	}

	public get isTableViewMode(): boolean {
		return this.mediaState.viewMode === MediaViewMode.TABLE;
	}
}
