import { Directive, ElementRef, Injector, Input, forwardRef, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IInsightOptions } from '@vbrick/angular-insight/src/Insight.Contract';
import { Subscription } from 'rxjs';

@Directive({
	selector: 'vb-ui-insight',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => VbUiInsightComponent),
		multi: true
	}]
})
export class VbUiInsightComponent extends UpgradeComponent implements ControlValueAccessor {
	@Input() public assignedOptionTemplateUrl: string;
	@Input() public insightOptions: IInsightOptions;
	@Input() public optionTemplateUrl: string;
	@Input() public templateUrl: string;
	@Input() public ngModel: any[]; //separate @Input binding is needed for UpgradeComponent to work

	@Output() public valueChanged: EventEmitter<any> = new EventEmitter();

	public onChangeFn: (val: any) => void;
	public onTouchFn: () => void;
	public isDisabled: boolean;
	public sub: Subscription;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('vbUiInsight', elementRef, injector);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.sub = this.valueChanged.subscribe(val => this.updateModelValue(val));
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.sub?.unsubscribe();
	}

	public updateModelValue(val: any): void {
		this.ngModel = val;
		this.onChangeFn?.(val);
		this.onTouchFn?.();
	}

	public writeValue(val: any): void {
		this.ngModel = val;
	}

	public registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchFn = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
}
