import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FormDirectivesAngularModule } from 'rev-shared/util/directives/form/FormDirectivesAngular.Module';
import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';

import { PublicVideoPasswordComponent } from './PublicVideoPassword.Component';

const components = [
	PublicVideoPasswordComponent
];

@NgModule({
	id: 'VBrick.Shared.Media.PublicVideoPassword',
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		CommonModule,
		DirectivesAngularModule,
		FormDirectivesAngularModule,
		FormsModule,
		PipesAngularModule,
		TranslateModule
	]
})
export class VBrickSharedPublicVideoPasswordModule {}
