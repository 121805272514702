import {
	Directive,
	HostListener,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAttributes,
	IAugmentedJQuery,
	ITimeoutService
} from 'angular';

@Directive({
	selector: '[disable-password-autocomplete]'
})
export class DisablePasswordAutocomplete implements OnInit {
	private isUpdateAndRefocus: boolean;

	constructor(
		private $attrs: IAttributes,
		private $element: IAugmentedJQuery,
		private $timeout: ITimeoutService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.isUpdateAndRefocus = false;

		if (this.$attrs.type !== 'password') {
			return;
		}

		this.$element
			.attr({
				'autocomplete': 'new-password', // makes Firefox happy, but others deliberately ignore autocomplete for passwords
				'data-name': this.$attrs.name, // replace name with data-name to disable Chrome password dropdown,
				'type': 'text'
			})
			.removeAttr('name');

		this.setElementReadonly(true);
	}

	@HostListener('blur')
	private onBlur(): void {
		if (this.isUpdateAndRefocus) {
			return;
		}

		this.setElementReadonly(true);
	}

	@HostListener('input change')
	private onChange(): void {
		const type: string = (this.$element.val() as string).length ? 'password' : 'text';

		this.$element.attr('type', type);
	}

	@HostListener('focus')
	private onFocus(): void {
		if (this.isUpdateAndRefocus) {
			this.isUpdateAndRefocus = false;
			return;
		}

		// can't update readOnly while focused on the field in IE, so have to blur and refocus
		this.isUpdateAndRefocus = true;
		this.$element.blur();

		this.setElementReadonly(false);

		this.$timeout(() => this.$element.focus());
	}

	private setElementReadonly(value: boolean): void {
		(this.$element[0] as HTMLInputElement).readOnly = value;
	}
}
