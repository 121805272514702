import {
	Directive,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';

import { INgModelController } from 'angular';
import { IPAddress as IPAddressService } from 'rev-shared/util/IPAddress.Service';

@Directive({
	selector: '[vb-multicast-ip-address]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbMulticastIpAddressDirective implements OnInit {
	private ngModel: INgModelController;

	constructor(
		private IPAddress: IPAddressService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.ngModel.$validators.vbMulticastIpAddress = modelValue => this.validate(modelValue);
	}

	private validate(modelValue: any) {
		return this.IPAddress.validateIPv4Multicast(modelValue);
	}
}
