export function configureCkEditor(): void {
	CKEDITOR.on('dialogDefinition', event => {
		if(event.data.name === 'link') {
			const dialog = event.data.definition;
			const targetTab = dialog.getContents('target');
			const targetMenu = targetTab.get('linkTargetType');
			const removeTargets = ['popup', 'frame', '_parent', '_top'];
			targetMenu.items = targetMenu.items.filter(item => !removeTargets.includes(item[1]));

			const infoTab = dialog.getContents('info');

			const linkTypeMenu = infoTab.get('linkType');
			linkTypeMenu.items = linkTypeMenu.items.filter(item => item[1] !== 'anchor');

			const protocolMenu = infoTab.get('protocol');
			const removeProtocols = ['ftp://', 'news://'];
			protocolMenu.items = protocolMenu.items.filter(item => !removeProtocols.includes(item[1]));

			targetMenu.default = '_blank';
		}
	});

	CKEDITOR.on('instanceReady', event => {
		const dp = event.editor.dataProcessor as CKEDITOR.htmlDataProcessor;
		dp.htmlFilter.addRules({
			elements: {
				a(el) {
					const relAttrs = new Set((el.attributes.rel || '').split(/\s+/))
						.add('noreferrer')
						.add('noopener');
					el.attributes.rel = Array.from(relAttrs)
						.filter(Boolean)
						.join(' ');
				}
			}
		});

		event.editor.addCommand('insertEmoji', {
			exec:(editor, data ) => {
				editor.insertHtml(`<span class="vb-emoji">${data.emojiText}</span>`);
				return true;
			}
		});
	});
}
