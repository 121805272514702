import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class SharedDeviceService {
	constructor(
		private http: HttpClient
	) {}

	public getDmeDevice(deviceId: string): Promise<any> {
		if (!deviceId) {
			return Promise.reject('device id not provided');
		}

		return this.http.get(`/devices/dme/${deviceId}`).toPromise();
	}
}
