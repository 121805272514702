import { Injectable } from '@vbrick/angular-ts-decorators';

import {
	IPromise,
	IQService,
	resource
} from 'angular';

import { TranslationsRequestService } from 'rev-shared/util/TranslationsRequest.Service';
import { ITranslationStrings } from 'rev-shared/util/ITranslationStrings';
import { UserAccountService } from 'rev-shared/security/UserAccount.Service';

export interface IRole {
	id: string;
	name: string;
	roleType: string;
}

//TODO: Move to userAdmin module
@Injectable('RoleService')
export class RoleService {
	private rolesByAccountId: Map<string, IRole[]>;
	private rolesResource: resource.IResourceClass<resource.IResource<any>>;
	private roleNames: ITranslationStrings;

	constructor(
		$resource: resource.IResourceService,
		private $q: IQService,
		private TranslationsRequest: TranslationsRequestService,
		private UserAccount: UserAccountService
	) {
		'ngInject';

		this.rolesByAccountId = new Map<string, IRole[]>();
		this.rolesResource = $resource('/network/accounts/:accountId/roles');
	}

	public get roles(): IRole[] {
		return this.rolesByAccountId.get(this.UserAccount.workingAccountId);
	}

	public fetchRoles(): IPromise<IRole[]> {
		const accountId: string = this.UserAccount.workingAccountId;
		return this.ensureRoleNamesLoaded()
			.then(() => this.rolesResource.get({ accountId }).$promise
				.then(data => {
					const roles: IRole[] = data.roles;
					roles.forEach(role => role.name = this.roleNames[role.roleType]);
					this.rolesByAccountId.set(accountId, roles);
					return roles;
				}));
	}

	private ensureRoleNamesLoaded(): IPromise<void>{
		if(this.roleNames){
			return this.$q.when();
		}

		return this.TranslationsRequest.requestTranslations('/partials/admin/account/role-names.html', 'roleNames')
			.then(roleNames => (this.roleNames = roleNames) as any);
	}
}
