import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';

import { StateService } from '@uirouter/angular';

import { MaxWidthSmallMediaQuery } from 'rev-shared/ui/size/Size.Constants';

import {
	ISidebarConfig,
	ISidebarButtonConfig
} from './ISidebarConfig';

import styles from './VbUiSidebar.Component.module.less';

export interface ISidebarToggleEvent {
	activeSidebarButtonId: string;
	isOpen: boolean;
}

export enum VbUiSidebarSlot {
	HEADER_CONTROLS = 'headerControls',
	PANEL_BODY = 'panelBody'
}

@Component({
	selector: 'vb-ui-sidebar',
	templateUrl: './VbUiSidebar.Component.html',
	host: { // layout attribute is specified below with a HostBinding
		'[class]': 'hostClass',
		'layout-gt-md': 'row',
		'layout-wrap': 'false'
	}
})
export class VbUiSidebarComponent implements OnChanges, OnInit, OnDestroy {
	@Input() public isMobileLayoutDisabled: boolean;
	@Input() public openWidthPx: number;
	@Input() public sidebarConfig: ISidebarConfig;
	@Input() public themed: boolean;

	@Output() public onToggle = new EventEmitter<ISidebarToggleEvent>();

	public buttonConfigLastClick: ISidebarButtonConfig;
	public isMobileWindow: boolean;
	private mobileMediaQuery: MediaQueryList;
	private mobileMediaQueryListener: () => void;
	@HostBinding('class.vbUiSidebarRootOpen')
	public isOpen: boolean = false;
	private sidebarClickedOnce: boolean = false;
	public readonly styles = styles;
	public hostClass: {[key: string]: boolean};

	constructor(
		private $state: StateService
	) {}

	public ngOnInit(): void {
		// listen for the mobile media query
		this.mobileMediaQuery = window.matchMedia(MaxWidthSmallMediaQuery);
		this.mobileMediaQueryListener = () => this.onMobileMediaQueryChange();
		this.mobileMediaQuery.addListener(this.mobileMediaQueryListener);

		// initial run since you won't get an event on init
		this.onMobileMediaQueryChange();
		this.hostClass = {
			[this.styles.root]: true,
			[this.styles.mobileLayoutDisabled]: this.isMobileLayoutDisabled
		};
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.sidebarConfig && this.sidebarConfig) {
			if(!this.sidebarClickedOnce && !this.activeSidebarButtonId) {
				this.openDefaultSidebarPanel();
			}
		}
	}

	public ngOnDestroy(): void {
		this.mobileMediaQuery.removeListener(this.mobileMediaQueryListener);
	}

	public get activeSidebarButtonId(): string {
		return this.isOpen && this.buttonConfigLastClick ?
			this.buttonConfigLastClick.id :
			null;
	}

	@HostBinding('attr.layout')
	public get layout(): string {
		return this.isMobileLayoutDisabled ?
			'row' :
			'column';
	}

	public closeSidebar(): void {
		this.toggleSidebar(false);
	}

	private onMobileMediaQueryChange(): void {
		this.isMobileWindow = this.mobileMediaQuery.matches;
	}

	private openDefaultSidebarPanel(): void {
		if (this.sidebarConfig.defaultClickedButtonId && !this.isOpen) {
			this.buttonConfigLastClick = this.getButtonConfig(this.sidebarConfig.defaultClickedButtonId);
			//fixing ExpressionChangedAfterItHasBeenCheckedError...
			setTimeout(() => this.toggleSidebar(true));
		}
	}

	private getButtonConfig(buttonId: string): ISidebarButtonConfig {
		return this.sidebarConfig.buttons
			.find(button => button.id === buttonId);
	}

	public sidebarButtonClick(buttonConfig: ISidebarButtonConfig): void {
		if (buttonConfig.uiSref) {
			const url = this.$state.href(buttonConfig.uiSref, buttonConfig.uiParams);
			window.open(url, '_blank');
			return;
		}

		const isSameAsLastClick: boolean = this.buttonConfigLastClick && this.buttonConfigLastClick.id === buttonConfig.id;
		this.sidebarClickedOnce = true;

		if (buttonConfig.onClick) {
			buttonConfig.onClick();
			return;
		}

		const isOpen: boolean = isSameAsLastClick ?
			!this.isOpen :
			true;

		this.buttonConfigLastClick = buttonConfig;

		this.toggleSidebar(isOpen);
	}

	private toggleSidebar(isOpen: boolean): void {
		this.isOpen = isOpen;

		this.onToggle.emit({
			activeSidebarButtonId: this.activeSidebarButtonId,
			isOpen
		});
	}
}
