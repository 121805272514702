export function loadScript(src: string): Promise<void> {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');

		script.addEventListener('load', resolve as any);
		script.addEventListener('error', reject);
		script.src = src;
		document.head.appendChild(script);
	});
}
