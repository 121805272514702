import { NgModule } from '@angular/core';

import { DisablePasswordAutoCompleteDirective } from './DisablePasswordAutocompleteAngular.Directive';

const directives = [
	DisablePasswordAutoCompleteDirective
];

@NgModule({
	declarations: directives,
	exports: directives
})
export class FormDirectivesAngularModule {}
