import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import { DirectUrlType } from 'rev-portal/navigation/uploadMenu/UploadConstant';

import styles from './VideoReports.module.less';

@Component({
	selector: 'video-reports',
	templateUrl: './VideoReports.Component.html'
})
export class VideoReportsComponent implements OnInit {
	@Input() public video: any;
	@Input() public videoSummary: any;

	public readonly styles = styles;
	public canShowViewerDropoffChart: boolean;

	public ngOnInit(): void {
		this.canShowViewerDropoffChart = this.checkShowViewerDropoffChart();
	}

	public checkShowViewerDropoffChart(): boolean {
		const { isLive, linkedUrl } = this.video;

		return (!isLive && linkedUrl == null) ||
			linkedUrl?.type === DirectUrlType.VOD;
	}
}
