import { Directive, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
	selector: '[vbMaxLength]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbMaxLengthDirective),
			multi: true
		}
	]
})
export class VbMaxLengthDirective implements Validator {
	@Input() public vbMaxLength: any;

	public validate(control: AbstractControl): ValidationErrors {
		if (this.vbMaxLength > 0 && control?.value?.length > this.vbMaxLength) {
			return { vbMaxLength: true };
		}
	}
}
