import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';

import { ForgotPasswordComponent } from './ForgotPassword.Component';
import { LoginComponent } from './Login.Component';
import { PasswordResetComponent } from './PasswordReset.Component';
import { SSOLogoutComponent } from './SSOLogout.Component';
import { Unauthorized401Component } from './Unauthorized401.Component';
import { UserAgreementComponent } from './UserAgreement.Component';
import { UserConfirmationComponent } from './UserConfirmation.Component';

const components = [
	ForgotPasswordComponent,
	LoginComponent,
	PasswordResetComponent,
	SSOLogoutComponent,
	Unauthorized401Component,
	UserAgreementComponent,
	UserConfirmationComponent
];

@NgModule({
	declarations: components,
	entryComponents: components,
	exports: components,
	imports: [
		BrandingAngularModule,
		CommonModule,
		DateAngularModule,
		DialogAngularModule,
		DirectivesAngularModule,
		FormsModule,
		SecurityAngularModule,
		SpinnerAngularModule,
		TranslateModule,
		UIRouterModule
	]
})
export class SecurityComponentsModule {}
