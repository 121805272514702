import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vb-integer]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbIntegerDirective),
		multi: true
	}]
})
export class VbIntegerDirective {
	public validate(control: AbstractControl): ValidationErrors {
		return ValidationRules.checkInteger(control.value) ?
			null :
			{ vbInteger: true };
	}
}
