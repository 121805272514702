import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { VbNavigationHandlerDirective } from 'rev-shared/uiRouterUtils/VbNavigationHandler.Directive';

import { AppUtilService } from './AppUtil.Service';
import { StateChangeStatus } from './StateChangeStatus.Service';
import { LanguageCodes } from './LanguageCodes.Service';

const declarations = [
	VbNavigationHandlerDirective
];

@NgModule({
	declarations,
	exports: declarations,

	imports: [
		HttpClientModule
	],
	providers: [
		AppUtilService,
		LanguageCodes,
		StateChangeStatus
	]
})
export class UtilAngularModule {}
