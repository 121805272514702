import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive, forwardRef } from '@angular/core';

import { parseMeetingId, validateMeetingIdFormatAngular, formatMeetingIdAngular } from './ZoomMeetingId';

@Directive({
	selector: '[ngModel][vbZoomMeetingId]',
	host: {
		'(blur)': 'onBlur($event)'
	},
	providers: [{
		multi: true,
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbZoomMeetingIdDirective)
	}]
})
export class VbZoomMeetingIdDirective implements Validator {
	private control: AbstractControl;

	public onBlur($event): void {
		const parsedValue = parseMeetingId($event.target.value);
		this.control.setValue(formatMeetingIdAngular(parsedValue));
	}

	public validate(control: AbstractControl): ValidationErrors {
		this.control = control;
		return !validateMeetingIdFormatAngular(control.value) ? { zoomMeetingId: false } : null;
	}
}
