import { Pipe, PipeTransform } from '@vbrick/angular-ts-decorators';
import { IFilterNumber } from 'angular';

@Pipe({ name: 'vbFormatTimespanLargeHours' })
export class VbFormatTimespanLargeHoursPipe implements PipeTransform {
	constructor(
		private numberFilter: IFilterNumber
	) {
		'ngInject';
	}

	public transform(formattedTimespan: string): string {
		const timespanPieces: string[] = formattedTimespan.split(':');

		timespanPieces[0] = this.numberFilter(timespanPieces[0], 0);

		return timespanPieces.join(':');
	}
}
