import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AnalyticsModule } from 'rev-shared/analytics/Analytics.Module';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DataGridModule } from 'rev-shared/ui/dataGrid/DataGrid.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { KpiModule } from 'rev-shared/ui/reports/kpi/Kpi.Module';
import { LineChartModule } from 'rev-shared/ui/dataVisualization/lineChart/LineChart.Module';
import { ReportCellModule } from 'rev-shared/ui/reports/reportCell/ReportCell.Module';
import { SearchInputModule } from 'rev-shared/ui/searchInputAngular/SearchInput.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';

import { VideoAnalyticsGridComponent } from './VideoAnalyticsGrid.Component';
import { VideoReportsComponent } from './VideoReports.Component';
import { VideoReportsKpisComponent } from './VideoReportsKpis.Component';
import { VideoAnalyticsWrapperComponent } from './VideoAnalyticsWrapper.Component';

const exports = [
	VideoAnalyticsGridComponent,
	VideoReportsComponent,
	VideoAnalyticsWrapperComponent
];

@NgModule({
	declarations: [
		VideoReportsKpisComponent,
		...exports
	],
	exports,
	imports: [
		AnalyticsModule,
		ButtonsAngularModule,
		CommonModule,
		DataGridModule,
		DateAngularModule,
		DirectivesAngularModule,
		FormsModule,
		KpiModule,
		LineChartModule,
		ReportCellModule,
		SearchInputModule,
		SpinnerAngularModule,
		TranslateModule
	]
})
export class VideoReportsModule {}
