import {
	Directive,
	Input,
	OnInit
} from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vb-url]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class VbUrlDirective implements OnInit {
	@Input() public vbUrl: string[]; // optional array of valid schemes for the url. If not present, any scheme is allowed.

	private ngModel: INgModelController;

	public ngOnInit(): void {
		this.ngModel.$parsers.push(value => this.validate(value));
		this.ngModel.$formatters.push(value => this.validate(value));
	}

	private validate(value: string): string {
		this.ngModel.$setValidity('vbUrl', ValidationRules.checkUrl(value, this.vbUrl));

		return value;
	}
}
