import { Component, Input, OnInit } from '@angular/core';

import { SignalRHubsConnection, SignalRHubsConnectionState } from './SignalRHubsConnection';

@Component({
	selector: 'signalr-connection-status',
	templateUrl: './SignalRConnectionStatus.Component.html'
})
export class SignalrConnectionStatusComponent implements OnInit {
	@Input() public signalrHubsConnection: SignalRHubsConnection;

	public pushConnectionInitialized: boolean;
	public pushConnectionHealthy: boolean;

	public ngOnInit(): void {
		this.signalrHubsConnection.on({
			stateChanged: event => {
				this.pushConnectionHealthy =
					!this.signalrHubsConnection.started ||
					event.newState === SignalRHubsConnectionState.Connected;

				if (this.pushConnectionHealthy) {
					this.pushConnectionInitialized = true;
				}
			}
		});
	}
}
