import { Component } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';

export interface ICheckboxCellRendererParams {
	enabled?: boolean;
}

interface ICheckboxCellRendererMergedParams extends ICellRendererParams, ICheckboxCellRendererParams {}

@Component({
	selector: 'checkbox-cell-renderer',
	templateUrl: './CheckboxCellRenderer.Component.html'
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {
	public isChecked: boolean;
	public isDisabled: boolean;

	public agInit(params: ICheckboxCellRendererMergedParams): void {
		this.updateCell(params);
	}

	public refresh(params: ICheckboxCellRendererMergedParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: ICheckboxCellRendererMergedParams): void {
		this.isChecked = params.valueFormatted ?? !!params.value;
		this.isDisabled = !params.enabled;
	}
}
