import { Injectable, Injector } from '@angular/core';

import { IEntryPointConfig } from 'rev-shared/entryPoint/Contract';

@Injectable()
export class AppUtilService {
	constructor(
		private injector: Injector
	) {}

	public isEmbed(): boolean {
		return window.location.pathname === '/embed';
	}

	public isMsTeamsEmbedLogin(): boolean {
		return window.location.pathname.includes('ms-teams') && !this.isMsTeamsMobile();
	}

	public isMsTeamsMobile(): boolean {
		const entryPointConfig: IEntryPointConfig = this.injector.get('EntryPointConfig', null);
		//EntryPointConfig is only in MsTeams module as of now.

		return entryPointConfig?.isMsTeamsMobileMode;
	}
}
