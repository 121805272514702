<img [attr.alt]="alt" [ngClass]="{
		'animated fadeIn': currentState === ForgivingImageState.SUCCESS
	}" (load)="onImageLoaded()" #vbImageRetry [hidden]="!(currentState === ForgivingImageState.SUCCESS)" [src]="src" vbImageRetry [totalDelayTimeMs]="totalDelayTimeMs" (onRetriesExhausted)="onRetriesExhausted()">

<vb-loading-spinner [hidden]="!(currentState === ForgivingImageState.SPINNER)">
</vb-loading-spinner>

<button [attr.aria-label]="ariaRetryButtonLabel || retryButtonLabel" type="button" (click)="tryAgain()" [hidden]="!(currentState === ForgivingImageState.ERROR)" vbUiBtnSecondary>
	<span class="glyphicons refresh"></span>
	{{ retryButtonLabel }}
</button>
