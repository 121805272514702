import { NgModule } from '@angular/core';

import { SignInBtnModule } from 'rev-shared/ui/signInBtn/SignInBtn.Module';
import { MediaUploadBtnModule } from 'rev-shared/ui/mediaUploadBtn/MediaUploadBtn.Module';

import { MediaStateService } from './MediaState.Service';
import { MediaSearchToolbarModule } from './search/toolbar/MediaSearchToolbar.Module';
import { PlaylistsAngularModule } from './playlists/Playlists.AngularModule';
import { SearchAngularModule } from './search/Search.AngularModule';
import { VideosAngularModule } from './videos/VideosAngular.Module';
import { VideosLoaderModule } from './videos/VideosLoader.Module';
import { RecordingAngularModule } from './recording/RecordingAngular.Module';

@NgModule({
	imports: [
		MediaSearchToolbarModule,
		MediaUploadBtnModule,
		PlaylistsAngularModule,
		RecordingAngularModule,
		SearchAngularModule,
		SignInBtnModule,
		VideosAngularModule,
		VideosLoaderModule
	],
	providers: [
		MediaStateService
	]
})
export class MediaAngularModule {}
