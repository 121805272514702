import { StateProvider, Transition } from '@uirouter/angularjs';
import { ResolvableLiteral } from '@uirouter/angular';

import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';
import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';

import { ForgotPasswordComponent } from './ForgotPassword.Component';
import { LoginComponent, LoginResolve, LoginResolveNg2 } from './Login.Component';
import { SSOLogoutComponent, SSOLogoutNg2Resolve } from './SSOLogout.Component';
import { SSOLogoutResolve } from './SSOLogout.Component';
import { UserAgreementResolve, UserAgreementComponent, UserAgreementNg2Resolve } from './UserAgreement.Component';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';

export function configureCommonSecurityStates(
	initializationResolve: any,
	$stateProvider: StateProvider,
	loginComponent: typeof LoginComponent = LoginComponent,
	userAgreementComponent: typeof UserAgreementComponent = UserAgreementComponent
): void {

	$stateProvider
		.state('login', {
			component: loginComponent as any,
			url: '/login?fwdUrl&initiateLogin',
			params: {
				fwdUrl: {
					value: '',
					replace: [{ // prevents the empty string from being replaced with undefined
						from: '',
						to: ''
					}]
				},
				initiateLogin: { value: false, type: 'bool', squash: true }
			},
			resolve: {
				...initializationResolve,
				...LoginResolve
			},
			allowAllAccess: true
		} as IVBrickStateDeclaration)

		.state('forgot-password', {
			url: '/forgot-password',
			resolve: initializationResolve,
			component: ForgotPasswordComponent as any,
			authorizationKey: 'resetPassword',
			allowAllAccess: true
		} as IVBrickStateDeclaration)

		.state('user-agreement', {
			url: '/user-agreement?fwdUrl&userId&ssoLogin',
			resolve: {
				...initializationResolve,
				...UserAgreementResolve,
			},
			component: userAgreementComponent as any,
			allowAllAccess: true,
			params: {
				fwdUrl: { value: '', squash: true },
				userId: { value: '', squash: true },
				ssoLogin: { value: false, type: 'bool', squash: true }
			}
		} as IVBrickStateDeclaration)
		.state('logout', {
			url: '/logout?sessionEnded&invalidCredentials&fwdUrl',
			resolve: initializationResolve,
			redirectTo(transition: Transition) {
				const LoginRedirectService = transition.injector().get<LoginRedirectService>('LoginRedirectService');

				return LoginRedirectService.getLogoutRedirect(transition.params());
			}
		} as IVBrickStateDeclaration)
		.state('sso-logout', {
			url:'/sso-logout?sessionEnded&fwdUrl',
			fwdUrl: {
				value: '',
				replace: [{ // prevents the empty string from being replaced with undefined
					from: '',
					to: ''
				}]
			},
			resolve: Object.assign({}, initializationResolve, SSOLogoutResolve),
			component: SSOLogoutComponent as any,
			allowAllAccess: true
		} as IVBrickStateDeclaration);
}

export function getCommonSecurityStatesNg2(
	initializationResolve: ResolvableLiteral[],
	loginComponent: typeof LoginComponent = LoginComponent,
	userAgreementComponent: typeof UserAgreementComponent = UserAgreementComponent
): IVbNg2StateDeclaration[] {
	return [
		{
			name: 'login',
			component: loginComponent,
			url: '/login?fwdUrl&initiateLogin',
			params: {
				fwdUrl: {
					value: '',
					replace: [{ // prevents the empty string from being replaced with undefined
						from: '',
						to: ''
					}]
				},
				initiateLogin: { value: false, type: 'bool', squash: true }
			},
			resolve: [
				...initializationResolve,
				...LoginResolveNg2
			],
			allowAllAccess: true
		},

		{
			name: 'forgot-password',
			url: '/forgot-password',
			resolve: initializationResolve,
			component: ForgotPasswordComponent,
			authorizationKey: 'resetPassword',
			allowAllAccess: true
		},

		{
			name: 'user-agreement',
			url: '/user-agreement?fwdUrl&userId&ssoLogin',
			resolve: [
				...initializationResolve,
				...UserAgreementNg2Resolve
			],
			component: userAgreementComponent,
			allowAllAccess: true,
			params: {
				fwdUrl: { value: '', squash: true },
				userId: { value: '', squash: true },
				ssoLogin: { value: false, type: 'bool', squash: true }
			}
		},

		{
			name: 'logout',
			resolve: initializationResolve,
			redirectTo(transition: Transition) {
				const LoginRedirectService = transition.injector().get<LoginRedirectService>('LoginRedirectService');

				return LoginRedirectService.getLogoutRedirect(transition.params());
			}
		},

		{
			name: 'sso-logout',
			url:'/sso-logout?sessionEnded&fwdUrl',
			params: {
				fwdUrl: {
					value: '',
					replace: [{ // prevents the empty string from being replaced with undefined
						from: '',
						to: ''
					}]
				}
			},
			resolve: [
				...initializationResolve,
				...SSOLogoutNg2Resolve
			],
			component: SSOLogoutComponent,
			allowAllAccess: true
		}
	];
}
