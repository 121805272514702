import { isString } from 'util';

const meetingUrlRx = (/^https:\/\/.*\/([0-9]+)/);
const validMeetingIdRx = (/^(\d{3})(\d{3,4}?)(\d{3,4})$/);
const validMeetingIdRxAngular = (/^(\d{3})-?(\d{3,4}?)-?(\d{3,4})$/);


export function parseMeetingId(input: string): string {
	const meetingId = getMeetingIdFromUrl(input) || input;
	if(isString(meetingId)) {
		return meetingId.replace(/[- ]/g, '');
	}
}

export function validateMeetingIdFormat(meetingId): boolean {
	return !meetingId || validMeetingIdRx.test(meetingId);
}

export function validateMeetingIdFormatAngular(meetingId): boolean {
	return !meetingId || validMeetingIdRxAngular.test(meetingId);
}

function getMeetingIdFromUrl(url: string): string {
	const match = meetingUrlRx.exec(url);
	return match ? match[1] : null;
}

export function formatMeetingId(meetingId: string | number): string {
	const matches = !meetingId ?
		undefined :
		(meetingId + '').match(validMeetingIdRx);

	if(!matches) {
		return meetingId as any;
	}

	const [_, a, b, c] = matches;
	return `${a}-${b}-${c}`;
}

export function formatMeetingIdAngular(meetingId: string | number): string {
	const matches = !meetingId ?
		undefined :
		(meetingId + '').match(validMeetingIdRxAngular);

	if(!matches) {
		return meetingId as any;
	}

	const [_, a, b, c] = matches;
	return `${a}-${b}-${c}`;
}
