import {
	Component,
	Input
} from '@angular/core';

import styles from './video-playback-overlay-dialog.module.less';

@Component({
	selector: 'video-playback-overlay-dialog',
	template: `
		<div [ngClass]="styles.wrapper" class="theme-primary theme-primary-border">
			<vb-ui-accent-header
				[headerText]="headerText"
				[headerWidth]="HEADER_WIDTH"
				[headerMobileWidth]="HEADER_MOBILE_WIDTH"
				[hasActions]="true">

				<ng-content select="header-actions"></ng-content>

			</vb-ui-accent-header>

			<ng-content select="content"></ng-content>
		</div>
	`,
	host: {
		'[class]': 'styles.host'
	}
})
export class VideoPlaybackOverlayDialogComponent {
	@Input() public headerText: string;

	public readonly HEADER_WIDTH: string = '35%';
	public readonly HEADER_MOBILE_WIDTH: string = '50%';

	public readonly styles = styles;
}
