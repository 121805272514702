import { NgModule } from '@vbrick/angular-ts-decorators';

import { VbDisableSubmitDirective } from './VbDisableSubmit.Directive';
import { VbAlternateSubmitDirective } from './VbAlternateSubmit.Directive';
import { AutoFocusDirective } from './AutoFocus.Directive';
import { VbElasticTextAreaDirective } from './VbElasticTextArea.Directive';
import { DisablePasswordAutocomplete } from './DisablePasswordAutocomplete.Directive';

@NgModule({
	id: 'VBrick.Util.Directives.Form',
	declarations: [
		AutoFocusDirective,
		DisablePasswordAutocomplete,
		VbAlternateSubmitDirective,
		VbDisableSubmitDirective,
		VbElasticTextAreaDirective
	],
	imports: [],
	providers: []
})
export default class VBrickFormDirectivesModule {}
