<header class="theme-accent-border position-relative" [ngClass]="styles.headerContainer">
	<div class="util-absolute-fill" layout="column" layout-wrap="false" flex-justify="center" [ngClass]="styles.headerAbsoluteContainer">
		<h1 [ngClass]="styles.header" [tooltip]="header" [placement]="'top left'" [containerClass]="styles.tooltipContainer">
			{{ header }}
		</h1>

		<h2 *ngIf="subheader" [ngClass]="styles.subheader" [tooltip]="subheader" [placement]="'top left'" [containerClass]="styles.tooltipContainer">
			{{ subheader }}
		</h2>
	</div>
</header>

<section flex="fill" flex-align="center" flex-justify="center" layout="column" layout-wrap="false" [ngClass]="styles.content">
	<ng-content></ng-content>
</section>

<footer *ngIf="footers?.length" flex="fill" layout="row" [ngClass]="styles.footer">
	<div flex="fill" *ngFor="let footer of footers" [ngClass]="styles.footerItem">
		<div>
			{{ footer.title }}
		</div>
		<div class="padding-top-10">
			{{ footer.data }}
		</div>
	</div>
</footer>