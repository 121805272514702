import { Component, Input, OnChanges, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import styles from './ProfilePicture.module.less';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

const Large = 'large';
const DefaultIcon = 'user';

@Component({
	selector: 'vb-profile-picture',
	host: {
		'[class]': 'styles.root'
	},
	template: `
		<span
			[ngClass]="[
				styles.wrap,
				hilight ? 'theme-accent-border' : ''
			]">
			<img
				*ngIf="url; else noImage"
				[ngClass]="styles.profilePicture"
				[alt]="altTxt"
				[src]="url"
				vbImageRetry/>

			<ng-template #noImage>
				<span
					class="glyphicons theme-accent-txt"
					[ngClass]="[styles.profileIcon, placeholderIconClass]"
					[hidden]="url">
				</span>
			</ng-template>
		</span>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VbProfilePictureComponent implements OnChanges {
	@Input() public altTxt: string;
	@Input() public hilight: boolean;
	@Input() public profileUrl: string;
	@Input() public size: string;
	@Input() public placeholderIconClass: string;

	public url: string;
	public readonly styles = styles;

	constructor(
		private ThemeService: ThemeService
	) {}

	private get userProfileImageDimensions(): string {
		return this.ThemeService.brandingSettings.headerSettings.userProfileImageDimensions;
	}

	public ngOnChanges(): void {
		this.url = this.profileUrl ?
			`${this.profileUrl}?size=${this.userProfileImageDimensions}` :
			undefined;

		this.placeholderIconClass = this.placeholderIconClass || DefaultIcon;
	}

	@HostBinding('class.vbProfilePictureLarge')
	public get isLargeSize(): boolean {
		return this.size === Large;
	}
}
