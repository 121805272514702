import { Injectable } from '@angular/core';
import moment from 'moment';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';

@Injectable({
	providedIn: 'root'
})
export class MomentLocaleLoader {
	private result: Promise<void> = null;

	public load(): Promise<void> {
		if(this.result) {
			return this.result;
		}

		const locale = BootstrapContext.momentLocale;
		this.result = !locale ?
			Promise.resolve() :
			import(`moment/locale/${locale}.js`)
				.then(() => {
					moment.locale(locale);
				});
		return this.result;
	}
}
