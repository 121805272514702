export enum WebcastStatus {
	Completed = 'Completed',
	Scheduled = 'Scheduled',
	Starting = 'Starting',
	InProgress = 'InProgress',
	Broadcasting = 'Broadcasting',
	Deleted = 'Deleted',
	Recording = 'Recording',
	RecordingStarting = 'RecordingStarting',
	RecordingStopping = 'RecordingStopping',
	VideoSourceStarting = 'VideoSourceStarting',
	WaitingForStream = 'WaitingForStream'
}

export enum WebcastVideoSource {
	MSTEAMS = 'MicrosoftTeams',
	PRESENTATION = 'PresentationProfile',
	RTMP = 'Rtmp',
	SIP_ADDRESS = 'SipAddress',
	WEBEX = 'WebexTeam',
	WEBEX_LIVE_STREAM = 'WebexLiveStream',
	ZOOM = 'Zoom'
}

export enum WebcastQuestionOption {
	IDENTIFIED = 'IDENTIFIED',
	SELFSELECT = 'SELFSELECT',
	ANONYMOUS = 'ANONYMOUS'
}
