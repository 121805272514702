import {
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	animate
} from 'angular';

// https://github.com/angular-ui/bootstrap/issues/1350#issuecomment-34595075
@Directive({
	selector: '[disable-ng-animate]',
	bindToController: true
})
export class DisableNgAnimateDirective implements OnInit {
	constructor(
		private $animate: animate.IAnimateService,
		private $element: IAugmentedJQuery
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.$animate.enabled(this.$element, false);
	}
}
