import { AppType } from 'rev-shared/contract/AppType';

export interface IBootstrapContext {
	app: AppType;
	account: IBootstrapAccount;
	analyticsUrl: string;
	bcp47Locale: string;
	ckEditorLocale: string;
	debug: boolean;
	environmentGoogleAnalyticsEnabled: boolean;
	fullcalendarLocale: string;
	googleAnalyticsTrackingCode: string;
	languagePreferenceOrder: string[];
	momentLocale: string;
	user: any;
	thumbnailUri: string;
	enableEmbedPopupAuth: boolean;
	rootHostName: string;
	language: string;
	playlistId?: string;
	ssoEnabled: boolean;
	baseCdnUrl: string;
	userLocation: string;
	userLocationTimeoutMs: number;
	videoId?: string;
	webcastId?: string;
}

export interface IBootstrapAccount {
	id: string;
	name: string;
	language: any;
	isRootAccount: boolean;
	hostname: string;
	googleAnalyticsDisabled: boolean;
	readonlyUserProfile: boolean;
}

const {	vbrickBootstrapContext,	vbrickAuxBootstrapContext } = window as any;

if(!vbrickBootstrapContext || !vbrickAuxBootstrapContext) {
	console.error('Missing boostrap context. Unable to continue.');
	throw new Error('missingBootstrapContext');
}

export const BootstrapContext: IBootstrapContext = {
	...vbrickBootstrapContext,
	...vbrickAuxBootstrapContext
};

export const DistUrl = `${ BootstrapContext.baseCdnUrl || '' }/dist/`;
