import { Directive, ElementRef, Injector, Input, Output, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { ILanguageCode } from 'rev-shared/media/transcription/TranscriptionLanguages.Service';

@Directive({
	selector: 'vb-ui-language-tags-input'
})
export class VbUiLanguageTagsInputComponent extends UpgradeComponent {
	@Input() public tags: ILanguageCode[];
	@Input() public filter: (lang: ILanguageCode) => boolean;
	@Input() public inputClass: string;
	@Input() public placeholder: string;
	@Input() public languageSource: ILanguageCode[];

	@Output() public onTagsChanged: EventEmitter<ILanguageCode[]>;

	constructor(elementRef: ElementRef, injector: Injector) {
		super('vbLanguageTagsInput', elementRef, injector);
	}
}
