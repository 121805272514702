import { IQService } from 'angular';
import {
	ParamTypes,
	StateProvider,
	StateService,
	Transition,
	UrlRouterProvider
} from '@uirouter/angularjs';

import { AccountLicenseService } from 'rev-shared/security/AccountLicense.Service';
import { ApprovalProcessService } from 'rev-shared/media/approvalProcess/ApprovalProcess.Service';
import { ApprovalStatus, UNCATEGORIZED } from 'rev-shared/media/MediaConstants';
import { ApproverTemplateInfo } from 'rev-shared/media/approvalProcess/ApproverTemplateInfo';
import { CategoryService } from 'rev-shared/media/Category.Service';
import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { StateChangeStatus } from 'rev-shared/util/StateChangeStatus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoService } from 'rev-shared/media/Video.Service';

import { SearchConstants, SortWhenUploaded, SortRecommended } from 'rev-portal/search/SearchConstants';
import { SearchFilterStateService } from 'rev-portal/media/search/SearchFilterState.Service';
import { SearchService } from 'rev-portal/search/Search.Service';
import { SparkService } from 'rev-portal/media/spark/Spark.Service';
import { TeamListingsService } from 'rev-portal/media/teams/TeamListings.Service';

import { MediaStateService, MediaViewMode } from './MediaState.Service';

export const MEDIA_STATE_ALL: string = 'portal.media.all';
export const MEDIA_STATE_BROWSE: string = 'portal.media.browse';

export const VIDEO_SEARCH_VIEWS = {
	'content@portal.media': {
		component: 'videoSearchResults'
	}
};

function redirectToAllTeamsState($state: StateService): void {
	$state.go('portal.team');
}

export function StateConfig($stateProvider: StateProvider, $urlRouterProvider: UrlRouterProvider): void {
	let lastSearchQuery: string;

	$urlRouterProvider.when('/guest', ($state: StateService, UserContext: UserContextService) => {
		'ngInject';

		//redirect guests while masking it with the /guest location
		$state.go(MEDIA_STATE_BROWSE, {}, { location: UserContext.isUserAuthenticated() });
	});

	$stateProvider
		.state('portal.media', {
			url: '/media?team',
			secureRedirects: [MEDIA_STATE_ALL],
			allowGuestAccess: true,
			component: 'mediaContainer',
			params: {
				team: { value: null, squash: true },
				config: { value: null, squash: true, type: ParamTypes.json, dynamic: true }
			},
			resolve: {
				approverProcessTemplates(ApprovalProcessService: ApprovalProcessService, UserContext: UserContextService) {
					'ngInject';

					return UserContext.isUserAuthenticated() ?
						ApprovalProcessService.fetchUserApprovalProcessTemplates()
							.then(() => ApprovalProcessService.approverProcessTemplates) :
						[];
				},

				uploadedVideoCount(VideoService: VideoService, UserContext: UserContextService) {
					'ngInject';

					return UserContext.isUserAuthenticated() ? VideoService.getUserUploadedVideosCount() : 0;
				},

				expirationsVideoCount(SearchService: SearchService, accountId: string) {
					'ngInject';

					return SearchService.expirationsVideoCount(accountId);
				},

				userHasEditableVideos(SearchService: SearchService, UserContext: UserContextService, accountId: string, teamId: string) {
					'ngInject';

					if (UserContext.isGuest()) {
						return false;
					}

					return SearchService.userHasEditableVideos(accountId, teamId);
				},

				mediaFeatures(MediaFeatures: MediaFeaturesService) {
					'ngInject';
					return MediaFeatures.getFeatures();
				},

				sparkSubscriptions(SparkService: SparkService, mediaFeatures: any) {
					'ngInject';
					if(mediaFeatures.enableSpark){
						return SparkService.loadCategorySubscriptions();
					}
				},

				loadAccountLicense(accountLicense: AccountLicenseService) {
					'ngInject';
					return accountLicense.reload();
				},

				teamId($transition$: Transition) {
					'ngInject';

					return $transition$.params().team;
				},

				initMediaStateConfig($transition$: Transition, MediaStateService: MediaStateService) {
					'ngInject';

					MediaStateService.initMediaStateConfig($transition$.params().config);
				},

				teamBranding($q: IQService, $state: StateService, TeamListingsService: TeamListingsService, teamId: string, MediaStateService: MediaStateService) {
					'ngInject';

					if (!teamId) {
						return;
					}

					return TeamListingsService.loadTeam(teamId)
						//If Team Admin who is not account or media admin removes themself from team, they get 401.
						.catch(err => err.status === 401 ? {} : $q.reject(err))
						.then(response => response.isTeamMember ? response :
							MediaStateService.redirectToAllTeams ?
								redirectToAllTeamsState($state) :
								$q.reject({ status: 401 })
						);
				},

				forceMediaView() {
					return null; //deliberately empty. may be overriden in child states
				},

				searchParams() {
					return {};
				},

				mediaInit(MediaStateService: MediaStateService, SearchFilterState: SearchFilterStateService, StateChangeStatus: StateChangeStatus, teamId: string) {
					'ngInject';

					if (!StateChangeStatus.isStateReloading) {
						SearchFilterState.initialize();
						MediaStateService.setIsSortDesc(false);
						MediaStateService.setSortField();
						MediaStateService.setViewMode(MediaViewMode.TILES);
					}
				}
			},

			onExit(SearchFilterState: SearchFilterStateService, StateChangeStatus: StateChangeStatus) {
				'ngInject';

				if (!StateChangeStatus.isStateReloading) {
					SearchFilterState.clear(false);
				}
			}
		} as IVBrickStateDeclaration)

		.state('portal.media.dummy', {
			url: '/dummy',
			allowGuestAccess: true
		} as IVBrickStateDeclaration)

		.state('portal.media.uploads', {
			url: '/uploads',
			views: VIDEO_SEARCH_VIEWS,
			scrollToTop: true,
			onEnter(MediaStateService: MediaStateService, SearchFilterState: SearchFilterStateService, StateChangeStatus: StateChangeStatus, UserContext: UserContextService) {
				'ngInject';

				SearchFilterState.filters.uploaderUserId.overrideValue([{ id: UserContext.getUser().id }]);

				if (!StateChangeStatus.isStateReloading) {
					MediaStateService.setIsSortDesc(true);
					MediaStateService.setSortField('whenUploaded');
					MediaStateService.showPendingButton = false;
				}

			},
			onExit(SearchFilterState: SearchFilterStateService, $transition$: Transition) {
				'ngInject';

				if (!$transition$.params().keepFilterOverrides) {
					SearchFilterState.filters.uploaderUserId.clearValueOverride();
				}
			}
		} as IVBrickStateDeclaration)

		.state(MEDIA_STATE_ALL, {
			url: '/all',
			views: VIDEO_SEARCH_VIEWS,
			scrollToTop: true,
			params: {
				viewMode: { value: null, squash: true },
				sortField: { value: null, squash: true }
			},
			resolve: {
				init(MediaStateService: MediaStateService,
					StateChangeStatus: StateChangeStatus,
					mediaInit: any, //mediaInit here to enforce resolve sequencing
					sortField: string,
					teamId: string,
					mediaFeatures: any,
					$transition$: Transition
				) {
					'ngInject';

					const isReloading = StateChangeStatus.isStateReloading && teamId === $transition$.params('from').team;
					if(isReloading) {
						return;
					}

					MediaStateService.setSortField(sortField ?? (teamId ? SortWhenUploaded : mediaFeatures.defaultVideoSortOrder));
					MediaStateService.setDefaultSortDirection();
				},

				viewMode($transition$: Transition) {
					'ngInject';

					return $transition$.params().viewMode;
				},
				sortField($transition$: Transition) {
					'ngInject';

					return $transition$.params().sortField;
				}
			},

			onEnter(MediaStateService: MediaStateService, SearchFilterState: SearchFilterStateService, teamId: string, viewMode: MediaViewMode) {
				'ngInject';

				if (teamId) {
					SearchFilterState.filters.teamIds.overrideValue([{ teamId }], true);
				}

				if (viewMode) {
					MediaStateService.setViewMode(viewMode);
				}
			},

			onExit(SearchFilterState: SearchFilterStateService, $transition$: Transition) {
				'ngInject';

				if (!$transition$.params().keepFilterOverrides) {
					SearchFilterState.filters.teamIds.clearValueOverride();
				}
			}
		} as IVBrickStateDeclaration)

		.state('portal.media.search', {
			url: '/search?{q:any}',
			views: VIDEO_SEARCH_VIEWS,
			scrollToTop: true,
			allowGuestAccess: true,
			resolve: {
				init(MediaStateService: MediaStateService, SearchFilterState: SearchFilterStateService, StateChangeStatus: StateChangeStatus, mediaInit: any) { //mediaInit here to enforce resolve sequencing
					'ngInject';

					const query: string = StateChangeStatus.transition.toParams.q;
					const isNewQuery: boolean = lastSearchQuery !== query;
					MediaStateService.showSortRecommended = false;

					if (!StateChangeStatus.isStateReloading || isNewQuery) {
						MediaStateService.reset();
						MediaStateService.setViewMode(MediaViewMode.TABLE);
						MediaStateService.setIsSortDesc(true);
						MediaStateService.setSortField(SearchConstants.defaultSortField);
						SearchFilterState.clear(false);

						lastSearchQuery = query;
					}
				},

				searchParams($transition$: Transition) {
					'ngInject';

					return { query: $transition$.params().q };
				}
			},

			onExit(MediaStateService: MediaStateService, StateChangeStatus: StateChangeStatus) {
				'ngInject';

				MediaStateService.showSortRecommended = true;
				if (!StateChangeStatus.isStateReloading) {
					lastSearchQuery = null;
				}
			}

		} as IVBrickStateDeclaration)

		.state(MEDIA_STATE_BROWSE, {
			url: '/browse',
			views: VIDEO_SEARCH_VIEWS,
			allowGuestAccess: true,
			scrollToTop: true,
			onEnter(MediaStateService: MediaStateService, mediaFeatures: any) {
				'ngInject';

				MediaStateService.setIsBulkEditDisabled(true);

				if (mediaFeatures.enableCategories) {
					MediaStateService.setIsFilteringDisabled(true);
					MediaStateService.setIsSortingDisabled(true);
				}
			},
			onExit(MediaStateService: MediaStateService) {
				'ngInject';

				MediaStateService.setIsBulkEditDisabled(false);
				MediaStateService.setIsSortingDisabled(false);
				MediaStateService.setIsFilteringDisabled(false);
			},
			resolve: {
				categoryRoot(mediaFeatures: any) {
					'ngInject';

					return mediaFeatures.enableCategories;
				},

				searchParams: () => ({
					hideNavbar: true
				}),

				desc($transition$: Transition) {
					'ngInject';

					return $transition$.params().desc;
				},

				sort($transition$: Transition) {
					'ngInject';

					return $transition$.params().sort;
				}
			}
		} as IVBrickStateDeclaration)

		.state('portal.media.pending-videos', {
			url: '/pending',
			views: VIDEO_SEARCH_VIEWS,
			scrollToTop: true,
			resolve: {
				templatesCheck($q: IQService, approverProcessTemplates: ApproverTemplateInfo[]) {
					'ngInject';

					if (!approverProcessTemplates.length) {
						return $q.reject({ status: 401 });
					}
				}
			},

			onEnter(MediaStateService: MediaStateService, SearchFilterState: SearchFilterStateService, approverProcessTemplates: ApproverTemplateInfo[]) {
				'ngInject';

				SearchFilterState.filters.approvalStatus.overrideValue(ApprovalStatus.PENDING_APPROVAL);

				SearchFilterState.filters.stepId.overrideValue(
					approverProcessTemplates.map(template => template.stepId)
				);

				MediaStateService.showSortRecommended = false;

				if(MediaStateService.getSortField() === SortRecommended) {
					MediaStateService.setSortField(SortWhenUploaded);
					MediaStateService.setIsSortDesc(true);
				}
			},

			onExit(MediaStateService: MediaStateService, SearchFilterState: SearchFilterStateService, $transition$: Transition) {
				'ngInject';

				MediaStateService.showSortRecommended = true;

				if (!$transition$.params().keepFilterOverrides) {
					SearchFilterState.filters.approvalStatus.clearValueOverride();
					SearchFilterState.filters.stepId.clearValueOverride();
				}
			}
		} as IVBrickStateDeclaration)

		.state('portal.media.category-detail', {
			url: '/videos/category/:categoryId',
			allowGuestAccess: true,
			views: VIDEO_SEARCH_VIEWS,
			scrollToTop: true,
			resolve: {
				categoryCheck(mediaFeatures: any, $q: IQService) {
					'ngInject';

					return !mediaFeatures.enableCategories && $q.reject({ status: 401 });
				},

				categoryId($transition$: Transition) {
					'ngInject';
					return $transition$.params().categoryId;
				},

				searchParams(categoryId: string, CategoryService: CategoryService) {
					'ngInject';

					if (categoryId === UNCATEGORIZED) {
						return { isUncategorized: true };
					}

					return CategoryService
						.getCategoryContent(categoryId)
						.then(categoryContent => ({
							categoryId,
							categoryContent,
							isUncategorized: false
						}));
				}
			},
			onEnter(SearchFilterState: SearchFilterStateService, StateChangeStatus: StateChangeStatus, MediaStateService: MediaStateService, searchParams: any) {
				'ngInject';

				SearchFilterState.filters.categoryIds.overrideValue([
					searchParams.isUncategorized ?
						UNCATEGORIZED :
						searchParams.categoryId
				]);

				if (!StateChangeStatus.isStateReloading) {
					MediaStateService.setIsSortDesc(true);
					MediaStateService.setSortField(SortWhenUploaded);
				}
			},

			onExit(SearchFilterState: SearchFilterStateService, $transition$: Transition) {
				'ngInject';

				if (!$transition$.params().keepFilterOverrides) {
					SearchFilterState.filters.categoryIds.clearValueOverride();
				}
			}
		} as IVBrickStateDeclaration)

		.state('portal.media.expirations', {
			url: '/expirations',
			views: VIDEO_SEARCH_VIEWS,
			scrollToTop: true,

			onEnter(MediaStateService: MediaStateService, StateChangeStatus: StateChangeStatus, SearchFilterState: SearchFilterStateService) {
				'ngInject';

				MediaStateService.showSortRecommended = false;
				if (!StateChangeStatus.isStateReloading) {
					const [todayIsoString] = new Date().toISOString().split('T');

					MediaStateService.reset();
					MediaStateService.setIsSortDesc(false);
					MediaStateService.setSortField('expiryDate');
					MediaStateService.setColumns({
						'title.sort': 0,
						'uploaderName.sort': 1,
						'deleteOnExpiration': 2,
						'expiryDate': 3
					});

					SearchFilterState.filters.expiryDateExists.overrideValue({
						from: todayIsoString,
						to: SearchConstants.maxDate
					});
				}
			},

			onExit(MediaStateService: MediaStateService, StateChangeStatus: StateChangeStatus, SearchFilterState: SearchFilterStateService) {
				'ngInject';

				MediaStateService.showSortRecommended = true;
				if (!StateChangeStatus.isStateReloading && StateChangeStatus.transition.toState.name !== 'portal.media.edit') {
					MediaStateService.resetColumns();
					MediaStateService.setIsViewModeDisabled(false);
					MediaStateService.setSortField('title.sort');

					SearchFilterState.filters.expiryDateExists.clearValueOverride();
				}
			}
		} as IVBrickStateDeclaration);
}
