import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { formatTimespanShort } from 'rev-shared/date/DateFormatters';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { ACCOUNT_DETAIL_SERVICE_TOKEN, AccountModel } from './AccountDetail.Provider';
import { IAutoIngestHours } from './IAutoIngestHours';
import { UserContextService } from './UserContext.Service';

interface IAccountDetailResponse {
	aiHoursBuckets: any[];
	viewingHoursBuckets: any[];
}

@Injectable({
	providedIn: 'root'
})
export class UserAccountService {
	public currentAdminAccount: AccountModel = {} as AccountModel;
	public fullAccountDetails: {[key: string]: AccountModel} = {};
	public readonly RouteScope = 'Admin.Accounts';

	constructor(
		@Inject(ACCOUNT_DETAIL_SERVICE_TOKEN) public AccountDetail: any,
		private DateParsers: DateParsersService,
		private UserContext: UserContextService,
		private http: HttpClient
	) {}

	/**
	 * Return the user's account ID or the admin account ID if one is currently being administered
	 */
	public get workingAccountId() {
		return this.currentAdminAccount.id || this.UserContext.getAccount().id;
	}

	/**
		Set currentAdminAccount using an existing account in the hash
	**/
	public enterAccountAdmin(accountId: string): void {
		const accountDetail = this.fullAccountDetails[accountId];
		if (!accountDetail) {
			throw new Error('AccountDetail for ' + accountId + ' must be loaded');
		}

		this.currentAdminAccount = this.fullAccountDetails[accountId];
	}

	/**
		Clear currentAdminAccount
	**/
	public exitAccountAdmin(): void {
		this.currentAdminAccount = {} as AccountModel;
	}

	/**
		Retrieve an account by ID from the server and push it into the accounts hash.
	**/
	public fetchAccountDetail(accountId: string): Promise<any> {
		return lastValueFrom(this.http.get<IAccountDetailResponse>(`/network/accounts/${accountId}`))
			.then(account => this.loadAccount(account));
	}

	public fetchAdminAccountDetail(accountId: string): Promise<any> {
		return lastValueFrom(this.http.get<IAccountDetailResponse>(`/network/accounts/${accountId}/admin`))
			.then(account => this.loadAccount(account));
	}

	private loadAccount(account: any): any {
		this.readLicenseBuckets(account.viewingHoursBuckets);
		this.readLicenseBuckets(account.aiHoursBuckets);
		this.pushAccountDetail(account);

		return account;
	}

	public fetchParentAutoIngestHours(accountId: string): Promise<IAutoIngestHours> {
		return lastValueFrom(this.http.get(`/network/accounts/${accountId}/parent-auto-ingest-hours`));
	}

	private readLicenseBuckets(buckets: any[]): void {
		if (!buckets) {
			return;
		}

		buckets.forEach(bucket =>
		{
			bucket.allocated = bucket.allocated / 60;
			bucket.used = formatTimespanShort(bucket.used * 60 * 1000, true);
			bucket.expiresOn = this.DateParsers.parseUTCDate(bucket.expiresOn);
		});
	}

	/**
		Set or update the account in the hash
	**/
	public pushAccountDetail(cfg: any): void {
		const data = {
			id: cfg.id || cfg.accountId,
			...cfg,
		};
		delete data.accountId;

		const account = this.fullAccountDetails[data.id];
		if (account) {
			account.update(data);
		} else {
			this.fullAccountDetails[data.id] = new this.AccountDetail(data);
		}

		if (this.fullAccountDetails[data.parentAccountId]) {
			this.pushChildAccount(data);
		}
	}


	/**
		Set or update the child account in the parent account's children array
	**/
	public pushChildAccount(cfg: any): void {
		const data = {
			id: cfg.id || cfg.accountId,
			...cfg
		};
		delete data.accountId;

		const account = this.fullAccountDetails[data.parentAccountId];
		if(account){
			account.pushChildAccount(data);
		}
	}
}
