import { Injectable } from '@angular/core';
import { SearchConstants } from './SearchConstants';
import { SearchService, IAccessEntitySearchHits } from './Search.Service';
import { AuthenticationSourceType } from 'rev-shared/security/AuthenticationSourceType';

const infiniteScrollPageSize = 25;

export interface ISearchResult {
	items: any[];
	count: number;
}

export interface IAccessEntityOpts {
	accountId: string;

	users?: boolean;
	groups?: boolean;
	teams?: boolean;
	sourceType?: AuthenticationSourceType;
	showEditAccessInput?: boolean;
	getTeamIds?: () => string[];
	getEntityIds?: () => string[];

	//Optional search api. If not defined, default search api will be used
	loadQueryPage?: (query: string) => Promise<ISearchResult>;
	mapQueryPage?: (page: ISearchResult) => ISearchResult;
	pauseSearch?: () => boolean;
	disableAssignedInfiniteScroll?: boolean;
	onAssignedInfiniteScroll?: () => Promise<any[]> | undefined; // todo: this should not be used long term. For now needed because common impl does not load data in the exact order its scrolled in the ui.
}

@Injectable()
export class InsightSearchHelper {

	constructor(
		private SearchService: SearchService
	){ }

	public buildAccessEntityInsightOptions(opts: IAccessEntityOpts): any {

		return {
			fieldDefs: {
				identifier: 'id',
				display: 'name',
				subDisplay: 'username',
				dataType: 'type',
				orderBy: 'name',
				ascending: true,
				edgeHilight: true,
				profileImageUri: 'profileImageUri'
			},
			dataTypes: {
				User: 'user',
				Team: 'team',
				Group: 'group'
			},
			loadQueryPage: (query: string) => {
				if (!query.length) {
					return;
				}
				const result = opts.loadQueryPage ?
					opts.loadQueryPage(query) :
					this.loadAccessEntityQueryPage(opts, query);

				return opts.mapQueryPage ?
					result.then(opts.mapQueryPage) :
					result;
			},
			onAssignedInfiniteScroll: this.getAccessEntityAssignedInfiniteScrollHandler(opts),
			showEditAccessInput: opts.showEditAccessInput
		};
	}

	private loadAccessEntityQueryPage(opts: IAccessEntityOpts, query: string): Promise<ISearchResult> | undefined {
		if(query.length < SearchConstants.minAccessEntitySearchQueryLength ||
			opts.pauseSearch && opts.pauseSearch()){
			return;
		}

		return this.SearchService
			.queryAccessEntities({
				accountId: opts.accountId,
				query,
				type: [
					opts.users && SearchConstants.accessEntityTypes.user,
					opts.groups && SearchConstants.accessEntityTypes.group,
					opts.teams && SearchConstants.accessEntityTypes.team
				].filter(Boolean),
				sourceType: opts.sourceType,
				sortField: SearchConstants.nameSortField,
				sortAscending: true,
				teamIds: opts.getTeamIds ? opts.getTeamIds() : undefined,
				count: SearchConstants.accessEntityPageSize,
				noScroll: true
			})
			.then(result => {
				return {
					items: result.accessEntities,
					count: result.totalHits
				};
			});
	}

	private getAccessEntityAssignedInfiniteScrollHandler(opts: IAccessEntityOpts): () => Promise<any> {
		if(opts.disableAssignedInfiniteScroll) {
			return;
		}
		if (opts.onAssignedInfiniteScroll) {
			return opts.onAssignedInfiniteScroll;
		}

		let selectedEntitiesLoaded = false;
		let offset = 0;

		return () => {
			const entityIds = opts.getEntityIds ? opts.getEntityIds() : [];
			if (selectedEntitiesLoaded || !entityIds.length) {
				return Promise.resolve([]);
			}
			const entitiesToLoad = entityIds.slice(offset, offset + infiniteScrollPageSize);

			return this.getAccessEntities(opts.accountId, entitiesToLoad)
				.then(({ accessEntities }) => {
					offset += infiniteScrollPageSize;
					selectedEntitiesLoaded = offset >= entityIds.length;

					return {
						results: accessEntities,
						complete: selectedEntitiesLoaded
					};
				});
		};
	}

	public getAccessEntities(accountId: string, ids: string[]): Promise<IAccessEntitySearchHits> {
		return this.SearchService
			.queryAccessEntities({
				accountId,
				ids,
				type: [
					SearchConstants.accessEntityTypes.user,
					SearchConstants.accessEntityTypes.group,
					SearchConstants.accessEntityTypes.team
				],
				sortField: SearchConstants.nameSortField,
				sortAscending: true,
				count: ids.length,
				noScroll: true
			});
	}
}
