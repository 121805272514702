import {
	IMessageContext,
	IMessageDispatcher,
	MessageSource,
	MessageType,
	VbrickLoggingClient
} from '@vbrick/vbrick-logging-client/src';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';

enum UserType {
	GUEST = 'Guest',
	INTERNAL = 'Internal'
}

export class RevLoggingClientBase extends VbrickLoggingClient {
	protected UserContext: UserContextService;

	constructor(
		UserContext: UserContextService,
		UserLocalIPService: UserLocalIPService,
		resourceId: string,
		type: string,
		isAnalytics: boolean,
		messageDispatcher: IMessageDispatcher
	) {
		const messageContext: IMessageContext = {
			accountId: BootstrapContext.account.id,
			hostname: BootstrapContext.account.hostname,
			ipAddress: UserLocalIPService.currentUserIp,
			resourceId,
			source: MessageSource.REV,
			subType: type,
			type: isAnalytics ? MessageType.ANALYTICS : MessageType.LOGS
		};

		super(messageContext, messageDispatcher);

		this.UserContext = UserContext;
	}

	public log(message: any): any {
		const user = this.UserContext.getUser();

		super.log({
			...message,
			userId: user.id
		});
	}

	protected getUserType(): UserType {
		return this.UserContext.isUserAuthenticated() ?
			UserType.INTERNAL :
			UserType.GUEST;
	}
}
