import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';

import { WebexTeamsAngularComponent } from './WebexTeamsAngular.Component';

@NgModule({
	declarations: [
		WebexTeamsAngularComponent
	],
	exports: [
		WebexTeamsAngularComponent
	],
	imports: [
		CommonModule,
		DialogAngularModule,
		FormsModule,
		SpinnerAngularModule,
		TextInputAngularModule,
		TranslateModule,
		TypeaheadModule
	]
})

export class WebexTeamsAngularModule {
	constructor(dialog: DialogRegistrationService) {
		dialog.register('sipAddressErrorDialog', {
			component: VbConfirmationDialogComponent
		});
	}
}
