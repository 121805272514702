import { APP_BOOTSTRAP_LISTENER, NgModuleRef } from '@angular/core';

/**
 * When using ngDoBootstrap() instead of a bootstrap component,
 * Angular for whatever reason does not trigger the APP_BOOTSTRAP_LISTENERs,
 * so we do it manually.
 *
 * This is of particular importance for us as certain module init/bootstrapping
 * depends on UI Router services which in turn tries to access the AngularJS $injector.
 * It will fail if you don't get the sequencing right and try to reference them too soon.
 */
export function triggerAppBootstrapListeners(platformRef: NgModuleRef<any>): NgModuleRef<any> {
	const listeners: any[] = platformRef.injector.get(APP_BOOTSTRAP_LISTENER);

	listeners.forEach(listener => listener());

	return platformRef;
}
