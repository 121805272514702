import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';

import { IAnalyticsSettings } from './IAnalyticsSettings';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {
	private realtimeKpiUpdateIntervalInSecs: number;
	private realtimeTableUpdateIntervalInSecs: number;

	constructor(
		private dateParsers: DateParsersService,
		private http: HttpClient
	) {}

	public getSettings(): Promise<IAnalyticsSettings> {
		return this.http.get<IAnalyticsSettings>(`/network/analytics-settings`)
			.toPromise()
			.then(result => {
				[
					'minHeartbeat',
					'videoHeartbeat',
					'webcastHeartbeat',
					'webcastRealtimeKpiUpdateInterval',
					'webcastRealtimeTableUpdateInterval',
					'webcastRealtimeAggregationInterval',
					'webcastRealtimeAggregationTimeout'
				].forEach(field => result[field] = this.dateParsers.parseTimespanToSecs(result[field]));

				return result;
			})
			.then(result => {
				this.realtimeKpiUpdateIntervalInSecs = result.webcastRealtimeKpiUpdateInterval;
				this.realtimeTableUpdateIntervalInSecs = result.webcastRealtimeTableUpdateInterval;
				return result;
			});
	}

	public get kpiUpdateIntervalInSecs(): number {
		return this.realtimeKpiUpdateIntervalInSecs;
	}

	public get tableUpdateIntervalInSecs(): number {
		return this.realtimeTableUpdateIntervalInSecs;
	}

	public clearEventScrollId = ({ webcastId, runNumber }: any, scrollId: string) => {
		this.http.delete(`/analytics/events/${webcastId}/${runNumber}/scroll`, { params: { scrollId } })
			.toPromise();
	};
}
