import { IHttpProvider, IRequestConfig, auto } from 'angular';

import { SignalRHubsConnection, SignalRHubsConnectionState } from 'rev-shared/push/SignalRHubsConnection';

import { UserContextService } from './UserContext.Service';
import { SessionService } from './Session.Service';

export function SessionConfig($httpProvider: IHttpProvider): void {
	// keep session alive if http requests are going through;
	$httpProvider.interceptors.push(($injector: auto.IInjectorService) => {
		'ngInject';

		let UserContext: UserContextService;
		let Session: SessionService;
		let isReady: boolean = false;

		// injecting UserContext would create a circular dependency -
		// UserContext depends on http, and http depends on the httpProvider interceptors.
		return {
			request(config: IRequestConfig): IRequestConfig {
				tryExtendSessionTimeout();

				return config;
			}
		};

		function tryExtendSessionTimeout(): void {
			try {
				if(!isReady) {
					const SignalRHubsConnection: SignalRHubsConnection = $injector.get<SignalRHubsConnection>('SignalRHubsConnection');

					if (SignalRHubsConnection.getConnectionStatus() !== SignalRHubsConnectionState.Connected) {
						return;
					}

					UserContext = $injector.get<UserContextService>('UserContext');
					Session = $injector.get<SessionService>('Session');
					isReady = true;
				}

				if (UserContext.isSessionStable()) {
					Session.tryExtendTimeout();
				}
			} catch(e) {
				// deliberately empty
			}
		}

	});
}
