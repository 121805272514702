import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Cache, CacheFactory } from 'cachefactory';

import { ITimeoutPromise } from 'rev-shared/ts-utils/ITimeoutPromise';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { LicenseType } from 'rev-shared/security/LicenseType';
import { SecondMs } from 'rev-shared/date/Time.Constant';

import { IAccountLicense } from './IAccountLicense';
import { UserContextService } from './UserContext.Service';

type TLicensePromise = ITimeoutPromise<IAccountLicense>;

@Injectable({
	providedIn: 'root'
})
export class LicenseService {
	private readonly expireTimeMs: number = 60 * SecondMs;
	private readonly licenseFileUploadUrl: string = '/uploads/license-file';

	private licenseCache: Cache;

	constructor(
		private UserContext: UserContextService,
		private http: HttpClient
	) {
		this.init();
	}

	public getLicense(accountId: string, forceReload: boolean = false): Promise<IAccountLicense> {
		let promise: TLicensePromise = this.licenseCache.get(accountId);

		if (promise) {
			if (!forceReload) {
				return promise;
			}

			window.clearTimeout(promise.timeout);
		}

		promise = this.http.get<IAccountLicense>(`/network/accounts/${accountId}/license`)
			.toPromise()
			.then(license => ({
				...license,
				isLicenseTypeByUsers: license.licenseType === LicenseType.BY_USERS,
				isLicenseTypeByHours: license.licenseType === LicenseType.BY_HOURS
			})) as TLicensePromise;

		// caching the promise prevents multiple rapid calls from loading data each time
		this.licenseCache.put(accountId, promise);

		promise.timeout = window.setTimeout(() => this.licenseCache.remove(accountId), this.expireTimeMs);

		return promise;
	}

	public getUsersLicense(accountId: string): Promise<any> {
		return lastValueFrom(this.http.get(`/network/accounts/${accountId}/users-license`));
	}

	public fetchLicenseHoursValidity(accountId: string): Promise<{viewingHoursValid: boolean, aiHoursValid: boolean}> {
		return lastValueFrom(this.http.get<{ viewingHoursValid: boolean, aiHoursValid: boolean }>(`/network/accounts/${accountId}/license-hours-valid`));

	}

	public uploadLicenseFile(file: any): Promise<any> {
		file.setOptions({
			url: this.licenseFileUploadUrl
		});

		return file.submit();
	}

	private init(): void {
		this.licenseCache = new CacheFactory().createCache('license', { capacity: 3 });

		this.UserContext.userIdChanged$.subscribe(() => this.licenseCache.removeAll());
	}
}
