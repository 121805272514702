import { NgModule } from '@vbrick/angular-ts-decorators';
import AngularUiRouter from '@uirouter/angularjs';
import VBrickDateModule from 'rev-shared/date/Date.Module';
import DirectivesModule from './directives';

import { AngularJsLazyLoadHelperService } from './AngularJsLazyLoadHelper.Service';
import { AppUtilService } from './AppUtil.Service';
import { BrowserFeatureService } from 'rev-shared/util/BrowserFeature.Service';
import { IPAddress } from './IPAddress.Service';
import { LanguageCodes } from './LanguageCodes.Service';
import { NgRepeatParser } from './NgRepeatParser.Service';
import { StateChangeStatus } from './StateChangeStatus.Service';
import { TranslationsRequestService } from './TranslationsRequest.Service';
import { UtilService } from './Util.Service';
import { downgradeInjectables } from './AngularHybridUtils';

@NgModule({
	id: 'VBrick.Util',
	imports: [
		AngularUiRouter,
		DirectivesModule,
		VBrickDateModule
	],

	providers: [
		AngularJsLazyLoadHelperService,
		IPAddress,
		NgRepeatParser,
		UtilService
	]
})
export default class UtilModule {
	public static run(
		StateChangeStatus: StateChangeStatus //Force service to initialize
	): void {
		'ngInject';
	}
}

downgradeInjectables(UtilModule, [
	{
		name: 'AppUtil',
		injectable: AppUtilService
	},
	{
		name: 'StateChangeStatus',
		injectable: StateChangeStatus
	},
	{
		name: 'TranslationsRequest',
		injectable: TranslationsRequestService
	},
	{
		name: 'LanguageCodes',
		injectable: LanguageCodes,
	},
	{
		name: 'BrowserFeature',
		injectable: BrowserFeatureService
	}
]);
