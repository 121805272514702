import { NgModule } from '@angular/core';
import { InlineEditAngularModule } from 'rev-shared/ui/inlineEdit/InlineEdit.AngularModule';

@NgModule({
	imports: [
		InlineEditAngularModule
	]
})
export class VideoSupplementalFilesAngularModule {

}
