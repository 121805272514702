import { Directive } from '@vbrick/angular-ts-decorators';
import { VbAuthorizationBaseDirective, vbAuthorizationBaseDirectiveConfig } from './VbAuthorizationBase.Directive';
import { getStateHierarchy } from './VbAuthorizeStateAngular.Directive';

/**
 * vb-authorize-state
 * Input is a state name supplied as a value to vb-authorize-state, the ui-sref on the same element.
 * Functions like ng-if where the condition is if you are authorized for each state in the hierarchy of the given state name.
 *
 * To provide fallback content to users who do not have access to the state, add the `vb-unauthorized-fallback` attribute.
 */
@Directive(Object.assign({
	selector: '[vb-authorize-state]'
}, vbAuthorizationBaseDirectiveConfig))
export class VbAuthorizeStateDirective extends VbAuthorizationBaseDirective {
	public static get $inject() {
		return VbAuthorizationBaseDirective.$inject;
	}

	public static set $inject(value: Readonly<string[]>) {
		VbAuthorizationBaseDirective.$inject = value;
	}

	public isAuthorized(): boolean {
		const srefState: string = this.findSrefState();

		return this.checkStateHierarchyAuthorization(srefState);
	}

	private checkStateHierarchyAuthorization(stateName: string): boolean {
		if (!stateName) {
			throw new Error('vb-authorize-state directive missing value/nested ui-sref');
		}

		const stateHeirarchy: string[] = getStateHierarchy(stateName);

		return stateHeirarchy.reduce((ok, state) => ok && this.SecurityContext.allowStateChangeSync(state), true);
	}

	private findSrefState(): string {
		const sref: string = this.$attrs.vbAuthorizeState || this.$attrs.uiSref;

		if (sref) {
			const i: number = sref.indexOf('(');

			return (i < 0) ? sref : sref.substring(0, i);
		}

		return null;
	}
}
