import { Injectable } from '@vbrick/angular-ts-decorators';
import { resource, IPromise, ILogService } from 'angular';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { ITemplateInfo } from './WebcastTemplates.Contract';

@Injectable('WebcastTemplatesService')
export class WebcastTemplatesService {
	private webcastTemplatesResource = this.$resource('/scheduled-events/accounts/:accountId/templates');
	private defaultTemplateResource = this.$resource('/scheduled-events/templates/default');
	private templateInfoResource = this.$resource('/scheduled-events/templates/:id');

	constructor(
		private $log: ILogService,
		private $resource: resource.IResourceService,
		private DateParsers: DateParsersService,
		private UserContext: UserContextService
	) {
		'ngInject';
	}

	public getTemplates(accountId?: string): IPromise<ITemplateInfo[]> {
		accountId = accountId || this.UserContext.getAccount().id;

		return this.webcastTemplatesResource.get({ accountId }).$promise
			.then(result => result.templates);
	}

	public getDefaultTemplate(): IPromise<ITemplateInfo> {
		return this.defaultTemplateResource.get().$promise
			.catch(e => {
				this.$log.error('Error loading default template', e);
			})
			.then(template => {
				if(template){
					return this.readTemplateDetail(template);
				}
			});
	}

	public getTemplate(templateId: string): IPromise<ITemplateInfo> {
		return this.templateInfoResource.get({ id: templateId }).$promise
			.then(template => {
				return this.readTemplateDetail(template);
			});
	}

	private readTemplateDetail(template: ITemplateInfo): ITemplateInfo {
		const data = template.metadata as any;

		if(data?.preProduction) {
			data.preProduction.durationMs = this.DateParsers.parseTimespan(data.preProduction.duration);
		}

		return template;
	}
}
