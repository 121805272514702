import {
	Directive,
	Input,
	OnChanges
} from '@vbrick/angular-ts-decorators';
import { INgModelController } from 'angular';

@Directive({
	selector: 'vb-validation-conditions',
	require: {
		ngModel: 'ngModel'
	},
	bindToController: true
})
export class VbValidationConditionsDirective implements OnChanges {
	@Input() public vbValidationConditions: { readonly [errorKey: string]: boolean };
	public ngModel: INgModelController;

	public ngOnChanges(): void {
		const validFlags = this.vbValidationConditions;
		if(!validFlags) {
			return;
		}
		Object.keys(validFlags).forEach(errorKey => {
			const isValid = validFlags[errorKey];
			this.ngModel.$setValidity(errorKey, isValid);
			if(!isValid && this.ngModel.$pristine) {
				this.ngModel.$setDirty();
			}
		});
	}
}
