<video-reports-kpis [data]="videoSummary" [isLive]="video?.isLive">
</video-reports-kpis>
<div layout="row">
	<vb-ui-report-cell [ngClass]="styles.mediumChartWrapper" [header]="'Media_Videos_Views' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-line-chart [ngClass]="styles.mediumChartSize" [dateField]="'key'" [data]="videoSummary.totalViewsByDay" [integerValueAxis]="true" [valueField]="'value'">
		</vb-ui-line-chart>
	</vb-ui-report-cell>
	<vb-ui-report-cell [ngClass]="[
			styles.mediumChartWrapper,
			canShowViewerDropoffChart ? '' : 'position-relative'
		]" [header]="'Reports_ViewerDropOff' | translate" [noHeaderBottomBorder]="true" [size]="'large'">
		<vb-ui-duration-line-chart *ngIf="canShowViewerDropoffChart; else notApplicable" [ngClass]="styles.mediumChartSize" [durationField]="'key'" [data]="videoSummary.sessions" [integerValueAxis]="true" [valueField]="'value'">
		</vb-ui-duration-line-chart>
		<ng-template #notApplicable>
			<h3 class="centered-text-block-content">
				{{ 'AnalysisChart_NotApplicableLabel' | translate }}
			</h3>
		</ng-template>
	</vb-ui-report-cell>
</div>
