<vb-title [title]="('Title_MediaSettings' | translate) + ' - ' + ('Title_Categories' | translate)">
</vb-title>

<vb-loading-spinner [block]="true" [size]="'large'" *ngIf="status.saving">
	{{ 'Processing' | translate }}
</vb-loading-spinner>

<div class="row" [hidden]="status.saving">
	<div class="col-xs-12">
		<form class="form-horizontal" #categoryForm="ngForm" (submit)="saveCategory()">
			<div class="panel panel-default">
				<header [ngClass]="styles.panelHeading" layout="row" layout-wrap="false">
					<h4 flex="fill">{{category.name}}</h4>
					<div>
						<button type="button" class="margin-right-5" [disabled]="!category.id" vbUiBtnSecondary (click)="removeCategory()">
							<span class="glyphicons bin"></span>
							{{ 'Delete' | translate }}
						</button>
						<a class="margin-right-5" vbUiBtnSecondary [uiSref]="cancelSref.name" [uiParams]="cancelSref.params">
							{{ 'Cancel' | translate }}
						</a>

						<button type="submit" vbUiBtnPrimary [disabled]="!categoryForm.valid">
							{{ 'SaveCategory' | translate }}
						</button>
						<vb-confirmation-dialog [title]="'Delete' | translate" [message]="'Admin_Categories_Delete' | translate : { '0': category.name }" [cancelText]="'Cancel' | translate" [actionText]="'Delete' | translate">
						</vb-confirmation-dialog>
					</div>
				</header>
				<div class="panel-body" [ngClass]="styles.container">
					<div [ngClass]="styles.column">
						<div [ngClass]="styles.columnTitle">
							{{ 'CategoryName' | translate }}
						</div>
						<vb-ui-form-group>
							<input type="text" class="form-control input-with-icon" name="categoryName" #categoryName="ngModel" vbRequired autocomplete="new-password" [(ngModel)]="category.name" uiControl vbUiTextInput [vbClearOnChange]="'isCategoryNameInUse'" [vbMaxLength]="100" trim="blur"/>
							<div *ngIf="categoryName.touched && categoryName.invalid" class="margin-bottom-10" role="alert" vbUiErrorMessage uiValidation>
								<ng-container *ngIf="categoryName.errors.required">
									{{ 'ThisFieldIsRequired' | translate }}
								</ng-container>
								<ng-container *ngIf="categoryName.errors.isCategoryNameInUse">
									{{ 'CategoryNameInUse' | translate }}
								</ng-container>
								<ng-container *ngIf="categoryName.errors.vbMaxLength">
									{{ 'CategoryInvalidName' | translate }}
								</ng-container>
							</div>
						</vb-ui-form-group>
					</div>
					<div [ngClass]="styles.column">
						<div [ngClass]="styles.columnTitle">{{'Admin_Roles_Permissions' | translate}}</div>

						<vb-ui-form-group>
							<vb-ui-checkbox name="restricted" [(ngModel)]="category.restricted" uiControl>
								{{ 'Admin_MediaSettings_Restricted' | translate }}

								<span [ngClass]="styles.helpIcon" [tooltip]="'Category_Restricted_Tooltip' | translate " placement="right">
									<span class="glyphicons circle_question_mark"></span>
								</span>
							</vb-ui-checkbox>
						</vb-ui-form-group>

						<vb-ui-insight [hidden]="!category.restricted" name="accessEntities" [attr.aria-label]="'Admin_Roles_Permissions' | translate" [insightOptions]="accessControlInsightOptions" [(ngModel)]="category.categoryPolicyItems" [optionTemplateUrl]="'/partials/portal/access-entity-insight-option-row.html'" [assignedOptionTemplateUrl]="'/partials/admin/media-settings/category-admin-assigned-option-row.html'">
						</vb-ui-insight>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
