import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UIRouterModule } from '@uirouter/angular';

import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { InlineEditAngularModule } from 'rev-shared/ui/inlineEdit/InlineEdit.AngularModule';
import { MediaUploadBtnModule } from 'rev-shared/ui/mediaUploadBtn/MediaUploadBtn.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SharedTeamAngularModule } from 'rev-shared/team/SharedTeam.AngularModule';
import { SignInBtnModule } from 'rev-shared/ui/signInBtn/SignInBtn.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';

import { SparkAngularModule } from 'rev-portal/media/spark/Spark.AngularModule';

import { VbMediaTeamsHeaderComponent } from './VbMediaTeamsHeader.Component';
import { VbMediaToolbarComponent } from './VbMediaToolbar.Component';
import { VbSortFilterComponent } from './VbSortFilter.Component';

@NgModule({
	imports: [
		BsDropdownModule,
		CommonModule,
		DialogAngularModule,
		DateAngularModule,
		InlineEditAngularModule,
		MediaUploadBtnModule,
		SecurityAngularModule,
		SharedTeamAngularModule,
		SignInBtnModule,
		SparkAngularModule,
		TooltipModule,
		TranslateModule,
		UIRouterModule.forChild(),
		ValidationAngularModule
	],
	declarations: [
		VbSortFilterComponent,
		VbMediaTeamsHeaderComponent,
		VbMediaToolbarComponent
	],

	bootstrap: [
		VbMediaToolbarComponent
	],
	exports: [
		VbMediaToolbarComponent
	]
})
export class MediaSearchToolbarModule {}
