import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';

import { VbUiForgivingImageComponent } from './VbUiForgivingImage.Component';

const components = [VbUiForgivingImageComponent];
@NgModule({
	declarations: components,
	exports: components,
	imports: [
		ButtonsAngularModule,
		CommonModule,
		DirectivesAngularModule,
		SpinnerAngularModule
	]
})
export class ForgivingImageModule {}
