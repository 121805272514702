import { Component, Input, OnInit } from '@angular/core';
import { ResolvableLiteral, Transition, UrlService } from '@uirouter/angular';

import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserAuthenticationService } from 'rev-shared/security/UserAuthentication.Service';

@Component({
	selector: 'user-agreement',
	templateUrl: './UserAgreement.Component.html'
})
export class UserAgreementComponent implements OnInit {
	@Input() public fwdUrl: string;
	@Input() public ssoLogin: boolean;
	@Input() public userAgreementMessage: string;
	@Input() public userId: string;

	public status: Partial<{
		active: boolean;
		error: boolean;
		processing: boolean;
	}>;

	constructor(
		private LoginRedirectService: LoginRedirectService,
		private UserContext: UserContextService,
		private urlService: UrlService
	) {}

	public ngOnInit(): void {
		this.status = { active: true };
	}

	public accept(): Promise<any> {
		this.status = { processing: true };

		const loadedLanguage: string = this.UserContext.getCurrentLanguage();

		return this.UserContext.acceptUserAgreement(this.userId, this.ssoLogin)
			.then(() => {
				const newLanguage: boolean = loadedLanguage !== this.UserContext.getCurrentLanguage();

				this.LoginRedirectService.redirectToLandingPage(this.fwdUrl, newLanguage);
			})
			.catch(() => {
				this.status = { error: true };
			});
	}

	public decline(): void {
		const url: string = this.UserContext.ssoEnabled() ? '/sso-logout' : '/login';

		this.urlService.url(url);
	}
}

export const UserAgreementResolve = {
	fwdUrl($transition$: Transition): string {
		'ngInject';

		return $transition$.params().fwdUrl;
	},

	ssoLogin($transition$: Transition): boolean {
		'ngInject';

		return $transition$.params().ssoLogin;
	},

	userAgreementMessage(UserAuthenticationService: UserAuthenticationService): Promise<any> {
		'ngInject';

		return UserAuthenticationService.getUserAgreement();
	},

	userId($transition$: Transition): string {
		'ngInject';

		return $transition$.params().userId;
	}
};

export const UserAgreementNg2Resolve: ResolvableLiteral[] = [
	{
		token: 'fwdUrl',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().fwdUrl
	},
	{
		token: 'ssoLogin',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().ssoLogin
	},
	{
		token: 'userAgreementMessage',
		deps: [UserAuthenticationService],
		resolveFn: (userAuthentication: UserAuthenticationService) => userAuthentication.getUserAgreement()
	},
	{
		token: 'userId',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().userId
	}
];
