import {
	Directive,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges
} from '@vbrick/angular-ts-decorators';

import {
	IAugmentedJQuery,
	IPromise,
	ITimeoutService
} from 'angular';

import { ComponentCallback } from 'rev-shared/ts-utils/ComponentCallback';
import { ComponentCallbackEvent } from 'rev-shared/ts-utils/ComponentCallbackEvent';
import { isLegacyEdge, isIe } from 'rev-shared/util/UserAgentUtil';

const DELAY_TIME_MS = 1000;
const DEFAULT_TOTAL_DELAY_TIME_MS = 100000;

@Directive({
	selector: '[vb-image-retry]',
	bindToController: true
})
export class VbImageRetryDirective implements OnChanges, OnDestroy {
	@Input('@') public ngSrc: string;
	@Input() public totalDelayTimeMs: number = DEFAULT_TOTAL_DELAY_TIME_MS;

	@Output() public onRetriesExhausted: ComponentCallback;

	private delay: number;
	private isIgnoreNextError: boolean;
	private timeoutRef: IPromise<void>;
	private totalDelay: number;

	constructor(
		private $element: IAugmentedJQuery,
		private $timeout: ITimeoutService
	) {
		'ngInject';
	}

	public ngOnChanges(changes: SimpleChanges<this>): void {
		if (changes.ngSrc) {
			this.reset();
		}
	}

	public ngOnDestroy(): void {
		this.reset();
	}

	@HostListener('error')
	private onImageError(): void {
		if (this.isIgnoreNextError) {
			this.isIgnoreNextError = false;
			return;
		}

		if (!this.delay) {
			this.delay = 0;
			this.totalDelay = 0;
		}

		this.delay += DELAY_TIME_MS;
		this.totalDelay += this.delay;

		if (this.totalDelay <= this.totalDelayTimeMs) {
			this.timeoutRef = this.$timeout(() => this.reapplySrc(), this.delay);
		} else {
			this.retriesExhausted();
		}
	}

	@HostListener('load')
	private onImageLoad(): void {
		this.reset();
	}

	public resetAndTryAgain(): void {
		this.reset();
		this.reapplySrc();
	}

	private reapplySrc(): void {
		const src: string = this.$element.attr('src').split('#')[0];
		const hash: string = isLegacyEdge() ? // Edge has weird internal caching behavior and won't even attempt a request, so need to bust it
			'#' + Date.now() :
			'';

		this.isIgnoreNextError = isIe(); // IE11 emits an error after setting src to ''

		this.$element
			.attr('src', '') // changing the value gets it playing nice with IE/Edge
			.attr('src', src + hash);
	}

	private reset(): void {
		if (this.timeoutRef) {
			this.$timeout.cancel(this.timeoutRef);
			this.timeoutRef = null;
		}

		this.delay =
			this.totalDelay =
			0;

		this.isIgnoreNextError = false;
	}

	private retriesExhausted(): void {
		if (this.onRetriesExhausted) {
			this.onRetriesExhausted(new ComponentCallbackEvent());
		}
	}
}
