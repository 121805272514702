<div [ngClass]="{
		'video-owner': userId === video.uploaderUserId,
		'uploading': isUploading,
		'not-ready': isUploading || isProcessing,
		'processing-error': isProcessingFailed,
		'inactive': video.isActive === false,
		'pending-approval': video?.approval?.status
			&& video?.approval?.status !== approvalStatusOptions.APPROVED
			&& video?.approval?.status !== approvalStatusOptions.REJECTED,
		'rejected': video?.approval?.status === approvalStatusOptions.REJECTED
	 }" [uiParams]="{videoId: video.id, config: config }" class="tile-item" role="link" uiSref="portal.video">
	<div class="fixed-ratio-wrapper">
		<a class="fixed-ratio group">
			<video-thumbnail-status [approvalStatus]="video?.approval?.status" [spinnerSize]="'medium'" [videoIsActive]="video.isActive" [videoStatus]="video.status" [videoThumbnailUri]="video.thumbnailUri">
			</video-thumbnail-status>
			<video-text-status [approvalStatus]="video?.approval?.status" [hasMediaEditAuth]="hasMediaEditAuth || hasEditVideoAuth" [isOnLegalHold]="video.legalHold" [isPasswordProtected]="isPasswordProtected" [isUnlisted]="video.unlisted" [isVideoOwner]="userId === video.uploaderUserId" [videoIsActive]="video.isActive">
			</video-text-status>

			<img [alt]="'ThumbnailForAria' | translate : { '0':  video.title }" [src]="video.thumbnailUri||'/shared/img/default-thumbnail.png'">
		</a>
	</div>

	<div class="fixed-ratio-wrapper">
		<div class="info-container fixed-ratio group">
			<h2 class="type-bold">
				<a>
					{{video.title}}
				</a>
			</h2>

			<div *ngIf="(mediaFeatures && mediaFeatures.enableRatings && video.ratingsEnabled)" class="rating">
				<div class="rating-readonly">
					<rating [(ngModel)]="video.averageRating" [readonly]="true"></rating>
					<span [hidden]="video.ratingsCount < 1" class="ratings-count">({{video.ratingsCount | number: '1.0-0'}})</span>
				</div>
			</div>
			<div class="user-content" [innerHTML]="video.description"></div>
		</div>
	</div>

	<div class="tile-footer">
		<span class="pull-left">
			<vb-profile-picture [altTxt]="'UserProfile_ProfilePicture' | translate" [profileUrl]="video.uploaderProfileImageUri">
			</vb-profile-picture>
			{{uploaderName}}
		</span>
		<span class="pull-right">{{video.whenUploaded|date:'mediumDate'}}</span>
	</div>

	<div class="video-overlay">
		<div [hidden]="video.isLive || !video.duration" class="video-duration-overlay">{{ video.duration | vbTimespan }}</div>
		<div [hidden]="!video.isLive" class="live-badge">{{ 'Media_Live' | translate }}</div>
		<video-360-indicator *ngIf="video.is360"></video-360-indicator>
	</div>
</div>
