import { NgModule } from '@vbrick/angular-ts-decorators';

import { VbClearOnChangeDirective } from './VbClearOnChange.Directive';
import { VbColorHexDirective } from './VbColorHex.Directive';
import { VbEmailDirective } from './VbEmail.Directive';
import { VbHostnameDirective } from './VbHostname.Directive';
import { VbIntegerDirective } from './VbInteger.Directive';
import { VbIpAddressDirective } from './VbIpAddress.Directive';
import { VbLongDirective } from './VbLong.Directive';
import { VbMacAddressDirective } from './VbMacAddress.Directive';
import { VbMaxDirective } from './VbMax.Directive';
import { VbMaxLengthDirective } from './VbMaxLength.Directive';
import { VbMediumDirective } from './VbMedium.Directive';
import { VbMinDirective } from './VbMin.Directive';
import { VbMulticastIpAddressDirective } from './VbMulticastIpAddress.Directive';
import { VbPortRangeInputDirective } from './VbPortRangeInput.Directive';
import { VbShortDirective } from './VbShort.Directive';
import { VbValidationConditionsDirective } from './VbValidationConditions.Directive';
import { VbUrlDirective } from './VbUrl.Directive';
import { VbAsyncValidatorsDirective } from './VbAsyncValidator.Directive';

@NgModule({
	id: 'VBrick.Util.Directives.Form.Validation',
	declarations: [
		VbAsyncValidatorsDirective,
		VbClearOnChangeDirective,
		VbColorHexDirective,
		VbEmailDirective,
		VbHostnameDirective,
		VbIntegerDirective,
		VbIpAddressDirective,
		VbLongDirective,
		VbMacAddressDirective,
		VbMaxDirective,
		VbMaxLengthDirective,
		VbMediumDirective,
		VbMinDirective,
		VbMulticastIpAddressDirective,
		VbPortRangeInputDirective,
		VbShortDirective,
		VbValidationConditionsDirective,
		VbUrlDirective
	],
	imports: [],
	providers: []
})
export default class ValidationDirectivesModule {}
