import { NgModule } from '@vbrick/angular-ts-decorators';

import { BrandingLogoComponent } from './BrandingLogo.Component';
import { BrandingPreviewBannerComponent } from './BrandingPreviewBanner.Component';
import { BrandingPreviewButtonComponent } from './BrandingPreviewButton.Component';
import { ThemeStylesComponent } from './ThemeStyles.Component';

import { BrandingService } from './Branding.Service';
import { ThemeService } from './Theme.Service';

import { downgradeInjectables, downgradeComponents } from 'rev-shared/util/AngularHybridUtils';

@NgModule({
	id: 'VBrick.Portal.Branding'
})
export default class BrandingModule {}

downgradeInjectables(BrandingModule, [
	{
		injectable: BrandingService,
		name: 'BrandingService'
	},
	{
		injectable: ThemeService,
		name: 'ThemeService'
	}
]);

downgradeComponents(BrandingModule, [
	{
		component: BrandingPreviewBannerComponent,
		name: 'brandingPreviewBanner'
	},
	{
		component: BrandingPreviewButtonComponent,
		name: 'brandingPreviewButton'
	},
	{
		component: BrandingLogoComponent,
		name: 'brandingLogo'
	},
	{
		component: ThemeStylesComponent,
		name: 'vbThemeStyles'
	}
]);
