import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IPasswordRules, PasswordRulesChecker } from './PasswordRulesChecker';

@Injectable({
	providedIn: 'root'
})
export class PasswordValidationService {
	constructor(
		private http: HttpClient
	) {}

	public getPasswordRulesChecker(accountId: string): Promise<PasswordRulesChecker> {
		return this.http.get<IPasswordRules>(`/network/accounts/${accountId}/password-rules`)
			.toPromise()
			.then(rules => new PasswordRulesChecker(rules));
	}
}
