<div [hidden]="status.sparkAuthenticationRequired || !rooms.length">
	<input [attr.aria-label]="'Media_Videos_Webex_Teams_Placeholder' | translate " autocomplete="off" class="form-control" name="roomId" placeholder="{{ 'Media_Videos_Webex_Teams_Placeholder' | translate }}" type="text" [(ngModel)]="teamName" [ngModelOptions]="{ debounce: 200 }" vbUiTextInput [typeahead]="rooms" (typeaheadOnSelect)="selectTeam($event)" typeaheadOptionField="title"/>
</div>
<p class="help-block" [hidden]="!(status.processing)" [ngClass]="styles.warning">
	<vb-loading-spinner [hidden]="!(status.processing)" [size]="'mini'"></vb-loading-spinner>
	{{ 'Media_Videos_Webex_Teams_LoadingMessage' | translate }}
</p>
<p class="help-block" [hidden]="!(status.loadRoomsError)" [ngClass]="styles.error">
	<span class="glyphicons warning_sign"></span>
	{{ 'Media_Videos_Webex_Teams_LoadingError' | translate }}
	<a class="theme-accent-link" [ngClass]="styles.lightText" (click)="loadRooms()">
		{{ 'Try_Again' | translate }}
	</a>
</p>
<p class="help-block" [hidden]="!(sparkAuthenticationRequired)">
	{{ 'Media_Videos_Sharing_Spark_LogIn' | translate }}
	<a class="theme-accent-link" href="/spark/oauth/authorize" target="_blank">
		{{ 'Media_Videos_Sharing_Spark_LogInLink' | translate }}
	</a>
</p>
<p class="help-block" [hidden]="!rooms || rooms.length">
	{{ 'Media_Videos_Sharing_Spark_NoRooms' | translate }}
</p>

<vb-confirmation-dialog #sipAddressErrorDialog [title]="'Error' | translate" [message]="'Media_Videos_Webex_Teams_SipAddress_Message_Error' | translate" [actionText]="'Ok' | translate">
</vb-confirmation-dialog>
