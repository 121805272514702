import { APP_BOOTSTRAP_LISTENER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateLoader, TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';

import { HttpParamsInterceptorProvider } from './HttpParamsInterceptor.Provider';
import { VbTranslateParser } from './VbTranslateParser';
import { VbXsrfCookieExtractorProvider } from './VbXsrfCookieExtractor.Provider';
import { createTranslateHttpLoader } from './TranslateHttpLoader.Provider';

const localeId: string = BootstrapContext.bcp47Locale;

//Import on lazy loaded root modules
@NgModule({
	imports: [
		HttpClientModule
	],
	providers: [
		HttpParamsInterceptorProvider,
		VbXsrfCookieExtractorProvider, // faciliates our dyanmic cookie naming
	]
})
export class AngularRootModuleDeps {}

//import on app root module
@NgModule({
	imports: [
		AngularRootModuleDeps,
		BrowserAnimationsModule,
		BrowserModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateHttpLoader,
				deps: [
					HttpClient
				]
			},
			parser: {
				provide: TranslateParser,
				useClass: VbTranslateParser
			},
			useDefaultLang: false
		})
	],
	providers: [{
		provide: LOCALE_ID,
		useValue: localeId
	},
	{
		provide: APP_BOOTSTRAP_LISTENER,
		deps: [TranslateService],
		useFactory: onAppBootstrap,
		multi: true
	}]
})
export class AngularAppBootstrapDepsModule {}


function onAppBootstrap(translateService: TranslateService) {
	return () => translateService.use(BootstrapContext.language);
}
