<form #form="ngForm" class="form-inline recording-menu-component-form" novalidate (submit)="preventMultipleRecordings()" autocomplete="off">
	<section class="link-url-wrapper group container-fluid" [hidden]="vcRecord.isDisabled">
		<div class="row padding-bottom-10">
			<div class="col-xs-12">
				<vb-ui-form-group>
					<div vbUiRadioBtnGroup [attr.aria-label]="'Event_Webcast_Video_RecordingSource' | translate " uiControl>
						<button name="videosource" type="button" *ngIf="vciEnabled" [(ngModel)]="selectedRecordingSource" [vbUiRadioBtn]="WebcastVideoSource.SIP_ADDRESS">
							{{ 'Event_Webcast_Video_Address' | translate }}
						</button>
						<button name="videosource" type="button" *ngIf="webexTeamsEnabled" [(ngModel)]="selectedRecordingSource" [vbUiRadioBtn]="WebcastVideoSource.WEBEX">
							{{ 'Media_Videos_Webex_Teams' | translate }}
						</button>
						<!-- decided not to release Microsoft Teams from upload tray in 7.38
						when things are ready just remove false from below ng-if -->
						<button name="videosource" type="button" *ngIf="false && vciMsTeamsEnabled" [(ngModel)]="selectedRecordingSource" [vbUiRadioBtn]="WebcastVideoSource.MSTEAMS">
							{{ 'Microsoft_Teams' | translate }}
						</button>
						<button name="videosource" type="button" *ngIf="zoomEnabled" [(ngModel)]="selectedRecordingSource" [vbUiRadioBtn]="WebcastVideoSource.ZOOM">
							{{ 'Zoom_Meeting' | translate }}
						</button>
					</div>
				</vb-ui-form-group>
			</div>
		</div>
		<div class="row" *ngIf="vciEnabled && selectedRecordingSource === WebcastVideoSource.SIP_ADDRESS">
                <div class="col-xs-12">
                    <label for="vdSipURL">{{ 'Media_Recording_Form_Video_Label' | translate }}</label>
                    <input autocomplete="off" #email="ngModel" autofocus required class="form-control required" name="sipURL" id="vdSipURL" placeholder="{{ 'Media_Recording_Form_Video_PlaceholderURI' | translate }}" type="email" [(ngModel)]="vcRecord.sipUrl" [typeaheadMinLength]="0" typeaheadOptionField="sipAddress" typeaheadWaitMs="250" container="body" [typeahead]="sipAddresses$" [typeaheadAsync]="true" vbEmail/>
                </div>
			<div class="row has-error control-label" vbUiErrorMessage uiValidation [hidden]="!(recordingHoursNotAvailable)">
                <span class="util-white-space-normal theme-accent-txt">
                    {{ 'Media_Recording_HoursNotAvailable' | translate }}
                </span>
            </div>
		</div>

		<div class="row" *ngIf="webexTeamsEnabled && (selectedRecordingSource === WebcastVideoSource.WEBEX)">
			<div class="col-xs-12">
				<label for="webexTeam">{{ 'Media_Videos_Webex_Teams_Label' | translate }}</label>
				<webex-teams name="webexTeam" [(ngModel)]="webexTeam" required>
				</webex-teams>
			</div>
		</div>

		<div class="row" *ngIf="vciMsTeamsEnabled && selectedRecordingSource === WebcastVideoSource.MSTEAMS">
			 <div class="col-xs-12">
				 <label for="microsoftTeamsMeetingUrl" class="util-uppercase">{{ 'Microsoft_Teams_Meeting_Url_Label' | translate }}</label>
				 <textarea autofocus required rows="4" class="form-control required" name="microsoftTeamsMeetingUrl" id="microsoftTeamsMeetingUrl" placeholder="{{ 'Microsoft_Teams_Meeting_Url' | translate }}" [(ngModel)]="vcRecord.microsoftTeamsMeetingUrl" vbUrl vbUiTextArea></textarea>
			</div>
			<div class="row has-error control-label" vbUiErrorMessage uiValidation [hidden]="!(recordingHoursNotAvailable)">
				<span class="util-white-space-normal theme-accent-txt">
					{{ 'Media_Recording_HoursNotAvailable' | translate }}
				</span>
			</div>
		</div>

		<div class="row" *ngIf="zoomEnabled && selectedRecordingSource === WebcastVideoSource.ZOOM">

			<ng-template #zoomMeetingTemplate let-model="item">
				<span>{{ ZoomService.formatMeeting(model) }}</span>
			</ng-template>

            <div class="col-xs-12">
            	<label for="vbZoomMeetingId">{{ 'Event_Edit_ZoomMeetingIdHelpText' | translate }}</label>
				<input type="text" required vbZoomMeetingId autocomplete="off" class="form-control required" name="zoomMeetingId" id="vbZoomMeetingId" placeholder="{{ 'Zoom_MeetingId' | translate }}" [(ngModel)]="zoomMeetingId" (typeaheadLoading)="ZoomService.loading" [typeahead]="zoomMeetings" [typeaheadItemTemplate]="zoomMeetingTemplate" typeaheadOptionField="id" container="body" [typeaheadMinLength]="0"/>
			</div>
            <div class="row has-error control-label" vbUiErrorMessage uiValidation [hidden]="!(recordingHoursNotAvailable)">
                <span class="util-white-space-normal theme-accent-txt">
                    {{ 'Media_Recording_HoursNotAvailable' | translate }}
                </span>
			</div>
		</div>

		<div class="row margin-bottom-15 padding-bottom-20" *ngIf="!integrationEnabled">
			<div class="col-xs-12">
				<span class="util-white-space-normal">
					{{ 'Media_Recording_IntegrationNotAvailable' | translate }}
				</span>
			</div>
		</div>

		<div class="row recording-menu-component-footer" *ngIf="integrationEnabled">
			<div class="col-xs-5">
				<div [hidden]="!(zoomEnabled && selectedRecordingSource === WebcastVideoSource.ZOOM)">
					<label for="vdZoomPassword">{{ 'Event_Edit_ZoomMeetingPasswordHelpText' | translate }}</label>
				</div>
			</div>
			<div class="col-xs-4 zoom-password">
				<div [hidden]="!(zoomEnabled && selectedRecordingSource === WebcastVideoSource.ZOOM)">
					<input autocomplete="off" class="form-control" name="zoomMeetingPassword" id="vdZoomMeetingPassword" placeholder="{{ 'Zoom_MeetingPassword' | translate }}" [(ngModel)]="zoomMeetingPassword" pattern="^\d{1,10}$"/>
				</div>
			</div>
			<div class="col-xs-3">
				<button type="submit" class="pull-right recording-button" [disabled]="form.invalid" vbUiBtnPrimary>
						<span class="theme-accent-txt">
							{{ 'DeviceAction_StartRecording' | translate }}
						</span>
				</button>
			</div>
		</div>
	</section>
	<section class="link-url-wrapper group container-fluid ng-hide" [hidden]="!(vcRecord.isDisabled && integrationEnabled)" layout="flex" flex-align="center" flex-justify="center">
		<vb-loading-spinner [block]="false" [size]="'large'" [msg]="'Media_Recording_Connecting' | translate">
		</vb-loading-spinner>
	</section>
</form>
