import { Component, Input } from '@angular/core';

import { BaseReportKpiComponent } from 'rev-shared/ui/reports/BaseReportKpi.Component';

@Component({
	selector: 'video-reports-kpis',
	templateUrl: './VideoReportsKpis.Component.html'
})
export class VideoReportsKpisComponent extends BaseReportKpiComponent {
	@Input() public data: any;
	@Input() public isLive: boolean;
}
