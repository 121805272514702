import {
	Directive,
	HostListener,
	OnInit
} from '@vbrick/angular-ts-decorators';

import {
	IAttributes,
	IAugmentedJQuery,
	ICompiledExpression,
	IParseService
} from 'angular';

/***
 * Example:  <input type="text" ng-model="..." vb-alternate-submit="expression"
 *
 * Blocks an input element from submitting form, and allows an alternate expression.
 * Intended to be used in places where an input in a form is not part of the form's data model. Submitting while on the input should perform some other action.
 ***/
@Directive({
	selector: '[vb-alternate-submit]'
})
export class VbAlternateSubmitDirective implements OnInit {
	private action: ICompiledExpression;

	constructor(
		private $attrs: IAttributes,
		private $element: IAugmentedJQuery,
		private $parse: IParseService
	) {
		'ngInject';
	}

	public ngOnInit(): void {
		this.action = this.$parse(this.$attrs.vbAlternateSubmit);
	}

	@HostListener('keydown', ['$event'])
	private onKeyDown($event: JQueryKeyEventObject): boolean {
		if ($event.keyCode === 13) {
			const scope = this.$element.scope();

			this.action(scope, { $event });

			$event.preventDefault();

			scope.$apply();

			return false;
		}
	}
}
