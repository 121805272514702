import {
	Input,
	Directive,
	OnInit
} from '@vbrick/angular-ts-decorators';

import { INgModelController } from 'angular';

import { AccessControl } from 'rev-shared/media/MediaConstants';

enum MediaType {
	Webcast = 'Webcast',
	Video = 'Video'
}
/**
 * This Diretcive helps to validate public access of video and webcast. it accounts the global level flag
 * and make the model valid/invalid...
 */
@Directive({
	selector: '[prohibit-public-access-validator]',
	bindToController: true,
	require: {
		ngModel: 'ngModel'
	}
})
export class ProhibitPublicAccessValidatorDirective implements OnInit {
	@Input('<? prohibitPublicAccessValidator') public mediaType: MediaType;
	@Input() public mediaFeatures: any;

	private ngModel: INgModelController;

	public ngOnInit(): void {
		this.ngModel.$validators.isProhibitPublicAccess = modelValue => {
			const isValid = this.validate(modelValue);
			if(!isValid) {
				this.ngModel.$setDirty();
			}
			return isValid;
		};
	}

	private validate(modelValue): boolean {
		const value: AccessControl = modelValue && modelValue.accessControl ?
			modelValue.accessControl :
			modelValue;
		const isPublic: boolean = value === AccessControl.Public;
		const mediaTypeAccess: boolean = this.mediaType === MediaType.Webcast
			? this.mediaFeatures.enablePublicWebcastAccess
			: this.mediaFeatures.enablePublicVideoAccess;
		const isProhibitPublicAccess: boolean = isPublic && !mediaTypeAccess;

		return !isProhibitPublicAccess;
	}
}
