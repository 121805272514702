import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
	defaultColors,
	defaultFont,
	defaultHeaderColors,
	defaultLogo
} from './Defaults';

import {
	BrandingSettings,
	FeaturedVideosSettings,
	HomepageTemplateType,
	ThemeSettings
} from 'rev-portal/branding/BrandingSettings.Contract';

import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { ImageService } from 'rev-shared/media/Image.Service';
import { PushService } from 'rev-shared/push/PushService';


@Injectable({
	providedIn: 'root'
})
export class BrandingService {
	constructor(
		private ImageService: ImageService,
		private PushService: PushService,
		private http: HttpClient
	) {}

	public getBranding(accountId: string, allTeams: boolean): Promise<BrandingSettings> {
		return this.http.get<BrandingSettings>(`/network/accounts/${accountId}/branding-settings`, {
			params: {
				accountId,
				allTeams: '' + allTeams
			}
		})
			.toPromise()
			.then(settings => this.readBrandingSettings(settings));
	}

	public saveBranding(accountId: string, brandingSettings: BrandingSettings): Promise<void>{
		return this.PushService.dispatchCommand('network:SaveBrandingSettings', {
			accountId,
			themeSettings: this.getThemeSettings(brandingSettings.themeSettings),
			headerSettings: brandingSettings.headerSettings,
			featuredVideosSettings: this.getFeaturedVideoSettings(brandingSettings.featuredVideosSettings),
			homepageSettings: brandingSettings.homepageSettings
		});
	}

	public readBrandingSettings(settings: BrandingSettings): BrandingSettings {
		const theme = settings.themeSettings;
		theme.fontUri = theme.fontUri || defaultFont.url;
		theme.logoUri = theme.logoUri || defaultLogo.url;
		theme.accentColor = theme.accentColor || defaultColors.accentColor;
		theme.accentFontColor = theme.accentFontColor || defaultColors.accentFontColor;
		theme.primaryColor = theme.primaryColor || defaultColors.primaryColor;
		theme.primaryFontColor = theme.primaryFontColor || defaultColors.primaryFontColor;

		const header = settings.headerSettings;
		header.backgroundColor = header.backgroundColor || defaultHeaderColors.background;
		header.fontColor = header.fontColor || defaultHeaderColors.font;

		const homepage = settings.homepageSettings;
		homepage.homepageTemplateType = homepage.homepageTemplateType || HomepageTemplateType.Classic;

		settings.isLoaded = true;
		return settings;
	}

	private getThemeSettings(themeSettings: ThemeSettings): ThemeSettings {
		return {
			accentColor: themeSettings.accentColor,
			accentFontColor: themeSettings.accentFontColor,
			fontFileName: themeSettings.fontFileName,
			fontId: themeSettings.fontId,
			logoImageId: themeSettings.logoImageId,
			primaryColor: themeSettings.primaryColor,
			primaryFontColor: themeSettings.primaryFontColor
		};
	}

	private getFeaturedVideoSettings(featuredVideoSettings: FeaturedVideosSettings): FeaturedVideosSettings {
		return {
			autoscrollDelay: featuredVideoSettings.autoscrollDelay,
			backgroundColor: featuredVideoSettings.backgroundColor,
			backgroundImageId: featuredVideoSettings.backgroundImageId,
			featuredCarouselType: featuredVideoSettings.featuredCarouselType
		};
	}

	public uploadLogo(file: FileWrapper): Promise<string> {
		return this.ImageService.uploadImage('AccountLogo', file);
	}

	public uploadFont(file: FileWrapper): Promise<string> {
		return this.getFontUploadUri()
			.then(result => this.ImageService.uploadFile(file, result, 'BrandingFontUploadFinished', 'BrandingFontUploadFailed'));
	}

	public uploadBackgroundImage(file: FileWrapper): Promise<string> {
		return this.ImageService.uploadImage('FeaturedVideosBackground', file);
	}

	private getFontUploadUri(): Promise<any> {
		return this.PushService.dispatchCommand('network:AddBrandingFontToAccount', {}, 'BrandingFontAddedToAccount')
			.then(result => ({
				id: result.message.fontId,
				uploadUri: result.message.uploadUri
			}));
	}
}
