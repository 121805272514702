import { TransitionService } from '@uirouter/angularjs';
import { IWindowService } from 'angular';
import { IVBrickStateDeclaration } from 'rev-shared/ts-utils/IVBrickStateDeclaration';

export function StateTransitionScrollToTop($transitions: TransitionService, $window: IWindowService): void {
	$transitions.onSuccess({}, transition => {
		const toStateDeclaration: IVBrickStateDeclaration = transition.to() as IVBrickStateDeclaration;

		if (toStateDeclaration.scrollToTop) {
			$window.scrollTo(0, 0);
		}
	});
}
