import { NgModule } from '@vbrick/angular-ts-decorators';
import angular, { auto } from 'angular';

import { VbTranslationStrings } from './VbTranslationStrings.Directive';

//This module doesn't need to be migrated. Use the translate service instead of directive

const directiveModule = angular.module('vbrick.shared.ui.translations.directive', [])
	.directive('vbTranslationStrings', ($injector: auto.IInjectorService) => {
		'ngInject';
		return $injector.instantiate(VbTranslationStrings);
	})
	.name;

@NgModule({
	id: 'vbrick.shared.ui.translations',
	imports: [
		directiveModule
	]
})
export class TranslationsModule {}
